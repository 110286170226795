import React, {Component} from 'react';
import {UserContext} from "../../../core/UserContext";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {
	Divider,
	ListItem,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	ListItemAvatar, Checkbox, FormControlLabel,
} from "@mui/material";
import List from "@mui/material/List";
import Badge2 from "../../../res/img/badge_2.png";
import GPayButton from "../../../res/img/gpay-button.svg";
import Grid from "@mui/material/Grid";
import FavoriteIcon from '@mui/icons-material/Favorite';
import moment from "moment";
import * as Sentry from "@sentry/react";
import Skeleton from '@mui/material/Skeleton';
import { Alert } from '@mui/material';
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	largeIcon: {
		width: 100,
		height: 100,
	},
	checkboxGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class DonationDialog extends Component {

	/**
	 * @param props Containing:
	 *      'isOpen': Says whether this dialog is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Is it open?
			 */
			isOpen: false,
			/**
			 * Used as a component 'isLoading' indicator.
			 */
			isDonationsLoading: true,
			/**
			 * The fetched donations list.
			 */
			donations: [],
			/**
			 * The supporter name to submit.
			 */
			supporterDisplayName: '',
			/**
			 * Used as a component 'isLoading' indicator.
			 */
			isProductsLoading: false,
			/**
			 * The products you can buy (the supporter product/s).
			 */
			products: [],
			/**
			 * Any error message to show for the payment.
			 */
			paymentErrorMessage: null,
			/**
			 * Any warning message to show for the payment.
			 */
			paymentWarningMessage: null,
			/**
			 * Any success message to show for the payment.
			 */
			paymentSuccessMessage: null,
			/**
			 * The checked product ID in the checkbox form.
			 */
			checkedProductId: 'supporter_badge_1_new',
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		if (this.state.isOpen !== Boolean(nextProps.isOpen)) {
			this.setState({
				isOpen: Boolean(nextProps.isOpen),
				supporterDisplayName: this.context.user.name ?? '',
				paymentErrorMessage: null,
				paymentWarningMessage: null,
				paymentSuccessMessage: null,
			});
			
			if (nextProps.isOpen) {
				this.refresh(); // Only refresh when it was closed before and now its opening.
			}
		}
	}

	refresh = () => {
		this.setState({
			paymentErrorMessage: null,
			paymentWarningMessage: null,
			paymentSuccessMessage: null,
		});
		this.fetchPurchaseProducts();
		this.fetchDonationsList();
		this.scanForUnacknowledgedPayments();
	}
	
	scanForUnacknowledgedPayments = async () => {
		if ('getDigitalGoodsService' in window) {
			let service;
			try {
				service = await window.getDigitalGoodsService('https://play.google.com/billing');
			} catch (e) {
				Sentry.captureException(e);
				return;
			}

			if (service) {
				// Gets all the purchases made by current user. See interface at https://github.com/WICG/digital-goods/blob/main/explainer.md#api-v20
				const existingPurchases = await service.listPurchases();
				for (const p of existingPurchases) {
					try {
						// Try to acknowledge any payments if you can.
						const isSuccess = await this.attemptPurchaseAcknowledgment(p.itemId, p.purchaseToken);
						if (isSuccess) {
							this.setState({
								paymentSuccessMessage: "Donation successfully made. Thank you!",
								paymentWarningMessage: null,
								paymentErrorMessage: null,
							});
							break;
						}
					} catch (e) {}
				}
			}
		}
	}
	
	resolveDisplayName = () => {
		let displayName;
		if (UserLocalStorage.get('paymentDisplayName')) { // First check if one was saved.
			displayName = UserLocalStorage.get('paymentDisplayName');
		} else if (this.state.supporterDisplayName) {
			displayName = this.state.supporterDisplayName;
		} else {
			displayName = this.context.user.name;
		}
		return displayName;
	}
	
	attemptPurchaseAcknowledgment = async (productName, purchaseToken) => {
		let isSuccess = await fetch(UrlBuilder.playPayments.acknowledge(productName, purchaseToken, this.resolveDisplayName()), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => response.status === 200) // If 200 then its success.
			.catch(res => {return false;})

		if (isSuccess) {
			let user = await fetch(UrlBuilder.user.currentUserApi())
				.then(response => response.status === 200 ? response.json() : null)
				.catch(res => {
					return null;
				});

			this.context.setUser(user);
			this.refresh();
			UserLocalStorage.remove('paymentDisplayName'); // Success? then remove this.
			return true;
		}
		
		return false;
	}

	fetchDonationsList = async () => {
		this.setState({
			isDonationsLoading: true
		});

		await fetch(
			UrlBuilder.donations.donationsApi()
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					isDonationsLoading: false,
					donations: response['data'] ?? [],
				});
			});
	}

	fetchPurchaseProducts = async () => {
		if ('getDigitalGoodsService' in window) {
			let service;
			try {
				service = await window.getDigitalGoodsService('https://play.google.com/billing');
			} catch (e) {
				Sentry.captureException(e);
				return;
			}

			if (service) {
				this.setState({
					isProductsLoading: true
				});

				let products = [];
				
				// Gets product details: https://github.com/WICG/digital-goods/blob/main/explainer.md#api-v20
				const fetchedProducts = await service.getDetails(['supporter_badge_1_new', 'supporter_badge_2', 'supporter_subscription', 'supporter_subscription_2']).catch(e => {
					Sentry.captureException(e);
					return [];
				});

				fetchedProducts.forEach(product => {
					// Format the price according to the user locale.
					const localizedPrice = new Intl.NumberFormat(
						navigator.language,
						{style: 'currency', currency: product.price.currency}
					).format(product.price.value);

					// Render the price to the UI.
					products.push({
						id: product.itemId,
						title: product.title,
						price: localizedPrice,
						description: product.description,
					});
				});
				
				this.setState({
					isProductsLoading: false,
					products: products,
				});
			}
		}
	}

	/**
	 * https://developer.chrome.com/docs/android/trusted-web-activity/receive-payments-play-billing/
	 */
	attemptPayment = async (productName = null) => {
		if (this.state.supporterDisplayName.trim() === '') {
			this.setState({
				supporterDisplayNameIsEmpty: true,
			});
			return;
		}
		
		productName = productName ?? 'supporter_badge_1_new';
		
		const paymentMethodData = [{
			supportedMethods: 'https://play.google.com/billing',
			data: {
				sku: productName,
			}
		}];

		const paymentDetails = {
			total: {
				label: `Total`,
				amount: {currency: `USD`, value: `0`}
			}
		};
		
		const request = new PaymentRequest(paymentMethodData, paymentDetails);
		
		let paymentMade = false;

		try {
			Sentry.captureMessage("1 - Payment showing initiated for " + this.context.user.email);
			
			const paymentResponse = await request.show();
			
			Sentry.captureMessage("2 - Payment response obtained for " + this.context.user.email);

			// We got this far if the payment was made, otherwise an error would've been thrown.
			const {token: purchaseToken} = paymentResponse.details;

			Sentry.captureMessage("3 - Payment made for " + this.context.user.email + " with token: " + purchaseToken, {
				extra: {
					response: JSON.stringify(paymentResponse),
				}
			});
			
			paymentMade = true;
			
			// Now that the payment was made, we acknowledge the payment with the backend.
			const isAcknowledgmentSuccess = await this.attemptPurchaseAcknowledgment(productName, purchaseToken);

			if (isAcknowledgmentSuccess) {
				Sentry.captureMessage("4 - Payment acknowledged for " + this.context.user.email + " with token: " + purchaseToken);

				// Optional: tell the PaymentRequest API the validation was
				// successful. The user-agent may show a "payment successful"
				// message to the user.
				const paymentComplete = await paymentResponse.complete('success');
				Sentry.captureMessage("5 - Payment response completed for " + this.context.user.email);

				this.setState({
					paymentSuccessMessage: "Donation successfully made. Thank you!",
					paymentWarningMessage: null,
					paymentErrorMessage: null,
				});
				
				return;
			} else {
				Sentry.captureMessage("4 - Payment acknowledged failed for " + this.context.user.email + " with token: " + purchaseToken);
				const paymentComplete = await paymentResponse.complete('fail');
			}

		} catch(e) {
			Sentry.captureMessage(`${e.name}: ${e.message}`);
			// The purchase failed, and we can handle the failure here. AbortError
			// usually means a user cancellation
			try {
				await request.abort();
			} catch (e) {}
		}

		if (paymentMade) {
			this.setState({
				paymentSuccessMessage: null,
				paymentWarningMessage: "Your payment was made but is still being processed by Google Play. Please check back here in a few minutes and your Supporter's Badge should be ready. If you have any questions please contact us at myworkinprogress.app@gmail.com or leave a feedback message.",
				paymentErrorMessage: null,
			});
			UserLocalStorage.set('paymentDisplayName', this.state.supporterDisplayName);
		} else {
			this.setState({
				paymentSuccessMessage: null,
				paymentWarningMessage: null,
				paymentErrorMessage: "Your payment failed. Please try again. If you have any questions please contact us at myworkinprogress.app@gmail.com or leave a feedback message.",
			});
		}
	}
	
	updateSupporterNameText = (event) => {
		this.setState({
			supporterDisplayName: event.target.value,
			supporterDisplayNameIsEmpty: false,
		});
	}

	handleCheckboxChange = (event, value) => {
		this.setState({ checkedProductId: value });
	};

	renderCheckboxes = () => {
		const checkboxes = [];

		if (this.state.products[0]) {
			checkboxes.push({id: 'supporter_badge_1_new', label: 'One-time payment -  ' + this.state.products[0].price});
		}

		if (this.state.products[1]) {
			checkboxes.push({id: 'supporter_badge_2', label: 'One-time payment -  ' + this.state.products[1].price});
		}
		
		if (this.state.products[2]) {
			checkboxes.push({ id: 'supporter_subscription', label: 'Monthly subscription - ' + this.state.products[2].price});
		}
		
		if (this.state.products[3]) {
			checkboxes.push({ id: 'supporter_subscription_2', label: 'Monthly subscription - ' + this.state.products[3].price});
		}

		return checkboxes.map((checkbox) => (
			<FormControlLabel
				key={checkbox.id}
				control={
					<Checkbox
						checked={this.state.checkedProductId === checkbox.id}
						onChange={(event) => this.handleCheckboxChange(event, checkbox.id)}
					/>
				}
				label={checkbox.label}
			/>
		));
	};

	render () {
		const {classes} = this.props;

		return (
            <Dialog fullWidth open={this.state.isOpen} onClose={this.props.closeSelfFunc} TransitionComponent={Transition}>
                <DialogTitle>Support My Work in Progress</DialogTitle>
                <DialogContent>
                    
                    <Typography gutterBottom>
                        Supporting the app encourages further development of the app and helps pay for server costs.
                    </Typography>

                    <br/>

	                {this.context.user.isSupporter &&
		                <>
			                <Grid container alignItems='center' justifyContent="center" direction="column">
				                <Grid xs item>
					                <img src={Badge2} alt='Supporter Badge' width={250}/>
				                </Grid>
				                <br/>
				                <Grid xs item>
					                <Typography variant='h2' style={{fontSize: 24}} gutterBottom>
						                You are a supporter.
					                </Typography>
				                </Grid>
				                <Grid xs item>
					                <Typography variant='h2' style={{fontSize: 24}}>
						                Thank you!
					                </Typography>
				                </Grid>
			                </Grid>
			                <br/>
		                </>
	                }
	                
                    {this.context.user.isSupporter ?
	                    <Typography gutterBottom>You may donate again if you wish!</Typography>
	                    :
	                    <Typography gutterBottom>
		                    You can become a Supporter by purchasing the Supporter Badge. Besides being awesome, in return you join the Supporters List and get a shiny Supporter's badge!
	                    </Typography>
                    }

                    <br/>
                        
                    {this.state.isProductsLoading ?
                        <Skeleton height={100} />
                        :
                            ('getDigitalGoodsService' in window && this.state.products.length > 0) ?
                                <Grid container alignItems='center' direction="column" spacing={2}>
	                                <Grid item>
		                                <TextField variant='outlined' color='primary' label="Your supporter name" defaultValue={this.state.supporterDisplayName} onChange={this.updateSupporterNameText} error={this.state.supporterDisplayNameIsEmpty} helperText={this.state.supporterDisplayNameIsEmpty ? 'Name is required to show on the Supporters List' : null}/>
	                                </Grid>
	                                <Grid item>
		                                <Grid container alignItems='center' direction="column">
			                                <Grid item>
				                                <div className={classes.checkboxGroup}>
					                                {this.renderCheckboxes()}
				                                </div>
			                                </Grid>
			                                <br/>
			                                <Grid item>
				                                <img src={GPayButton} alt='Pay with GPay Button' width={215} onClick={() => this.attemptPayment(this.state.checkedProductId)}/>
			                                </Grid>
			                                <Grid item>
				                                <Typography variant='caption'>Secure payment done through Google Play Billing. Subscriptions can be cancelled anytime through Google Play.</Typography>
			                                </Grid>
		                                </Grid>
	                                </Grid>
                                </Grid>
                                :
                                <Grid container alignItems='center' justifyContent="center" direction="column">
                                    <Typography>
                                        Making a donation is unfortunately not possible in this version of the app.
                                    </Typography>
                                </Grid>
                    }

                    {this.state.paymentErrorMessage ? <> <br/> <Alert severity="error">{this.state.paymentErrorMessage}</Alert> </> : null}
                    {this.state.paymentWarningMessage ? <> <br/> <Alert severity="info">{this.state.paymentWarningMessage}</Alert> </> : null}
                    {this.state.paymentSuccessMessage ? <> <br/> <Alert severity="success">{this.state.paymentSuccessMessage}</Alert> </> : null}

                    <br/>
                    <Divider light/>
                    <br/>

                    <Typography variant='h5' style={{fontSize: 24}}>
                        Supporters List:
                    </Typography>
                    
                    {this.state.isDonationsLoading ?
                        <>
                            <Skeleton height={50} />
                            <Skeleton height={50} />
                            <Skeleton height={50} />
                        </>
                        : this.state.donations.length === 0 ?
                            <Typography variant='caption'>
                                No supporters yet. Become the first one!
                            </Typography> : null
                    }
                    
                    <List>
                        {this.state.donations.map(supporter => 
                            <ListItem dense key={supporter.displayName}>
                                <ListItemAvatar>
                                    <FavoriteIcon/>
                                </ListItemAvatar>
                                <ListItemText primary={supporter.displayName} secondary={'On ' + moment(supporter.dateCreated).format('L')}/>
                            </ListItem>
                        )}
                    </List>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeSelfFunc}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
	}
}

DonationDialog.contextType = UserContext;

export default withStyles(useStyles)(DonationDialog);