export class DatePrettifier {
	/**
	 * @param {Date} date
	 * @returns {string}
	 */
	static prettifyLastTrained(date) {
		let standardDate = new Date(date.valueOf());
		standardDate.setHours(0, 0, 0); // Set this to 'really' consider the date as 'one' day when taking the difference.

		const todayDate = new Date(); // Initialize as today/now.
		todayDate.setHours(0, 0, 0); // Set this to 'really' consider the date as 'one' day when taking the difference.
		const dateDifference = standardDate - todayDate; // Get the difference.

		const oneDay = 86400000; // in milliseconds
		const daysDifference = Math.round(dateDifference / oneDay);

		if (daysDifference === 0) {
			return "Today";
		}

		if (daysDifference === -1) {
			return "Yesterday";
		}

		if (daysDifference === 1) {
			return "Tomorrow";
		}

		if (daysDifference === 2) {
			return "Day after tomorrow";
		}

		return daysDifference + " days (" + date.toLocaleDateString() + ")";
	}
}