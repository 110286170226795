import {UserLocalStorage} from "../storage/UserLocalStorage";
import {ExerciseTypeAttribute} from "../models/ExerciseTypeAttribute";

export class UnitPrettifier {
	static kg = 1;
	static lbs = 2;
	
	/**
	 * @returns {string}
	 */
	static weight(weight = null) {
		const user = UserLocalStorage.get('user');
		
		if (user && user.preferredUnitSystemId === UnitPrettifier.lbs) {
			if (weight) {
				return weight + ' lbs';
			}
			return 'lbs';
		}

		if (weight) {
			return weight + ' kg';
		}
		
		return 'kg';
	}
	
	/**
	 * @returns {string}
	 */
	static distance(distance = null) {
		const user = UserLocalStorage.get('user');
		
		if (user && user.preferredUnitSystemId === UnitPrettifier.lbs) {
			if (distance) {
				return distance + ' mi';
			}
			return 'mi';
		}

		if (distance) {
			return distance + ' km';
		}
		
		return 'km';
	}

	static forAttribute(attribute) {
		if (attribute.key === ExerciseTypeAttribute.Weight) {
			return UnitPrettifier.weight();
		}
		
		if (attribute.key === ExerciseTypeAttribute.Distance) {
			return UnitPrettifier.distance()
		}
		
		return '';
	}
}