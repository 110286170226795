import React, {useEffect, useState} from 'react';
import { useTheme, Typography, Avatar, Card, CardHeader, Divider, CardContent, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {Bar} from "react-chartjs-2";
import {useNavigate} from "react-router";

const useStyles = makeStyles(() => ({
	root: {}
}));

const LastWeekWorkoutsCard = (props) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const options = {
		cornerRadius: 20,
		layout: {padding: 0},
		legend: {display: false, position: 'bottom'},
		maintainAspectRatio: false,
		borderWidth: 0,
		responsive: true,
		scales: {
			xAxes: [
				{
					barThickness: 12,
					maxBarThickness: 10,
					barPercentage: 0.5,
					categoryPercentage: 0.5,
					ticks: {
						fontColor: theme.palette.text.secondary
					},
					gridLines: {
						display: false,
						drawBorder: false
					}
				}
			],
			yAxes: [
				{
					ticks: {
						fontColor: theme.palette.text.secondary,
						beginAtZero: true,
						min: 0
					},
					gridLines: {
						borderDash: [2],
						borderDashOffset: [2],
						color: theme.palette.divider,
						drawBorder: false,
						zeroLineBorderDash: [2],
						zeroLineBorderDashOffset: [2],
						zeroLineColor: theme.palette.divider
					}
				}
			]
		},
		tooltips: {
			backgroundColor: theme.palette.background.default,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: 'index',
			titleFontColor: theme.palette.text.primary
		}
	};

	const [allWorkouts, setWorkouts] = useState(props.workouts);

	useEffect(() => {
		setWorkouts(props.workouts);
	}, [props.workouts]);

	const findWorkoutsForDate = (date) => {
		let workouts = [];
		let amount = 0;
		for (const workout of allWorkouts) {
			if (moment(workout.dateTimeStarted).format('DD/MM/YYYY') === date) {
				workouts.push(workout);
			}
			amount++;
			if (amount > 14) { // Basically, we don't want to search too deep in the workouts because we only show the past 7 days anyway, and most likely anyway after 14 days are too far in the past so it's useless to check them. 14 is a bit of a number. It's an educated guess.
				break;
			}
		}
		return workouts;
	}

	const redirectToWorkoutDetail = (workout) => {
		navigate(UrlBuilder.workout.workoutDetailPage(workout.id));
	}

	let dates = [];
	
	let workoutsPerDate = [];
	
	let totalVolumePerDate = [];
	let volumeColors = [];
	for (let days = 6; days >= 0 ; days--) {
		let day = moment().subtract(days, 'day');
		dates.push(day);
		
		let workouts = findWorkoutsForDate(day.format('DD/MM/YYYY'));
		workoutsPerDate.push(workouts);
		
		let totalVolume = 0;
		for (const workout of workouts) {
			totalVolume += workout.totalVolume;
		}
		totalVolumePerDate.push(totalVolume);
		
		volumeColors.push(workouts.length > 0 ? (workouts[0].routines[0].color ?? ColorPicker.pick(workouts[0].routines[0].name)) : '#FFFFFF')
	}

	const data = {
		datasets: [
			{
				data: totalVolumePerDate,
				backgroundColor: volumeColors,
				label: 'Volume'
			}
		],
		labels: dates.map(date => date.format('ddd D MMM')),
	};
	
	return (
        <Card variant='outlined'>
            <CardHeader
                title={<Typography variant='h5' sx={{ fontSize: 21 }}>Last 7 Days</Typography>}
                style={{ paddingBottom: '12px', paddingTop: '12px' }}
            />
            <Divider/>
            <CardContent>
                <Grid container justifyContent='space-evenly' direction='row' wrap='nowrap'>
                    {dates.map((date, idx) => {
                        const workouts = workoutsPerDate[idx];

	                    let dateString = date.format('ddd');
	                    
	                    return <Grid item key={dateString}>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <IconButton size='small'>
                                        {workouts.length > 0 ?
                                            <Avatar style={{backgroundColor: volumeColors[idx]}} onClick={() => redirectToWorkoutDetail(workouts[0])}>
                                                {workouts[0].routines[0].name.charAt(0).toUpperCase()}
                                                {
                                                	workouts[1] ? 
		                                                '/' + workouts[1].routines[0].name.charAt(0).toUpperCase() : 
		                                                (
		                                                	workouts[0].routines[1] ? 
				                                                '/' + workouts[0].routines[1].name.charAt(0).toUpperCase() : 
				                                                null
		                                                )
                                                }
                                            </Avatar>
                                            :
                                            <Avatar style={{backgroundColor: '#c6c6c6'}}>
                                                -
                                            </Avatar>
                                        }
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption' align='center'>
                                        {idx === 6 ? 'today' : dateString}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
                
                <br/>
                <Divider/>
                <br/>
                
                <Box height={110} position="relative">
                    <Bar data={data} options={options}/>
                </Box>
            </CardContent>
        </Card>
    );
};

export default LastWeekWorkoutsCard;
