import React, {Component} from 'react';
import {UrlBuilder} from "../../../../core/url/UrlBuilder";

class GoalCardBase extends Component {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise you want to modify.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise,
			 */
			exercise: this.props.exercise,
			/**
			 * The goal to show.
			 */
			goal: this.props.goal,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			goal: nextProps.goal,
			exercise: nextProps.exercise,
		});
	}
	
	goToRoutine = (e) => {
		e.stopPropagation();
		// window.location.href = UrlBuilder.routine.routineDetailPage(this.state.goal.currentLog.routineId, this.state.goal.currentLog.exerciseId);
		this.props.navigate(UrlBuilder.redirect(UrlBuilder.routine.routineDetailPage(this.state.goal.currentLog.routineId, this.state.goal.currentLog.routineExerciseId)));
	}
}

export default GoalCardBase;