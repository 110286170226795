import React, {PureComponent} from 'react';
import {Route, Routes} from 'react-router-dom';

import {UrlBuilder} from "./core/url/UrlBuilder";
import {UserContext} from "./core/UserContext";

import Container from "@mui/material/Container";
import {ThemeProvider, StyledEngineProvider, keyframes} from '@mui/material/styles'
import { styled } from '@mui/system';
import withStyles from '@mui/styles/withStyles';
import Backdrop from "@mui/material/Backdrop";
import {PageDataContext} from "./core/PageDataContext";

// We purposefully load these non-lazily because we expect them to be frequently accessed.
import Home from "./components/home/Home";
import BottomNavMenu from "./components/core/BottomNavMenu";
import TopNavMenu from "./components/core/TopNavMenu";
import RoutinesOverview from "./components/routine/pages/RoutinesOverview";
import ExercisesOverview from "./components/exercise/pages/ExercisesOverview";
import RoutineDetail from "./components/routine/pages/RoutineDetail";
import theme from "./theme";
import workoutTheme from "./workoutTheme";
import darkTheme from "./darkTheme";
import CssBaseline from "@mui/material/CssBaseline";
import WorkoutCompleteBloop from "./components/workout/WorkoutCompleteBloop";
import Signin from "./components/user/Signin";
import Signup from "./components/user/Signup";
import WorkoutDetail from "./components/workout/pages/WorkoutDetail";
import MeOverview from "./components/me/pages/MeOverview";
import {UserLocalStorage} from "./core/storage/UserLocalStorage";
import ScrollToTop from "./core/util/ScrollToTop";
import WIPIcon from "./res/img/icon.png";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import {bounceInDown} from 'react-animations';
import Settings from "./components/user/Settings";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";
import Typography from "@mui/material/Typography";
import RefreshIcon from '@mui/icons-material/Refresh';
import Redirect from "./components/core/Redirect";
import Admin from "./components/admin/pages/Admin";
import PrivacyPolicyPage from "./components/help/pages/PrivacyPolicyPage";

const bounceAnimation = keyframes`${bounceInDown}`;
const PulseDiv = styled('div')(({ theme }) => ({
	animation: `1.2s ${bounceAnimation}`
}));

const useStyles = theme => ({
	hidden: {
		display: 'none',
	}
});

class App extends PureComponent {
	
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The user to be loaded universally in the app (through the provider/consumer).
			 */
			user: null,
			/**
			 * Page meta data loaded universally in the app (through the provider/consumer).
			 */
			pageData : {
				title: "My Work in Progress",
			},
			/**
			 * Initialize as false. This is usually triggered by the user himself manually.
			 */
			isWorkoutBloopOpen: false, 
			/**
			 * Initially we do set as loading, as we fetch the user until we have mounted.
			 */
			isLoading: true,
			/**
			 * Show the backdrop?
			 */
			showBackdrop: true,
		};
	}
	
	componentDidCatch(error, errorInfo) {
		localStorage.clear(); // Here we guess that something went wrong with the localStorage situation.
	}

	componentDidMount() {
		setTimeout(() => this.closeBackdrop(), 1000);
		this.initializeUser();
	}
	
	closeBackdrop = () => {
		this.setState({
			showBackdrop: false,
		});
	}

	initializeUser = async () => {
		let user = await fetch('/api/users/current')
			.then(response => response.status === 200 ? response.json() : null)
			.catch(res => {
				return null;
			})

		this.setUser(user);
	}

	setUser = (user) => {
		if (user) {
			UserLocalStorage.set('user', user)
			if (user.activeWorkout) {
				this.updateMetaThemeColor(user.hasDarkModeEnabled, true);
				this.setState({
					user: user,
					isLoading: false,
				});
			} else {
				this.updateMetaThemeColor(user.hasDarkModeEnabled, false);
				this.setState({
					user: user,
					isLoading: false,
					isWorkoutBloopOpen: false,
				});
			}
		} else {
			localStorage.clear();
			this.updateMetaThemeColor(false, false);
			this.setState({
				user: null,
				isLoading: false,
				isWorkoutBloopOpen: false,
			});
		}
		this.forceUpdate();
	}
	
	updatePageData = (data) => {
		this.setState({
			pageData: data,
		});
	}
	
	openActiveWorkoutBloop = (isOpen) => {
		if (isOpen && this.isWorkoutActive()) {
			this.setState({
				isWorkoutBloopOpen: true
			});
		} else {
			this.setState({
				isWorkoutBloopOpen: false
			});
		}
	}
	
	updateMetaThemeColor = (isDarkModeEnabled, isWorkoutActive) => {
		let metaThemeColor = document.querySelector("meta[name=theme-color]");
		metaThemeColor.setAttribute("content",
			isDarkModeEnabled ? darkTheme.palette.primary.main : (isWorkoutActive ? workoutTheme.palette.primary.main : theme.palette.primary.main)
		);
	}
	
	isWorkoutActive = () => {
		return this.state.user && this.state.user.activeWorkout;
	}
	
	isDarkModeEnabled = () => {
		return this.state.user && this.state.user.hasDarkModeEnabled;
	}

	renderErrorPage = () => {
		const {classes} = this.props;

		const refresh = () => {
			window.location.href = '/';
		}

		return (
            <Backdrop open sx={{backgroundColor: 'rgb(255,255,255)'}}>
                <PulseDiv>
                    <Grid container alignItems='center' justifyContent="center" direction="column">
                        <Grid xs item>
                            <RefreshIcon fontSize={'large'} onClick={refresh}/>
                        </Grid>
                        <Grid xs item>
                            <Typography variant={'h6'}>
                                Something went wrong.
                            </Typography>
                        </Grid>
                    </Grid>
                </PulseDiv>
            </Backdrop>
        );
	}
	
	getErrorDialogOptions = () => {
		return {
			user: {
				email: this.state.user ? this.state.user.email : null, 
				name: this.state.user ? this.state.user.name : null
			},
		}
	}

	handleErrors = () => {
		localStorage.clear(); // Here we guess that something went wrong with the localStorage situation.
	}

	renderApp = () => {
		const {classes} = this.props;

		return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={this.isDarkModeEnabled() ? darkTheme : (this.isWorkoutActive() ? workoutTheme : theme)}>
                    <CssBaseline/>
                    <UserContext.Provider value={{
                        user: this.state.user,
                        setUser: this.setUser,
                        isWorkoutBloopOpen: this.state.isWorkoutBloopOpen, // This could either be here or in the PageData. But I picked this because you need a user to have a workout, and you dont need a user for page-data.
                        openActiveWorkoutBloop: this.openActiveWorkoutBloop,
                    }}>
                    <PageDataContext.Provider value={{
                        data: this.state.pageData,
                        setPageData: this.updatePageData,
                    }}>
                    <div className={this.state.showBackdrop ? classes.hidden : null}>
                        <TopNavMenu/>

                        <ScrollToTop />

                        <Container>
                            <Routes>
                                <Route exact path = '/' element={<Home/>} />

                                <Route exact path={ UrlBuilder.exercise.exercisesOverviewPage() } element={ <ExercisesOverview/> } />

                                <Route exact path = { UrlBuilder.routine.routineOverviewPage() } element={ <RoutinesOverview/> } />
                                <Route path={UrlBuilder.routine.routineDetailPage()} element={<RoutineDetail/>} />

                                <Route path={UrlBuilder.user.settingsPage()} element={<Settings/>} />
                                <Route path={UrlBuilder.user.SignInPage()} element={<Signin/>} />
                                <Route path={UrlBuilder.user.SignUpPage()} element={<Signup/>} />
                                
                                <Route path={UrlBuilder.workout.workoutDetailPage()} element={<WorkoutDetail/>} />
                                
                                <Route path={UrlBuilder.me.mePage()} element={<MeOverview/>} />
                                
                                <Route path={UrlBuilder.admin.overviewPage()} element={<Admin/>} />

                                <Route path={UrlBuilder.user.privacyPage()} element={<PrivacyPolicyPage/>} />
	                            
                                <Route path={'/redirect'} element={<Redirect/>} />
                            </Routes >
                        </Container >
                        
                        <BottomNavMenu/>

                        <WorkoutCompleteBloop
                            isOpen={Boolean(this.isWorkoutActive() && this.state.isWorkoutBloopOpen)}
                            setUser={this.setUser}
                            closeSelfFunc={() => this.openActiveWorkoutBloop(false)}
                        />
                    </div>
                    </PageDataContext.Provider>
                    </UserContext.Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
	}
	
	render() {
		return (
            <Sentry.ErrorBoundary showDialog dialogOptions={this.getErrorDialogOptions()} fallback={this.renderErrorPage()} onError={this.handleErrors}>
				<StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Backdrop 
	                        open={this.state.isLoading || this.state.showBackdrop} 
	                        sx={{
	                            zIndex: (theme) => theme.zIndex.drawer + 1,
	                            backgroundColor: 'rgb(255,255,255)',
	                        }}
                        >
                            <PulseDiv>
                                <Grid container alignItems='center' justifyContent="center" direction="column">
                                    <Grid xs item>
                                        <img src={WIPIcon} width={105} alt='Logo'/>
                                    </Grid>
                                </Grid>
                                
                                <Box paddingTop={2}/>

                                <LinearProgress style={{backgroundColor: '#b5b5b5'}}/>
                            </PulseDiv>
                        </Backdrop>
                    </ThemeProvider>
                </StyledEngineProvider>
			
				{!this.state.isLoading ? this.renderApp() : null}
            </Sentry.ErrorBoundary>
        );
	}
}

export default withStyles(useStyles)(App)