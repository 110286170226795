import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import {Link as RouterLink} from 'react-router-dom';
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import List from "@mui/material/List";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import {Avatar} from "@mui/material";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import EditIcon from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import CardActionArea from "@mui/material/CardActionArea";
import ArchiveIcon from '@mui/icons-material/Archive';
import {withRouter} from "../../../hooks/withRouter";

const useStyles = theme => ({
	floatRight: {
		float: 'right'
	},
	grow: {
		flexGrow: 1,
	},
});

/**
 * A card that can be popped up into fullscreen mode.
 */
class RoutineOverviewCard extends PureComponent {
	static displayName = RoutineOverviewCard.name;

	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'toggleOpenEditRoutinePopup': Trigger the edit routine popuo.
	 *      'deleteRoutine': Deletes itself.
	 *      'archiveRoutine': Archives itself.
	 *      'isInArchivedMode': Is it in archive mode?
	 *      'dragHandle': The handle icon for dragging this card to reorder it.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The dropdown menu for each routine card.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * Is the collapse open?
			 */
			isCollapseOpen: false,
			/**
			 * In archive mode?
			 */
			isInArchivedMode: props.isInArchivedMode,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			routine: nextProps.routine,
			isInArchivedMode: nextProps.isInArchivedMode,
		});
	}

	openDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget
		});
	};

	closeDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};
	
	triggerCollapse = (event) => {
		this.setState({
			isCollapseOpen: !this.state.isCollapseOpen
		});
	}

	deleteRoutine = async (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
		this.props.deleteRoutine(this.state.routine);
	}

	archiveRoutine = async (event, shouldArchive) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
		this.props.archiveRoutine(shouldArchive);
	}

	toggleOpenEditRoutinePopup = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
		this.props.toggleOpenEditRoutinePopup();	
	}
	
	goToRoutineDetail = () => {
		this.props.navigate(UrlBuilder.routine.routineDetailPage(this.state.routine.id));
	}

	render() {
		const {classes} = this.props;

		let description = this.state.routine.dateLastLogged ?
			DatePrettifier.prettifyLastTrained(new Date(this.state.routine.dateLastLogged))
			: '';

		if (this.state.routine.description) {
			description += description ? ' — ' : '';
			description += this.state.routine.description;
		}
		
		return (
			<Grid item xs={12} sm={12} md={12}>
				<Card key={this.state.routine.id} variant="outlined">
					<CardActionArea>
					<CardHeader
						title={
							<Typography variant="button" display="block">
								{this.state.routine.name}
							</Typography>
						}
						subheader={description}
						avatar={
							<Avatar style={{backgroundColor: this.state.routine.color ?? ColorPicker.pick(this.state.routine.name)}}>
								{this.state.routine.name.charAt(0).toUpperCase()}
							</Avatar>
						}
						action={
							<div>
								<IconButton
									id={this.state.routine.id}
									name="icon"
									size="small"
									className={classes.floatRight}
									onClick={this.openDropdownMenu}
								>
									<MoreVertIcon name="icon"/>
								</IconButton>
								<Menu
									id={this.state.routine.id}
									anchorEl={this.state.dropDownMenuAnchorEl}
									open={Boolean(this.state.dropDownMenuAnchorEl && this.state.dropDownMenuAnchorEl.id == this.state.routine.id)}
									onClose={this.closeDropdownMenu}
									TransitionComponent={Fade}
								>
									{this.state.isInArchivedMode ?
										<>
											<MenuItem onClick={this.toggleOpenEditRoutinePopup}>
												<ListItemIcon>
													<EditIcon fontSize="small"/>
												</ListItemIcon>
												<ListItemText primary="Edit"/>
											</MenuItem>
											<MenuItem onClick={(e) => this.archiveRoutine(e, false)}>
												<ListItemIcon>
													<ArchiveIcon fontSize="small"/>
												</ListItemIcon>
												<ListItemText primary='Unarchive'/>
											</MenuItem>
											<MenuItem onClick={this.deleteRoutine}>
												<ListItemIcon>
													<DeleteIcon fontSize="small"/>
												</ListItemIcon>
												<ListItemText primary='Delete'/>
											</MenuItem>
										</>
										 : <>
											<MenuItem onClick={this.toggleOpenEditRoutinePopup}>
												<ListItemIcon>
													<EditIcon fontSize="small"/>
												</ListItemIcon>
												<ListItemText primary="Edit"/>
											</MenuItem>
											<MenuItem onClick={(e) => this.archiveRoutine(e, true)}>
												<ListItemIcon>
													<ArchiveIcon fontSize="small"/>
												</ListItemIcon>
												<ListItemText primary='Archive'/>
											</MenuItem>
										 </>
									}
									
								</Menu>
							</div>
						}
						onClick={this.goToRoutineDetail}
					/>
					</CardActionArea>

					<Collapse in={this.state.isCollapseOpen}>
						<Divider/>
						{this.state.routine.exercises && this.state.routine.exercises.length ?
							<CardContent>
								<List dense>
									{this.state.routine.exercises.map(exercise => {
										return (
											<ListItem key={exercise.id}>
												<ListItemIcon>
													<FitnessCenterIcon/>
												</ListItemIcon>
												<ListItemText
													primary={
														<Link component={RouterLink}
														      to={UrlBuilder.routine.routineDetailPage(this.state.routine.id, exercise.routineExerciseId)}
														      color="inherit">
															{exercise.name}
														</Link>
													}
												/>
											</ListItem>
										);
									})}
								</List>
							</CardContent>
							: null
						}
					</Collapse>

					<Divider/>
	
					<CardActions disableSpacing>
						<Link component={RouterLink}
						      to={UrlBuilder.routine.routineDetailPage(this.state.routine.id)}
							>
							<Button type="button">Open routine</Button>
						</Link>
						
						<div className={classes.grow}/>

						<IconButton size="small" onClick={this.triggerCollapse}>
							{this.state.isCollapseOpen ? <ExpandLess/> : <ExpandMore/>}
						</IconButton>

						{this.props.dragHandle}
					</CardActions>
				</Card>
			</Grid>
		);	
	}
}

export default withStyles(useStyles)(withRouter(RoutineOverviewCard));