export class Regexer {
	static getEmailRegex() {
		return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	}
	
	static testEmail(email) {
		return Regexer.getEmailRegex().test(email);
	}
	
	static getPasswordRegex() {
		return /^(?=.*\d).{6,}$/;
	}

	static testPassword(password) {
		return Regexer.getPasswordRegex().test(password);
	}
}