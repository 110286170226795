import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {Avatar, IconButton, LinearProgress, ListItemSecondaryAction} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import RoutineSupersetNewOrEditMenuPopUp from "./RoutineSupersetNewOrEditMenuPopUp";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {green} from "@mui/material/colors";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from "@mui/icons-material/Link";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	appBar: {
		position: 'relative',
	},
	check: {
		color: green[400],
	},
	grow: {
		flexGrow: 1,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class RoutineSupersetMenuPopUp extends PureComponent {
	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'isOpen': Says whether this popup is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise that wants to be added to a superset.
			 */
			exercise: this.props.exercise,
			/**
			 * Is it open?
			 */
			isOpen: this.props.isOpen,
			/**
			 * Is this component loading?
			 */
			isLoading: true,
			/**
			 * The supersets to show.
			 */
			supersets: UserLocalStorage.get(UrlBuilder.routine.supersetsApi(this.props.routine.id)) ?? null,
			/**
			 * The superset to edit, if any.
			 */
			supersetToEdit: null,
			/**
			 * Is the new superset pop up open?
			 */
			isNewSupersetPopUpOpen: false,
			/**
			 * Has it ever opened?
			 */
			hasOpenedAtLeastOnce: false,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			routine: nextProps.routine,
			exercise: nextProps.exercise ? nextProps.exercise : this.state.exercise, // Can not set it as null.
			isOpen: nextProps.isOpen,
			hasOpenedAtLeastOnce: nextProps.isOpen ? true : this.state.hasOpenedAtLeastOnce,
		});

		if (nextProps.isOpen) {
			this.fetch();
		}
	}
	
	fetch = async () => {
		this.setState({
			isLoading: true
		});

		let response = await fetch(UrlBuilder.routine.supersetsApi(this.state.routine.id));
		response = await response.json();
		const supersets = response['data'] ?? [];

		this.setState({
			supersets: supersets,
			isLoading: false,
		});

		UserLocalStorage.set(UrlBuilder.routine.supersetsApi(this.state.routine.id), supersets ?? []);
	}

	hasNeverBeenFetched = () => {
		return this.state.supersets === null;
	}

	moveRoutineExerciseToSuperSet = async (routineExerciseId, superset) => {
		this.setState({
			isLoading: true
		});
		
		let supersetId = superset.id;
		for (const routineExercise of superset.routineExercises) {
			if (routineExercise.id === routineExerciseId) {
				supersetId = null; // If the routine-exercise is already in the superset, then just remove it (deselect action).
				break;
			}
		}

		let routineExercise = {
			id: routineExerciseId,
			superSetId: supersetId,
		};

		await fetch(UrlBuilder.routine.routineExercisesApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(routineExercise)
		});

		await this.fetch();
	}
	
	exerciseIsInSuperset = (superset) => {
		if (!this.state.exercise) {
			return false;
		}
		
		for (const routineExercise of superset.routineExercises) {
			if (routineExercise.id === this.state.exercise.routineExerciseId) {
				return true;
			}
		}
		return false;
	}
	
	renderLoadingDialog = () => {
		return (
            <List>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={180} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={180} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={180} height={20}/>
				</ListItem>
			</List>
        );
	}

	renderNoSupersetsDialog = () => {
		return <>
            <Box paddingTop={2}/>

            <Grid container justifyContent="center">
                <Typography variant="overline" gutterBottom>
                    You have not added any supersets.
                </Typography>
            </Grid>
        </>;
	}

	renderSupersets = () => {
		const {classes} = this.props;

		return (
            <List>
                {this.state.supersets.map((superset, idx) => (
                    <ListItem key={superset.id} button onClick={() => this.moveRoutineExerciseToSuperSet(this.state.exercise.routineExerciseId, superset)}>
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: superset.color}}>
                                <LinkIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={superset.name} />

                        <ListItemSecondaryAction>
                            {this.exerciseIsInSuperset(superset) && 
                                <IconButton disabled size="large">
                                    <CheckCircleIcon className={classes.check}/>
                                </IconButton>
                            }
                            <IconButton onClick={() => this.openNewSupersetPopup(true, superset)} size="large">
                                <EditIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        );
	}
	
	openNewSupersetPopup = (isOpen, superset = null, shouldReload = false) => {
		this.setState({
			isNewSupersetPopUpOpen: isOpen,
			supersetToEdit: superset,
		});

		if (shouldReload) {
			this.fetch();
		}
	}
	
	render() {
		const {classes} = this.props;

		if (!this.state.hasOpenedAtLeastOnce) {
			return null;
		}

		let content;
		if (this.hasNeverBeenFetched()) {
			content = this.renderLoadingDialog();
		} else if (this.state.supersets.length === 0) {
			content = this.renderNoSupersetsDialog();
		} else {
			content = this.renderSupersets();
		}

		return <>
			<Dialog onClose={this.props.closeSelfFunc} open={this.state.isOpen} fullWidth keepMounted={false} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap>
							Add Superset: {this.state.exercise?.name}
						</Typography>
					</Toolbar>
				</AppBar>

				{this.state.isLoading ? <LinearProgress/> : <Box paddingTop={0.45}/>}

				{content}

				<DialogActions>
					<Button startIcon={<AddIcon/>} variant="contained" color='primary' className={classes.button} onClick={() => this.openNewSupersetPopup(true)}>New</Button>

					<div className={classes.grow}/>

					<Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>Close</Button>
				</DialogActions>
			</Dialog>
			
			{this.state.isNewSupersetPopUpOpen ?
				<RoutineSupersetNewOrEditMenuPopUp
					routine={this.state.routine}
					exercise={this.state.exercise}
					superset={this.state.supersetToEdit}
					name={this.state.supersets ? ('Superset ' + (this.state.supersets.length + 1)) : ''}
					closeSelfFunc={(shouldReload) => this.openNewSupersetPopup(false, null, shouldReload)}
				/> : null
			}
		</>
	}
}

export default withStyles(useStyles)(RoutineSupersetMenuPopUp);