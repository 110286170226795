import React, {PureComponent} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import withStyles from '@mui/styles/withStyles';
import Fab from "@mui/material/Fab";
import AddIcon from '@mui/icons-material/Add';
import {PageDataContext} from "../../../core/PageDataContext";
import Grid from "@mui/material/Grid";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from "@mui/material/LinearProgress";

import { forwardRef } from 'react';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import ExerciseNewOrEditFormPopUp from "../partials/ExerciseNewOrEditFormPopUp";
import MaterialTable from "@material-table/core";
import Paper from "@mui/material/Paper";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = theme => ({
	speedDialWrapper: {
		margin: 0,
		top: 'auto',
		right: 35,
		bottom: 80,
		left: 'auto',
		position: 'fixed',
	},
	largeIcon: {
		width: 200,
		height: 200,
	},
	media: {
		height: 600,
	},
});

class ExercisesOverview extends PureComponent {
	static displayName = ExercisesOverview.name;

	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * All the exercises that we have available. These are fetched from the backend.
			 */
			exercises: UserLocalStorage.get(UrlBuilder.exercise.exercisesApi()) ?? null,
			/**
			 * Show the add exercise popup
			 */
			showAddExercisePopUp: false,
			/**
			 * Indicates which 'edit exercise' popup is being shown.
			 */
			exerciseInEditableMode: null,
			/**
			 * Used as a component 'isLoading' indicator.
			 */
			isLoading: true,
			/**
			 * The dropdown menu for each exercise. 
			 */
			dropDownMenuAnchorEl: null,
		};

		this.fetchExercises = this.fetchExercises.bind(this);
		this.refresh = this.refresh.bind(this);
		this.toggleOpenAddExercisePopup = this.toggleOpenAddExercisePopup.bind(this);
		this.toggleEditableExercise = this.toggleEditableExercise.bind(this);
		this.toggleCloseAllPopups = this.toggleCloseAllPopups.bind(this);
		this.renderExercises = this.renderExercises.bind(this);
	}

	componentDidMount() {
		this.context.setPageData({
			title: "My Exercises",
		});
		this.fetchExercises();
	}

	openDropdownMenu = (event) => {
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget
		});
	};

	closeDropdownMenu = () => {
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};
	
	refresh() {
		this.fetchExercises();
	}

	async fetchExercises() {
		this.setState({
			isLoading: true
		});

		const exercisesResponse = await fetch(UrlBuilder.exercise.exercisesApi());
		const exercises = await exercisesResponse.json();

		this.setState({
			isLoading: false,
			exercises: exercises, 
		});

		UserLocalStorage.set(UrlBuilder.exercise.exercisesApi(), exercises ?? []);
	}

	deleteExercise = async (event, exercise) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		await fetch(UrlBuilder.exercise.exercisesApi(exercise.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(() => this.toggleCloseAllPopups(true));
	}

	toggleOpenAddExercisePopup() {
		this.setState({
			showAddExercisePopUp: !this.state.showAddExercisePopUp
		});
	}

	toggleEditableExercise(exercise) {
		this.setState({
			exerciseInEditableMode: exercise,
			dropDownMenuAnchorEl: null
		});
	}

	toggleCloseAllPopups(shouldRefresh = true) {
		this.setState({
			showAddExercisePopUp: false,
			exerciseInEditableMode: null,
			dropDownMenuAnchorEl: null
		});
		if (shouldRefresh) {
			this.refresh();
		}
	}

	renderExercises() {
		const columns = [
			{field: 'name', title: 'Name', width: '42%'},
			{field: 'category', title: 'Category', width: '25%'},
			{field: 'type', title: 'Type', width: '33%'},
		];

		let rows = [];
		this.state.exercises.forEach(exercise => {
			rows.push({
				name: exercise.name,
				category: exercise.category ? exercise.category.name : 'None',
				type: exercise.type.name,
				exercise: exercise // Used to pass along to function online.
			});
		});

		return <>
			<MaterialTable
				icons={tableIcons}
				columns={columns}
				data={rows}
				components={{
					Container: props => <Paper {...props} elevation={0} sx={{ marginLeft: '-16px', marginRight: '-16px', paddingTop: '8px'}}/>
				}}
				actions={[
					rowData => ({
						icon: tableIcons.Edit,
						tooltip: 'Edit ' + rowData.name,
						onClick: (event, rowData) => this.toggleEditableExercise(rowData.exercise),
					})
				]}
				title={'My Exercises'}
				options={{
					paging: false,
					showTitle: false,
					rowStyle: {
						'fontSize': 14,
					},
					tableLayout: "fixed",
				}}
			/>

			<Box paddingTop={3}/>
		</>
	}

	renderNoExercises = () => {
		const {classes} = this.props;

		return <>
			<Box paddingTop={5}/>

			<Grid container justifyContent="center">
				<IconButton
                    touch
                    tooltip="See routines"
                    onClick={this.toggleOpenAddExercisePopup}
                    size="large">
					<AddToPhotosIcon className={classes.largeIcon}/>
				</IconButton>
			</Grid>
			<Grid container justifyContent="center">
				<Typography variant="overline" gutterBottom>
					You have no exercises yet
				</Typography>
			</Grid>

			<Grid container justifyContent="center">
				<Button>
					<Typography variant="overline" onClick={this.toggleOpenAddExercisePopup}>
						<strong>Create an exercise</strong>
					</Typography>
				</Button>
			</Grid>
		</>;
	}
	
	renderPage = () => {
		if (this.state.exercises && this.state.exercises.length > 0) {
			return this.renderExercises();
		}
		return this.renderNoExercises();
	}

	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}
	
	hasNeverBeenFetched = () => {
		return this.state.exercises === null;
	}

	render() {
		const {classes} = this.props;
		
		return (
			<>
				<Fade in={this.state.isLoading}>
					<LinearProgress />
				</Fade>

				<Box paddingTop={1} />

				<ExerciseNewOrEditFormPopUp
					isOpen={this.state.showAddExercisePopUp || this.state.exerciseInEditableMode}
					exercise={this.state.exerciseInEditableMode}
					deleteExerciseFunc={this.deleteExercise}
					closeSelfFunc={this.toggleCloseAllPopups}
				/>

				{
					this.hasNeverBeenFetched() ?
						this.renderLoadingSkeletons()
						: this.renderPage()
				}

				<Zoom in={true} unmountOnExit>
					<Fab color="primary" className={classes.speedDialWrapper} aria-label="add" onClick={this.toggleOpenAddExercisePopup}>
						<AddIcon />
					</Fab>
				</Zoom>
			</>
		);
	}
}

ExercisesOverview.contextType = PageDataContext;

export default withStyles(useStyles)(ExercisesOverview);