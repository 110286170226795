import React, {PureComponent} from 'react'
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import {Alert, Popover} from '@mui/material';
import Slide from "@mui/material/Slide";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HelpIcon from "@mui/icons-material/Help";
import {ExerciseType} from "../../../core/models/ExerciseType";
import ColorSelector from "../../core/utils/ColorSelector";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {blue} from "@mui/material/colors";
import Box from "@mui/material/Box";

const useStyles = theme => ({
	formControl: {
		minWidth: 175,
	},
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 250,
	},
	listSection: {
		backgroundColor: 'inherit',
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0,
	},
	floatRight: {
		float: 'right'
	},
	container: {
		backgroundColor: theme.palette.background.default,
	},
	containerBig: {
		width: '100%',
		padding: 0,
	},
	popOverText: {
		padding: theme.spacing(2),
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ExerciseNewOrEditFormPopUp extends PureComponent {
	/**
	 * @param props Containing:
	 *      'isOpen': Says whether this modal is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * 'isOpen': Says whether this modal is open.
			 */
			isOpen: Boolean(this.props.isOpen),
			/**
			 * If we want to edit -> give an exercise.
			 */
			exercise: this.props.exercise,
			/**
			 * Set the default exercise type id.
			 */
			selectedExerciseTypeId: this.props.exercise ? this.props.exercise.exerciseTypeId : 1,
			/**
			 * The name of the exercise (fallback to 'name' for props, this is to pre-fill)
			 */
			name: this.props.exercise ? this.props.exercise.name : (this.props.name ?? ''),
			/**
			 * The category name of the exercise.
			 */
			category: this.props.exercise && this.props.exercise.category ? this.props.exercise.category.name : '',
			/**
			 * The description of the exercise.
			 */
			description: this.props.exercise && this.props.exercise.description ? this.props.exercise.description : '',
			/**
			 * The example exercise categories (contains exercises).
			 */
			exampleExerciseCategories: [],
			/**
			 * The category suggestions (does not contain exercises).
			 */
			exerciseCategorySuggestions: [],

			/**
			 * Which field is being focused? (to open the suggestions collapse box).
			 */
			focusedField: null,
			/**
			 * Is it currently being submitted?
			 */
			isSubmissionLoading: false,
			/**
			 * Is the delete confirmation dialog open?
			 */
			isDeleteConfirmationDialogOpen: false,
			/**
			 * Any message we want to show (error message for example).
			 */
			message: null,
			/**
			 * Is the advanced collapse open?
			 */
			isAdvancedCollapseOpen: false,
			/**
			 * The help text for the tooltip explaining what the Exercise Muscle Breakdown does.
			 */
			showingMuscleBreakdownPopupHelpText: null,
			/**
			 * The exercise color.
			 */
			color: props.exercise?.color ?? (props.exercise ? ColorPicker.pick(props.exercise.name) : blue[500]),
		};

		this.submit = this.submit.bind(this);
		this.updateStateInputValues = this.updateStateInputValues.bind(this);
		this.updateSelectedExerciseTypeFromDropdownInputSelection = this.updateSelectedExerciseTypeFromDropdownInputSelection.bind(this);
		this.submitWithEnter = this.submitWithEnter.bind(this);
	}

	submitWithEnter(e) {
		if (e.key === 'Enter') {
			this.submit(e);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.state.isOpen !== Boolean(nextProps.isOpen)) {
			this.setState({
				isOpen: Boolean(nextProps.isOpen),
				exercise: nextProps.exercise,
				selectedExerciseTypeId: nextProps.exercise ? nextProps.exercise.exerciseTypeId : 1,
				name: nextProps.exercise ? nextProps.exercise.name : (nextProps.name ?? ''),
				color: nextProps.exercise?.color ?? (nextProps.exercise ? ColorPicker.pick(nextProps.exercise.name) : blue[500]),
				category: nextProps.exercise && nextProps.exercise.category ? nextProps.exercise.category.name : '',
				description: nextProps.exercise && nextProps.exercise.description ? nextProps.exercise.description : '',
				isDeleteConfirmationDialogOpen: false,
				message: null,
				isAdvancedCollapseOpen: false,
				showingMuscleBreakdownPopupHelpText: null,
				...this.getMusclePercentagesStateFromExercise(nextProps.exercise),
			});
			if (nextProps.isOpen) {
				this.refresh(); // Only refresh when it was closed before and now its opening.
			}
		}
	}

	refresh = () => {
		this.fetchExerciseFormExamples();
	}

	fetchExerciseFormExamples = async () => {
		this.setState({
			isLoading: true
		});

		await fetch(
			UrlBuilder.exercise.exerciseFormExamplesApi()
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					isLoading: false,
					exampleExerciseCategories: response['data']['exampleExerciseCategories'] ?? [],
					exerciseCategorySuggestions: response['data']['exerciseCategorySuggestions'] ?? [],
				});
			});
	}

	async submit(event) {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});

		let muscles = [];
		let percentageTotal = 0;

		for (const num of [0, 1, 2, 3, 4]) {
			let musclePercentage = this.state[this.getStateKeyForMusclePercentage(num)] ?? null;
			const muscleId = this.state[this.getStateKeyForMuscleId(num)] ?? null;
			if (muscleId && musclePercentage) {
				musclePercentage = parseInt(musclePercentage);
				if (musclePercentage > 0 && musclePercentage <= 100) {
					muscles.push({
						muscleId: muscleId,
						percentage: musclePercentage,
					});
					percentageTotal += musclePercentage;
				} else {
					this.setState({
						message: 'Only values between 0-100 are allowed for muscle percentages.',
						isSubmissionLoading: false,
					});
					return;
				}
			}
		}

		if (muscles.length > 0 && percentageTotal !== 100) {
			this.setState({
				message: 'The total muscle percentages should be equal to 100. Otherwise, please set the muscle breakdown selections to None.',
				isSubmissionLoading: false,
			});
			return;
		}

		let res;

		if (this.state.exercise) {
			let exerciseToUpdate = {
				id: this.state.exercise.id,
				Name: this.state.name,
				Description: this.state.description,
				Category: {
					name: this.state.category,
				},
				Muscles: muscles,
				Color: this.state.color,
			};
			
			res = await fetch(UrlBuilder.exercise.exercisesApi(), {
				method: 'PUT',
				headers: {
					'accept': 'application/json',
					'content-type': 'application/json'
				},
				body: JSON.stringify(exerciseToUpdate)
			})
			
		} else {
			let exerciseToSave = {
				Name: this.state.name,
				Description: this.state.description,
				ExerciseTypeId: this.state.selectedExerciseTypeId,
				Category: {
					name: this.state.category,
				},
				Muscles: muscles,
				Color: this.state.color,
			};
			
			res = await fetch(UrlBuilder.exercise.exercisesApi(), {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(exerciseToSave)
			});
		}

		if (res.status === 200) {
			this.props.closeSelfFunc();
			this.setState({
				isSubmissionLoading: false,
			});
		} else if (res.status === 409) {
			this.setState({
				message: 'Exercise with given name already exists. Try another name.',
				isSubmissionLoading: false,
			});
		} else {
			this.setState({
				message: 'Something went wrong. Please try again.',
				isSubmissionLoading: false,
			});
		}
	}

	updateStateInputValues(event) {
		let state = {
			[event.target.name]: event.target.value,
			message: null, // Reset any messages.
		};

		if (event.target.name === 'name') {
			state['color'] = ColorPicker.pick(event.target.value);
		}
		
		this.setState(state);
	}

	clearStateInputValue(fieldName) {
		this.setState({
			[fieldName]: '',
			focusedField: fieldName,
			message: null, // Reset any messages.
		});
	}

	updateSelectedExerciseTypeFromDropdownInputSelection(event) {
		this.setState({
			selectedExerciseTypeId: event.target.value
		});
	}

	setExampleExercise = (exampleExercise, exampleExerciseCategory) => {
		let state = {
			name: exampleExercise.name,
			color: ColorPicker.pick(exampleExercise.name),
			selectedExerciseTypeId: exampleExercise.exerciseTypeId,
			category: exampleExerciseCategory.name,
			focusedField: null,
			...this.getMusclePercentagesStateFromExampleExercise(exampleExercise),
		};

		this.setState(state);
	}
	
	prefillMusclePercentages = () => {
		const exampleExercise = this.findExampleExerciseWithMusclePercentages();
		if (exampleExercise) {
			this.setState(this.getMusclePercentagesStateFromExampleExercise(exampleExercise));
		}
	}

	findExampleExerciseWithMusclePercentages = () => {
		if (!this.state.isAdvancedCollapseOpen) { // Don't calculate unless it's open!
			return null;
		}
		
		for (const exampleExerciseCategory of this.state.exampleExerciseCategories) {
			for (const exampleExercise of exampleExerciseCategory.exampleExercises) {
				if (exampleExercise.type.id === this.state.selectedExerciseTypeId && exampleExercise.name === this.state.name && exampleExercise.exampleMuscles?.length > 0) {
					return exampleExercise;
				}
			}
		}
		
		return null;
	}
	
	getMusclePercentagesStateFromExampleExercise = (exampleExercise = null) => {
		let state = {};
		
		[0, 1, 2, 3, 4].forEach(num => {
			if (exampleExercise?.exampleMuscles && exampleExercise.exampleMuscles[num]) {
				const exampleMuscle = exampleExercise.exampleMuscles[num];

				state[this.getStateKeyForMuscleId(num)] = exampleMuscle.muscle.id;
				state[this.getStateKeyForMusclePercentage(num)] = exampleMuscle.percentage;
			} else { // Reset
				state[this.getStateKeyForMuscleId(num)] = undefined;
				state[this.getStateKeyForMusclePercentage(num)] = undefined;
			}
		})
		
		return state;
	}

	getMusclePercentagesStateFromExercise = (exercise = null) => {
		let state = {};

		[0, 1, 2, 3, 4].forEach(num => {
			if (exercise?.muscles && exercise.muscles[num]) {
				const exerciseMuscle = exercise.muscles[num];

				state[this.getStateKeyForMuscleId(num)] = exerciseMuscle.muscle.id;
				state[this.getStateKeyForMusclePercentage(num)] = exerciseMuscle.percentage;
			} else { // Reset
				state[this.getStateKeyForMuscleId(num)] = undefined;
				state[this.getStateKeyForMusclePercentage(num)] = undefined;
			}
		})

		return state;
	}

	setExampleCategory = (exampleExerciseCategory) => {
		this.setState({
			category: exampleExerciseCategory.name,
			focusedField: null,
		});
	}

	setFieldOnFocus = (fieldName) => {
		this.setState({
			focusedField: fieldName
		})
	}

	openExerciseDeleteConfirmationDialog = (isOpen) => {
		this.setState({
			isDeleteConfirmationDialogOpen: isOpen
		})
	}

	renderDeleteConfirmationDialog = (e) => {
		if (!this.state.exercise) {
			return null;
		}
		if (!this.state.isDeleteConfirmationDialogOpen) {
			return null;
		}

		return <Dialog open={true} onClose={() => this.openExerciseDeleteConfirmationDialog(false)} TransitionComponent={Transition}>
			<DialogTitle>{"Are you sure you want to delete " + this.state.exercise.name + '?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Deleting an exercise will remove it from any routines along with its logs, goals, and workouts associated with it.
					<br/>
					This cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' color='grey' onClick={() => this.openExerciseDeleteConfirmationDialog(false)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={(e) => this.props.deleteExerciseFunc(e, this.state.exercise)} color="primary" autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	}

	getStateKeyForMuscleId = (num) => {
		return `muscle${num}`;
	}

	getStateKeyForMusclePercentage = (num) => {
		return `musclePercentage${num}`;
	}

	toggleAdvancedCollapse = () => {
		this.setState({
			isAdvancedCollapseOpen: !this.state.isAdvancedCollapseOpen,
			focusedField: null,
		});
	}

	hasAdvancedOptions = () => {
		return this.state.selectedExerciseTypeId === ExerciseType.WeightAndReps || this.state.exercise?.type.id === ExerciseType.WeightAndReps;
	}

	showMuscleBreakdownPopupHelpText = (e) => {
		this.setState({
			showingMuscleBreakdownPopupHelpText: e.currentTarget,
		});
	}

	closeShowMuscleBreakdownPopupHelpText = () => {
		this.setState({
			showingMuscleBreakdownPopupHelpText: null,
		});
	}

	updateStateInputColorValue = (color) => {
		this.setState({
			color: color,
		});
	}

	renderMuscleBreakdownPopupHelpText = () => {
		const {classes} = this.props;

		return <Popover
			open={this.state.showingMuscleBreakdownPopupHelpText !== null}
			anchorEl={this.state.showingMuscleBreakdownPopupHelpText}
			onClose={this.closeShowMuscleBreakdownPopupHelpText}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Typography variant='body2' className={classes.popOverText}>
				With this data, you can see the muscle breakdown of a workout. You can view this in a workout detail page.
			</Typography>
		</Popover>
	}

	render() {
		const {classes} = this.props;
		
		let hasExerciseSuggestions = false;
		let hasCategorySuggestions = false;

		return (
            <Dialog
				open={this.state.isOpen}
				onClose={() => this.props.closeSelfFunc(false)}
				closeAfterTransition
				TransitionComponent={Transition}
				fullScreen
			>
				<DialogTitle>
					{this.state.exercise ? 'Edit Exercise' : 'Create Exercise'}
					
					<IconButton edge="end" size='small' color="inherit" onClick={() => this.props.closeSelfFunc(false)} className={classes.floatRight}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				
				{this.state.message && <Alert severity="error">{this.state.message}</Alert>}
				
				<DialogContent dividers className={classes.container}>
					<Container className={classes.containerBig}>
						<FormControl fullWidth size={'small'} variant="standard">
							<InputLabel htmlFor="name">Name</InputLabel>
							<Input
								required
								fullWidth
								id="name"
								label="Name"
								autoComplete='off'
								name="name"
								autoFocus={!this.state.exercise}
								onChange={this.updateStateInputValues}
								onKeyPress={this.submitWithEnter}
								onFocus={() => this.setFieldOnFocus('name')}
								placeholder={'Type for suggestions...'}
								value={this.state.name}
								startAdornment={
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								}
								endAdornment={
									<IconButton onClick={() => this.clearStateInputValue('name')} size="large">
										<ClearIcon />
									</IconButton>
								}
							/>
						</FormControl>

						<Collapse in={this.state.focusedField === 'name' && this.state.exampleExerciseCategories.length > 0} unmountOnExit>
							<List dense className={classes.root} subheader={<li />}>
								{this.state.exampleExerciseCategories.map((exampleExerciseCategory) => {
									let exampleExercises = exampleExerciseCategory
										.exampleExercises
										.filter(exampleExercise => 
											exampleExercise.name.toLowerCase().includes(this.state.name.toLowerCase())
										);
	
									if (exampleExercises.length === 0) {
										return null;
									}
									
									hasExerciseSuggestions = true;
									
									return <li key={exampleExerciseCategory.id} className={classes.listSection}>
										<ul className={classes.ul}>
											<ListSubheader color='inherit'>{exampleExerciseCategory.name} Exercise Suggestions</ListSubheader>
											{exampleExercises.map((exampleExercise) => (
												<ListItem dense key={exampleExercise.id} button onClick={() => this.setExampleExercise(exampleExercise, exampleExerciseCategory)}>
													<ListItemText primary={exampleExercise.name} />
												</ListItem>
											))}
										</ul>
									</li>;
								})}
							</List>

							{!this.state.exercise ?
								<Collapse in={!hasExerciseSuggestions}>
									<Typography variant={'caption'}>
										Adding new exercise "{this.state.name}".
									</Typography>
								</Collapse> : null
							}

							<Divider light/>
						</Collapse>

						<Box paddingTop={2}/>

						<FormControl fullWidth size={'small'} variant="standard">
							<InputLabel htmlFor="name">Category</InputLabel>
							<Input
								required
								fullWidth
								id="category"
								label="Category"
								autoComplete='off'
								name="category"
								onChange={this.updateStateInputValues}
								onKeyPress={this.submitWithEnter}
								onFocus={() => this.setFieldOnFocus('category')}
								placeholder={'Type for suggestions...'}
								value={this.state.category}
								startAdornment={
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								}
								endAdornment={
									<IconButton onClick={() => this.clearStateInputValue('category')} size="large">
										<ClearIcon />
									</IconButton>
								}
							/>
						</FormControl>

						<Collapse in={this.state.focusedField === 'category' && this.state.exampleExerciseCategories.length > 0} unmountOnExit>
							<List dense className={classes.root} subheader={<li />}>
								{this.state.exerciseCategorySuggestions
									.filter(ecs => ecs.name.toLowerCase().includes(this.state.category.toLowerCase()))
									.map((exerciseCategorySuggestion) => {
										hasCategorySuggestions = true;
										return <ListItem dense key={exerciseCategorySuggestion.name} button
										          onClick={() => this.setExampleCategory(exerciseCategorySuggestion)}>
											<ListItemText primary={exerciseCategorySuggestion.name}/>
										</ListItem>
									}
								)}
							</List>

							{!this.state.exercise ?
								<Collapse in={!hasCategorySuggestions} unmountOnExit>
									<Typography variant={'caption'}>
										No suggestions. Adding new category "{this.state.category}".
									</Typography>
								</Collapse> : null
							}

							<Divider light/>
						</Collapse>

						<Box paddingTop={2.25}/>

						<FormControl className={classes.formControl} disabled={!!this.state.exercise} onClick={() => this.setFieldOnFocus(null)}>
							<InputLabel shrink variant="standard">
								Type
							</InputLabel>
							<Select
								name="selectedExerciseTypeId"
								id="selectedExerciseTypeId"
								variant="standard"
								value={this.state.selectedExerciseTypeId}
								onChange={this.updateSelectedExerciseTypeFromDropdownInputSelection}
							>
								<MenuItem value={ExerciseType.WeightAndReps}>Weight & Reps</MenuItem>
								<MenuItem value={ExerciseType.WeightAndTime}>Weight & Time</MenuItem>
								<MenuItem value={ExerciseType.Reps}>Reps</MenuItem>
								<MenuItem value={ExerciseType.DistanceAndTime}>Distance & Time</MenuItem>
								<MenuItem value={ExerciseType.Time}>Time</MenuItem>
							</Select>
						</FormControl>

						<Box paddingTop={0.75}/>

						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							name="description"
							label="Description (optional)"
							InputLabelProps={{
								shrink: true,
							}}
							type="text"
							id="description"
							onChange={this.updateStateInputValues}
							value={this.state.description}
							onClick={() => this.setFieldOnFocus(null)}
							onKeyPress={this.submitWithEnter}
						/>

						<Box paddingTop={1}/>

						<Typography variant='subtitle1' color='textSecondary' style={{ fontSize: 12 }}>
							Color
						</Typography>

						<ColorSelector color={this.state.color} updateColorValueParentFunc={this.updateStateInputColorValue}/>

						<br/>
						
						<Button style={{ float: 'right' }} variant='contained' onClick={this.toggleAdvancedCollapse} endIcon={<ArrowDropDownIcon/>}>
							Advanced
						</Button>

						<br/>
						<br/>

						<Collapse in={this.state.isAdvancedCollapseOpen}>
							<Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
								<Grid item>
									<Typography variant='h6' gutterBottom>
										Muscle Breakdown &nbsp;
										<IconButton
											edge="end"
											onClick={this.showMuscleBreakdownPopupHelpText}
											color="inherit"
											sx={{ fontSize: '0.5rem !important', padding: '0px !important'}}
											size="small"
										>
											<HelpIcon/>
										</IconButton>
									</Typography>
									
								</Grid>
								{this.findExampleExerciseWithMusclePercentages() !== null &&
								<Grid item>
									<Button size='small' variant='contained'
									        onClick={this.prefillMusclePercentages}>
										<AutoFixHighIcon/>
									</Button>
								</Grid>
								}
							</Grid>

							<br/>

							<Grid container direction='column' spacing={2}>
								{[0, 1, 2, 3, 4].map(num => {
									const muscleKey = this.getStateKeyForMuscleId(num);
									const musclePercentageKey = this.getStateKeyForMusclePercentage(num)

									return <Grid item>
										<Grid container direction='row' spacing={1}>
											<Grid item>
												<FormControl style={{minWidth: 120}}>
													<InputLabel>
														Muscle
													</InputLabel>
													<Select
														name={muscleKey}
														id={muscleKey}
														required
														label='Muscle'
														value={this.state[muscleKey] ?? 0} // Fallback to 0 (no selection).
														onChange={this.updateStateInputValues}
													>
														<MenuItem value={0}><i>None</i></MenuItem>
														<MenuItem value={1}>Triceps</MenuItem>
														<MenuItem value={2}>Biceps</MenuItem>
														<MenuItem value={3}>Forearms</MenuItem>
														<MenuItem value={4}>Shoulders</MenuItem>
														<MenuItem value={5}>Chest</MenuItem>
														<MenuItem value={6}>Lats</MenuItem>
														<MenuItem value={7}>Traps</MenuItem>
														<MenuItem value={8}>Quads</MenuItem>
														<MenuItem value={9}>Hamstrings</MenuItem>
														<MenuItem value={10}>Calves</MenuItem>
														<MenuItem value={11}>Glutes</MenuItem>
														<MenuItem value={12}>Core</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid item>
												<TextField
													name={musclePercentageKey}
													type='number'
													label="Percentage (0-100)"
													autoComplete='off'
													sx={{width: '175px'}}
													disabled={(this.state[muscleKey] ?? 0) === 0} // Only enabled when Muscle is not set to None
													endAdornment={<InputAdornment position="end">%</InputAdornment>}
													error={this.state[musclePercentageKey] <= 0 || this.state[musclePercentageKey] > 100}
													onChange={this.updateStateInputValues}
													value={this.state[musclePercentageKey] ?? 0}
												/>
											</Grid>
										</Grid>
									</Grid>
								})}
							</Grid>
						</Collapse>
					</Container>

					{this.renderDeleteConfirmationDialog()}
					{this.renderMuscleBreakdownPopupHelpText()}
				</DialogContent>
				<DialogActions>
					{this.state.exercise ?
						<IconButton
                            onClick={() => this.openExerciseDeleteConfirmationDialog(true)}
                            size="large">
							<DeleteIcon/>
						</IconButton> : null
					}
					
					<Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>
						Close
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={this.submit}
						type="submit"
						value="Submit"
						disabled = {this.state.isSubmissionLoading || this.state.name.trim() === '' || this.state.message}
					>
						{this.state.isSubmissionLoading ?
							<div>
								<CircularProgress size="1rem" color="inherit" /> Loading...
							</div> :
							'Save Exercise'
						}
					</Button>
				</DialogActions>
			</Dialog>
        );
	}
}

export default withStyles(useStyles)(ExerciseNewOrEditFormPopUp);