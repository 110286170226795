export class GraphQLQueryBuilder{
	
	static fullMeasurements(measurementId = null) {
		const uniqueString = measurementId ? '(id: ' + measurementId + ')' : '';
		
		return JSON.stringify({ query:
`
query {
	measurements ${uniqueString} {
		id
		name,
		color,
		sortNumber,
		logs {
			id,
			value,
			dateCreated
		}
	}
}
`
		});
	}
}