import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import moment from "moment";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import DialogContent from "@mui/material/DialogContent";
import Container from "@mui/material/Container";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from "@mui/material/Slide";
import ColorSelector from "../../core/utils/ColorSelector";
import {blue} from "@mui/material/colors";

const useStyles = theme => ({
	appBar: {
		position: 'relative',
	},
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class RoutineSupersetNewOrEditMenuPopUp extends PureComponent {
	/**
	 * @param props Containing:
	 *      'routine': The routine that a superset is being added to.
	 *      'name': The name of the superset.
	 *      'exercise': The exercise to be added to the superset.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine that a superset is being added to.
			 */
			routine: props.routine,
			/**
			 * The name of the superset (can be passed by parent).
			 */
			name: props.superset ? props.superset.name : props.name,
			/**
			 * The superset to edit, if any.
			 */
			superset: props.superset,
			/**
			 * The exercise to be added to the superset.
			 */
			exercise: props.exercise,
			/**
			 * Is this component loading?
			 */
			isLoading: false,
			/**
			 * The color.
			 */
			color: props.superset ? props.superset.color : blue[500],
			/**
			 * Is the custom color picker open?
			 */
			customColorPickAnchorEl: null,
		};
	}
	
	submit = async (e) => {
		e.preventDefault();
		
		this.setState({
			isLoading: true,
		});

		let superset;

		if (this.state.superset) {
			superset = {
				id: this.state.superset.id,
				name: this.state.name,
				color: this.state.color,
				dateModified: moment().format('L, h:mm:ss A')
			};
		} else {
			superset = {
				name: this.state.name,
				routineId: this.state.routine.id,
				color: this.state.color,
				dateCreated: moment().format('L, h:mm:ss A'),
				routineExercises: [
					{
						id: this.state.exercise.routineExerciseId,
					}
				],
			};
		}

		let res = await fetch(UrlBuilder.routine.supersetsApi(), {
			method: this.state.superset ? 'PUT' : 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(superset)
		});

		this.props.closeSelfFunc(true);
	}

	submitWithEnter = (e) => {
		if (e.key === 'Enter') {
			this.submit(e);
		}
	}
	
	deleteSelf = async (supersetId) => {
		this.setState({
			isLoading: true,
		});

		await fetch(UrlBuilder.routine.supersetsApi(supersetId), {
			method: 'DELETE',
		});

		this.props.closeSelfFunc(true);
	}

	updateStateInputValues = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}
	
	updateStateInputColorValue = (color) => {
		this.setState({
			color: color,
		});
	}

	render() {
		const {classes} = this.props;

		return (
            <Dialog onClose={() => this.props.closeSelfFunc(false)} open TransitionComponent={Transition}>
                <AppBar className={classes.appBar} enableColorOnDark>
                    <Toolbar variant='dense'>
                        <Typography variant="h6" noWrap>
                            {this.state.superset ? 'Edit Superset' : 'New Superset'}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers>
                    <Container maxWidth='md' disableGutters>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Name"
                            name="name"
                            value={this.state.name}
                            onChange={this.updateStateInputValues}
                            onKeyPress={this.submitWithEnter}
                            placeholder="e.g. Upper body"
                        />
                        
                        <Typography variant='subtitle1' color='textSecondary' style={{ fontSize: 12 }}>
                            Color:
                        </Typography>
                        
                        <ColorSelector color={this.state.color} updateColorValueParentFunc={this.updateStateInputColorValue}/>
                    </Container>
                </DialogContent>

                <DialogActions>
                    {this.state.superset ?
                        <IconButton onClick={() => this.deleteSelf(this.state.superset.id)} size="large">
                            <DeleteIcon/>
                        </IconButton> : null
                    }
                    
                    <Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>Close</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.submit}
                        type="submit"
                        value="Submit"
                        disabled={this.state.isLoading || this.state.name.trim() === ''}
                    >
                        {this.state.isLoading ?
                            <div>
                                <CircularProgress size="1rem" color="inherit" /> Save
                            </div> :
                            'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        ); 
	}
}

export default withStyles(useStyles)(RoutineSupersetNewOrEditMenuPopUp);