import React, {Component} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import moment from "moment";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from '@mui/icons-material/Add';
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import ExerciseNewOrEditFormPopUp from "../../exercise/partials/ExerciseNewOrEditFormPopUp";
import {InputBase, ListItem, ListItemAvatar, ListItemText, Paper} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import List from "@mui/material/List";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";

const useStyles = theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 100,
	},
	grow: {
		flexGrow: 1,
	},
	root: {
		width: '100%',
	},
	small: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	searchBoxRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	searchBoxInput: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Bloop to add new exercises to a routine.
 */
class RoutineExercisesEditFormBloop extends Component {
	/**
	 * @param props Containing:
	 *      'isOpen': Says whether this modal is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine to edit, if any. Otherwise the form will try to create one instead.
			 */
			routine: this.props.routine,
			/**
			 * 'isOpen': Says whether this bloop is open.
			 */
			isOpen: false,
			/**
			 * Used as a component 'is loading' indicator.
			 */
			isLoading: true,
			/**
			 * Is it currently being submitted?
			 */
			isSubmissionLoading: false,
			/**
			 * All the exercises that we have available for the user.
			 */
			fetchedExercises: [],
			/**
			 * The new exercises selected to be added to the routine from the list of fetchedExercises.
			 */
			selectedExercises: {},
			/**
			 * The exercises belonging to the routine
			 */
			routineExercises : this.props.routine ? this.props.routine.exercises : [],
			/**
			 * Show the add exercise popup
			 */
			showAddExercisePopUp: false,
			/**
			 * The search box text.
			 */
			searchBoxText: '',
		};
    }
    
    componentWillReceiveProps(nextProps, nextContext) {
	    if (this.state.isOpen !== Boolean(nextProps.isOpen)) {
		    this.setState({
			    isOpen: Boolean(nextProps.isOpen),
			    routine: nextProps.routine,
			    selectedExercises: {},
		    });
		    if (nextProps.isOpen) {
		        this.refresh(); // Only refresh when it was closed before and now its opening.
		    }
	    }
    }

    submitWithEnter = (e) => {
        if (e.key==='Enter') {
            this.submit(e);
        }
    }

	refresh = () => {
		this.fetchExercises();
	}
	
	fetchExercises = async () => {
		this.setState({
			isLoading: true
		});
		
		const exercisesResponse = await fetch(UrlBuilder.exercise.exercisesApi());
		let fetchedExercises = await exercisesResponse.json();

		// A new exercise was added because the list got bigger by 1, so let's pre-select it (kinda hacky) :)
		if (this.state.fetchedExercises && (fetchedExercises.length - 1 === this.state.fetchedExercises.length)) {
			let newSelectedExercises = this.state.selectedExercises;
			newSelectedExercises[fetchedExercises[fetchedExercises.length - 1].id] = true;
			
			this.setState({
				fetchedExercises: fetchedExercises,
				isLoading: false,
				selectedExercises: newSelectedExercises,
			});
			return;
		}

		this.setState({
			fetchedExercises: fetchedExercises,
			isLoading: false
		});
	}

	submit = async (event) => {
		event.preventDefault();
		
		this.setState({
			isSubmissionLoading: true,
		});

		let routineExercises = [];
		
		let sortNumber = this.state.routine && this.state.routine.exercises[this.state.routine.exercises.length - 1] ? 
			this.state.routine.exercises[this.state.routine.exercises.length - 1].sortNumber + 1 : 
			0; // If routine exists, take the biggest one which should be the last one in the list.
		
		for (let i = 0; i < this.state.fetchedExercises.length; i++) {
			let exercise = this.state.fetchedExercises[i];
			if (this.state.selectedExercises[exercise.id]) {
				routineExercises.push({
					id: exercise.id,
					sortNumber: sortNumber,
				});
				sortNumber++;
			}
		}

		let routineToSave = {
			id: this.state.routine.id,
			exercises: routineExercises,
		};
		
		await fetch(UrlBuilder.routine.routinesApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(routineToSave)
		})
			.then(response => response.json())
			.then(routine => this.props.closeSelfFunc(true));

		this.setState({
			selectedExercises: {}, // reset
			isSubmissionLoading: false,
		});
	}

	updateSearchBoxText = (value) => {
		this.setState({
			searchBoxText: value,
		});
	}
	
	getSearchedAvailableExercises = () => {
		let textToSearch = this.state.searchBoxText;

		if (this.state.searchBoxText.trim() === '') {
			return this.state.fetchedExercises;
		}

		textToSearch = textToSearch.toLowerCase();
		
		return this.state.fetchedExercises.filter(exercise =>
			exercise.name.toLowerCase().includes(textToSearch)
		);
	}
	
	updateStateCheckboxInputValues = (id) => {
		let newSelectedExercises = this.state.selectedExercises;
		newSelectedExercises[id] = !Boolean(newSelectedExercises[id]);
		
		this.setState({
			selectedExercises: newSelectedExercises
		});
	}

	toggleCloseAllPopups = (shouldRefresh = true) => {
		this.setState({
			showAddExercisePopUp: false,
		});
		if (shouldRefresh) {
			this.refresh();
		}
	}

	toggleOpenAddExercisePopup = () => {
		this.setState({
			showAddExercisePopUp: true
		});
	}

	render() {
		const {classes} = this.props;

		let searchedAvailableExercises = this.getSearchedAvailableExercises();
		
		return (
            <Dialog fullScreen open={this.state.isOpen} onClose={() => this.props.closeSelfFunc(false)} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap className={classes.title}>
							Add Exercises to {this.state.routine.name}
						</Typography>
						
						<div className={classes.grow}/>

						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.props.closeSelfFunc(false)}
                            size="large">
							<CloseIcon/>
						</IconButton>
					</Toolbar>
				</AppBar>

				<Fade in={this.state.isLoading}>
					<LinearProgress />
				</Fade>

				<DialogContent dividers>
					<Paper variant='outlined' component="form" className={classes.searchBoxRoot}>
						<IconButton className={classes.iconButton} size="large">
							<SearchIcon />
						</IconButton>	
						
						<InputBase
							className={classes.searchBoxInput}
							placeholder="Search Your Exercises"
							onChange={(e) => this.updateSearchBoxText(e.target.value)}
							value={this.state.searchBoxText}
						/>
						
						{this.state.searchBoxText ?
							<IconButton
                                className={classes.iconButton}
                                onClick={(e) => this.updateSearchBoxText('')}
                                size="large">
								<CloseIcon />
							</IconButton> : null
						}

						<Divider className={classes.divider} orientation="vertical" />

						<IconButton
                            className={classes.iconButton}
                            onClick={this.toggleOpenAddExercisePopup}
                            size="large">
							<AddIcon />
						</IconButton>
					</Paper>

					<List dense>
						{searchedAvailableExercises.map(exercise => {
							return <ListItem
								key={exercise.id}
								button
								style={{paddingLeft: 0, paddingRight: 0}}
								onClick={() => this.updateStateCheckboxInputValues(exercise.id)}
							>
								<ListItemAvatar>
									<Avatar style={{backgroundColor: exercise.color ?? ColorPicker.pick(exercise.name)}} className={classes.small}>
										<Typography variant='body2'>
											{exercise.name.charAt(0).toUpperCase() ?? '?'}
										</Typography>
									</Avatar>
								</ListItemAvatar>

								<ListItemText primary={exercise.name} secondary={(exercise.category ? exercise.category.name : 'No category') + ' -  ' + exercise.type.name + (exercise.description ? (' - ' + exercise.description) : '')}/>

								<Checkbox
									checked={Boolean(this.state.selectedExercises[exercise.id])}
									color='secondary'
									disableRipple
								/>
							</ListItem>
						})}
					</List>

					<Grid container justifyContent="center" alignItems="center" direction='column'>
						{searchedAvailableExercises.length === 0 ?
							<Grid item>
								<Typography variant='body2'>No exercises found.</Typography>
							</Grid>
							: null
						}
					
						<Grid item>
							<Button type="button" onClick={this.toggleOpenAddExercisePopup} ><AddIcon/>  Add a new exercise</Button>
						</Grid>
					</Grid>
				</DialogContent>
				
				<DialogActions>
					<Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>Close</Button>
					<Button 
						variant="contained" 
						color="primary" 
						className={classes.button} 
						onClick={this.submit} 
						type="submit" 
						value="Submit"
						disabled={this.state.isSubmissionLoading || this.state.isLoading}
					>
						{this.state.isSubmissionLoading ?
							<div>
								<CircularProgress size="1rem" color="inherit" /> Loading...
							</div> :
							'Add To Routine ' + `(${Object.values(this.state.selectedExercises).filter(w => w).length})`
						}
					</Button>
				</DialogActions>

				<ExerciseNewOrEditFormPopUp
					isOpen={this.state.showAddExercisePopUp}
					name={this.state.searchBoxText.trim() !== '' ? this.state.searchBoxText : ''}
					closeSelfFunc={this.toggleCloseAllPopups}
				/>
			</Dialog>
        );
	}
}

export default withStyles(useStyles)(RoutineExercisesEditFormBloop);