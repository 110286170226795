import React, {Component} from "react";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import RoutineExerciseLogEditFormPopUp from "./RoutineExerciseLogEditFormPopUp";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from '@mui/icons-material/Delete';
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import {AttributeValuesPrettier} from "../../../core/prettifier/AttributeValuesPrettier";
import moment from "moment";
import Button from "@mui/material/Button";
import MessageIcon from "@mui/icons-material/Message";
import Popover from "@mui/material/Popover";
import {ExerciseTypeAttribute} from "../../../core/models/ExerciseTypeAttribute";
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

const useStyles = theme => ({
	root: {
		width: '100%',
		padding: 0,
	},
	logComment: {
		padding: theme.spacing(2),
	},
	iconButton: {
		border: `2px solid ${theme.palette.primary.main}`, // Adjust the border width and color as needed
	},
	loadMoreContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(1.5),
		width: '100%',
	},
	table: {
		display: 'table',
		width: '100%',
		maxWidth: 960,
		margin: '0 auto',
		overflow: 'hidden',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		height: theme.spacing(5.3),
	},
	nonHoverRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.paper,
		height: theme.spacing(5.5),
	},
	firstCell: {
		display: 'table-cell',
		padding: theme.spacing(2),
		flex: 2, // Change this to any number that is greater than 1 to make the first column wider
		textAlign: 'left',
	},
	cell: {
		display: 'table-cell',
		padding: theme.spacing(2),
		flex: 1,
		textAlign: 'left',
	},
	lastCell: {
		display: 'table-cell',
		padding: theme.spacing(2),
		flex: 1,
		textAlign: 'right',
	},
	header: {
		backgroundColor: theme.palette.background.default,
	},
});

class RoutineExerciseLogsTable extends Component {

	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'exercise': The exercise you want to add an exercise-log for.
	 *      'setSelectedLogParentFunc': Function to call to 'select' a log.
	 *      'refreshParentFunc': The function to call to refresh the parent.
	 *      'openFilterByMenu': The function to call to open the filter by menu.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise we want to add an exercise-log for.
			 */
			exercise: this.props.exercise,
			/**
			 * Indicates which 'edit log' popup is being shown.
			 */
			logInEditableMode: null,
			/**
			 * The dropdown menu for each exercise.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * The log to edit based on dropDownMenuAnchorEl.
			 */
			logToEdit: null,
			/**
			 * The popup for a log comment.
			 */
			dropDownCommentAnchorEl: null,
			/**
			 * A log comment to show in the dropDownCommentAnchorEl, if any.
			 */
			logComment: null,
		};

		this.toggleEditableLog = this.toggleEditableLog.bind(this);
		this.toggleCloseAllPopups = this.toggleCloseAllPopups.bind(this);
		this.deleteRoutineExerciseLog = this.deleteRoutineExerciseLog.bind(this);

		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 50
		});

		this.listRef = React.createRef();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			dropDownMenuAnchorEl: null,
			logToEdit: null,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.exercise !== this.state.exercise) {
			this.cache.clearAll();
			this.listRef.current.recomputeRowHeights();
		}
	}
	
	async deleteRoutineExerciseLog(event, log) {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.
		event.stopPropagation();

		await fetch(UrlBuilder.routine.routineExerciseLogsApi(log.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(() => this.props.refreshParentFunc("Log successfully deleted."));
	}

	openDropdownMenu = (event, logToEdit) => {
		event.preventDefault();
		event.stopPropagation();
		
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget,
			logToEdit: logToEdit,
		});
	};

	closeDropdownMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();
		
		this.setState({
			dropDownMenuAnchorEl: null,
			logToEdit: null,
		});
	};

	toggleEditableLog(e, log) {
		e.preventDefault();
		e.stopPropagation();
		
		this.setState({
			logInEditableMode: log,
			dropDownMenuAnchorEl: null
		});
	}

	toggleCloseAllPopups(shouldRefresh = true, withMessage = null) {
		this.setState({
			logInEditableMode: null,
		});
		if (shouldRefresh) {
			this.props.refreshParentFunc(withMessage);
		}
	}

	isToday = (someDateString) => {
		const someDate = new Date(someDateString);
		const today = new Date();
		return (someDate.toDateString() === today.toDateString());
	}

	openDropDownCommentAnchorEl = (event, comment) => {
		event.stopPropagation();
		this.setState({
			dropDownCommentAnchorEl: event.currentTarget,
			logComment: comment,
		});
	};

	closeDropDownCommentAnchorEl = () => {
		this.setState({
			dropDownCommentAnchorEl: null,
			logComment: null,
		});
	};

	renderLogGroup = ({ index, parent, key, style }) => {
		const { classes } = this.props;

		// Check if this is the "footer" row
		if (index === this.state.exercise.logGroups.length) {
			return (
				<CellMeasurer
					cache={this.cache}
					parent={parent}
					columnIndex={0}
					rowIndex={index}
					key={key}
				>
					<div style={style} key={key} className={classes.nonHoverRow}>
						{this.isRoutineOlderThanDefaultFetchDate() ?
							<div className={classes.loadMoreContainer}>
								{this.state.exercise.logGroups.length === 0 &&
									<>
										<Typography variant='caption'>
											No logs found so far. Try loading more.
										</Typography>
										<br/>
									</>
								}
								
								<div>
									<Button variant='outlined' className={classes.iconButton} onClick={this.props.openFilterByMenu}>
										LOAD MORE
									</Button>
								</div>
							</div>
							:
							<>
								{this.state.exercise.logGroups.length === 0 &&
									<div className={classes.loadMoreContainer}>
										<Typography variant='caption'>
											Logs list empty.
										</Typography>
									</div>
								}
							</>
						}
					</div>
				</CellMeasurer>
			);
		}

		let logGroup = this.state.exercise.logGroups[index];

		return (
			<CellMeasurer
				cache={this.cache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
				key={key}
			>
				<div style={style} key={key}>
					{/*The group */}
					<div className={`${classes.nonHoverRow}`}>
						<div className={classes.firstCell}>
							<Typography variant={"overline"} noWrap>
								{this.isToday(logGroup.date) ?
									<strong>{(new Date(logGroup.date)).toDateString()}</strong>
									: (new Date(logGroup.date)).toDateString()
								}
							</Typography>
						</div>
						{this.state.exercise.type.attributes.map(attribute =>
							<div key={"row_" + attribute.key} className={classes.cell}/>
						)}
						<div className={classes.lastCell}/>
					</div>

					{/*The logs in the group*/}
					{logGroup.logs.map((log, idx) =>
						<div key={"logs_" + log.id} className={classes.row} onClick={() => this.props.setSelectedLogParentFunc(log)}>
							<div className={classes.firstCell}>
								<Typography variant='caption'>
									&nbsp;&nbsp;<strong>{logGroup.logs.length - idx}</strong> - {moment(log.dateCreated).format('HH:mm')}
								</Typography>

								{log.comment ?
									<>
										&nbsp;
										<IconButton size='small' onClick={(e) => this.openDropDownCommentAnchorEl(e, log.comment)}>
											<MessageIcon/>
										</IconButton>
									</> : null
								}
							</div>

							{this.state.exercise.type.attributes.map((attribute, idx) =>
								<div key={"attr_" + attribute.key} className={classes.cell}>
									<Typography variant={"body2"}>
										{AttributeValuesPrettier.prettifyLogValueForAttribute(attribute.key, log.values[idx])}
										{attribute.key === ExerciseTypeAttribute.Reps && !isNaN(log.rpeValue) && !isNaN(log.rirValue) ?
											<Typography variant='caption'>
												@{log.rpeValue},{log.rirValue}
											</Typography> : null
										}
									</Typography>
								</div>
							)}

							<div className={classes.lastCell}>
								<IconButton
									id={log.id}
									onClick={(e) => this.openDropdownMenu(e, log)}
									size="small"
								>
									<MoreVertIcon />
								</IconButton>
							</div>
						</div>
					)}
				</div>
			</CellMeasurer>
		);
	}

	isRoutineOlderThanDefaultFetchDate = () => {
		return moment(this.state.routine.dateCreated).isBefore(moment().subtract(3, 'months'));
	}

	render() {
		const { classes } = this.props;
		let logGroups = this.state.exercise.logGroups ?? [];

		let totalRows = 0;
		logGroups.forEach(logGroup => {
			totalRows += logGroup.logs.length; // add the number of logs in this logGroup
			totalRows++; // increment for the logGroup itself
		});

		const rowHeight = 40; // replace this with the actual height of your rows
		const totalHeight = rowHeight + (totalRows * rowHeight) + 67;
		
		return (
			<div className={classes.root}>
				<RoutineExerciseLogEditFormPopUp
					exercise={this.state.exercise}
					isOpen={this.state.logInEditableMode !== null}
					log={this.state.logInEditableMode}
					closeSelfFunc={this.toggleCloseAllPopups}
				/>

				{this.state.logComment && this.state.dropDownCommentAnchorEl ?
					<Popover
						open={this.state.dropDownCommentAnchorEl !== null}
						anchorEl={this.state.dropDownCommentAnchorEl}
						onClose={this.closeDropDownCommentAnchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Typography variant='body2' className={classes.logComment}>
							{this.state.logComment}
						</Typography>
					</Popover> : null
				}

				{this.state.logToEdit && this.state.dropDownMenuAnchorEl ?
					<Menu
						id={this.state.logToEdit.id}
						anchorEl={this.state.dropDownMenuAnchorEl}
						open={Boolean(this.state.dropDownMenuAnchorEl && this.state.dropDownMenuAnchorEl.id == this.state.logToEdit.id)}
						onClose={this.closeDropdownMenu}
					>
						<MenuItem onClick={(e) => this.toggleEditableLog(e, this.state.logToEdit)}>
							<ListItemIcon>
								<EditIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Edit" />
						</MenuItem>
						<MenuItem onClick={e => this.deleteRoutineExerciseLog(e, this.state.logToEdit)}>
							<ListItemIcon>
								<DeleteIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Delete" />
						</MenuItem>
					</Menu> : null
				}
				
				<div className={classes.table}>
					{/*The top row (the head)*/}
					<div className={`${classes.nonHoverRow} ${classes.header}`}>
						<div className={classes.firstCell}>
							<Typography variant='overline'>
								Date
							</Typography>
						</div>
						{this.state.exercise.type.attributes.map(attribute =>
							<div key={"h_attr_" + attribute.key} className={classes.cell}>
								<Typography variant='overline'>
									{attribute.name}
								</Typography>
							</div>
						)}
						<div className={classes.lastCell}/>
					</div>

					{/*The table body*/}
					<AutoSizer disableHeight>
						{({width}) => (
							<List
								ref={this.listRef}
								width={width}
								height={Math.min(window.innerHeight * 0.75, totalHeight)}
								deferredMeasurementCache={this.cache}
								rowHeight={this.cache.rowHeight}
								rowCount={logGroups.length + 1} // Increment row count to account for footer
								rowRenderer={this.renderLogGroup}
								overscanRowCount={5} // This prop helps in faster scrolling
							/>
						)}
					</AutoSizer>
				</div>
			</div>
		);
	}
}

export default withStyles(useStyles)(RoutineExerciseLogsTable);