import React, {PureComponent} from 'react';
import { Line } from 'react-chartjs-2';
import {ColorPicker} from "../../core/colors/ColorPicker";

export default class LineGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			data: nextProps.data,
		});
	}
	
	render() {
		let color = ColorPicker.pick(this.state.data.label);
		
		const data = {
			labels: this.state.data.x,
			datasets: [
				{
					label: this.state.data.label,
					fill: false,
					lineTension: 0.1,
					backgroundColor: color,
					borderColor: color,
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: color,
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: color,
					pointHoverBorderColor: color,
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: this.state.data.y
				}
			]
		};
		
		let options= {
			legend: {
				display: typeof(this.props.showLegend) === 'boolean' ? this.props.showLegend : true,
			},
		};

		return (
			<Line ref="chart" data={data} options={options}/>
		);
	}

	componentDidMount() {
		const { datasets } = this.refs.chart.chartInstance.data
	}
}