import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {Avatar} from "@mui/material";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import LineGraph from "../../graphs/LineGraph";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {withRouter} from "../../../hooks/withRouter";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = theme => ({
	floatRight: {
		float: 'right'
	},
	grow: {
		flexGrow: 1,
	},
});

class MeasurementCard extends PureComponent {
	/**
	 * @param props Containing:
	 *      'measurement': The measurement.
	 *      'deleteSelfFunc': Deletes itself.
	 *      'editSelfFunc': Opens edit form for itself.
	 *      'dragHandle': The handle icon for dragging this card to reorder it.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The measurement.
			 */
			measurement: this.props.measurement,
			/**
			 * The dropdown menu for each measurement card.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * Is the collapse open?
			 */
			isCollapseOpen: true,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			measurement: nextProps.measurement,
			dropDownMenuAnchorEl: null
		});
	}

	openDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget,
		});
	};

	closeDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};

	triggerCollapse = () => {
		this.setState({
			isCollapseOpen: !this.state.isCollapseOpen
		});
	}
	
	getLineGraphData = () => {
		let x = [];
		let y = [];

		this.state.measurement.logs.forEach(log => {
			x.push((new Date(log.dateCreated)).toLocaleDateString());
			y.push(log.value);
		});

		return {
			x: x.reverse(),
			y: y.reverse(),
			label: this.state.measurement ? this.state.measurement.name : ''
		};
	}

	render() {
		const {classes} = this.props;

		return (
			<Card variant={'outlined'}>
				<CardActionArea>
				<CardHeader
					title={
						<Typography variant="button" display="block">
							{this.state.measurement.name}
						</Typography>
					}
					avatar={
						<Avatar style={{backgroundColor: this.state.measurement.color ? this.state.measurement.color : ColorPicker.pick(this.state.measurement.name)}}>
							{this.state.measurement.name.charAt(0).toUpperCase()}
						</Avatar>
					}
					// subheader={'hai'}
					action={
						<span>
							<IconButton
								id={this.state.measurement.id}
								size="small"
								className={classes.floatRight}
								onClick={this.openDropdownMenu}
							>
								<MoreVertIcon/>
							</IconButton>
							<Menu
								anchorEl={this.state.dropDownMenuAnchorEl}
								open={Boolean(this.state.dropDownMenuAnchorEl)}
								onClose={this.closeDropdownMenu}
								TransitionComponent={Fade}
							>
								<MenuItem onClick={this.props.editSelfFunc}>
									<ListItemIcon>
										<EditIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Edit"/>
								</MenuItem>
								<MenuItem onClick={this.props.deleteSelfFunc}>
									<ListItemIcon>
										<DeleteIcon fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Delete"/>
								</MenuItem>
							</Menu>
						</span>
					}
					onClick={() => this.props.setBloopOpen(this.state.measurement)}
				/>
				</CardActionArea>
				
				<Collapse in={this.state.isCollapseOpen}>
					<Divider/>
					<CardContent>
						{<LineGraph data={this.getLineGraphData()} showLegend={false}/>}
					</CardContent>
				</Collapse>

				<Divider/>

				<CardActions>
					<Button type="button" onClick={() => this.props.setBloopOpen(this.state.measurement)}>Open</Button>
					
					<div className={classes.grow}/>

					<IconButton size="small" onClick={this.triggerCollapse}>
						{this.state.isCollapseOpen ? <ExpandLess/> : <ExpandMore/>}
					</IconButton>

					{this.props.dragHandle}
				</CardActions>
			</Card>
		);
	}
}

export default withStyles(useStyles)(withRouter(MeasurementCard));