import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from '@mui/icons-material/Close';
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Box from "@mui/material/Box";
import {GraphQLQueryBuilder} from "../../../core/url/GraphQLQueryBuilder";
import MeasurementLogsTable from "./MeasurementLogsTable";
import LineGraph from "../../graphs/LineGraph";
import {UserContext} from "../../../core/UserContext";
import {withRouter} from "../../../hooks/withRouter";
import { red } from '@mui/material/colors';

const useStyles = theme => ({
	title: {
		marginLeft: theme.spacing(1),
		flex: 100,
	},
	container: {
		width: '100%',
		padding: 0,
		backgroundColor: theme.palette.background.default,
	},
	grow: {
		flexGrow: 1,
	},
	tabMenu: {
		color: "rgba(241,241,241,0.89)",
	},
	'@keyframes flicker': {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.4,
		},
	},
	flicker: {
		animationName: '$flicker',
		animationDuration: '1000ms',
		animationIterationCount: 'infinite',
		animationDirection: 'alternate',
		animationTimingFunction: 'ease-in-out',
		color: red[300],
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A card that can be popped up into fullscreen mode.
 */
class MeasurementDetailBloop extends PureComponent {

	/**
	 * @param props Containing:
	 *      'measurement': The measurement.
	 *      'isOpen': Is this bloop open?
	 *      'closeSelfFunc': Close self.
	 *      'updateParentMeasurement': The function the parent's measurement.
	 *      'switchMeasurementBloop': The function to switch which measurement is displayed here.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The measurement.
			 */
			measurement: this.props.measurement,
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: false,
			/**
			 * Indicates whether the bloop is open.
			 */
			isOpen: this.props.isOpen,
			/**
			 * The message to show for the toast notification. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationMessage: null,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			measurement: nextProps.measurement,
			isOpen: nextProps.isOpen,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Detects the browser back button and closes the detail popup.
		window.onpopstate = e => {
			this.props.closeSelfFunc();
		}
	}

	/**
	 * @param event
	 * @param reason
	 */
	closeToastNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			toastNotificationMessage: null,
		});
	};

	refresh = async (withMessage = null) => {
		this.setState({
			isLoading: true
		});

		await fetch(UrlBuilder.graphql, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}, // Add the ID of this measurement to fetch it only.
			body: GraphQLQueryBuilder.fullMeasurements(this.state.measurement.id),
		})
			.then(res => res.json())
			.then(res => {
				let measurement = res['data']['measurements'][0] ?? this.state.measurement;
				this.props.updateParentMeasurement(measurement); // This will pass the new measurement to the bloop, so not need to set it here.
				this.setState({
					isLoading: false,
					toastNotificationMessage: withMessage,
				});
			});
	}
	
	getLineGraphData = () => {
		let x = [];
		let y = [];
		
		this.state.measurement.logs.forEach(log => {
			x.push((new Date(log.dateCreated)).toLocaleDateString());
			y.push(log.value);
		});
		
		return {
			x: x.reverse(),
			y: y.reverse(),
			label: this.state.measurement ? this.state.measurement.name : ''
		};
	}
	
	render() {
		const {classes} = this.props;
		
		return (
            <Dialog fullScreen open={this.state.isOpen} onClose={this.props.closeSelfFunc} TransitionComponent={Transition}>
				<AppBar position={"relative"} enableColorOnDark>
					<Toolbar variant={"dense"}>
						<Typography variant="h6" noWrap className={classes.title}>
							{this.state.measurement?.name}
						</Typography>
						
						{this.context.user && this.context.user.activeWorkout ?
							<IconButton edge="end" size="large">
								<RadioButtonCheckedIcon onClick={() => this.context.openActiveWorkoutBloop(true)} className={classes.flicker}/>
							</IconButton> : null
						}

						<div className={classes.grow}/>

						<IconButton size={"small"} color="inherit" onClick={this.props.closeSelfFunc} aria-label="close">
							<CloseIcon/>
						</IconButton>
					</Toolbar>
				</AppBar>

				<DialogContent className={classes.container}>
					<Container className={classes.container}>
						<Box paddingTop={2}/>

						{this.state.measurement ? <LineGraph data={this.getLineGraphData()}/> : null}

						<br/>
						
						<MeasurementLogsTable
							measurement={this.state.measurement}
							isLoading={this.state.isLoading}
							refreshParentFunc={this.refresh}
						/>
					</Container>
				</DialogContent>

				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.toastNotificationMessage !== null}
					autoHideDuration={1500}
					onClose={this.closeToastNotification}
					message={this.state.toastNotificationMessage}
					action={
						<React.Fragment>
							<IconButton size="small" aria-label="close" color="inherit" onClick={this.closeToastNotification}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
				/>
			</Dialog>
        );
	}
}

MeasurementDetailBloop.contextType = UserContext;

export default withStyles(useStyles)(withRouter(MeasurementDetailBloop));