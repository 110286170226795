export class WorkoutVolumeParser {
	static parse(workouts) {
		let volumes = [];
		let dates = [];

		for (let i = 0; i < workouts.length; i++) {
			let workout = workouts[i];
			
			volumes.push(workout.totalVolume);
			dates.push((new Date(workout.dateTimeStarted)).toLocaleDateString());
		}
		
		return {
			y: volumes,
			x: dates,
			label: "Total Volume"
		};
	}
}