import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {Collapse, List, ListItem, ListItemSecondaryAction, ListItemText, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExampleExerciseNewOrEditFormPopUp from "../partials/ExampleExerciseNewOrEditFormPopUp";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

const useStyles = theme => ({
});


class Admin extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The exercise categories to load in the fetch (each category has exercises).
			 */
			exampleExerciseCategories: [],
			/**
			 * The category to add an exercise for.
			 */
			categoryToAddExerciseFor: null,
			/**
			 * The exercise to edit, if any.
			 */
			exerciseToEdit: null,
			/**
			 * Latest workouts.
			 */
			latestWorkouts: [],
			/**
			 * The days ago.
			 */
			daysAgo: null,
		};
	}

	componentDidMount() {
		this.refresh();
	}

	refresh = () => {
		this.fetchExerciseFormExamples();
	}

	fetchExerciseFormExamples = async () => {
		await fetch(
			UrlBuilder.exercise.allExerciseFormExamplesApi()
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					exampleExerciseCategories: response['data'] ?? [],
				});
			});
	}

	fetchLatestWorkouts = async () => {
		if (this.state.daysAgo === null) {
			return;
		}
		
		await fetch(
			UrlBuilder.workout.workoutsApi(null, this.state.daysAgo)
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					latestWorkouts: response['data'] ?? [],
				});
			});
	}
	
	openCollapse = (categoryName) => {
		this.setState({
			[categoryName]: !this.state[categoryName], // make it the opposite of what it was.
		});
	}

	closePopup = (shouldRefresh = false) => {
		this.setState({
			categoryToAddExerciseFor: null,
			exerciseToEdit: null,
		});
		
		if (shouldRefresh) {
			this.refresh();
		}
	}
	
	setCategoryToAddExerciseFor = (e, categoryToAddExerciseFor) => {
		e.stopPropagation();
		
		this.setState({
			categoryToAddExerciseFor: categoryToAddExerciseFor,
		});
	}
	
	setExerciseToEdit = (exercise) => {
		this.setState({
			exerciseToEdit: exercise,
		});
	}

	handleInputChange = event => {
		const value = event.target.value;
		this.setState({ daysAgo: value });
	};
	
	getDurationInHoursAndMinutes = (start, end) => {
		const startTime = new Date(start);
		const endTime = new Date(end);
		const durationInMs = endTime - startTime;

		const hours = Math.floor(durationInMs / (1000 * 60 * 60));
		const minutes = Math.floor((durationInMs % (1000 * 60 * 60)) / (1000 * 60));

		return `${hours}h ${minutes}m`;
	};
	
	renderLatestWorkouts = () => {
		const workouts = this.state.latestWorkouts;

		return <Box>
			<List>
				{workouts.slice().reverse().map((workout, index) => (
					<React.Fragment key={workout.id}>
						<ListItem>
							<ListItemText
								primary={
									<>
										<Typography variant="subtitle1">{`${workouts.length - index}. ${workout.user.name}`}</Typography>
										<Typography variant="body2" color="textSecondary">{`Email: ${workout.user.email}`}</Typography>
									</>
								}
								secondary={
									<>
										<Typography variant="body2" color="textSecondary">{`Started: ${new Date(workout.dateTimeStartedUtc).toLocaleString()}`}</Typography>
										{workout.dateTimeEndedUtc && (
											<Typography variant="body2" color="textSecondary">{`Ended: ${new Date(workout.dateTimeEndedUtc).toLocaleString()}`}</Typography>
										)}
										{workout.dateTimeEndedUtc && (
											<Typography variant="body2" color="textSecondary">{`Duration: ${this.getDurationInHoursAndMinutes(workout.dateTimeStartedUtc, workout.dateTimeEndedUtc)}`}</Typography>
										)}
									</>
								}
							/>
						</ListItem>
						{index !== workouts.length - 1 && <Divider variant="inset" component="li" />}
					</React.Fragment>
				))}
			</List>
		</Box>
	}

	render() {
		return (
			<>
				<ExampleExerciseNewOrEditFormPopUp
					exercise={this.state.exerciseToEdit}
					category={this.state.categoryToAddExerciseFor}
					closeSelfFunc={(shouldRefresh) => this.closePopup(shouldRefresh)}
				/>

				<TextField
					label="Days Ago"
					type="number"
					value={this.state.daysAgo}
					onChange={this.handleInputChange}
					fullWidth
					variant="outlined"
					margin="normal"
				/>
				<Button type="submit" variant="contained" color="primary" onClick={this.fetchLatestWorkouts}>
					Submit
				</Button>
				
				{this.renderLatestWorkouts()}
				
				<Typography variant='h4'>
					Categories
				</Typography>
				<List>
					{this.state.exampleExerciseCategories.map(category => 
						<>
							<ListItem button divider onClick={() => this.openCollapse(category.name)}>
								<ListItemText>
									{category.name}
								</ListItemText>
								
								<Button onClick={(e) => this.setCategoryToAddExerciseFor(e, category)}>
									ADD NEW
								</Button>
							</ListItem>
							
							<Collapse in={!!this.state[category.name]}>
								<List dense>
									{category.exampleExercises.map(exercise =>
										<ListItem divider button onClick={() => this.setExerciseToEdit(exercise)}>
											{exercise.name}
										</ListItem>
									)}
								</List>
							</Collapse>
						</>
					)}
				</List>
			</>
		);
	}
}

export default withStyles(useStyles)(Admin);