import { createTheme } from '@mui/material';
import {grey} from "@mui/material/colors";

const theme = createTheme({
	palette: {
		primary: {
			main: '#2d3b4a',
		},
		secondary: {
			main: '#f50057',
			stopWorkoutColor: '#ffcdd2',
			startWorkoutColor: '#c8e6c9',
		},
		contrastThreshold: 2,
		tonalOffset: 0.2,
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: {variant: "contained", color: "grey"},
					style: {
						backgroundColor: grey[300],
						color: '#242424',
					},
				},
			]
		},
		MuiLink: {
			defaultProps: {
				underline: 'none',
			},
		},
	},
});

export default theme;