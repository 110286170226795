import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import {Link as RouterLink} from 'react-router-dom';
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Skeleton from '@mui/material/Skeleton';
import Link from "@mui/material/Link";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {Avatar, Card} from "@mui/material";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import HelpIcon from '@mui/icons-material/Help';
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	appBar: {
		position: 'relative',
	},
	grow: {
		flexGrow: 1,
	},
	popOverText: {
		padding: theme.spacing(2),
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A card that can be popped up into fullscreen mode.
 */
class MoveRoutineExerciseDialog extends PureComponent {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise to move.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The exercise to move to another routine.
			 */
			exercise: props.exercise,
			/**
			 * The current routine.
			 */
			routine: props.routine,
			/**
			 * All the routines (to pick to where to move the exercise to).
			 */
			routines: UserLocalStorage.get(UrlBuilder.routine.routinesApi(null, true)) ?? null,
			/**
			 * Is it loading?
			 */
			isLoading: true,
			/**
			 * Where to show the help popup.
			 */
			helpPopupAnchorEl: null,
		};
	}
	
	componentDidMount() {
		this.fetch();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			routine: nextProps.routine,
		});
	}

	hasNeverBeenFetched = () => {
		return this.state.routines === null;
	}

	fetch = async () => {
		this.setState({
			isLoading: true
		});

		const response = await fetch(UrlBuilder.routine.routinesApi(null, true));
		const routines = await response.json();
		this.setState({
			routines: routines,
			isLoading: false
		});

		UserLocalStorage.set(UrlBuilder.routine.routinesApi(null, true), routines ?? []);
	}
	
	moveExerciseToRoutine = async (routine) => {
		this.setState({
			isLoading: true
		});
		
		let routineExerciseToUpdate = {
			id: this.state.exercise.routineExerciseId,
			routineId: routine.id,
		};

		await fetch(UrlBuilder.routine.routineExercisesApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(routineExerciseToUpdate)
		})
			.then(response => response.json())
			.then(() => this.props.closeSelfFunc(true,
				<>
					<>{this.state.exercise.name + ' has been moved to '}</>
					<Link color='secondary' component={RouterLink} to={UrlBuilder.redirect(UrlBuilder.routine.routineDetailPage(routine.id))}>{routine.name}</Link>
					<>{'.'}</>
				</>
			));
	}

	closeDropDownCommentAnchorEl = () => {
		this.setState({
			helpPopupAnchorEl: null
		});
	};

	openHelpPopupAnchorEl = (event) => {
		this.setState({
			helpPopupAnchorEl: event.currentTarget
		});
	};
	
	renderHelpPopup = () => {
		const {classes} = this.props;

		return <Popover
			open={this.state.helpPopupAnchorEl !== null}
			anchorEl={this.state.helpPopupAnchorEl}
			onClose={this.closeDropDownCommentAnchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Typography className={classes.popOverText}>
				Moves the exercise to another routine, along with all its data: logs, goals, and statistics. Your historical workouts will be updated to reflect this change.
			</Typography>
		</Popover>
	}

	renderLoadingDialog = () => {
		return (
            <List>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
			</List>
        );
	}

	renderRoutines = () => {
		return <List>
			{this.state.routines.map((routine) => (
				<ListItem button onClick={() => this.moveExerciseToRoutine(routine)} key={routine.id} disabled={routine.id === this.state.routine.id}>
					<ListItemAvatar>
						<Avatar style={{backgroundColor: routine.color ?? ColorPicker.pick(routine.name)}}>
							{routine.name.charAt(0).toUpperCase()}
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={routine.name} />
				</ListItem>
			))}
		</List>;
	}
	
	render() {
		const {classes} = this.props;

		let content;
		if (this.hasNeverBeenFetched()) {
			content = this.renderLoadingDialog();
		} else {
			content = this.renderRoutines();
		}
		
		return (
            <Dialog onClose={() => this.props.closeSelfFunc(false)} open={true} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} enableColorOnDark>
                    <Toolbar variant='dense'>
                        <Typography variant="h6" noWrap>
                            Move {this.state.exercise.name} to:
                        </Typography>

                        <div className={classes.grow}/>

                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={this.openHelpPopupAnchorEl}
                            size="large">
                            <HelpIcon/>
                        </IconButton>
                    </Toolbar>
                    <Fade in={this.state.isLoading}>
                        <LinearProgress />
                    </Fade>
                </AppBar>
                
                {this.renderHelpPopup()}

                {content}
            </Dialog>
        );
	}
}

export default withStyles(useStyles)(MoveRoutineExerciseDialog);