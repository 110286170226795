export class Utils {
	static _isPWA = null;

	/**
	 * https://stackoverflow.com/questions/59662776/how-to-distinguish-between-pwa-and-twa-in-code
	 * 
	 * @returns {null}
	 */
	static isApp() {
		if (Utils._isPWA === null) {
			// Utils._isPWA = !!document.referrer.includes('android-app://app.myworkinprogress.twa');
			Utils._isPWA = this.isAndroidApp();
		}
		return Utils._isPWA;
	}

	static isAndroidApp() {
		return document.referrer.includes('android-app://');
	}
}