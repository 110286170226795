import React, {PureComponent} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Link as RouterLink, Link} from "react-router-dom";
import {UserContext} from "../../core/UserContext";
import Button from "@mui/material/Button";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../core/PageDataContext";
import ActiveWorkoutButton from "./utils/ActiveWorkoutButton";
import {Utils} from "../../core/util/Utils";
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from "@mui/material/Badge";
import {withRouter} from "../../hooks/withRouter";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const useStyles = theme => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(1),
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
});

class TopNavMenu extends PureComponent {
	static displayName = TopNavMenu.name;

	constructor(props) {
		super(props);
		this.state = {};
	}

	goToSettingsPage = () => {
		this.props.navigate(UrlBuilder.user.settingsPage());
	};

	goToAdminPage = () => {
		this.props.navigate(UrlBuilder.admin.overviewPage());
	};

	render() {
		const { classes } = this.props;

		return (
            <AppBar position={"sticky"} enableColorOnDark>
				<Toolbar variant={"dense"}>
					<PageDataContext.Consumer>
						{({data, setPageData}) =>
							<Typography variant="h6" noWrap>
								<UserContext.Consumer>
									{({user, setUser}) => data.title ?? (user?.name ? (user.name + "'s Work in Progress") : "My Work in Progress")
									}
								</UserContext.Consumer>
							</Typography>
						}
					</PageDataContext.Consumer>

					<div className={classes.grow}/>

					<UserContext.Consumer>
						{({user, setUser}) => user ?
							<React.Fragment>
								<span className={ classes.sectionDesktop } >
									<Button component={Link} to={'/'} color={"inherit"}>Home</Button>
									<Button component={Link} to={UrlBuilder.routine.routineOverviewPage()}
									        color={"inherit"}>Routines</Button>
									<Button component={Link} to={UrlBuilder.exercise.exercisesOverviewPage()}
									        color={"inherit"}>Exercises</Button>
									<Button component={Link} to={UrlBuilder.me.mePage()}
									        color={"inherit"}>{'Me'}</Button>
								</span>

								<ActiveWorkoutButton/>

								{user.email === 'henryhs97@gmail.com' &&
									<IconButton edge="end" onClick={this.goToAdminPage} color="inherit" size="large">
										<AdminPanelSettingsIcon/>
									</IconButton>
								}

								<IconButton edge="end" onClick={this.goToSettingsPage} color="inherit" size="large">
									{user.hasNotifications ?
										<Badge badgeContent={1} color="secondary">
											<SettingsIcon />
										</Badge> 
										:
										<SettingsIcon />
									}
								</IconButton>
							</React.Fragment>
							:
							<span className={ classes.sectionDesktop } >
								{/*in TWA- mode, don't show Home */}
								{Utils.isApp()  ? null : <Button component={Link} to={'/'} color={"inherit"}>Home</Button>}
								<Button component={Link} to={UrlBuilder.user.SignInPage()} color={"inherit"}>Sign in</Button>
								<Button component={Link} to={UrlBuilder.user.SignUpPage()} color={"inherit"}>Sign up</Button>
							</span>
						}
					</UserContext.Consumer>
				</Toolbar>
			</AppBar>
        );
	}
}

export default withRouter((withStyles(useStyles)(TopNavMenu)))