import React, {PureComponent} from 'react';
import {
	Avatar,
	Card,
	CardContent,
	Typography,
	colors,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {AttributeValuesPrettier} from "../../../core/prettifier/AttributeValuesPrettier";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Chip from "@mui/material/Chip";
import moment from "moment";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CardActionArea from "@mui/material/CardActionArea";
import {withRouter} from "../../../hooks/withRouter";

const useStyles = theme => ({
	differenceIcon: {
		color: colors.red[900]
	},
	differenceValue: {
		color: colors.red[900],
		marginRight: theme.spacing(1)
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	floatRight: {
		float: 'right'
	},
});

class WorkoutCard extends PureComponent {

	/**
	 * @param props Containing:
	 *      'workout': The workout you want to show.
	 */
	constructor(props) {
		super(props);

		this.state = {
			workout: this.props.workout,
			openRoutineExerciseKey: null
		};
	}

	openRoutineExerciseCollapse = (routineExerciseKey) => {
		this.setState({
			openRoutineExerciseKey: routineExerciseKey !== this.state.openRoutineExerciseKey ? routineExerciseKey : null,
		});
	}

	redirectToWorkoutDetail = () => {
		this.props.navigate(UrlBuilder.workout.workoutDetailPage(this.state.workout.id));
	}

	render() {
		const {classes} = this.props;

		let avatar = this.state.workout.routines[0]?.name.charAt(0).toUpperCase() ?? '?';
		
		let workoutDurationObj = moment.duration(moment(this.state.workout.dateTimeEnded).diff(moment(this.state.workout.dateTimeStarted)));
		let workoutDuration = moment.utc(workoutDurationObj.asMilliseconds()).format('HH:mm');
		
		return (
			<Card variant={'outlined'}>
				<CardActionArea>
				<CardHeader
					avatar={
						<Avatar aria-label="recipe" style={{backgroundColor: this.state.workout.routines[0]?.color ?? ColorPicker.pick(this.state.workout.routines[0]?.name ?? '')}}>
							{avatar}
						</Avatar>
					}
					title={
						<Typography variant="button" display="block">
							{this.state.workout.name}
							<Chip label={workoutDuration} className={classes.floatRight} variant="outlined"/>
						</Typography>
				}
					subheader={DatePrettifier.prettifyLastTrained(new Date(this.state.workout.dateTimeStarted))}
					onClick={this.redirectToWorkoutDetail}
				/>
				</CardActionArea>

				<Divider/>

				<CardContent>
					<List dense>
						{this.state.workout.routines.map(routine =>
							routine.exercises.map(exercise => {
								const key = routine.id + '_' + exercise.id;

								return (
									<React.Fragment key={key}>
										<ListItem button onClick={() => this.openRoutineExerciseCollapse(key)}>
											<ListItemIcon>
												<FitnessCenterIcon/>
											</ListItemIcon>
											<ListItemText primary={exercise.name}/>
											{this.state.openRoutineExerciseKey === key ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>

										<Collapse in={this.state.openRoutineExerciseKey === key} timeout="auto" unmountOnExit>
											<List dense>
												{exercise.logs.map((log, idx) =>
													<ListItem key={log.id} className={classes.nested}>
														<ListItemIcon>
															<Typography variant="subtitle2">
																{idx + 1}
															</Typography>
														</ListItemIcon>
														<ListItemText primary={AttributeValuesPrettier.prettifyAttributeValues(exercise.type, log)}/>
													</ListItem>
												)}
											</List>
										</Collapse>
									</React.Fragment>
								);
							})
						)}
					</List>
				</CardContent>
			</Card>
		);
	}
}

export default withStyles(useStyles)(withRouter(WorkoutCard));