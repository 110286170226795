import React, {Component} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Box from "@mui/material/Box";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import RoutineExerciseDetailCard from "../partials/RoutineExerciseDetailCard";
import {PageDataContext} from "../../../core/PageDataContext";
import Fab from "@mui/material/Fab";
import WorkoutCallToAction from "../../workout/WorkoutCallToAction";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import Skeleton from '@mui/material/Skeleton';
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import IconButton from "@mui/material/IconButton";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import Typography from "@mui/material/Typography";
import RoutineExerciseDetailBloop from "../partials/RoutineExerciseDetailBloop";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import moment from "moment";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import RoutineExercisesEditFormBloop from "../partials/RoutineExercisesEditFormBloop";
import AddIcon from "@mui/icons-material/Add";
import RoutineSupersetMenuPopUp from "../partials/RoutineSupersetMenuPopUp";
import MoveRoutineExerciseDialog from "../partials/MoveRoutineExerciseDialog";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {withRouter} from "../../../hooks/withRouter";
import Slide from "@mui/material/Slide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SpeedDial from "@mui/material/SpeedDial";
import MenuIcon from "@mui/icons-material/Menu";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ArchiveIcon from "@mui/icons-material/Archive";

const useStyles = theme => ({
	speedDialWrapper: {
		margin: 0,
		top: 'auto',
		right: 35,
		bottom: 80,
		left: 'auto',
		position: 'fixed',
	},
	root: {
		width: '100%',
	},
	snackbar: {
		bottom: 75,
		[theme.breakpoints.down('sm')]: {
			bottom: 150,
		},
	},
	largeIcon: {
		width: 200,
		height: 200,
	},
	media: {
		height: 125,
	},
	mediaSmaller: {
		height: 65,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class RoutineDetail extends Component {
	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine we want to show details for.
			 */
			routine: UserLocalStorage.get(this.getApiUrl()) ?? null,
			/**
			 * Used as a component 'is loading' indicator.
			 */
			isLoading: true,
			/**
			 * Is the popup to add a new exercise to this routine open?
			 */
			isEditRoutineBloopOpen: false,
			/**
			 * The toast notification if it should show up. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationObject: null,
			/**
			 * The exercise ID we should open the bloop for. We use ID and not exercise to make sure the exercise we were given is not outdated.
			 * This class will always hold the most up-to-date exercise.
			 */
			routineExerciseIdBloopOpen: null,
			/**
			 * If we want to delete a routine-exercise, then we first confirm with a dialog.
			 */
			routineExerciseToConfirmDeletion: null,
			/**
			 * The routine exercise to move.
			 */
			exerciseToMove: null,
			/**
			 * The exercise to add a superset for.
			 */
			supersetMenuExercise: null,
			/**
			 * Speed dial open?
			 */
			isSpeedDialOpen: false,
			/**
			 * Are we going into archived mode?
			 */
			isInArchivedMode: false,
		};
	}
	
	getApiUrl = (datetime = null) => {
		return UrlBuilder.routine.routinesApi(
			this.props.params.routineId, 
			false,
			this.state?.isInArchivedMode ?? false,
			datetime
		);
	}

	componentDidMount() {
		if (this.hasNeverBeenFetched()) {
			this.fetchRoutine().then(() => {
				this.openBloopFromURLIfPossible();
			});
		} else {
			this.openBloopFromURLIfPossible();
			this.fetchRoutine(); // Don't try to open bloop after this.
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.isInArchivedMode !== this.state.isInArchivedMode) {
			this.refresh();
		}
	}
	
	getExerciseFromRoutineExerciseId = (routineExerciseId) => {
		if (routineExerciseId) {
			for (const exercise of this.state.routine.exercises) {
				if (exercise.routineExerciseId == routineExerciseId) {
					return exercise;
				}
			}
		}
		return null;
	}
	
	openBloopFromURLIfPossible = () => {
		let routineExerciseId = (new URLSearchParams(this.props.location.search)).get('exercise');
		if (routineExerciseId && this.state.routine && this.state.routine.exercises) {
			for (const exercise of this.state.routine.exercises) {
				if (exercise.routineExerciseId == routineExerciseId) {
					this.setState({
						routineExerciseIdBloopOpen: exercise.routineExerciseId,
					});
				}
			}
		}
	}

	fetchRoutine = async () => {
		this.setState({
			isLoading: true
		});

		let datetime = (moment()).subtract(3, 'months');

		await fetch(this.getApiUrl(datetime.format('L')))
			.then(response => response.json())
			.then(routine => {
				this.context.setPageData({
					title: routine.isArchived ? 
						(routine.name + ' - Archived' + (this.state?.isInArchivedMode ? ' Exercises' : '')) 
						: 
						(routine.name + (this.state?.isInArchivedMode ? ' - Archived Exercises' : '')),
				});
				
				this.setState({
					routine: routine,
					isLoading: false
				});
				
				UserLocalStorage.set(this.getApiUrl(), routine);
			});
	}

	refresh = () => {
		this.fetchRoutine();
	}

	handleBloopOpen = (exercise) => {
		if (exercise) {
			this.setState({
				routineExerciseIdBloopOpen: exercise.routineExerciseId,
			});

			let currentUrlParams = new URLSearchParams(window.location.search);
			currentUrlParams.set('exercise', exercise.routineExerciseId);
			this.props.navigate(window.location.pathname + "?" + currentUrlParams.toString());
		} else {
			this.setState({
				routineExerciseIdBloopOpen: null,
			});

			this.props.navigate(window.location.pathname);
		}
	};

	switchExerciseBloop = (exercise) => {
		this.setState({
			routineExerciseIdBloopOpen: null,
		});
		
		// Wait before doing this, to get the full animation :)
		setTimeout(() => this.handleBloopOpen(exercise), 200);
	}

	updateRoutineExercise = (newExercise) => {
		for (let i = 0; i < this.state.routine.exercises.length; i++) {
			let exercise = this.state.routine.exercises[i];

			if (exercise.routineExerciseId == newExercise.routineExerciseId) {
				let routine = this.state.routine;
				routine.exercises[i] = newExercise;
				this.setState({
					routine: routine,
				});
				UserLocalStorage.set(UrlBuilder.routine.routinesApi(this.props.params.routineId), routine);
				break;
			}
		}
	}

	toggleEditRoutineBloop = () => {
		this.setState({
			isEditRoutineBloopOpen: !this.state.isEditRoutineBloopOpen,
		});
	}

	refreshAfterEdit = (shouldRefresh = true) => {
		this.setState({
			isEditRoutineBloopOpen: false,
		});
		
		if (shouldRefresh) {
			this.refresh();
		}
	}

	deleteRoutineExercise = async (exercise) => {
		this.setState({
			isLoading: true,
			routineExerciseToConfirmDeletion: null
		});
		
		await fetch(UrlBuilder.routine.routineExercisesApi(exercise.routineExerciseId), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(this.refresh)

		this.showRoutineExerciseDeletedToastNotification(exercise);
	}

	showRoutineExerciseDeletedToastNotification = (exercise) => {
		const {classes} = this.props
		this.setState({
			toastNotificationObject: <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={exercise.name + " has been deleted."}
				className={classes.snackbar}
			/>
		});
	}

	showRoutineExerciseMovedToastNotification = (message) => {
		const {classes} = this.props
		this.setState({
			toastNotificationObject: <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={message}
				className={classes.snackbar}
			/>
		});
	}

	showExerciseArchiveToastNotification = (exercise, mode) => {
		const {classes} = this.props

		let toastNotificationObject;
		if (mode === 'archive_routine') {
			toastNotificationObject = <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={exercise.name + " has been archived."}
				className={classes.snackbar}
				action={
					<Button style={{ color: '#5d88bc'}} size="small" onClick={() => this.archiveRoutineExercise(exercise, false)}>
						Undo
					</Button>
				}
			/>;
		} else if (mode === 'unarchive_routine') {
			toastNotificationObject = <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={exercise.name + " has been unarchived."}
				className={classes.snackbar}
			/>;
		}

		this.setState({
			toastNotificationObject: toastNotificationObject
		});
	}
	
	closeToastNotification = (e, reason) => {
		this.setState({
			toastNotificationObject: null
		});
	}

	archiveRoutineExercise = async (exercise, shouldArchive) => {
		this.setState({
			isLoading: true
		});

		let routineExerciseView = {
			id: exercise.routineExerciseId,
			isArchived: shouldArchive
		};

		await fetch(UrlBuilder.routine.routineExercisesApi(), {
			method: 'PUT',
			headers: {
				'accept': 'application/json',
				'content-type': 'application/json'
			},
			body: JSON.stringify(routineExerciseView)
		})
			.then(this.refresh);

		// Refresh will set isLoading to false if success.

		let mode = shouldArchive ? 'archive_routine' : 'unarchive_routine';
		this.showExerciseArchiveToastNotification(exercise, mode)
	}

	renderEmptyExercises = () => {
		const {classes} = this.props;

		return <>
			<Box paddingTop={5}/>

			<Grid container justifyContent="center">
				{this.state.isInArchivedMode ?
					<IconButton
						touch
						tooltip="Go Back"
						onClick={() => this.toggleArchivedMode(false)}
						size="large">
						<ArrowBackIcon className={classes.largeIcon}/>
					</IconButton>
					:
					<IconButton
						touch
						tooltip="See routines"
						onClick={this.toggleEditRoutineBloop}
						size="large">
						<AddToPhotosIcon className={classes.largeIcon}/>
					</IconButton>
				}
			</Grid>
			<Grid container justifyContent="center">
				<Typography variant="overline" gutterBottom>
					{this.state.isInArchivedMode ?
						"You have no archived exercises"
						: "No exercises in routine"
					}
				</Typography>
			</Grid>

			<Grid container justifyContent="center">
				<Button>
					{this.state.isInArchivedMode ?
						<Typography variant="overline" onClick={() => this.toggleArchivedMode(false)}>
							<strong>Go back</strong>
						</Typography>
						:
						<Typography variant="overline" onClick={this.toggleEditRoutineBloop}>
							<strong>Add an exercise</strong>
						</Typography>
					}
				</Button>
			</Grid>
		</>;
	}

	 reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	onDragEnd = async (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		
		let routine = this.state.routine;

		routine.exercises = this.reorder(
			routine.exercises,
			result.source.index,
			result.destination.index
		);

		this.setState({
			routine: routine
		});
		this.forceUpdate();
		
		await this.submitRoutineExerciseReordering(routine.exercises);
	};
	
	submitRoutineExerciseReordering = async (exercises) => {
		let exerciseViews = [];
		exercises.forEach(exercise => exerciseViews.push({
			routineExerciseId: exercise.routineExerciseId
		}));
		
		let routine = {
			Id: this.state.routine.id,
			exercises: exerciseViews,
		}
		
		await fetch(UrlBuilder.routine.routinesApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(routine)
		});
		
		UserLocalStorage.remove(this.getApiUrl()); // Let's stale the localStorage so that the next time a refresh occurs it doesnt look choppy.
	}
	
	openSupersetMenu = (exercise) => {
		this.setState({
			supersetMenuExercise: exercise,
		});

		if (exercise === null) {
			this.refresh();
		}
	}

	renderExerciseCards = () => {
		return <>
			{!this.state.routine.isArchived && !this.state.isInArchivedMode ? <WorkoutCallToAction/> : null}

			<br/><Divider light/><br/>

			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<Grid container spacing={1} ref={provided.innerRef}>
							{this.state.routine.exercises.map((exercise, index) => {
								let key = "exercise_" + exercise.routineExerciseId;
								return <Draggable key={key} draggableId={key} index={index}>
									{(provided, snapshot) => (
										<Grid item xs={12} sm={12} md={12} ref={provided.innerRef}{...provided.draggableProps}>
											<RoutineExerciseDetailCard
												exercise={exercise}
												routine={this.state.routine}
												deleteSelfFunc={this.openRoutineExerciseDeleteConfirmationDialog}
												openMoveRoutineExerciseDialog={this.openMoveRoutineExerciseDialog}
												archiveRoutineExercise={(shouldArchive) => this.archiveRoutineExercise(exercise, shouldArchive)}
												setBloopOpen={this.handleBloopOpen}
												isInArchivedMode={this.state.isInArchivedMode}
												openSupersetMenu={this.openSupersetMenu}
												dragHandle={<Typography {...provided.dragHandleProps}><IconButton size="small"><UnfoldMoreIcon color="action" /></IconButton></Typography>}
											/>
										</Grid>
									)}
								</Draggable>
							})}
						</Grid>
					)}
				</Droppable>
			</DragDropContext>

			<RoutineExerciseDetailBloop
				exercise={this.getExerciseFromRoutineExerciseId(this.state.routineExerciseIdBloopOpen)}
				routine={this.state.routine}
				isOpen={this.state.routineExerciseIdBloopOpen !== null}
				closeSelfFunc={() => this.handleBloopOpen(null)}
				updateParentExercise={this.updateRoutineExercise}
				switchExerciseBloop={this.switchExerciseBloop}
			/>
			
			<RoutineSupersetMenuPopUp
				routine={this.state.routine}
				exercise={this.state.supersetMenuExercise}
				isOpen={this.state.supersetMenuExercise !== null}
				closeSelfFunc={() => this.openSupersetMenu(null)}
			/>
		</>;
	}
	
	renderPage = () => {
		return (
			<>
				{!this.state.routine.isArchived ?
					<RoutineExercisesEditFormBloop
						routine={this.state.routine}
						isOpen={this.state.isEditRoutineBloopOpen}
						closeSelfFunc={this.refreshAfterEdit}
					/> : null
				}
				
				{this.state.routine.exercises && this.state.routine.exercises.length > 0 ?
					this.renderExerciseCards()
					: this.renderEmptyExercises()
				}
			</>
		);
	}

	hasNeverBeenFetched = () => {
		return this.state.routine === null;
	}

	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="wave" variant="rectangular" className={classes.mediaSmaller} />
			<br/><Divider light /><br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}

	renderRoutineExerciseDeleteConfirmationDialog = () => {
		if (!this.state.routineExerciseToConfirmDeletion) {
			return null;
		}

		return <Dialog
			open={true}
			onClose={() => this.openRoutineExerciseDeleteConfirmationDialog(null)}
			TransitionComponent={Transition}
		>
			<DialogTitle>{"Are you sure you want to delete " + this.state.routineExerciseToConfirmDeletion.name + '?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This will delete this routine-exercise combination and its logs (including your historical workout logs for this specific routine-exercise). Other logs for this exercise will not be affected.
					<br/>
					This cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' color='grey' onClick={() => this.openRoutineExerciseDeleteConfirmationDialog(null)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={() => this.deleteRoutineExercise(this.state.routineExerciseToConfirmDeletion)} color="primary" autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	}

	openRoutineExerciseDeleteConfirmationDialog = (routineExercise) => {
		this.setState({
			routineExerciseToConfirmDeletion: routineExercise,
		});
	}

	renderMoveRoutineExerciseDeleteConfirmationDialog = () => {
		if (!this.state.exerciseToMove) {
			return null;
		}

		return <MoveRoutineExerciseDialog
			exercise={this.state.exerciseToMove}
			routine={this.state.routine}
			closeSelfFunc={(shouldRefresh, message = null) => this.openMoveRoutineExerciseDialog(null, shouldRefresh, message)}
		/>
	}
	
	openMoveRoutineExerciseDialog = (exercise, shouldRefresh = false, message= null) => {
		this.setState({
			exerciseToMove: exercise,
		});

		if (shouldRefresh) {
			this.refresh();
			if (message) { // Have the previous one at hand to show a toast notification.
				this.showRoutineExerciseMovedToastNotification(message);
			}
		}
	}

	openSpeedDial = (isOpen) => {
		this.setState({
			isSpeedDialOpen: isOpen,
		})
	}

	toggleArchivedMode = (isInArchiveMode) => {
		this.setState({
			isInArchivedMode: isInArchiveMode,
			isSpeedDialOpen: false,
			routine: null  //  will fetch again, so set routines null
		});
	}

	renderSpeedDial = () => {
		const {classes} = this.props

		if (this.state.isInArchivedMode) {
			return <Fab color="primary" className={classes.speedDialWrapper} onClick={() => this.toggleArchivedMode(false)}>
				<ArrowBackIcon />
			</Fab>
		}

		return <SpeedDial
			className={classes.speedDialWrapper}
			icon={<MenuIcon/>}
			onClose={() => this.openSpeedDial(false)}
			onOpen={() => this.openSpeedDial(true)}
			disabled={!this.state.routine || this.state.routine.isArchived}
			open={this.state.isSpeedDialOpen}
			ariaLabel={'Routine Menu'}
		>
			<SpeedDialAction
				icon={<AddIcon/>}
				tooltipTitle={<Typography noWrap>Add Exercise</Typography>}
				tooltipOpen
				onClick={this.toggleEditRoutineBloop}
				title={'Add Exercise'}
			/>
			<SpeedDialAction
				icon={<ArchiveIcon/>}
				tooltipTitle={<Typography noWrap>Archived</Typography>}
				tooltipOpen
				onClick={() => this.toggleArchivedMode(true)}
				title={'Archived'}
			/>
		</SpeedDial>;
	}

	render() {
		const {classes} = this.props;

		return (
			<div>
				<Fade in={this.state.isLoading}>
					<LinearProgress />
				</Fade>

				<Box paddingTop={1} />
				
				{this.renderRoutineExerciseDeleteConfirmationDialog()}

				{this.renderMoveRoutineExerciseDeleteConfirmationDialog()}

				{
					this.hasNeverBeenFetched() ?
						this.renderLoadingSkeletons()
						: this.renderPage()
				}

				{this.renderSpeedDial()}

				{this.state.toastNotificationObject}
			</div>
		);
	}
}

RoutineDetail.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(RoutineDetail));