import React, {PureComponent} from "react";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from "@mui/material/InputAdornment";
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from "@mui/material/Grid";
import {HoursMinutesTimeConverter} from "../../../core/prettifier/HoursMinutesTimeConverter";
import Avatar from "@mui/material/Avatar";
import {AttributeValuesPrettier} from "../../../core/prettifier/AttributeValuesPrettier";
import Chip from "@mui/material/Chip";
import GradeIcon from '@mui/icons-material/Grade';
import {UnitPrettifier} from "../../../core/prettifier/UnitPrettifier";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import BookIcon from '@mui/icons-material/Book';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {ButtonGroup, Checkbox, FormControlLabel} from "@mui/material";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import MessageIcon from '@mui/icons-material/Message';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import {pulse} from "react-animations";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import WorkoutPlanCard from "./WorkoutPlanCard";
import {UserContext} from "../../../core/UserContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider, StaticTimePicker, TimePicker} from "@mui/lab";
import {keyframes} from "@mui/material/styles";
import { styled } from '@mui/system';
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import {ExerciseType} from "../../../core/models/ExerciseType";
import {ExerciseTypeAttribute} from "../../../core/models/ExerciseTypeAttribute";
import TimerIcon from '@mui/icons-material/Timer';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SettingsIcon from '@mui/icons-material/Settings';
import TimerSettingsDialog from "./TimerSettingsDialog";

const pulseAnimation = keyframes`${pulse}`;
const PulseDiv = styled('div')(({ theme }) => ({
	animation: `1.5s ${pulseAnimation} infinite`
}));

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
	};
}

const useStyles = theme => ({
	card: {
		minWidth: 475,
		[theme.breakpoints.down('sm')]: {
			minWidth: '95%',
		},
	},
	inputField: {
		maxWidth: 110,
	},
	margin: {
		marginBottom: 10,
		marginTop: 10,
	},
	timerInputField: {
		maxWidth: 80,
	},
	inputFieldLarger: {
		maxWidth: 125,
	},
	avatarButton: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
		backgroundColor: theme.palette.primary.main,
	},
	grow: {
		flexGrow: 1,
	},
	chip: {
		// https://github.com/mui-org/material-ui/issues/14864
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		minHeight: '2rem'
	},
	timer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	timerInfoText: {
		fontSize: 15,
		color: '#aaa',
	},
	timerLogIcon: {
		width: 48,
		height: 48,
	},
	popOverText: {
		padding: theme.spacing(2),
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

/**
 * Note this is very similar to {@see RoutineExerciseLogEditFormPopUp}, so keep both updated.
 */
class RoutineExerciseLogNewFormBox extends PureComponent {
	/**
	 * @param props Containing:
	 *      'routine': The routine you want to add an exercise-log for.
	 *      'exercise': The exercise you want to add an exercise-log for.
	 *      'selectedLog': If any, this will make the default values set to the ones in this selected log.
	 *      'refreshParentFunc': The function to call to refresh the parent.
	 *      'switchExerciseBloop': The function to switch which exercise is displayed here.
	 *      'shouldAcceptNextProps': Whether or not we should accept the next props. Used to prevent setting state too much.
	 *      'scrollToTopFunc': Function to scroll to the top.
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine we want to add an exercise-log for.
			 */
			routine: this.props.routine,
			/**
			 * The exercise we want to add an exercise-log for.
			 */
			exercise: this.props.exercise,
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: false,

			/**
			 * The comment, if any.
			 */
			comment: '',

			/**
			 * The selected tab index. By default it is 1 (the logger).
			 */
			selectedTabIndex: 1,


			/**
			 * The timer time.
			 */
			timerTime: this.getTimerValue(this.props.exercise),
			/**
			 * Is the timer on? (aka is it paused or ticking?)
			 */
			isTimerOn: false,
			/**
			 * The key of the circle timer. Used to reset the timer. (https://github.com/vydimitrov/react-countdown-circle-timer#restartreset-timer-at-any-given-time)
			 */
			circleTimerKey: 0,
			/**
			 * The exercise to switch to (in superset) after the timer ends.
			 */
			exerciseToSwitchToAfterTimerIsOver : null,
			/**
			 * Are the timer settings open?
			 */
			isTimerSettingsOpen: false,


			/**
			 * The popup for logging RPE.
			 */
			dropDownRPEAnchorEl: null,
			/**
			 * The RPE value, if applicable (basically when an attribute is 'Reps').
			 */
			RPE: 8,
			/**
			 * The RIR value, if applicable (basically when an attribute is 'Reps').
			 */
			RIR: 2,
			/**
			 * Should we save the RPE/RIR value?  (basically when an attribute is 'Reps').
			 */
			shouldSaveRPE: false,
			/**
			 * The popup for the comment.
			 */
			dropDownCommentAnchorEl: null,


			/**
			 * The popup for the log dateCreated overwrite.
			 */
			dropDownLogDateCreatedOverwriteAnchorEl: null,
			/**
			 * The custom date a log was created, if any.
			 */
			customLogDateCreated: null,
			/**
			 * Should we save the customLogDateCreated value?
			 */
			shouldSaveCustomLogDateCreated: false,
			/**
			 * Is the tooltip for log date creation overwrite open?
			 */
			logDateCreatedOverwriteTooltipAnchorEl: null,
		};

		this.timer = null; // Used for mouse long press functionality in up/down buttons.
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.shouldAcceptNextProps) {
			// shouldAcceptNextProps is important here to prevent getDefaultAttribute values from doing work when props are
			// sent from the parent needlessly. This happens when we set isLoading to true in the parent, but that doesn't
			// actually impact the attributes here.
			let	newState;
			if (this.state.exercise.routineExerciseId != nextProps.exercise.routineExerciseId) {
				// If exercise changed -> isFirstTime
				newState = this.getLogValues(nextProps.exercise, true, nextProps.selectedLog ?? null);

				// We update the time and key if we switch exercises (only allowed when isTimerOn is false ofc).
				newState['circleTimerKey'] = this.state.circleTimerKey + 1;
				newState['exerciseToSwitchToAfterTimerIsOver'] = null;
				newState['selectedTabIndex'] = 1;
				newState['isTimerOn'] = false;
				newState['timerTime'] = this.getTimerValue(nextProps.exercise);
			} else {
				newState = this.getLogValues(nextProps.exercise, false, nextProps.selectedLog ?? null);
			}

			newState['exercise'] = nextProps.exercise;
			newState['routine'] = nextProps.routine;
			newState['comment'] = '';
			newState['shouldSaveRPE'] = false; // Reset whenever a log is submitted or exercise changes.
			newState['shouldSaveCustomLogDateCreated'] = false; // Reset whenever a log is submitted or exercise changes.

			this.setState(newState);
		}
	}

	componentDidMount() {
		let newAttributes = this.getLogValues(this.state.exercise, true);
		this.setState(newAttributes);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}

	getTimerValue = (exercise) => {
		if (UserLocalStorage.get('global_timer_value_setting')) {
			return UserLocalStorage.get('global_timer_value') ?? UserLocalStorage.get(this.getRoutineExerciseRestTimerCacheKey(exercise)) ?? 45;
		}
		
		return UserLocalStorage.get(this.getRoutineExerciseRestTimerCacheKey(exercise)) ?? 45;
	}
	
	submitWithEnter = (e) => {
		if (e.key === 'Enter' && !this.state.isLoading) {
			this.submit(e);
		}
	}

	/**
	 * From the exercise, it gets the default attribute values to put in the box.
	 */
	getLogValues = (exercise, isFirstTime, selectedLog = null) => {
		let newLogValues = {};

		let attributes = exercise.type.attributes;

		if (selectedLog) {
			attributes.forEach((attribute, idx) => {
				newLogValues[attribute.key] = selectedLog.values[idx];
			});
			
			if (!isNaN(selectedLog.rpeValue) && !isNaN(selectedLog.rirValue)) {
				newLogValues['RPE'] = selectedLog.rpeValue;
				newLogValues['RIR'] = selectedLog.rirValue;
			}

			newLogValues['customLogDateCreated'] = new Date(selectedLog.dateCreated);
		} else if (isFirstTime) {
			if (exercise.logGroups && exercise.logGroups[0] && exercise.logGroups[0].logs && exercise.logGroups[0].logs[0]) {
				let firstLog = exercise.logGroups[0].logs[0];

				attributes.forEach((attribute, idx) => {
					newLogValues[attribute.key] = firstLog.values[idx];
				});

				if (!isNaN(firstLog.rpeValue) && !isNaN(firstLog.rirValue)) {
					newLogValues['RPE'] = firstLog.rpeValue;
					newLogValues['RIR'] = firstLog.rirValue;
				}
			} else { // Default scenario.
				attributes.forEach((attribute, idx) => {
					newLogValues[attribute.key] = 8; // default
				});

				newLogValues['RPE'] = 8; // Default value
				newLogValues['RIR'] = 2; // Default value
			}

			if (this.context.user?.activeWorkout?.isPastWorkout) { // Default customLogDateCreated is when the workout started if it's a past workout.
				newLogValues['customLogDateCreated'] = new Date(this.context.user.activeWorkout.dateTimeStarted);
			} else {
				newLogValues['customLogDateCreated'] = new Date(); // otherwise it's now.
			}
		}

		return newLogValues;
	}

	submit = async (event, useLogDateCreatedOverwrite = false, submitAndStartTimer = false) => {
		event?.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		this.setState({
			isLoading: true
		});

		let values = [];
		for (const attribute of this.state.exercise.type.attributes) {
			values.push(this.state[attribute.key]);
		}

		let exerciseLogToSave = {
			routineExerciseId: this.state.exercise.routineExerciseId,
			values: values,
		};

		if (useLogDateCreatedOverwrite) {
			let customLogDateCreated = this.state.customLogDateCreated;
			if (!customLogDateCreated) {
				await this.props.refreshParentFunc("No custom log creation date given.");
				return;
			}
			customLogDateCreated = new Date(customLogDateCreated);

			let workoutStartedDate = this.context.user.activeWorkout?.dateTimeStarted ?? null;
			workoutStartedDate = workoutStartedDate ? new Date(workoutStartedDate) : new Date(); // Keep the workout date but change the hours and minutes. If no workout was started, the start date is now.
			workoutStartedDate.setHours(customLogDateCreated.getHours());
			workoutStartedDate.setMinutes(customLogDateCreated.getMinutes());

			exerciseLogToSave['dateCreated'] = moment(workoutStartedDate).format('L, h:mm:ss A');
		} else if (this.state.shouldSaveCustomLogDateCreated && this.state.customLogDateCreated) {
			let customLogDateCreated = new Date(this.state.customLogDateCreated);

			let dateCreated =  new Date();
			dateCreated.setHours(customLogDateCreated.getHours());
			dateCreated.setMinutes(customLogDateCreated.getMinutes());

			exerciseLogToSave['dateCreated'] = moment(dateCreated).format('L, h:mm:ss A');
		} else {
			exerciseLogToSave['dateCreated'] = moment().format('L, h:mm:ss A');
		}

		if (this.state.shouldSaveRPE) {
			exerciseLogToSave['rpeValue'] = this.state.RPE;
			exerciseLogToSave['rirValue'] = this.state.RIR;
		}

		if (this.state.comment && this.state.comment.trim()) {
			exerciseLogToSave['comment'] = this.state.comment;
		}

		let workoutStarted = false;
		if (!this.context.user.activeWorkout) {
			// This has purposefully been put in this order. The startWorkout() must come before all the logic, as startWorkout() will wipe the state above due to componentWillReceiveProps().
			await this.startWorkout(exerciseLogToSave['dateCreated']);
			workoutStarted = true;
		}

		let isSuccess = false;
		await fetch(UrlBuilder.routine.routineExerciseLogsApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(exerciseLogToSave)
		})
			.then(() => {
				isSuccess = true;
			})
			.catch(() => {
				isSuccess = false;
			});

		this.setState({
			isLoading: false,
			dropDownLogDateCreatedOverwriteAnchorEl: null, // In case this was open, close it.
		});

		if (isSuccess) {
			let latestLogGroup = this.state.exercise.logGroups[0] ?? null;
			// We do a quick fetch whenever the current workout is today and the latest log group coincides with the current workout date.
			let isQuickFetch = latestLogGroup &&
				this.context.user?.activeWorkout &&
				!this.context.user.activeWorkout.isPastWorkout && 
				(
					moment(latestLogGroup.date).format('YYYY-MM-DD') === 
					moment(this.context.user.activeWorkout.dateTimeStarted).format('YYYY-MM-DD')
				);

			await this.props.refreshParentFunc(workoutStarted ? 
				"Workout started and log saved." : submitAndStartTimer ? "Log successfully saved and timer started." : "Log successfully saved.", 
				null, 
				false, 
				isQuickFetch
			);

			if (!submitAndStartTimer) { // If we don't start timer automatically, we go to next exercise if possible.
				const nextExerciseInSuperset = this.findNextExerciseInSupersetIfPossible();
				if (nextExerciseInSuperset) {
					this.props.switchExerciseBloop(nextExerciseInSuperset);
				}
			}
		} else {
			await this.props.refreshParentFunc("Something went wrong with saving the log.");
		}
	}

	findNextExerciseInSupersetIfPossible = () => {
		if (!this.state.exercise.superset) {
			return null;
		}

		let isFound = false;
		for (const exercise of this.state.routine.exercises) {
			if (exercise.routineExerciseId === this.state.exercise.routineExerciseId) {
				isFound = true;
				continue;
			}

			// We try to find the next exercise in the list that is in the superset.
			if (isFound && this.state.exercise.superset.id === exercise.superset?.id) {
				return exercise;
			}
		}

		// If we reached here is because we want to start searching for another exercise in the superset that was BEFORE the exercise we're currently in (search from the front).
		if (isFound) {
			for (const exercise of this.state.routine.exercises) {
				// If we find the same exercise we're in immediately, forget it. Return. There is no other exercise in the superset.
				if (exercise.routineExerciseId === this.state.exercise.routineExerciseId) {
					return null;
				}

				// We try to find the next exercise in the list that is in the superset.
				if (this.state.exercise.superset.id === exercise.superset?.id) {
					return exercise;
				}
			}
		}

		return null;
	}

	updateStateAttributeValues = (event) => {
		if (event.target.name === 'RIR') {
			let RIR = event.target.value;
			let RPE = 10 - RIR;

			this.setState({
				[event.target.name]: RIR,
				RPE: RPE < 1 ? 1 : RPE,
				shouldSaveRPE: true,
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	}

	/**
	 * @param val A string representing a dateTime object.
	 */
	updateTimeAttributeValue = (val) => {
		this.setState({
			time: (HoursMinutesTimeConverter.convertDateStringToFloat(val)),
		});
	}

	isAcceptableInput = (numberString) => {
		let number = Number(numberString);
		return !isNaN(number) && number > 0 ;
	}

	canSubmit = () => {
		if (this.state.isLoading || this.state.selectedTabIndex !== 1) {
			return false;
		}

		let attributes = this.state.exercise.type.attributes;
		for (let i = 0; i < attributes.length; i++) {
			let attribute = attributes[i];

			if (!this.isAcceptableInput(this.state[attribute.key])) {
				return false;
			}
		}

		if (this.state.RPE && (isNaN(this.state.RPE) || this.state.RPE < 1)) {
			return false;
		}

		if (this.state.RIR && (isNaN(this.state.RIR) || this.state.RIR < 0)) {
			return false;
		}

		return true;
	}

	/**
	 * @param attribute
	 * @param isSmall Should it increase/decrease 'little' or a lot?
	 * @param isDecrease Is it a decrease? Otherwise it's an increase.
	 */
	incdecAttributeValue = (attribute, isSmall, isDecrease) => {
		let val;
		if (isSmall) {
			if (attribute.key === ExerciseTypeAttribute.Time) {
				val = 0.0166666666666666667; // one second in scale of 1-100.
			} else {
				val = 0.5;
			}
		} else {
			val = 1;
		}

		if (isDecrease) {
			val *= -1.0;
		}

		if (this.state[attribute.key] + val < 0) {
			this.stopTimer(); // Just for safety
			return;
		}

		let number = (this.isAcceptableInput(this.state[attribute.key]) ? Number(this.state[attribute.key]) : 0) + val;
		// We don't round time because we need it to be as precise as possible.
		number = attribute.key === ExerciseTypeAttribute.Time ? number : Math.round(number * 100) / 100;

		if (attribute.key === 'RIR') { // Business rule: When the attribute is RIR, we want to also set RPE.
			let RPE = 10 - number;
			this.setState({
				[attribute.key]: number,
				RPE: RPE < 1 ? 1 : RPE,
				shouldSaveRPE: true, // Set this to true if they tried to change it.
			});
		} else {
			this.setState({
				[attribute.key]: number
			});
		}

		// Makes the function be executed after waiting 200ms.
		this.timer = setTimeout(() => this.incdecAttributeValue(attribute, isSmall, isDecrease), 200); // Store the timer. We will destroy when mouse is up.
	}

	stopTimer = () => {
		// To prevent the setTimeout function from running.
		clearTimeout(this.timer);
	}

	getBest = () => {
		if (this.state.exercise.type.id === ExerciseType.Reps) {
			let repsAttribute = this.state.exercise.type.attributes[0];
			if (repsAttribute) {
				let best = null;

				this.state.exercise.logGroups.forEach(logGroup => {
					let topRepValue = logGroup.logs[logGroup.topRepsLogIdx]?.values[0];
					if (best === null || topRepValue > best) {
						best = topRepValue;
					}
				});

				if (best) {
					return 'Highest done: ' + AttributeValuesPrettier.prettifyLogValueForAttribute(ExerciseTypeAttribute.Reps, best);
				}
			}
			
			return null;
		}

		if (this.state.exercise.type.id === ExerciseType.Time) {
			let timeAttribute = this.state.exercise.type.attributes[0];
			if (timeAttribute) {
				let best = null;

				this.state.exercise.logGroups.forEach(logGroup => {
					let topTimeValue = logGroup.logs[logGroup.topTimeLogIdx]?.values[0];
					if (best === null || topTimeValue > best) {
						best = topTimeValue;
					}
				});

				if (best) {
					return 'Highest done: ' + AttributeValuesPrettier.prettifyLogValueForAttribute(ExerciseTypeAttribute.Time, best);
				}
			}
			
			return null;
		}

		if (this.state.exercise.type.id === ExerciseType.WeightAndReps) {
			// Get the best amount of reps done for the currently weight set by the user input.
			let bestRepsForInputWeight = null;

			this.state.exercise.logGroups.forEach(logGroup => {
				logGroup.logs.forEach(log => {
					if ( // Same weight as the user-input one ? Then fine the best reps.
						this.state[ExerciseTypeAttribute.Weight] == log.values[0] &&
						(bestRepsForInputWeight === null || log.values[1] > bestRepsForInputWeight)
					) {
						bestRepsForInputWeight = log.values[1];
					}
				});
			});

			if (bestRepsForInputWeight) {
				return 'Highest reps for ' + UnitPrettifier.weight(this.state[ExerciseTypeAttribute.Weight]) + ': ' + bestRepsForInputWeight;
			}

			return null;
		}

		return null;
	}

	resetTimer = () => {
		this.setState({
			circleTimerKey: this.state.circleTimerKey + 1,
			isTimerOn: false,
			exerciseToSwitchToAfterTimerIsOver: null,
		})
	}

	setTimerTime = (time) => {
		let timeInSeconds = HoursMinutesTimeConverter.convertDateStringtoSeconds(time);
		this.setState({
			timerTime: timeInSeconds,
			circleTimerKey: this.state.circleTimerKey + 1,
		});

		if (UserLocalStorage.get('global_timer_value_setting')) {
			UserLocalStorage.set('global_timer_value', timeInSeconds);
			return;
		}

		UserLocalStorage.set(this.getRoutineExerciseRestTimerCacheKey(this.state.exercise), timeInSeconds);
	}

	getRoutineExerciseRestTimerCacheKey = (exercise) => {
		return 'restTimer[' + exercise.routineExerciseId + ']';
	}

	setTimerOn = (isOn, exerciseToSwitchToAfterTimerIsOver = null) => {
		let newState = {
			isTimerOn: isOn,
		};

		if (exerciseToSwitchToAfterTimerIsOver) {
			newState['exerciseToSwitchToAfterTimerIsOver'] = exerciseToSwitchToAfterTimerIsOver;
		}
		
		this.setState(newState);
	}

	onTimerComplete = () => {
		this.props.scrollToTopFunc();

		const audio = new Audio('/countdown_beep.mp3');
		audio.play();

		window.navigator?.vibrate([300, 200, 300, 200, 300]); // vibrate 300 ms, rest 200, vibrate 300, rest 200, vibrate 300.

		this.setState({
			isTimerOn: false,
		}, () => {
			if (this.state.exerciseToSwitchToAfterTimerIsOver) {
				this.props.switchExerciseBloop(this.state.exerciseToSwitchToAfterTimerIsOver);
			}
		});
	}
	
	toggleTimerSettingsDialog = (isOpen) => {
		this.setState({
			isTimerSettingsOpen: isOpen,
		})
	}

	renderTimer = () => {
		const {classes} = this.props;

		return (
			<>
				<TimerSettingsDialog 
					isOpen={this.state.isTimerSettingsOpen}
					closeSelfFunc={() => this.toggleTimerSettingsDialog(false)}
				/>
				
				<Box position="relative">
					<CardContent>
						<IconButton onClick={this.toggleTimerSettingsDialog} style={{ position: 'absolute', top: '10px', right: '10px' }}>
							<SettingsIcon />
						</IconButton>
	
						<Box paddingTop={1}/>
	
						<Grid container spacing={2} justifyContent='center' alignItems="center" direction='column' >
	
							<Grid item>
								<CountdownCircleTimer
									key={this.state.circleTimerKey}
									isPlaying={this.state.isTimerOn}
									duration={this.state.timerTime}
									colors={[['rgba(165,61,61,0.9)', 0.33], ["#642c2c"]]}
									onComplete={this.onTimerComplete}
									isLinearGradient
									trailStrokeWidth={0}
								>
									{this.renderTimeText}
								</CountdownCircleTimer>
							</Grid>
	
							<Grid item>
								<IconButton onClick={this.resetTimer} size="large">
									<RotateLeftIcon fontSize='large'/>
								</IconButton>
	
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<TimePicker
										ampm={false}
										openTo="seconds"
										views={["minutes", "seconds"]}
										inputFormat="mm:ss"
										label='Timer'
										value={HoursMinutesTimeConverter.convertSecondsToDateTime(this.state.timerTime)}
										onChange={this.setTimerTime}
										renderInput={(params) => <TextField {...params} className={classes.timerInputField} />}
									/>
								</LocalizationProvider>
	
								{this.state.isTimerOn ?
									<IconButton onClick={() => this.setTimerOn(false)} size="large">
										<PauseCircleFilledIcon fontSize='large'/>
									</IconButton>
									:
									<IconButton onClick={() => this.setTimerOn(true)} size="large">
										<PlayCircleFilledIcon fontSize='large'/>
									</IconButton>
								}
							</Grid>
						</Grid>
					</CardContent>
				</Box>
			</>
		);
	}

	renderTimeText = ({ remainingTime }) => {
		const {classes} = this.props;

		if (remainingTime === 0) {
			return (
				<div className={classes.timer}>
					<Typography variant='h2' className={classes.timerInfoText} gutterBottom>Rest over</Typography>
					<PulseDiv>
						<IconButton onClick={(e) => this.selectTabIndex(e, 1)} color='primary' size="large">
							<BookIcon className={classes.timerLogIcon} />
						</IconButton>
					</PulseDiv>
					<Typography variant='h2' className={classes.timerInfoText} gutterBottom>Go to logger</Typography>
				</div>
			);
		}

		return (
			<div className={classes.timer}>
				<Typography variant='h2' className={classes.timerInfoText} gutterBottom>Remaining</Typography>
				<Typography variant='h2' style={{fontSize: 40}} color='textPrimary' gutterBottom>{remainingTime}</Typography>
				<Typography variant='h2' className={classes.timerInfoText} gutterBottom>seconds</Typography>
			</div>
		);
	};

	openDropDownRPEAnchorEl = (event) => {
		this.setState({
			dropDownRPEAnchorEl: event.currentTarget
		});
	};

	closeDropDownRPEAnchorEl = () => {
		this.setState({
			dropDownRPEAnchorEl: null
		});
	};

	openDropDownCommentAnchorEl = (event) => {
		this.setState({
			dropDownCommentAnchorEl: event.currentTarget
		});
	};

	closeDropDownCommentAnchorEl = () => {
		this.setState({
			dropDownCommentAnchorEl: null
		});
	};

	updateRPE = (e, newValue) => {
		let RIR = 10 - newValue;

		this.setState({
			RPE: newValue,
			RIR: RIR < 1 ? 0 : RIR,
			shouldSaveRPE: true, // Set this to true if they tried to change it.
		})
	}

	setShouldSaveRPE = (e) => {
		this.setState({
			shouldSaveRPE: e.target.checked,
		});
	}

	/**
	 * @param dateTimeStarted With the right format! 'L, h:mm:ss A'.
	 * @returns {Promise<void>}
	 */
	startWorkout = async (dateTimeStarted) => {
		let workout = {
			dateTimeStarted: dateTimeStarted ?? moment().format('L, h:mm:ss A'),
		};

		let res = await fetch(UrlBuilder.workout.startWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		}).then(res => res.json());

		this.context.setUser(res.data);
	}

	renderRPEMenu = () => {
		const {classes} = this.props;

		let attribute = { // We fake it so it works.
			key: 'RIR'
		};

		return <>
			<IconButton size='small' onClick={this.openDropDownRPEAnchorEl}>
				<AssessmentIcon/>
			</IconButton>

			<Popover
				open={this.state.dropDownRPEAnchorEl !== null}
				anchorEl={this.state.dropDownRPEAnchorEl}
				onClose={this.closeDropDownRPEAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Card>
					<AppBar style={{ position: 'relative'}} enableColorOnDark>
						<Toolbar variant='dense'>
							<Typography variant="h6" noWrap className={classes.title}>
								Log RPE/RIR
							</Typography>
						</Toolbar>
					</AppBar>

					<CardContent>
						<Typography variant='caption' color='textSecondary'>
							Rated Perceived Exertion
						</Typography>
						<Slider
							value={this.state.RPE}
							onChange={this.updateRPE}
							step={1}
							marks
							min={1}
							max={10}
							valueLabelDisplay="on"
						/>

						<Divider light/>

						<Grid container spacing={1} justifyContent='center' alignItems="center">
							<Grid item>
								<IconButton size='small'
								            onPointerDown={() => this.incdecAttributeValue(attribute, false, true)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								            disabled={this.state[attribute.key] < 1}
								>
									<Avatar className={classes.avatarButton}>
										<RemoveIcon/>
									</Avatar>
								</IconButton>
							</Grid>
							<Grid item>
								<TextField
									className={classes.inputFieldLarger}
									variant='outlined'
									margin="normal"
									type='number'
									inputMode='decimal'
									id={attribute.key}
									label={'Reps in Reserve'}
									name={attribute.key}
									value={this.state[attribute.key]}
									onChange={this.updateStateAttributeValues}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item>
								<IconButton size='small'
								            onPointerDown={() => this.incdecAttributeValue(attribute, false, false)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								>
									<Avatar className={classes.avatarButton}>
										<AddIcon />
									</Avatar>
								</IconButton>
							</Grid>
						</Grid>
					</CardContent>

					<Grid container justifyContent='flex-end' alignItems="flex-end">
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.shouldSaveRPE}
									onChange={this.setShouldSaveRPE}
									color="secondary"
								/>
							}
							label={<Typography variant='button'>Save with log</Typography>}
						/>
					</Grid>

					<br/>

				</Card>
			</Popover>
		</>;
	}

	renderCommentPopup = () => {
		return <>
			<IconButton size='small' onClick={this.openDropDownCommentAnchorEl}>
				<MessageIcon/>
			</IconButton>

			<Popover
				open={this.state.dropDownCommentAnchorEl !== null}
				anchorEl={this.state.dropDownCommentAnchorEl}
				onClose={this.closeDropDownCommentAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Card>
					<CardContent>
						<TextField
							label="Add a comment"
							multiline
							rows={4}
							variant="outlined"
							fullWidth
							name="comment"
							value={this.state.comment}
							onChange={this.updateStateAttributeValues}
						/>
					</CardContent>
				</Card>
			</Popover>
		</>
	}

	setSelectedLog = (log) => {
		let state = this.getLogValues(this.state.exercise, false, log);
		state['selectedTabIndex'] = 1; // Return
		this.setState(state);
	}

	selectTabIndex = (e, idx) => {
		this.setState({
			selectedTabIndex: idx,
			exerciseToSwitchToAfterTimerIsOver: null, // just in case, we reset this.
		});
	}
	
	submitLogAndStartTimer = async () => {
		await this.submit(null, false, true); // Do not go to next set yet.

		this.setState({
			selectedTabIndex: 2,
		}, () => this.setTimerOn(true, this.findNextExerciseInSupersetIfPossible()));
	}

	renderPlanPopup = () => {
		return <WorkoutPlanCard
			exercise={this.state.exercise}
			setSelectedLogParentFunc={this.setSelectedLog}
			updateSwipeableViewsHeightFunc={this.props.updateSwipeableViewsHeightFunc}
		/>;
	}

	openDropDownLogDateCreatedOverwriteAnchorEl = (event) => {
		this.setState({
			dropDownLogDateCreatedOverwriteAnchorEl: event.currentTarget
		});
	};

	closeDropDownLogDateCreatedOverwriteAnchorEl = () => {
		this.setState({
			dropDownLogDateCreatedOverwriteAnchorEl: null
		});
	};

	setCustomLogDateCreated = (dateString) => {
		this.setState({
			customLogDateCreated: dateString,
			shouldSaveCustomLogDateCreated: true,
		})
	}
	
	setShouldSaveCustomLogDateCreated = (e) => {
		this.setState({
			shouldSaveCustomLogDateCreated: e.target.checked,
		});
	}

	openLogDateCreatedOverwriteTooltip = (event) => {
		this.setState({
			logDateCreatedOverwriteTooltipAnchorEl: event?.currentTarget ?? null,
		});
	}

	renderLogDateCreatedOverwritePopup = () => {
		const {classes} = this.props;

		return <Popover
			open={this.state.dropDownLogDateCreatedOverwriteAnchorEl !== null}
			anchorEl={this.state.dropDownLogDateCreatedOverwriteAnchorEl}
			onClose={this.closeDropDownLogDateCreatedOverwriteAnchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<AppBar style={{ position: 'relative'}} enableColorOnDark>
				<Toolbar variant='dense'>
					<Typography variant="h6" noWrap className={classes.title}>
						Pick Custom Time Created
					</Typography>

					<div className={classes.grow}/>

					<IconButton size='small' onClick={this.openLogDateCreatedOverwriteTooltip}>
						<InfoIcon/>
					</IconButton>

					<Popover
						open={this.state.logDateCreatedOverwriteTooltipAnchorEl !== null}
						anchorEl={this.state.logDateCreatedOverwriteTooltipAnchorEl}
						onClose={() => this.openLogDateCreatedOverwriteTooltip(null)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Typography className={classes.popOverText}>
							{this.context.user?.activeWorkout?.isPastWorkout ?
								'Pick the time in which this set was done during the workout.' 
								:
								'You can optionally pick a custom time in which this set was done during a workout. Otherwise the default time is now.' + (!this.context.user?.activeWorkout ? ' If no workout was started, one will be started automatically.' : '')
							}
						</Typography>
					</Popover>
				</Toolbar>
			</AppBar>
			<Card>
				<CardContent>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<StaticTimePicker
							value={this.state.customLogDateCreated ? new Date(this.state.customLogDateCreated) : new Date()}
							onChange={this.setCustomLogDateCreated}
							label={
								this.context.user?.activeWorkout? 
									'Workout Date: ' + DatePrettifier.prettifyLastTrained(new Date(this.context.user?.activeWorkout?.dateTimeStarted)) 
									: 
									undefined
							}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</CardContent>

				{this.context.user?.activeWorkout?.isPastWorkout ?
					<Grid container alignItems='center' justifyContent="center">
						<Button variant="contained"
						        color='primary'
						        onClick={(e) => this.submit(e, true)}
						        disabled={this.state.isLoading || this.state.customLogDateCreated === null}
						>
							{this.state.isLoading ?
								<>
									<CircularProgress size="1rem" color="inherit" sx={{marginRight: '10px'}}/> Saving
								</> :
								'Save Log'
							}
						</Button>
					</Grid>
					:
					<Grid container justifyContent="center" alignItems='center'>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.shouldSaveCustomLogDateCreated}
									onChange={this.setShouldSaveCustomLogDateCreated}
									color="secondary"
								/>
							}
							label={<Typography variant='button'>Save with log</Typography>}
						/>
					</Grid>
				}

				<br/>

			</Card>
		</Popover>;
	}

	renderMenuButtons = () => {
		return <Grid container spacing={1} justifyContent='center' alignItems="center">
			<Grid item>
				<IconButton size='small' onClick={this.openDropDownLogDateCreatedOverwriteAnchorEl}>
					<ScheduleSendIcon/>
				</IconButton>
			</Grid>

			<Grid item>
				{this.renderCommentPopup()}
			</Grid>

			{this.state.exercise.type.attributes.find(attr => attr.key === ExerciseTypeAttribute.Reps) ? // If the exercise has reps as an attribute, then we show the RPE menu too.
				<Grid item>
					{this.renderRPEMenu()}
				</Grid> : null
			}
		</Grid>;
	}

	renderUnitLogger = () => {
		const {classes} = this.props;

		let bestAttributeText = this.getBest();

		return (
			<>
			<CardContent>
				<Box paddingTop={0.75}/>
				{this.state.exercise.type.attributes.map((attribute, idx) =>
					<Grid container spacing={1} justifyContent='center' alignItems="center" key={attribute.key}>
						<Grid item>
							{attribute.key !== ExerciseTypeAttribute.Reps ?
								<IconButton size='small' color='inherit'
								            onPointerDown={() => this.incdecAttributeValue(attribute, true, true)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								            disabled={this.state[attribute.key] < 0.05}
								>
									<RemoveIcon/>
								</IconButton> : null
							}
							<IconButton size='small'
							            onPointerDown={() => this.incdecAttributeValue(attribute, false, true)}
							            onPointerUp={this.stopTimer}
							            onPointerLeave={this.stopTimer}
							            disabled={this.state[attribute.key] < 1}
							>
								<Avatar className={classes.avatarButton}>
									<RemoveIcon/>
								</Avatar>
							</IconButton>
						</Grid>
						<Grid item>
							{attribute.key === ExerciseTypeAttribute.Time ?
								<div className={classes.margin}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											key={attribute.key}
											ampm={false}
											openTo="minutes"
											views={["hours", "minutes", "seconds"]}
											inputFormat="HH:mm:ss"
											label={attribute.name}
											value={HoursMinutesTimeConverter.convertFloatToDateTime(this.state[attribute.key])}
											onChange={this.updateTimeAttributeValue}
											renderInput={(params) => <TextField {...params} className={classes.inputField} />}
										/>
									</LocalizationProvider>
								</div>
								:
								<TextField
									key={attribute.key}
									className={`${classes.inputField} ${classes.margin}`}
									variant='outlined'
									margin="none"
									type='number'
									inputMode='decimal'
									required
									id={attribute.key}
									label={attribute.name}
									name={attribute.key}
									value={this.state[attribute.key]}
									onChange={this.updateStateAttributeValues}
									onKeyPress={this.submitWithEnter}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment: <InputAdornment position="end">{UnitPrettifier.forAttribute(attribute)}</InputAdornment>
									}}
								/>
							}
						</Grid>
						<Grid item>
							<IconButton size='small'
							            onPointerDown={() => this.incdecAttributeValue(attribute, false, false)}
							            onPointerUp={this.stopTimer}
							            onPointerLeave={this.stopTimer}
							>
								<Avatar className={classes.avatarButton}>
									<AddIcon />
								</Avatar>
							</IconButton>
							{attribute.key !== ExerciseTypeAttribute.Reps ?
								<IconButton size='small' color='inherit'
								            onPointerDown={() => this.incdecAttributeValue(attribute, true, false)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								>
									<AddIcon/>
								</IconButton> : null
							}
						</Grid>
					</Grid>
				)}
				{this.renderMenuButtons()}
				{this.renderLogDateCreatedOverwritePopup()}
			</CardContent>

			<Divider />

			<DialogActions>
				<Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
					<Grid item>
						{bestAttributeText ? <Chip size="small" icon={<GradeIcon/>} label={bestAttributeText} variant='filled'/> : null}
					</Grid>

					<Grid item>
						{this.context.user?.activeWorkout?.isPastWorkout ?
							<Button endIcon={<HistoryToggleOffIcon fontSize="small"/>}
							        variant="contained"
							        color='primary'
							        onClick={this.openDropDownLogDateCreatedOverwriteAnchorEl}
							        disabled={!this.canSubmit()}
							>
								Pick Time
							</Button>
							:
							<ButtonGroup variant="contained" color="primary">
								<Button variant="contained"
								        color='primary'
								        onClick={(e) => this.submit(e, false)}
								        disabled={!this.canSubmit()}
								>
									Save Log
								</Button>
								<Button onClick={this.submitLogAndStartTimer} size='small' disabled={this.state.isLoading}>
									{this.state.isLoading ?
										<CircularProgress size="1rem" color="inherit" />
										:
										<TimerIcon fontSize='small'/>
									}
								</Button>
							</ButtonGroup>
						}
					</Grid>
				</Grid>
			</DialogActions>
			</>
		);
	}

	render() {
		const {classes} = this.props;

		return (
			<Card className={classes.card} square variant='outlined'>
				<Toolbar variant={"dense"} disableGutters>
					<Tabs
						value={this.state.selectedTabIndex}
						onChange={this.selectTabIndex}
						indicatorColor="primary"
						textColor="inherit"
						variant="fullWidth"
						style={{flexGrow: 1}}
					>
						<Tab className={classes.tabMenu} label="Plan" {...a11yProps(0)} disabled={this.state.isTimerOn} />
						<Tab className={classes.tabMenu} label="Log" {...a11yProps(1)} disabled={this.state.isTimerOn}/>
						<Tab className={classes.tabMenu} label="Timer" {...a11yProps(2)} disabled={this.state.isTimerOn} />
					</Tabs>
				</Toolbar>

				{this.state.selectedTabIndex === 0 && this.renderPlanPopup()}
				{this.state.selectedTabIndex === 1 && this.renderUnitLogger()}
				{this.state.selectedTabIndex === 2 && this.renderTimer()}
			</Card>
		);
	}
}

RoutineExerciseLogNewFormBox.contextType = UserContext;

export default withStyles(useStyles)(RoutineExerciseLogNewFormBox);