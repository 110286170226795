import React, {PureComponent} from 'react';
import {UserContext} from "../../core/UserContext";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../core/PageDataContext";
import {Link as RouterLink} from "react-router-dom";
import WorkoutsModule from "./modules/WorkoutsModule";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import './stylez.css';
import GooglePlayButton from "../../res/img/google_play.png";
import WIPIcon from "../../res/img/icon.png";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Link from "@mui/material/Link";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Container from "@mui/material/Container";
import SpaIcon from '@mui/icons-material/Spa';
import StarsIcon from '@mui/icons-material/Stars';
import BarChartIcon from '@mui/icons-material/BarChart';
import {Utils} from "../../core/util/Utils";
import HomeFabButton from "./modules/HomeFabButton";
import Alert from '@mui/material/Alert';
import {withRouter} from "../../hooks/withRouter";
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {Collapse, IconButton} from "@mui/material";
import {AlertTitle} from "@mui/lab";
import {UserLocalStorage} from "../../core/storage/UserLocalStorage";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = theme => ({
	root: {
		flexGrow: 1,
	},
	content: {
		padding: theme.spacing(2),
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(4),
		},
		marginTop: theme.spacing(21),
	},
	video: {
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(0),
		},
		marginTop: theme.spacing(6),
		textAlign: 'center',
	},
	centerized: {
		textAlign: 'center',
	},
	ultimateWorkoutTrackerText: {
		fontSize: 18,
		color: theme.palette.text.secondary,
	},
	headerText: {
		[theme.breakpoints.down('md')]: {
			fontSize: 33,
		},
		fontSize: 52,
	},
	margin: {
		margin: theme.spacing(1),
		marginTop: 0,
	},
	footerText: {
		fontSize: 16,
		color: theme.palette.text.secondary,
	},
	uspText: {
		color: '#435f9b',
		fontSize: 24
	},
	dialogRoot: {
		marginBottom: theme.spacing(2),
		cursor: 'pointer',
		paddingBottom: 0,
	},
	dialogContent: {
		textAlign: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		float: 'right'
	},
});

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			{'My Work in Progress '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

class Home extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			isSigningUpAsGuest: false,
			isDonateDialogOpen: false,
		};
	}

	componentDidMount() {
		this.context.setPageData({
			title: null, // Null is for the fallback
		});
	}

	redirectToGoogleAuth = () => {
		window.location = '/api/users/google-signin';
	}

	redirectToAppleAuth = () => {
		window.location = '/api/users/apple-signin';
	}

	signUpAsGuest = async (event, setUser) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		this.setState({
			isSigningUpAsGuest: true
		});

		let guestUser = {
			dateCreated: moment().format('L, h:mm:ss A'),
		};

		let response = await fetch(UrlBuilder.user.SignUpAsGuestApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(guestUser)
		});

		let jsonResponse = await response.json();

		if (response.status === 200) {
			setUser(jsonResponse['data']);
			this.props.navigate('/');
		}
		
		this.setState({
			isSigningUpAsGuest: false,
		})
	}
	
	getPromoPart = () => {
		const {classes} = this.props;

		return <>
			<img src={WIPIcon} width={120} alt='Get it on Google Play'/>

			<Box paddingTop={1.5}/>

			<Typography variant="h2" gutterBottom className={classes.headerText}>
				My Work in Progress
			</Typography>

			<Typography variant="caption" gutterBottom className={classes.ultimateWorkoutTrackerText}>
				THE ULTIMATE WORKOUT TRACKER
			</Typography>

			<Box paddingTop={4}/>

			<Grid container direction='column' display="flex" justifyContent="center" alignItems="center" spacing={1}>
				<Grid item>
					<Button sx={{ width: 250, height: 43 }} variant='outlined' onClick={this.redirectToGoogleAuth} size='medium' startIcon={<GoogleIcon fontSize='large'/>}>
						Continue with Google
					</Button>
				</Grid>
				<Grid item>
					<Button sx={{ width: 250, height: 43 }} variant='outlined' onClick={this.redirectToAppleAuth} size='medium' startIcon={<AppleIcon fontSize='large'/>}>
						Continue with Apple
					</Button>
				</Grid>
			</Grid>

			<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 1.3, marginBottom: 0 }}>
				<Typography variant='overline'>
					Or Manually
				</Typography>
			</Box>

			<Link component={RouterLink} to={UrlBuilder.user.SignInPage()}>
				<Button startIcon={<LockOpenIcon/>} variant="contained" size="large" color="primary" className={classes.margin}>
					Sign in
				</Button>
			</Link>

			<Link component={RouterLink} to={UrlBuilder.user.SignUpPage()}>
				<Button startIcon={<PersonAddIcon/>} variant="outlined" size="large" color="primary" className={classes.margin}>
					Sign Up
				</Button>
			</Link>

			<Box paddingTop={1.2}/>

			<UserContext.Consumer>
				{({user, setUser}) => (
					<Button
						type="submit"
						startIcon={this.state.isSigningUpAsGuest ? <CircularProgress size="1rem" color="inherit" /> : <PersonIcon/>}
						variant="outlined"
						size='large'
						color="primary"
						sx={{fontSize: 14}}
						onClick={e => this.signUpAsGuest(e, setUser)}
						disabled={this.state.isSigningUpAsGuest}
					>
						Continue as Guest
					</Button>
				)}
			</UserContext.Consumer>

			<br/>
			<br/>
			<Divider light/>
			<br/>
			<br/>

			<Box display="flex" justifyContent="center" alignItems="center">
				<a href="https://play.google.com/store/apps/details?id=app.myworkinprogress.twa" target='_blank'>
					<img src={GooglePlayButton} alt='Get it on Google Play'/>
				</a>
			</Box>
		</>;
	}
	
	renderNoUserPage = () => {
		const {classes} = this.props;

		if (Utils.isApp()) {
			// If we're in the TWA, just go to sign in page. Skip home page. 
			this.props.navigate(UrlBuilder.user.SignInPage());
			return;
		}

		let error = (new URLSearchParams(this.props.location.search)).get('error');

		// We're in a normal browser. Let's show the homepage.
		return <div className={classes.root}>
			{error === 'auth' ? <>
				<br/>
				<Alert severity="warning">Something went wrong with external authentication. Please try again or manually.</Alert> 
			</> : null}
			
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<div className={classes.content}>
						{this.getPromoPart()}
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<div className={classes.video}>
						<div className="smartphone">
							<div className="content">
								<video controls autoPlay loop width="305" height="670" src="/promo.mp4" />
							</div>
						</div>
					</div>
				</Grid>
			</Grid>

			<br/>
			<br/>
			<Divider light/>
			<br/>
			<br/>

			<Container maxWidth="sm">
				<div className={classes.centerized}>
					<Typography variant='h2' style={{fontSize: 40}} gutterBottom>
						Simple, Smart, and Goal-based.
					</Typography>

					<br/>

					<Typography variant='h4' className={classes.uspText}>
						<EmojiEmotionsIcon/> Made With You in Mind
					</Typography>
					<Typography className={classes.footerText} gutterBottom>
						We know the hassle of finding an intuitive and simple workout app. This is why we made My Work
						in Progress. We took what we learned about our 6 years of gym training using various gym apps
						and put the best of the best into one package, and best of all, it's free.
					</Typography>

					<br/>
					<br/>

					<Typography variant='h4' className={classes.uspText}>
						<BarChartIcon/> Intuitive Statistics and Graphs
					</Typography>
					<Typography className={classes.footerText} gutterBottom>
						We want to make sure that you get the best out of logging. This is why we have hand-picked the
						most relevant and intuitive graphs and statistics to spotting trends and progress in your gym
						journey. Never miss another detail again.
					</Typography>

					<br/>
					<br/>

					<Typography variant='h4' className={classes.uspText}>
						<StarsIcon/> Goal-based Workouts (optional)
					</Typography>
					<Typography className={classes.footerText} gutterBottom>
						We believe that the best way to achieve your goals is through detail planning and scheduling.
						This is why we have built an integrated goal maker into the app. You are able to specify your
						specific goals: how many sets, how much weight, reps, and by when.
					</Typography>

					<br/>
					<br/>

					<Typography variant='h4' className={classes.uspText}>
						<SpaIcon/> Intuitive and Ergonomic Design
					</Typography>
					<Typography className={classes.footerText} gutterBottom>
						We know it's already a pain to use your phone in the gym to log your workouts. This is why we
						made sure to make this experience as seamless as possible using a continuously tested intuitive
						and ergonomic design in the app. Buttons are easy to reach, pages are quick to access, and
						everything is straight to the point.
					</Typography>
				</div>
			</Container>

			<br/>
			<Divider light/>
			<br/>

			<div className={classes.centerized}>
				{this.getPromoPart()}
			</div>

			<Box mt={8}>
				<Copyright/>
			</Box>

			<br/>
		</div>;
	}

	handleOpenDonateDialog = () => {
		this.setState({
			isDonateDialogOpen: !this.state.isDonateDialogOpen,
		})
	}
	
	blockDonationDialog = () => {
		UserLocalStorage.set('block_donation_dialog', true);
		this.forceUpdate();
	}
	
	redirectToSettings = () => {
		this.props.navigate(UrlBuilder.user.settingsPage() + '?goToDonation=1');
	}
	
	renderDonateDialog = (user) => {
		if (UserLocalStorage.get('block_donation_dialog') || user.isSupporter || !Utils.isAndroidApp()) {
			return null;
		}
		
		const {classes} = this.props;

		const userCreationDate = user.dateCreated ? new Date(user.dateCreated) : null;
		
		const oneMonthAgo = new Date();
		oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

		// if dateCreated is null then the account is very old and we're sure we want to show the dialog.
		if (userCreationDate && userCreationDate > oneMonthAgo) {
			return null;
		}
		
		return <Alert variant="filled" severity="info" icon={false} className={classes.dialogRoot} onClick={this.handleOpenDonateDialog} action={this.state.isDonateDialogOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}>
			<AlertTitle>🏋️ Love Using My Work in Progress? ❤️Help Keep Us Strong!</AlertTitle>

			<Collapse in={this.state.isDonateDialogOpen}>
				<Typography>
					I sincerely hope you enjoy using My Work in Progress and it adds value to your life. It's my mission to help you keep track of your fitness journey with no strings attached.

					At the same time, keeping the app free and constantly improving it comes with certain costs - from server upkeep to new feature development. If you love using this app and would like to contribute towards its growth and sustainability, please consider making a donation.

					Every little bit helps us to continue delivering a premium, ad-free experience, and to keep shaping My Work in Progress into the best workout tracker it can be.

					Let's keep growing stronger together!
				</Typography>
				<Button variant="contained" className={classes.button} color='secondary' onClick={this.redirectToSettings}>
					💙 DONATE 💙
				</Button>
				<Button variant="contained" className={classes.button} color='primary' onClick={this.blockDonationDialog}>
					Close
				</Button>
			</Collapse>
		</Alert>
	}

	render() {
		return (
			<UserContext.Consumer>
				{({user}) => user ?
					<React.Fragment>
						<Box paddingTop={2} />
						
						{this.renderDonateDialog(user)}
						
						<WorkoutsModule/>

						<HomeFabButton/>
					</React.Fragment>
					:
					this.renderNoUserPage()
				}
			</UserContext.Consumer>
		);
	}
}

Home.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(Home));