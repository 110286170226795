import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import RoutineExerciseLogNewFormBox from "./RoutineExerciseLogNewFormBox";
import RoutineExerciseLogsTable from "./RoutineExerciseLogsTable";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const useStyles = theme => ({
});

class RoutineExerciseDetailBloopLogsTab extends PureComponent {
	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'exercise': The exercise.
	 *      'isLoading': Loading?
	 *      'refreshParentFunc': Function to refresh the parent.
	 *      'switchExerciseBloop': Function to switch bloops.
	 *      'openFilterByMenu': The function to call to open the filter by menu.
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: false,
			/**
			 * If any, this will make the default values set to the ones in this selected log
			 */
			selectedLogInBox: null,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			routine: nextProps.routine,
			exercise: nextProps.exercise,
			isLoading: nextProps.isLoading,
			selectedLogInBox: null, // Reset it to prevent it from happening again after its been set.
		});
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return nextProps.isShowing || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}
	
	setSelectedLogInBox = (log) => {
		this.setState({
			selectedLogInBox: log,
		});
		this.scrollToTop();
	}
	
	scrollToTop = () => {
		if (this.refs.routineExerciseLogNewFormBox) {
			this.refs.routineExerciseLogNewFormBox.scrollIntoView({ behavior: 'smooth' });
		}
	}

	updateSwipeableViewsHeightFunc = () => {
		this.props.updateSwipeableViewsHeightFunc();
	}
	
	render() {
		return (
            <React.Fragment>
                {!this.state.routine.isArchived && !this.state.exercise.isArchived ?
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        ref='routineExerciseLogNewFormBox'
                    >
                        <RoutineExerciseLogNewFormBox
                            routine={this.state.routine}
                            exercise={this.state.exercise}
                            selectedLog={this.state.selectedLogInBox}
                            refreshParentFunc={this.props.refreshParentFunc}
                            switchExerciseBloop={this.props.switchExerciseBloop}
                            shouldAcceptNextProps={!this.state.isLoading}
                            scrollToTopFunc={this.scrollToTop}
                            updateSwipeableViewsHeightFunc={this.updateSwipeableViewsHeightFunc}
                        />
                    </Grid> : null
                }

	            <Box paddingTop={1}/>

	            {this.state.isLoading ? <LinearProgress value={20} style={{backgroundColor: 'rgba(255,255,255,0)'}}/> : <Box paddingTop={0.5}/>}

                <RoutineExerciseLogsTable
                    routine={this.state.routine}
                    exercise={this.state.exercise}
                    refreshParentFunc={this.props.refreshParentFunc}
                    setSelectedLogParentFunc={this.setSelectedLogInBox}
                    openFilterByMenu={this.props.openFilterByMenu}
                />
            </React.Fragment>
        );
	}
}

export default withStyles(useStyles)(RoutineExerciseDetailBloopLogsTab);