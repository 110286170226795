import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {DialogTitle, Stack} from "@mui/material";
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import {SentimentVeryDissatisfied, SentimentVerySatisfied} from "@mui/icons-material";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {withRouter} from "../../../hooks/withRouter";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider, TimePicker} from "@mui/lab";
import TextField from "@mui/material/TextField";

const useStyles = theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 100,
	},
	grow: {
		flexGrow: 1,
	},
	nested: {
		paddingLeft: theme.spacing(5),
	},
	media: {
		height: 125,
	},
	floatRight: {
		float: 'right',
	},
	logComment: {
		padding: theme.spacing(2),
	},
	container: {
		backgroundColor: theme.palette.background.default,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class WorkoutCompleteEditForm extends PureComponent {
	/**
	 * @param props Containing:
	 *      'isOpen': Is it open? is it safe?!
	 *      'setUser': Updates the user -> needed to finish the workout.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The workout.
			 */
			workout: props.workout,
			/**
			 * Is it open?
			 */
			isOpen: false,
			/**
			 * Is it loading?
			 */
			isSubmissionLoading: false,
			/**
			 * The duration of the workout (a DateTime is defined if the isConfirmationDialogOpen is true)
			 */
			workoutDuration: null,
			/**
			 * Which moodLevel?
			 */
			moodLevel: props.workout.moodLevel,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen) {
			this.setState({
				workout: nextProps.workout,
				isOpen: true,
				moodLevel: nextProps.workout.moodLevel,
				workoutDuration: this.getDefaultWorkoutDurationDateTime(), // If the dialog is opened again, reset this date.
				isSubmissionLoading: false,
			});
		} else {
			this.setState({
				isOpen: false,
			});
		}
	}
	
	/**
	 * @param val A Datetime object indicating the duration (only HH:mm matters).
	 */
	updateWorkoutDuration = (val) => {
		this.setState({
			workoutDuration: val,
		});
	}

	submitWorkout = async (event, moodLevel = null, workoutEndedMoment = null) => {
		event.preventDefault();

		if (this.isWorkoutAlreadyFinished()) {
			await this.submitAsEdit(moodLevel, workoutEndedMoment);
			return;
		}
		
		await this.submitAsNew(moodLevel, workoutEndedMoment);
	}
	
	submitAsEdit = async (moodLevel = null, workoutEndedMoment = null) => {
		this.setState({
			isSubmissionLoading: true,
		});

		let workout = {
			id: this.state.workout.id,
			dateTimeEnded: workoutEndedMoment ? workoutEndedMoment.format('L, h:mm:ss A') : moment().format('L, h:mm:ss A'),
		};

		if (moodLevel) {
			workout['moodLevel'] = moodLevel;
		}

		await fetch(UrlBuilder.workout.workoutsApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		})
			.then(response => response.json())
			.then(response => {
				this.props.closeSelfFunc(true);
				
				this.setState({
					isSubmissionLoading: false,
				});
			});
	}

	submitAsNew = async (moodLevel = null, workoutEndedMoment = null) => {
		this.setState({
			isSubmissionLoading: true,
		});

		let workout = {
			dateTimeEnded: workoutEndedMoment ? workoutEndedMoment.format('L, h:mm:ss A') : moment().format('L, h:mm:ss A'),
			comment: this.state.workoutComment,
		};

		if (moodLevel) {
			workout['moodLevel'] = moodLevel;
		}

		await fetch(UrlBuilder.workout.finishWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		})
			.then(response => response.json())
			.then(response => {
				this.props.closeSelfFunc(true);

				let user = response.data;

				this.props.setUser(user);

				if (this.state.workout.routines.length > 0) { // Only redirect if you had some exercises to show.. (backend logic duplication)
					this.props.navigate(UrlBuilder.workout.workoutDetailPage(this.state.workout.id, true));
				}
			})
	}

	/**
	 * Based on the time started, it gets the time ended based on the workoutDuration DateTime var.
	 * 
	 * @returns {Moment}
	 */
	getMomentEnded = (workoutDurationDateTime) => {
		const dateTimeStarted = moment(this.state.workout.dateTimeStarted);

		return dateTimeStarted
			.add(workoutDurationDateTime.getHours(), 'hours')
			.add(workoutDurationDateTime.getMinutes(), 'minutes');
	}

	/**
	 * Gets the default workout duration dateTime (based on the dateTimeStarted and now()).
	 * 
	 * @returns {Date}
	 */
	getDefaultWorkoutDurationDateTime = () => {
		if (this.state.workout.isPastWorkout && !this.isWorkoutAlreadyFinished()) { // If it's a past workout in progress, we don't get a default time, we let the user decide it.
			return null;
		}
		
		const dateTimeStarted = moment(this.state.workout.dateTimeStarted);
		
		const dateTimeEnded = this.isWorkoutAlreadyFinished() ? moment(this.state.workout.dateTimeEnded) : moment();
		
		let workoutDurationObj = moment.duration(dateTimeEnded.diff(dateTimeStarted));
		let workoutDuration = moment.utc(workoutDurationObj.asMilliseconds());
		
		// The duration is any date but with a specific time.
		const duration = new Date();
		duration.setHours(workoutDuration.hours());
		duration.setMinutes(workoutDuration.minutes());
		duration.setMilliseconds(workoutDuration.milliseconds());
		
		return duration;
	}

	isWorkoutAlreadyFinished = () => {
		return !!this.state.workout.dateTimeEnded;
	}

	openConfirmationDialog = (isOpen) => {
		if (isOpen) {
			this.setState({
				isConfirmationDialogOpen: true,
				
			});
		} else {
			this.setState({
				isConfirmationDialogOpen: false,
			});
		}
	}
	
	setMoodLevel = (moodLevel) => {
		this.setState({
			moodLevel: this.state.moodLevel === moodLevel ? null : moodLevel, // If equal to now, remove it.
		});
	}

	render() {
		const {classes} = this.props;

		const dateTimeStarted = moment(this.state.workout.dateTimeStarted);
		const momentEnded = this.state.workoutDuration ? this.getMomentEnded(this.state.workoutDuration) : null;
		
		return (
            <Dialog fullWidth open={this.state.isOpen} onClose={() => this.props.closeSelfFunc(false)} TransitionComponent={Transition}>
                <DialogTitle>
                    {this.isWorkoutAlreadyFinished() ?
                        'Edit Workout'
                        :
                        this.state.workout.isPastWorkout ? 'Complete Past Workout' : 'Complete Workout'
                    }
                    
                </DialogTitle>
                <DialogContent dividers className={classes.container}>
                    <Grid container spacing={3} justifyContent="center" alignItems="center" direction='column'>
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" direction='column'>
                                <Grid item>
                                    <Typography color="textSecondary" variant="subtitle1">
                                        Started at:
                                    </Typography>
                                </Grid>
                                <Grid item>
	                                <Grid container justifyContent="center" alignItems="center" direction='column'>
		                                <Grid item>
			                                <Typography color="textSecondary" variant="h5">
				                                {dateTimeStarted.format('LT')}
			                                </Typography>
		                                </Grid>
		                                <Grid item>
			                                <Typography color="textSecondary" variant="caption">
				                                {dateTimeStarted.format('DD/MM/YYYY')}
			                                </Typography>
		                                </Grid>
	                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

	                    <Grid item>
		                    <Grid container justifyContent="center" alignItems="center" direction='column'>
			                    <Grid item>
				                    <Typography color="textSecondary" variant="subtitle1" gutterBottom>
					                    Select workout duration:
				                    </Typography>
			                    </Grid>
			                    <Grid item>
				                    <LocalizationProvider dateAdapter={AdapterDateFns}>
					                    <TimePicker
						                    ampm={false}
						                    toolbarTitle='Pick workout length (hh:mm)'
						                    openTo="hours"
						                    views={["hours", "minutes"]}
						                    label='hh:mm'
						                    inputFormat="HH:mm"
						                    value={this.state.workoutDuration}
						                    onChange={this.updateWorkoutDuration}
						                    renderInput={(params) => <TextField {...params} sx={{maxWidth: 85}} />}
					                    />
				                    </LocalizationProvider>
			                    </Grid>
		                    </Grid>
	                    </Grid>
                        
                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" direction='column'>
                                <Grid item>
                                    <Typography color="textSecondary" variant="subtitle1">
                                        Ended at:
                                    </Typography>
                                </Grid>
                                <Grid item>
	                                <Grid container justifyContent="center" alignItems="center" direction='column'>
		                                <Grid item>
			                                <Typography color="textSecondary" variant="h5">
				                                {momentEnded ? momentEnded.format('LT') : '-'}
			                                </Typography>
		                                </Grid>
		                                {momentEnded &&
			                                <Grid item>
				                                <Typography color="textSecondary" variant="caption">
					                                {momentEnded.format('DD/MM/YYYY')}
				                                </Typography>
			                                </Grid>
		                                }
	                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent="center" alignItems="center" direction='column'>
                                <Grid item>
                                    <Typography color="textSecondary" variant="subtitle1">
                                        Rate your mood:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <IconButton
                                                color={this.state.moodLevel === 1 ? 'secondary' : 'inherit'}
                                                onClick={() => this.setMoodLevel(1)}
                                                size="large">
                                                <SentimentVeryDissatisfied fontSize='large'/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                color={this.state.moodLevel === 2 ? 'secondary' : 'inherit'}
                                                onClick={() => this.setMoodLevel(2)}
                                                size="large">
                                                <SentimentSatisfiedIcon fontSize='large'/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                color={this.state.moodLevel === 3 ? 'secondary' : 'inherit'}
                                                onClick={() => this.setMoodLevel(3)}
                                                size="large">
                                                <SentimentVerySatisfied fontSize='large'/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color='grey' onClick={() => this.props.closeSelfFunc(false)}>Close</Button>

                    <Button variant="contained" color="primary"
                            disabled={this.state.isSubmissionLoading || !this.state.workoutDuration}
                            onClick={(e) => this.submitWorkout(e, this.state.moodLevel, this.getMomentEnded(this.state.workoutDuration))}
                    >
                        {this.state.isSubmissionLoading ?
                            <><CircularProgress size="1rem" color="inherit" />&nbsp;Loading... </>
                            :
                            (this.isWorkoutAlreadyFinished() ?
                                'Save Workout' 
                                    :
                                <>{this.state.workout && this.state.workout.routines.length > 0 ? 'Submit Workout' : 'Quit Workout'}</>

                            )
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        );
	}
}
	
export default withStyles(useStyles)(withRouter(WorkoutCompleteEditForm));