import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as Sentry from "@sentry/react";

import 'fontsource-roboto';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://733310e04a374ebe8e8f3fa253ce4a99@o573578.ingest.sentry.io/5724185",
        environment: 'production'
    });
}


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App/>
    </BrowserRouter>,
    rootElement
);

// Register the service worker.
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('service_worker.js')
            .then(reg => console.log('Service Worker: Registered (Pages)'))
            .catch(err => console.log(`Service Worker: Error: ${err}`));
    });
}
