import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import {ExerciseType} from "../../../core/models/ExerciseType";
import AsyncLineGraph from "../../graphs/AsyncLineGraph";
import AsyncBarGraph from "../../graphs/AsyncBarGraph";

const useStyles = theme => ({
	tabBar: {
		flexGrow: 1,
	},
	media: {
		height: 225,
	},
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			{...other}
		>
			{value === index ? children : null}
		</div>
	);
}

class RoutineExerciseDetailBloopGraphsTab extends PureComponent {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise you want to modify.
	 *      'isShowing': Is it showing?
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * The tab that's currently being shown. Default: 0 -> the first one.
			 */
			value: UserLocalStorage.get('routineChartTabChoice') ?? 1,
			/**
			 * Has it ever been shown? Similar to 'isShowing' but once it's true, it's never false again.
			 */
			hasEverBeenShown: false,
			/**
			 * Whether the component is currently being shown.
			 */
			isShowing: false,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			hasEverBeenShown: !this.state.hasEverBeenShown || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId ? nextProps.isShowing : true, // once it's true, it's never false again, unless the exercise changes.
			isShowing: nextProps.isShowing,
		});
	}

	shouldComponentUpdate (nextProps) {
		return nextProps.isShowing || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId; // once it's true, it's never false again, unless the exercise changes.
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}

	handleChange = (event, newValue) => {
		this.setState({
			value: newValue
		});
		UserLocalStorage.set('routineChartTabChoice', newValue);
	};

	renderPerLogGraphs = () => {
		if (!this.state.hasEverBeenShown || this.state.value !== 0) {
			return <div/>;
		}

		let logs = this.state.exercise.logGroups.map(group => group.logs).flat();
		logs = logs.reverse();

		let dates = logs.map(log => (new Date(log.dateCreated)).toLocaleDateString());

		let exerciseType = this.state.exercise.type;

		if (exerciseType.id === ExerciseType.WeightAndReps) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Reps', dates, () => logs.map(log => log.values[1]))}
					{this.renderLineGraph('Weight', dates, () => logs.map(log => log.values[0]))}
					{this.renderLineGraph('Volume', dates, () => logs.map(log => log.multiplied))}
					{this.renderBarGraph(
						'Max Reps Per Weight', 
						() => {
							return this.processLogsForBarGraph(logs);
						})
					}
					{this.renderLineGraph('1RM (Brzycki formula)', dates, () => logs.map(log => Math.round(log.values[0] / ( 1.0278-(0.0278*log.values[1])))))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.Reps) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Reps', dates, () => logs.map(log => log.values[0]))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.DistanceAndTime) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Distance', dates, () => logs.map(log => log.values[0]))}
					{this.renderLineGraph('Time', dates, () => logs.map(log => log.values[1]))}
					{this.renderLineGraph('Average Speed', dates, () => logs.map(log => log.divided))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.Time) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Time', dates, () => logs.map(log => log.values[0]))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.WeightAndTime) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Weight', dates, () => logs.map(log => log.values[0]))}
					{this.renderLineGraph('Time', dates, () => logs.map(log => log.values[1]))}
				</React.Fragment>
			);
		}

		return <React.Fragment/>
	};

	renderPerDay = () => {
		if (!this.state.hasEverBeenShown || this.state.value !== 1) {
			return <div/>;
		}

		let logGroups = [...this.state.exercise.logGroups].reverse();
		
		let dates = logGroups.map(logGroup => (new Date(logGroup.date)).toLocaleDateString());

		let exerciseType = this.state.exercise.type;

		if (exerciseType.id === ExerciseType.WeightAndReps) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Max Reps', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topRepsLogIdx].values[1]))}
					{this.renderLineGraph('Max Weight', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topWeightLogIdx].values[0]))}
					{this.renderLineGraph('Max Volume', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topVolumeLogIdx].multiplied))}
					{this.renderLineGraph('Total Volume', dates, () => logGroups.map(logGroup => logGroup.totalVolume))}
					{this.renderBarGraph(
						'Max Reps Per Weight',
						() => {
							let logs = logGroups.map(group => group.logs).flat();
							
							return this.processLogsForBarGraph(logs);
						}
					)}
					{this.renderLineGraph(
						'Max 1RM (Brzycki formula)', 
						dates,
						() => {
							return logGroups.map(logGroup => {
								let log1RMs = logGroup.logs.map(log => Math.round(log.values[0] / ( 1.0278-(0.0278*log.values[1]))));

								return Math.max(...log1RMs);
							});
						}
					)}

				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.Reps) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Max Reps', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topRepsLogIdx].values[0]))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.DistanceAndTime) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Max Distance', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topDistanceLogIdx].values[0]))}
					{this.renderLineGraph('Max Time', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topTimeLogIdx].values[1]))}
					{this.renderLineGraph('Max Average Speed', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topSpeedLogIdx].divided))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.Time) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Max Time', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topTimeLogIdx].values[0]))}
				</React.Fragment>
			);
		}

		if (exerciseType.id === ExerciseType.WeightAndTime) {
			return (
				<React.Fragment>
					<br/>
					{this.renderLineGraph('Max Weight', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topWeightLogIdx].values[0]))}
					{this.renderLineGraph('Max Time', dates, () => logGroups.map(logGroup => logGroup.logs[logGroup.topTimeLogIdx].values[1]))}
				</React.Fragment>
			);
		}
		
		return <React.Fragment/>
	}

	processLogsForBarGraph = (logs) => {
		let maxRepsPerWeight = new Map();
		let rpeValuesPerWeight = new Map();
		
		logs.forEach(log => {
			let weight = log.values[0];
			let reps = log.values[1];
			
			if (maxRepsPerWeight.has(weight)) {
				if (reps > maxRepsPerWeight.get(weight)) { // Found a log with more reps, select this one.
					maxRepsPerWeight.set(weight, reps);
					rpeValuesPerWeight.set(weight, log.rpeValue);
				} else if (reps === maxRepsPerWeight.get(weight)) { // Found a log with same reps, let's see if we should update the RPE.
					if (
						log.rpeValue &&
						(
							!rpeValuesPerWeight.get(weight) ||
							(log.rpeValue < rpeValuesPerWeight.get(weight))
						)
					) {
						rpeValuesPerWeight.set(weight, log.rpeValue);
					}
				}
			} else {
				maxRepsPerWeight.set(weight, reps);
				rpeValuesPerWeight.set(weight, log.rpeValue);
			}
		});

		// Sort
		let sortedWeights = Array.from(maxRepsPerWeight.keys()).sort((a, b) => a - b);
		// Get reps by weight.
		let maxReps = sortedWeights.map(weight => maxRepsPerWeight.get(weight));
		// Get RPEs by weight.
		let rpeValues = sortedWeights.map(weight => rpeValuesPerWeight.get(weight));

		return [sortedWeights, maxReps, rpeValues];
	}

	renderLineGraph = (label, dates, closure) => {
		return <React.Fragment key={label}>
			<Paper variant="outlined">
				<AsyncLineGraph label={label} dates={dates} fetchData={closure}/>
			</Paper>
			<br/>
		</React.Fragment>;
	}

	renderBarGraph = (label, fetchData) => {
		return <React.Fragment key={label}>
			<Paper variant="outlined">
				<AsyncBarGraph label={label} fetchData={fetchData}/>
			</Paper>
			<br/>
		</React.Fragment>;
	}
	
	render() {
		const {classes} = this.props;

		return <React.Fragment>
			<Paper square className={classes.tabBar}>
				<Tabs
					variant="fullWidth"
					value={this.state.value}
					onChange={this.handleChange}
					indicatorColor="secondary"
					textColor="secondary"
					centered
				>
					<Tab label="All Logs" />
					<Tab label="Per Day" />
				</Tabs>
			</Paper>
			
				<TabPanel value={this.state.value} index={0}>
					{this.renderPerLogGraphs()}
				</TabPanel>
				<TabPanel value={this.state.value} index={1}>
					{this.renderPerDay()}
				</TabPanel>
		</React.Fragment>
	}
}

export default withStyles(useStyles)(RoutineExerciseDetailBloopGraphsTab);