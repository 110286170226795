import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../../core/PageDataContext";
import {Card, CardContent, Popover, Typography} from "@mui/material";

const useStyles = theme => ({
	smallFont: {
		"font-size": "90.5%", /* 62.5% of 16px = 10px */
		textAlign: "center",
	},
	smallerFont: {
		"font-size": "58.5%", /* 62.5% of 16px = 10px */
		textAlign: "center",
	},
	card: {
		height: "100%",
	},
	popOverText: {
		padding: theme.spacing(2),
	}
});

class WorkoutSmallNote extends PureComponent {
	/**
	 * No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Title
			 */
			title: props.title,
			/**
			 * Description
			 */
			description: props.description,
			/**
			 * A small detail thing.
			 */
			smallDetail: props.smallDetail,
			/**
			 * The popover text.
			 */
			popOverText: props.popOverText,
			/**
			 * Is the pop over open?
			 */
			isPopOverOpen: false,
			/**
			 * The anchorEL for the popover.
			 */
			popOverAnchorEl: null,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			title: nextProps.title,
			description: nextProps.description,
			smallDetail: nextProps.smallDetail,
			popOverText: nextProps.popOverText,
		})
	}
	
	openPopOver = (event, isOpen) => {
		this.setState({
			isPopOverOpen: isOpen,
			popOverAnchorEl: isOpen ? event.currentTarget : null,
		})
	}

	render() {
		const {classes} = this.props;

		return <>
			<Card variant='outlined' elevation={0} className={classes.card} onClick={(e) => this.openPopOver(e, true)}>
				<CardContent sx={{padding: 0, paddingTop: '16px', paddingBottom: '16px'}}>
					<Typography className={classes.smallFont} display="block" color="textSecondary" gutterBottom variant="subtitle1">
						{this.state.title}
					</Typography>
					<Typography
						color="textPrimary" 
						variant="h4" 
						gutterBottom
						sx={{
							textAlign: "center",
							fontSize: {
								md: 24,
								xs: 18,
							}
						}}
					>
						{this.state.description}
					</Typography>
					{this.state.smallDetail ?
						<Typography className={classes.smallerFont} color="textSecondary" variant="subtitle2">
							{this.state.smallDetail}
						</Typography> : null
					}
				</CardContent>
			</Card>

			{this.state.popOverText ?
				<Popover
					open={this.state.isPopOverOpen}
					anchorEl={this.state.popOverAnchorEl}
					onClose={() => this.openPopOver(null, false)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Typography className={classes.popOverText}>{this.state.popOverText}</Typography>
				</Popover>
				: null
			}
			
		</>;
	}
}

WorkoutSmallNote.contextType = PageDataContext;

export default withStyles(useStyles)(WorkoutSmallNote);