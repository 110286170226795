import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {Card, CardContent, IconButton, LinearProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import GoalCardBase from "./GoalCardBase";
import CallMadeIcon from "@mui/icons-material/CallMade";
import {withRouter} from "../../../../hooks/withRouter";

const useStyles = theme => ({
	grow: {
		flexGrow: 1,
	},
});

class RepsGoalCard extends GoalCardBase {
	render() {
		const {classes} = this.props;

		let currentValue;
		if (this.state.goal.type.attributes.length === 1) {
			currentValue = this.state.goal.currentLog?.values[0] ?? null;
		} else { // length === 2
			currentValue = this.state.goal.currentLog?.values[1] ?? null; // Reps always goes second, so we can assume it's in value1
		}
		
		let percentage;
		if (this.state.goal.dateCompleted) {
			percentage = 100;
		} else {
			percentage = this.state.goal.currentLog ? 100 * currentValue / this.state.goal.values[0] : 0;
			percentage = Math.min(percentage, 100);
		}
		
		return <Card variant='outlined'>
			<CardContent>
				{!this.state.exercise && // Only if the goal is for all exercises and the current log is for another routine.
					<Box display="flex">
						<Typography variant="h4" fontSize={18} sx={{ paddingBottom: 1 }}>
							<strong>{this.state.goal.exercise.name}</strong>
						</Typography>
					</Box>
				}
				
				<Box display="flex">
					<Typography variant='overline'>
						<strong>Reps Goal</strong>
					</Typography>
					<div className={classes.grow}/>
					{this.state.goal.deadline ?
						<Typography variant='overline'>
							Due {moment(this.state.goal.deadline).format('DD/MM/YYYY')}
						</Typography> : null
					}
				</Box>

				<LinearProgress variant='determinate' value={percentage} style={{height: 20, borderRadius: 3}}/>

				<Box display="flex">
					{this.state.goal.dateCompleted ?
						<Typography variant="overline" color="textSecondary"><strong>Completed on {moment(this.state.goal.dateCompleted).format('DD/MM/YYYY')}</strong></Typography>
						:
						<Typography variant="overline" color="textSecondary">Current: <strong>{currentValue ? currentValue : '-'} reps</strong></Typography>
					}
					<div className={classes.grow}/>
					<Typography variant="overline" color="textSecondary">Goal: <strong>{this.state.goal.values[0]} reps</strong></Typography>
				</Box>

				{this.state.goal.currentLog && (!this.state.routine || (!this.state.goal.routineExerciseId && this.state.routine.id !== this.state.goal.currentLog.routineId)) ? // Only if the goal is for all exercises and the current log is for another routine.
					<Box display="flex">
						<Typography variant="overline" color="textSecondary">Done in: <strong>{this.state.goal.currentLog.routine.name}</strong> <IconButton size='small'><CallMadeIcon onClick={this.goToRoutine} style={{fontSize: 18}}/></IconButton> </Typography>
					</Box>
					: null
				}
			</CardContent>
		</Card>
	}
}

export default withStyles(useStyles)(withRouter(RepsGoalCard));