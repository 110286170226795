import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {UserContext} from "../../../core/UserContext";
import { red } from '@mui/material/colors';

const useStyles = theme => ({
	'@keyframes flicker': {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.4,
		},
	},
	flicker: {
		animationName: '$flicker',
		animationDuration: '1000ms',
		animationIterationCount: 'infinite',
		animationDirection: 'alternate',
		animationTimingFunction: 'ease-in-out',
		color: red[300],
	},
	button: {
		color: red[300],
	}
});

class ActiveWorkoutButton extends PureComponent {
	render() {
		if (!this.context.user || !this.context.user.activeWorkout) {
			return null;
		}

		const {classes} = this.props;

		return <>
            <IconButton
                edge="end"
                onClick={() => this.context.openActiveWorkoutBloop(true)}
                size="large"> 
                <Typography variant="button" className={classes.button}>
                    WORKING	&nbsp;
                </Typography>
                <RadioButtonCheckedIcon className={classes.flicker}/>
            </IconButton>
        </>;
	}
}

ActiveWorkoutButton.contextType = UserContext;

export default withStyles(useStyles)(ActiveWorkoutButton);