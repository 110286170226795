import { createTheme } from '@mui/material';
import {grey} from "@mui/material/colors";

const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#2d3b4a',
			dark: 'rgb(54, 73, 91)',
		},
		secondary: {
			main: '#f50057',
			stopWorkoutColor: '#580505',
			startWorkoutColor: '#0f3b04',
		},
		text: {
			primary: '#f6f6f6',
			secondary: '#d9d9d9',
		},
		background: {
			paper: '#1f2a33',
			default: '#151b24',
		},
		divider: '#2c3340',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					color: '#f6f6f6',
				},
			},
			variants: [
				{
					props: {variant: "contained", color: "grey"},
					style: {
						backgroundColor: grey[300],
						color: '#242424',
					},
				},
			]
		},
		MuiPaper: {
			styleOverrides: { 
				root: { 
					backgroundImage: 'unset', // https://mui.com/guides/migration-v4/#paper
				} 
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'none', // https://mui.com/guides/migration-v4/#link-underline-hover
			},
		},
		MuiDivider: {
			styleOverrides: {
				light: {
					borderColor: 'rgb(36,42,53)'
				}
			}
		},
	},
});

export default theme;