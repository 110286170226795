import React, {PureComponent} from 'react';
import {
	Card,
	CardContent,
	Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActionArea from "@mui/material/CardActionArea";
import {withRouter} from "../../../hooks/withRouter";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GoalCard from "../../routine/partials/Goals/GoalCard";

const useStyles = theme => ({});

class GoalStatsCard extends PureComponent {

	/**
	 * @param props Containing:
	 *      'workout': The workout you want to show.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The goals.
			 */
			goals: UserLocalStorage.get(UrlBuilder.goals.exerciseGoalsApi(null, null, true)) ?? null,
			/**
			 * Is the collapse open?
			 */
			isCollapseOpen: false,
			/**
			 * Is loading?
			 */
			isLoading: false,
			/**
			 * Has it loaded at least once?
			 */
			hasLoadedAtLeastOnce: false,
		};
	}
	
	hasNeverBeenFetched = () => {
		return this.state.goals === null;
	}

	fetch = async () => {
		this.setState({
			isLoading: true,
		});

		const response = await fetch(UrlBuilder.goals.exerciseGoalsApi(null, null, true));

		if (response.status !== 200) {
			this.setState({
				isLoading: false,
			});
			return;
		}

		const responseData = await response.json();

		this.setState({
			goals: responseData['data'] ?? [],
			isLoading: false,
			hasLoadedAtLeastOnce: true,
		});

		UserLocalStorage.set(UrlBuilder.goals.exerciseGoalsApi(null, null, true), responseData['data'] ?? []);
	}

	triggerCollapse = (event) => {
		this.setState({
			isCollapseOpen: !this.state.isCollapseOpen
		});

		const isOpening = !this.state.isCollapseOpen;
		if (isOpening && !this.state.hasLoadedAtLeastOnce) {
			this.fetch();
		}
	}

	renderLoading = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="pulse" variant="rectangular" style={{height: 275}}/>

			<Box paddingTop={4}/>

			<Skeleton animation="pulse" variant="text" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
		</>;
	}

	renderGoalCards = () => {
		if (this.hasNeverBeenFetched()) {
			return <Skeleton animation="pulse" variant="rectangular" style={{height: 275}}/>
		}

		if (this.state.goals.length === 0) {
			return <Box display="flex" justifyContent="center" alignItems="center">
				<Typography variant='caption'>
					No goals found. Create them in your routine exercises.
				</Typography>
			</Box>
		}

		return <Grid container spacing={2}>
			{this.state.goals.map(goal =>
				<Grid item xs={12} key={goal.id}>
					{/*<CardActionArea>*/}
						<GoalCard goal={goal}/>
					{/*</CardActionArea>*/}
				</Grid>
			)}
		</Grid>
	}

	render() {
		return (
			<Card variant='outlined'>
				<CardActionArea>
					<CardHeader
						avatar={<EmojiEventsIcon/>}
						title={<Typography variant='h5' sx={{ fontSize: 21 }}>Goals</Typography>}
						style={{paddingBottom: '12px', paddingTop: '12px'}}
						
						action={
							<IconButton size="small">
								{this.state.isCollapseOpen ? <ExpandLess/> : <ExpandMore/>}
							</IconButton>
						}
						onClick={this.triggerCollapse}
					/>
					<Collapse in={this.state.isLoading}>
						<LinearProgress/>
					</Collapse>
				</CardActionArea>

				<Collapse in={this.state.isCollapseOpen}>
					<Divider/>
					<CardContent style={{padding: '10px'}}>
						{this.renderGoalCards()}
					</CardContent>
				</Collapse>
			</Card>
		);
	}
}

export default withStyles(useStyles)(withRouter(GoalStatsCard));