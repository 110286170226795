import React, {PureComponent} from 'react';
import {UserContext} from "../../core/UserContext";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Regexer} from "../../core/auth/Regexer";
import Button from "@mui/material/Button";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import {PageDataContext} from "../../core/PageDataContext";
import {
	CardHeader,
	Divider,
	CardContent,
	Card,
	CardActions,
	ListItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	ListItemAvatar,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Checkbox,
	IconButton, Collapse, InputAdornment
} from "@mui/material";
import SecurityIcon from '@mui/icons-material/Security';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {UnitPrettifier} from "../../core/prettifier/UnitPrettifier";
import List from "@mui/material/List";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TuneIcon from '@mui/icons-material/Tune';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import FavoriteIcon from '@mui/icons-material/Favorite';
import Badge2 from "../../res/img/badge_2.png";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Badge from "@mui/material/Badge";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SubmitHasNoNotifications from "./partials/SubmitHasNoNotifications";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DonationDialog from "./partials/DonationDialog";
import {withRouter} from "../../hooks/withRouter";
import Slide from "@mui/material/Slide";
import {Utils} from "../../core/util/Utils";
import {Alert, AlertTitle} from "@mui/lab";
import GoogleIcon from "@mui/icons-material/Google";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import AppleIcon from "@mui/icons-material/Apple";

const useStyles = theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	grow: {
		flexGrow: 1,
	},
	nested: {
		paddingLeft: theme.spacing(2),
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class Settings extends PureComponent {

	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Is the submit feedback dialog open?
			 */
			isSubmitFeedbackDialogOpen: false,
			/**
			 * shouldIncludeFeedbackEmail?
			 */
			shouldIncludeFeedbackEmail: true,
			/**
			 * The message to show for the toast notification. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationMessage: null,
			/**
			 * Is the supporter dialog open?
			 */
			isSupporterDialogOpen: false,
			/**
			 * Is the announcements collapse open?
			 */
			isAnnouncementsCollapseOpen: false,
			/**
			 * Indicates whether the email is already taken by someone else.
			 */
			isEmailAlreadyTaken: false,
			/**
			 * Do we show the password field?
			 */
			showPassword: false,
		};

		this.configSectionRef = React.createRef();
		this.supporterSectionRef = React.createRef();
	}

	componentDidMount() {
		this.context.setPageData({
			title: "My Settings",
		});

		let goToDonation = (new URLSearchParams(this.props.location.search)).has('goToDonation');
		if (goToDonation) {
			setTimeout(() => this.scrollToSupporterSection(), 500);
		}
	}

	/**
	 * @param event
	 * @param reason
	 */
	closeToastNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			toastNotificationMessage: null,
		});
	};
	
	setToastNotificationText = (text) => {
		this.setState({
			toastNotificationMessage: text,
		});
	}

	updateStateInputValues = (event) => {
		if (event.target.type !== "checkbox") {
			this.setState({
				[event.target.name]: event.target.value,
				isEmailAlreadyTaken: false,
			});
		} else {
			this.setState({
				[event.target.name]: event.target.checked,
				isEmailAlreadyTaken: false,
			});
		}
	}

	submitAccountDetailsWithEnter = (e, setUser, shouldSubmit) => {
		if (shouldSubmit && e.key === 'Enter') {
			this.submitAccountDetailsUpdate(e, setUser);
		}
	}

	submitConvertGuestToUserWithEnter = (e, setUser, shouldSubmit) => {
		if (shouldSubmit && e.key === 'Enter') {
			this.submitConvertGuestToUser(e, setUser);
		}
	}

	submitPasswordWithEnter = (e, setUser, shouldSubmit) => {
		if (shouldSubmit && e.key === 'Enter') {
			this.submitPasswordUpdate(e, setUser);
		}
	}

	logOut = async (event, setUser) => {
		event.preventDefault(); // prevents it from reloading the page and adding the params to the url.

		await fetch('/api/users/logout', {
			method: 'post',
			headers: {
				'accept': 'application/json',
				'content-type': 'application/json'
			},
		});

		setUser(null);
		this.props.navigate('/');
	}
	
	updateUser = async (userModel, setUser) => {
		let response = await fetch(UrlBuilder.user.currentUserApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(userModel)
		});

		if (response.status === 200) {
			let userData = await response.json();
			setUser(userData['data']);
		}
	}

	submitPreferredUnitSystemId = async (event, user, setUser) => {
		event.preventDefault(); // prevents it from reloading the page and adding the params to the url.
		event.stopPropagation();

		let value = event.target.value;
		
		let userView = {
			preferredUnitSystemId: value,
		};

		await this.updateUser(userView, setUser);

		this.setToastNotificationText('Unit system updated to ' + (value == UnitPrettifier.kg ? 'metric.' : 'imperial.'));
	}

	submitHasDarkModeEnabled = async (event, user, setUser) => {
		event.preventDefault(); // prevents it from reloading the page and adding the params to the url.
		event.stopPropagation();

		let isEnabled = event.target.value === 'true';
		
		let userView = {
			hasDarkModeEnabled: isEnabled,
		};

		await this.updateUser(userView, setUser);

		this.setToastNotificationText(isEnabled ? 'Dark Mode enabled.' : 'Dark Mode disabled.');
	}
	
	submitConvertGuestToUser = async(e, setUser) => {
		e.preventDefault();

		let user = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
		};

		let response = await fetch(UrlBuilder.user.convertGuestToUserApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(user)
		});

		let jsonResponse = await response.json();

		if (response.status === 200) {
			setUser(jsonResponse['data']);
			this.setToastNotificationText('Account registered successfully.');
			this.setState({
				password: null,
				name: null,
				email: null,
			})
			return;
		}

		if (jsonResponse.message === "email_already_taken") {
			this.setState({
				isEmailAlreadyTaken: true,
			})
		}
	}

	submitAccountDetailsUpdate = async (event, setUser) => {
		event.preventDefault();

		let userView = {
			name: this.state.name,
		};
		
		await this.updateUser(userView, setUser);

		this.setToastNotificationText('Details updated.')
	}

	submitPasswordUpdate = async (event, setUser) => {
		event.preventDefault();

		let userView = {
			password: this.state.password,
		};

		await this.updateUser(userView, setUser);

		this.setToastNotificationText('Password updated.')
	}
	
	submitFeedback = async (user) => {
		if (this.state.feedbackText) {
			let feedbackView = {
				text: this.state.feedbackText,
			};

			if (this.state.shouldIncludeFeedbackEmail) {
				feedbackView['email'] = this.state.feedbackEmail ? this.state.feedbackEmail : user.email;
			}

			await fetch(UrlBuilder.user.feedbackApi(), {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(feedbackView)
			});
			
			this.setToastNotificationText('Thanks for your feedback!')
		}
		
		this.openSubmitFeedbackDialog(false);
	}
	
	openSubmitFeedbackDialog = (isOpen) => {
		this.setState({
			isSubmitFeedbackDialogOpen: isOpen,
		});
	}
	
	openSupporterDialog = (isOpen) => {
		this.setState({
			isSupporterDialogOpen: isOpen,
		});
	}

	scrollToSupporterSection = () => {
		if (this.supporterSectionRef && this.supporterSectionRef.current) {
			this.supporterSectionRef.current.scrollIntoView({behavior: 'smooth'});
		}
	}

	scrollToConfigSection = () => {
		if (this.configSectionRef) {
			this.configSectionRef.current.scrollIntoView({behavior: 'smooth'});
		}
	}
	
	openAnnouncementsCollapse = () => {
		this.setState({
			isAnnouncementsCollapseOpen: !this.state.isAnnouncementsCollapseOpen,
		})
	}

	redirectToGoogleAuth = () => {
		window.location = '/api/users/google-signin';
	}

	redirectToAppleAuth = () => {
		window.location = '/api/users/apple-signin';
	}

	showPassword = () => {
		this.setState({
			showPassword: !this.state.showPassword,
		});
	}
	
	renderPersonalDetailsCardForUser = (user, setUser) => {
		const {classes} = this.props;

		let isSubmitDisabled = !this.state.name;
		
		return <Card variant='outlined'>
			<CardHeader
				title={
					<Typography variant="button" display="block">
						Account
					</Typography>
				}
				subheader='Your personal details.'
				avatar={<AccountCircleIcon fontSize='large' />}
			/>
			<Divider light/>
			<CardContent>
				<form className={classes.root} noValidate autoComplete="off">
					<TextField
						id="email"
						label="Email Address"
						name="email"
						defaultValue={user.email}
						disabled
					/>
					<br/>
					<TextField
						required 
						id="name"
						name="name"
						onChange={this.updateStateInputValues}
						label="Name"
						onKeyPress={e => this.submitAccountDetailsWithEnter(e, setUser, !isSubmitDisabled)}
						defaultValue={user.name}
					/>
				</form>
			</CardContent>
			<Divider light/>
			<CardActions>
				<div className={classes.grow}/>
				<Button variant="contained" color="primary" onClick={e => this.submitAccountDetailsUpdate(e, setUser)} disabled={isSubmitDisabled}>
					Save Details
				</Button>
			</CardActions>
		</Card>;
	}
	
	renderPersonalDetailsCardForGuest = (user, setUser) => {
		const {classes} = this.props;

		let isEmailInvalid = false;
		let emailFieldHelperText = null;
		if (this.state.email && !Regexer.testEmail(this.state.email)) {
			isEmailInvalid = true;
			emailFieldHelperText = 'Please enter a valid email'
		} else if (this.state.isEmailAlreadyTaken) {
			isEmailInvalid = true;
			emailFieldHelperText = 'Email is already taken, please try another one';
		}

		let isPasswordInvalid = false;
		let passwordFieldHelperText = null;
		if (this.state.password && !Regexer.testPassword(this.state.password)) {
			isPasswordInvalid = true;
			passwordFieldHelperText = 'Password must have a minimum length of 6 and contain a number.';
		}

		let isSubmitDisabled = !this.state.name || !this.state.email || isEmailInvalid || !this.state.password || isPasswordInvalid;

		return <Card variant='outlined'>
			<CardHeader
				title={
					<Typography variant="button" display="block">
						Account
					</Typography>
				}
				subheader='Your personal details.'
				avatar={<AccountCircleIcon fontSize='large' />}
			/>
			
			<Divider light/>

			<Alert severity="info">
				<AlertTitle>Register your account</AlertTitle>
				You're currently using a guest account. To be able to sign back in to the app and avoid losing your data, register an account. No email verification required.
			</Alert>

				<CardContent>
					<>
						<Box display="flex" justifyContent="center" alignItems="center" sx={{marginTop: 0.75, marginBottom: 2.25 }}>
							<Button sx={{ width: 250, height: 45}} variant='contained' onClick={this.redirectToGoogleAuth} size='medium' startIcon={<GoogleIcon fontSize='large'/>}>
								Continue with Google
							</Button>
						</Box>

						<Box display="flex" justifyContent="center" alignItems="center" sx={{marginTop: -0.8, marginBottom: 0.4}}>
							<Button sx={{width: 250, height: 45}} variant='contained' onClick={this.redirectToAppleAuth} size='medium' startIcon={<AppleIcon fontSize='large'/>}>
								Continue with Apple
							</Button>
						</Box>

						<Box display="flex" justifyContent="center" alignItems="center" sx={{marginTop: 1.75, marginBottom: 0.45}}>
							<Typography variant='overline'>
								Or Manually Register
							</Typography>
						</Box>
					</>

					<Box display="flex" justifyContent="center" alignItems="center">
						<form className={classes.root} noValidate autoComplete="off">
							<TextField
								required
								id="name"
								name="name"
								onChange={this.updateStateInputValues}
								label="Name"
								defaultValue={user.name}
								onKeyPress={e => this.submitConvertGuestToUserWithEnter(e, setUser, !isSubmitDisabled)}
							/>
		
							<br/>
		
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								onChange={this.updateStateInputValues}
								error={isEmailInvalid}
								helperText={emailFieldHelperText}
								onKeyPress={e => this.submitConvertGuestToUserWithEnter(e, setUser, !isSubmitDisabled)}
							/>
		
							<br/>
		
							<TextField
								InputProps={{
									endAdornment:
										<InputAdornment position="end">
											<IconButton onClick={this.showPassword} edge="end" size="large">
												{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
								}}
								variant="outlined"
								required
								fullWidth
								name="password"
								label="Password"
								type={this.state.showPassword ? "text" : "password"}
								id="password"
								autoComplete="current-password"
								onChange={this.updateStateInputValues}
								error={isPasswordInvalid}
								helperText={passwordFieldHelperText}
								onKeyPress={e => this.submitConvertGuestToUserWithEnter(e, setUser, !isSubmitDisabled)}
							/>
						</form>
					</Box>
				</CardContent>
			
				<CardActions>
					<div className={classes.grow}/>

					<Button variant="contained" sx={{ marginBottom: 1, marginRight: 1, marginTop: -1 }} color="primary" onClick={e => this.submitConvertGuestToUser(e, setUser)} disabled={isSubmitDisabled}>
						Register Account
					</Button>
				</CardActions>
		</Card>
	}
	
	renderSubmitFeedbackDialog = (user) => {
		return <Dialog
			fullWidth
			maxWidth={'sm'}
			open={this.state.isSubmitFeedbackDialogOpen}
			onClose={() => this.openSubmitFeedbackDialog(false)}
			TransitionComponent={Transition}
		>
			<DialogTitle>Submit Feedback</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Want to help shape and improve the app? Submit bug reports, feature requests, or general feedback.
				</DialogContentText>
				<TextField
					autoFocus
					label="Feedback"
					multiline
					rows={6}
					variant="outlined"
					fullWidth
					required 
					name="feedbackText"
					onChange={this.updateStateInputValues}
				/>
				
				<br/><br/>

				<FormControlLabel
					control={
						<Checkbox color='secondary' checked={this.state.shouldIncludeFeedbackEmail} name="shouldIncludeFeedbackEmail" onChange={this.updateStateInputValues} />
					}
					label="If we have further questions, can we contact you by email?"
				/>
				{this.state.shouldIncludeFeedbackEmail ?
					<TextField
						margin="dense"
						name="feedbackEmail"
						label="Email Address"
						type="email"
						fullWidth
						defaultValue={user.email}
						onChange={this.updateStateInputValues}
						value={this.state.feedbackEmail}
					/> : null
				}
				
			</DialogContent>
			<DialogActions>
				<Button onClick={() => this.openSubmitFeedbackDialog(false)}>
					Close
				</Button>
				<Button onClick={() => this.submitFeedback(user)}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	}

	render() {
		const {classes} = this.props;

		let isPasswordInvalid = this.state.password && !Regexer.testPassword(this.state.password);
		let isConfirmDifferent = this.state.confirmPassword && this.state.password !== this.state.confirmPassword;
		let isPasswordSubmitDisabled = !this.state.password || isPasswordInvalid || !this.state.confirmPassword || isConfirmDifferent;

		return (
            <UserContext.Consumer>
				{({user, setUser}) => {
					if (user) {
						return <>
							<Box paddingTop={1.5}/>
							
							{this.renderSubmitFeedbackDialog(user)}
							
							<DonationDialog
								isOpen={this.state.isSupporterDialogOpen}
								closeSelfFunc={() => this.openSupporterDialog(false)}
							/>

							<Snackbar
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								open={this.state.toastNotificationMessage !== null}
								autoHideDuration={1500}
								onClose={this.closeToastNotification}
								message={this.state.toastNotificationMessage}
								action={
									<React.Fragment>
										<IconButton size="small" aria-label="close" color="inherit" onClick={this.closeToastNotification}>
											<CloseIcon fontSize="small" />
										</IconButton>
									</React.Fragment>
								}
								style={{paddingBottom: 55}}
							/>

							<Card variant='outlined'>
								<CardHeader
									title={
										<Typography variant="button" display="block">
											Announcements
										</Typography>
									}
									subheader='See the latest new features, changes, and announcements for the app.'
									avatar={
										user.hasNotifications ?
											<Badge badgeContent={1} color="secondary">
												<WhatshotIcon fontSize='large'/>
											</Badge>
											:
											<WhatshotIcon fontSize='large'/>
									}
								/>
								<Divider light/>
								<CardContent style={{paddingBottom: 4}}>
									<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
										<strong>20 October 2024:</strong> <br/>
										• Include goals overview in home page. <br/>
										• Include Start Workout action bar in Routines page (also allowing to log past workouts). <br/>
										• ☕ Consider purchasing a <strong>Supporter's Badge</strong> to support the
										app's development and help cover server costs. Learn more: <IconButton size='small'><CallMadeIcon onClick={this.scrollToSupporterSection} style={{fontSize: 18}}/></IconButton> <br/>
									</Typography>

									<Collapse in={this.state.isAnnouncementsCollapseOpen}>
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>21 January 2024:</strong> <br/>
											• 1RM graphs now included in graphs tab (Brzycki formula). <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>9 July 2023:</strong> <br/>
											• You can export your exercise logs as a CSV. Option is in the Filter menu (bottom left) of any routine exercise logs page. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>3 July 2023:</strong> <br/>
											• The timer now has a settings icon where you can set whether setting a timer value is global (i.e. applies to all exercises). <br/>
											• RPE values (Rating of perceived exertion) are now included in the 'Max Reps per Weight' graph. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>25 June 2023:</strong> <br/>
											• Added a workout statistics section in the homepage. <br/>
											• Overall performance and UI improvements. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>20 June 2023:</strong> <br/>
											• Performance improvements to the logs table and graphs. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>6 April 2023:</strong> <br/>
											• Bug fix for app breaking when timer field was opened. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>6 Nov 2022:</strong> <br/>
											• You can now set custom colors for routines, exercises, and measurements. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>25 Jul 2022:</strong> <br/>
											• The quick button behaviour in the logger is now such that it first logs your set and only then starts the timer. <br/>
											• Added a quick timer start button in the logger. <br/>
											• Lots of internal performance and security improvements. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>22 Jun 2022:</strong> <br/>

											• You can now add comments to your exercise plan logs. <br/>
											• The adjusting weights + - buttons values were updated. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>2 Apr 2022:</strong> <br/>

											• You can now enter a muscle breakdown per exercise (in the exercises page). With this, in the workout detail page you can see a muscle breakdown graph of your current/past workout. <br/>
											• Performance and stability improvements.<br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>19 Feb 2022:</strong> <br/>

											• You can now archive exercises in routines. Use this to hide exercises you no longer want to see during your workout routines but still want access to. <br/>
											• Added option to show all logs for an exercise, even if the exercise is part of multiple routines. Do this in the time filtering menu on the bottom left of the exercise logger. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>26 Dec 2021:</strong> <br/>

											• Added possibility to add past workouts. Do this by visiting a routine page and
											clicking the 3-dot menu next to the Start Workout toolbar. <br/>
										</Typography>
										
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>23 Dec 2021:</strong> <br/>

											• (Internal change) Upgraded the theme/design engine to the latest version.
											If any bugs are found, please report them immediately. <IconButton
											size='small'><CallMadeIcon onClick={this.scrollToSupporterSection}
											                           style={{fontSize: 18}}/></IconButton> <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>16 Dec 2021:</strong> <br/>

											• Routine and measurement cards can now be re-ordered. Hold and drag the
											double arrow icon in the bottom right of the cards to do this. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>11 Dec 2021:</strong> <br/>

											• Superset exercises now automatically switch to the next exercise in the
											superset when you log a set. <br/>
											• Lots of under-the-hood improvements and upgrades. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>14 Nov 2021:</strong> <br/>

											• Added ability to move an exercise to another routine while keeping your
											logs. Do this via the exercise card menus in your routine pages. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>21 Oct 2021:</strong> <br/>

											• Implemented exercise supersets. Add them via the exercise card menus in
											your routine pages. <br/>
											• Overall UI and performance improvements. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>26 Aug 2021:</strong> <br/>

											• You can now edit the workout times and moods of existing workouts. <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>6 Aug 2021:</strong> <br/>

											• Added a workout planner feature in the exercise logger to remind you of
											which and how many sets to do during your workouts. Noticed any bugs or have
											suggestions? Go to feedback section: <IconButton size='small'><CallMadeIcon
											onClick={this.scrollToSupporterSection}
											style={{fontSize: 18}}/></IconButton> <br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>6 Aug 2021:</strong> <br/>

											• Allow exercises to be added more than once in a routine.<br/>
											• General UI fixes and improvements.<br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>23 Jun 2021:</strong> <br/>

											• Overhaul of the goals system: Rebuilt the system from the ground up with
											lots of improvements. The goals are now part of your routine-exercises (see
											Goals tab in the logger).<br/>
										</Typography>

										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>12 Jun 2021:</strong> <br/>

											• Overhaul of the logger: improved design and added dedicated statistics
											section. <br/>
										</Typography>
										<Typography style={{fontSize: 14, lineHeight: 1.9}} gutterBottom>
											<strong>2 Jun 2021:</strong> <br/>

											• When completing a workout, you can now specify a mood and end time.
										</Typography>
										<Typography style={{fontSize: 14, lineHeight: 1.9}}>
											<strong>27 May 2021:</strong> <br/>

											• Added dark mode option in the Settings page. <IconButton
											size='small'><CallMadeIcon onClick={this.scrollToConfigSection}
											                           style={{fontSize: 18}}/></IconButton> <br/>
											• Added RPE/RIR logging. <br/>
										</Typography>
									</Collapse>

									<Typography align='center'>
										<IconButton onClick={this.openAnnouncementsCollapse} size="large">
											{this.state.isAnnouncementsCollapseOpen ? <ExpandLessIcon/> :
												<ExpandMoreIcon/>}
										</IconButton>
									</Typography>
								</CardContent>
							</Card>

							<br/>
							
							{user.email ? 
								this.renderPersonalDetailsCardForUser(user, setUser) 
								: 
								this.renderPersonalDetailsCardForGuest(user, setUser)
							}

							<br/>

							<Card variant='outlined'>
								<CardHeader
									title={
										<Typography variant="button" display="block">
											Configurations
										</Typography>
									}
									subheader='Tweak the app to your taste.'
									avatar={<TuneIcon fontSize='large' />}
								/>
								<Divider light/>
								<CardContent ref={this.configSectionRef}>
									<List component="div" disablePadding>
										<ListItem>
											<FormControl component="fieldset">
												<FormLabel component="legend">
													<Typography variant='body1'>Unit System</Typography>
												</FormLabel>
												<RadioGroup className={classes.nested} value={user.preferredUnitSystemId ?? UnitPrettifier.kg} onChange={(e) => this.submitPreferredUnitSystemId(e, user, setUser)}>
													<FormControlLabel value={UnitPrettifier.kg} control={<Radio color="secondary" />} label={<Typography variant='body2'>Metric (kg, km)</Typography>} />
													<FormControlLabel value={UnitPrettifier.lbs} control={<Radio color="secondary" />} label={<Typography variant='body2'>Imperial (lbs, miles)</Typography>} />
												</RadioGroup>
											</FormControl>
										</ListItem>
										<ListItem>
											<FormControl component="fieldset">
												<FormLabel component="legend">
													<Typography variant='body1'>Dark Mode</Typography>
												</FormLabel>
												<RadioGroup className={classes.nested} value={user.hasDarkModeEnabled} onChange={(e) => this.submitHasDarkModeEnabled(e, user, setUser)}>
													<FormControlLabel value={true} control={<Radio color="secondary"/>} label={<Typography variant='body2'>Enabled</Typography>} />
													<FormControlLabel value={false} control={<Radio color="secondary"/>} label={<Typography variant='body2'>Disabled</Typography>} />
												</RadioGroup>
											</FormControl>
										</ListItem>
									</List>
								</CardContent>
							</Card>

							<br/>

							<Card variant='outlined'>
								<CardHeader
									title={
										<Typography variant="button" display="block">
											Other
										</Typography>
									}
									subheader='Submit feedback and support development.'
									avatar={<InfoIcon fontSize='large' />}
								/>
								<Divider light/>
								<CardContent>
									<List component="div">
										<ListItem button onClick={() => this.openSubmitFeedbackDialog(true)}>
											<ListItemAvatar>
												<FeedbackIcon fontSize='large'/>
											</ListItemAvatar>
											<ListItemText primary={<Typography variant='button'>Submit Feedback</Typography>} secondary='Submit bug reports, feature requests, or general feedback.'/>
										</ListItem>
										
										{user.email &&
											<ListItem button onClick={() => this.openSupporterDialog(true)} ref={this.supporterSectionRef}>
												<ListItemAvatar>
													{user.isSupporter ?
														<img src={Badge2} alt='Supporter Badge' width={42}/> :
														<FavoriteIcon/>
													}
												</ListItemAvatar>
												{user.isSupporter ?
													<ListItemText primary={<Typography variant='button'>You are a supporter!</Typography>} secondary='See your Supporter Badge and the Supporters List. Donate again if you wish.'/>
													:
													<ListItemText primary={<Typography variant='button'>Become a supporter </Typography>} secondary='Purchase a supporter badge and get listed in the Supporters List.'/>
												}
											</ListItem>
										}
										
									</List>
								</CardContent>
							</Card>
							
							{user.email &&
								<>
									<br/>

									<Card variant='outlined'>
										<CardHeader
											title={
												<Typography variant="button" display="block">
													Security
												</Typography>
											}
											subheader='Change your password.'
											avatar={<SecurityIcon fontSize='large' />}
										/>
										<Divider light/>
										<CardContent>
											<form className={classes.root} noValidate autoComplete="off">
												<div>
													<TextField
														required
														fullWidth
														name="password"
														label="Password"
														type="password"
														id="password"
														autoComplete="current-password"
														onChange={this.updateStateInputValues}
														placeholder={"*********"}
														InputLabelProps={{
															shrink: true,
														}}
														error={isPasswordInvalid}
														helperText={isPasswordInvalid ? "Password must have a minimum length of 6 and contain a number." : null}
													/>
												</div>
												<div>
													<div>
														<TextField
															required
															fullWidth
															name="confirmPassword"
															label="Confirm Password"
															type="password"
															id="confirmPassword"
															autoComplete="current-password"
															onChange={this.updateStateInputValues}
															placeholder={"*********"}
															InputLabelProps={{
																shrink: true,
															}}
															onKeyPress={e => this.submitPasswordWithEnter(e, setUser, !isPasswordSubmitDisabled)}
															error={isConfirmDifferent}
															helperText={isConfirmDifferent ? "Passwords must match." : null}
														/>
													</div>
												</div>
											</form>
										</CardContent>
										<Divider light/>
										<CardActions>
											<div className={classes.grow}/>
											<Button variant="contained" color="primary" disabled={isPasswordSubmitDisabled} onClick={e => this.submitPasswordUpdate(e, setUser)}>
												Update Password
											</Button>
										</CardActions>
									</Card>
								</>
							}

							<Box display="flex" justifyContent="center" alignItems="center" minHeight="13vh">
								<Button variant="contained" color='primary' onClick={e => this.logOut(e, setUser)} size='large'>
									Log out
								</Button>
							</Box>
							
							<SubmitHasNoNotifications/>
						</>;
					}
				}}
			</UserContext.Consumer>
        );
	}
}

Settings.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(Settings));