import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import {UserContext} from "../../../core/UserContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import {Checkbox, FormControlLabel, Popover, Tooltip} from "@mui/material";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

class TimerSettingsDialog extends PureComponent {
	/**
	 * @param props Containing:
	 *      'isOpen':
	 *      'closeSelfFunc':
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Are the timer settings open?
			 */
			isOpen: props.isOpen,
			/**
			 * The Anchor El for the help tooltip.
			 */
			helpToolTipAnchorEl: null,
		};

	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			isOpen: nextProps.isOpen,
		})
	}
	
	submit = () => {
		this.props.closeSelfFunc();
	}
	
	toggleTimerValueGloballySetting = (e) => {
		UserLocalStorage.set('global_timer_value_setting', e.target.checked);
		this.forceUpdate();
	}

	toggleHelpTooltip = (e) => {
		this.setState({
			helpToolTipAnchorEl: e.currentTarget,
		})
	}

	closeHelpTooltip = () => {
		this.setState({
			helpToolTipAnchorEl: null,
		})
	}
	
	render() {
		const {classes} = this.props;

		return (
			<Dialog
				className={classes.modal}
				open={this.state.isOpen}
				onClose={this.props.closeSelfFunc}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				TransitionComponent={Transition}
			>
				<AppBar style={{position: 'relative'}} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap>
							Timer Settings
						</Typography>
					</Toolbar>
				</AppBar>

				<DialogContent dividers>
					<FormControlLabel
						control={
							<Checkbox
								checked={UserLocalStorage.get('global_timer_value_setting') ?? false}
								onChange={this.toggleTimerValueGloballySetting}
							/>
						}
						label={
							<React.Fragment>
								Apply timer value globally
								<IconButton size="small" onClick={this.toggleHelpTooltip}>
									<HelpIcon/>
								</IconButton>
								<Popover
									id="help-popover"
									open={Boolean(this.state.helpToolTipAnchorEl)}
									anchorEl={this.state.helpToolTipAnchorEl}
									onClose={this.closeHelpTooltip}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									<Typography sx={{ p: 2 }}>
										Activating this means that setting the timer value will also set the same timer value for all exercises. If it's disabled, each exercise will have its own timer value.
									</Typography>
								</Popover>
							</React.Fragment>
						}
					/>
				</DialogContent>
				
				<DialogActions>
					<Button variant="contained" color='grey' onClick={this.props.closeSelfFunc}>Close</Button>
					<Button variant="contained" color='primary' onClick={this.submit}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

TimerSettingsDialog.contextType = UserContext;

export default withStyles(useStyles)(TimerSettingsDialog);