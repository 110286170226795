import React from "react";
import withStyles from '@mui/styles/withStyles';
import Badge from "@mui/material/Badge";
import LinkIcon from '@mui/icons-material/Link';

const styles = theme => ({
	customBadge: {
		backgroundColor: props => props.color,
		color: "white",
		fontSize: '0.5rem'
	}
});

function SimpleBadge(props) {
	const { classes, children } = props;
	return (
        <Badge
			classes={{ badge: classes.customBadge }}
			badgeContent={<LinkIcon style={{ fontSize: '0.95rem'}} />}
			overlap="circular"
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
		>
			{children}
		</Badge>
    );
}
const SupersetBadge = withStyles(styles)(SimpleBadge);

export default SupersetBadge;
