import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {Avatar, Dialog, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import Box from "@mui/material/Box";
import {withRouter} from "../../../hooks/withRouter";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider, PickersDay, StaticDatePicker} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	media: {
		height: 120,
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		top: '17% !important'
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class CalendarModule extends PureComponent {
	static workoutsObj = {};

	constructor(props) {
		super(props);

		this.state = {
			selectedDate: new Date(),
			isLoading: true,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			isOpen: nextProps.isOpen,
		});
		
		if (nextProps.isOpen) {
			this.fetch();
		}
	}

	fetch = async (whichMonthYear = null) => {
		this.setState({
			isLoading: true,
		});
		
		if (whichMonthYear) {
			whichMonthYear = moment(whichMonthYear).format('L'); // format it
		} else {
			whichMonthYear = moment().format('L'); // Get now, format it.
		}

		const response = await fetch(UrlBuilder.modules.calendarModuleApi(whichMonthYear));

		if (response.status !== 200) {
			this.setState({
				isLoading: false,
			});
			return;
		}

		const responseData = await response.json();

		let workouts = responseData['data']['workouts'] ?? [];

		if (Array.isArray(workouts)) {
			for (const workout of workouts) {
				CalendarModule.workoutsObj[new Date(workout.dateTimeStarted).toDateString()] = workout;
			}
		}

		this.setState({ // Needed in order to force a re-render.
			isLoading: false,
		});
	}

	findWorkout(day) {
		return CalendarModule.workoutsObj[day.toDateString()] ?? null;
	}

	updateDate = (day) => {
		let date = new Date(day);

		let workout = this.findWorkout(date);

		if (workout) {
			this.props.navigate(UrlBuilder.workout.workoutDetailPage(workout.id));
		} else {
			this.setState({
				selectedDate: date,
			})
		}
	}

	render() {
		const {classes} = this.props;

		return <Dialog
			open={this.state.isOpen}
			onClose={this.props.closeSelfFunc}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			TransitionComponent={Transition}
		>
			<Card variant='outlined'>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<StaticDatePicker
						value={this.state.selectedDate}
						orientation='portrait'
						openTo="day"
						onChange={this.updateDate}
						disableFuture
						onMonthChange={(date) => {
							this.fetch(date);
						}}
						renderInput={(params) => <TextField {...params} />}
						renderDay={(date, selectedDates, pickersDayProps) => {
							let workout = this.findWorkout(date);

							let badge = workout ?
								<Avatar 
									style={{backgroundColor: workout.routines[0]?.color ?? ColorPicker.pick(workout.routines[0]?.name)}} 
									className={classes.small}
								>
									<Typography variant='body2'>
										{workout.routines[0].name.charAt(0).toUpperCase() ?? '?'}
									</Typography>
								</Avatar>
								: null

							return <Badge key={date.toString()} badgeContent={badge} overlap='circular'>
								<PickersDay {...pickersDayProps} disabled={badge === null} showDaysOutsideCurrentMonth/>
							</Badge>;
						}}
					/>
				</LocalizationProvider>
				{this.state.isLoading ? <LinearProgress/> : <Box paddingTop={0.45}/>}
			</Card>
		</Dialog>
		;
	}
}

export default withRouter((withStyles(useStyles)(CalendarModule)))