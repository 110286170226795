import moment from "moment";

export class HoursMinutesTimeConverter {

	/**
	 * @param val A string representing a dateTime object.
	 * @returns {number} The time in seconds (int).
	 */
	static convertDateStringtoSeconds = (val) => {
		let dateTime = new Date(val);
		return (dateTime.getMinutes() * 60) + dateTime.getSeconds();
	}

	/**
	 * @param seconds Int
	 * @returns {Date}
	 */
	static convertSecondsToDateTime = (seconds) => {
		let date = new Date();
		date.setHours(0, 0, seconds, 0);
		return date;
	}
	
	/**
	 * @param val A string representing a dateTime object.
	 */
	static convertDateStringToFloat = (val) => {
		let time = new Date(val);
		let asFloat = (time.getHours() * 60) + time.getMinutes() + (time.getSeconds() / 60); // Converts to a float point num.
		return Math.round(asFloat * 100) / 100; // Round 2 to decimal places.
	}

	/**
	 * @param timeAsFloat A floating point number representing minutes and seconds. Whole part is minutes, decimal is seconds but in scale of 1-100.
	 * @returns {Date} 
	 */
	static convertFloatToDateTime = (timeAsFloat) => {
		let hours = Math.trunc(timeAsFloat / 60);
		let minutes = Math.trunc(timeAsFloat % 60); // Get remaining minutes.
		let seconds = Math.round((timeAsFloat - Math.trunc(timeAsFloat)) * 60); // Get the decimal points basically, then multiply by 60 to get secs.
		let date = new Date();
		date.setHours(hours, minutes, seconds, 0);
		return date;
	}
	
	static convertFloatToString = (timeAsFloat) => {
		let dateTime = HoursMinutesTimeConverter.convertFloatToDateTime(timeAsFloat);
		return moment(dateTime).format('HH:mm:ss');
	}
}