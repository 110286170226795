import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import {UnitPrettifier} from "../../../../core/prettifier/UnitPrettifier";
import {Checkbox, Collapse, Divider, FormControlLabel} from "@mui/material";
import {UrlBuilder} from "../../../../core/url/UrlBuilder";
import moment from "moment";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogContentText from "@mui/material/DialogContentText";
import {HoursMinutesTimeConverter} from "../../../../core/prettifier/HoursMinutesTimeConverter";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import Slide from "@mui/material/Slide";
import {ExerciseType} from "../../../../core/models/ExerciseType";
import {ExerciseGoalType} from "../../../../core/models/ExerciseGoalType";

const useStyles = theme => ({
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 250,
	},
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class GoalNewOrEditFormPopUp extends Component {

	/**
	 * @param props Containing:
	 *      'goal': The goal to edit, if any.
	 *      'isOpen': Says whether this modal is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The exercise this goal is for.
			 */
			exercise: this.props.exercise,
			/**
			 * The goal.
			 */
			goal: this.props.goal,
			/**
			 * Is it open?
			 */
			isOpen: this.props.isOpen,
			/**
			 * The selected goal-type. The different types depend on the exercise type. {@see renderGoalTypesMenu}
			 */
			selectedGoalTypeId: this.props.goal ? this.props.goal.type.id : null,
			/**
			 * The goal deadline, if any.
			 */
			deadline: this.props.goal && this.props.goal.deadline ? this.props.goal.deadline : null,
			/**
			 * The first value.
			 */
			value1: this.props.goal ? this.props.goal.value1 : 0,
			/**
			 * The second value.
			 */
			value2: this.props.goal ? this.props.goal.value2 : 0,
			/**
			 * The goal description, if any.
			 */
			description: this.props.goal ? this.props.goal.description : '',
			/**
			 * Is it routine specific? If so, we'll include the routineExerciseId in the goal.
			 */
			isRoutineSpecific: this.props.goal && this.props.goal.routineExerciseId ? this.props.goal.routineExerciseId : false,
			/**
			 * Is it loading?
			 */
			isLoading: false,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			
			goal: nextProps.goal,
			isOpen: nextProps.isOpen,
			selectedGoalTypeId: nextProps.goal ? nextProps.goal.type.id : null,
			deadline: nextProps.goal && nextProps.goal.deadline ? nextProps.goal.deadline : null,
			value1: nextProps.goal ? (nextProps.goal.values[0] ?? 0) : 0,
			value2: nextProps.goal ? (nextProps.goal.values[1] ?? 0) : 0,
			description: nextProps.goal ? nextProps.goal.description : '',
			isRoutineSpecific: nextProps.goal && nextProps.goal.routineExerciseId ? nextProps.goal.routineExerciseId : false,
			
			isDeleteConfirmationDialogOpen: false,
		});
	}

	updateSelectedGoalTypeIdFromDropdownInputSelection = (event) => {
		this.setState({
			selectedGoalTypeId: event.target.value,
			value1: 0, // Reset these values if the type changed.
			value2: 0,
		});
	}

	updateStateInputValues = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	updateRoutineSpecificCheckboxInputValue = (e) => {
		this.setState({
			isRoutineSpecific: e.target.checked,
		});
	}

	/**
	 * This is similar to {@see updateStateInputValues} but handles the date.
	 *
	 * @param date
	 */
	handleDateChange = (date) => {
		this.setState({
			deadline: date
		});
	};

	submitWithEnter = (e) => {
		if (e.key === 'Enter' && !this.state.isLoading) {
			this.submit(e);
		}
	}

	submit = async (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let exerciseGoalToSave;
		if (this.state.goal) {
			exerciseGoalToSave = {
				id: this.state.goal.id,
				routineExerciseId: this.state.isRoutineSpecific ? this.state.exercise.routineExerciseId : null,
				description: this.state.description,
				deadline: this.state.deadline ? moment(this.state.deadline).format('L, h:mm:ss A') : null,
				dateModified: moment().format('L, h:mm:ss A'),
			};
		} else {
			exerciseGoalToSave = {
				type: {
					id: this.state.selectedGoalTypeId,
				},
				exerciseId: this.state.exercise.id,
				routineExerciseId: this.state.isRoutineSpecific ? this.state.exercise.routineExerciseId : null,
				description: this.state.description,
				deadline: this.state.deadline ? moment(this.state.deadline).format('L, h:mm:ss A') : null,
				dateCreated: moment().format('L, h:mm:ss A'),
			};
		}

		let values = [this.state.value1];
		if (this.state.value2) {
			values.push(this.state.value2);
		}
		exerciseGoalToSave['values'] = values;

		await fetch(UrlBuilder.goals.exerciseGoalsApi(), {
			method: this.state.goal ? 'PUT' : 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(exerciseGoalToSave)
		}).then(() => this.props.closeSelfFunc(true));

		this.setState({
			isLoading: false,
		});
	}

	openGoalDeleteConfirmationDialog = (isOpen) => {
		this.setState({
			isDeleteConfirmationDialogOpen: isOpen
		})
	}

	renderDeleteConfirmationDialog = (e) => {
		if (!this.state.goal) {
			return null;
		}
		if (!this.state.isDeleteConfirmationDialogOpen) {
			return null;
		}

		return <Dialog open={true} onClose={() => this.openGoalDeleteConfirmationDialog(false)} TransitionComponent={Transition}>
			<DialogTitle>{"Are you sure you want to delete this goal?"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' color='grey' onClick={() => this.openGoalDeleteConfirmationDialog(false)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={(e) => this.props.deleteGoalFunc(e, this.state.goal)} color="primary" autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	}

	renderGoalTypesMenu = () => {
		switch (this.state.exercise.type.id) {
			case ExerciseType.WeightAndReps:
				return <Select
					value={this.state.selectedGoalTypeId}
					onChange={this.updateSelectedGoalTypeIdFromDropdownInputSelection}
					disabled={!!this.state.goal}
				>
					<MenuItem value={ExerciseGoalType.WeightAndRepsGoal}>Weight & Reps</MenuItem>
					<MenuItem value={ExerciseGoalType.WeightGoal}>Weight</MenuItem>
					<MenuItem value={ExerciseGoalType.RepsGoal}>Reps</MenuItem>
				</Select>;
			case ExerciseType.Reps:
				return <Select
					value={this.state.selectedGoalTypeId}
					onChange={this.updateSelectedGoalTypeIdFromDropdownInputSelection}
					disabled={!!this.state.goal}
				>
					<MenuItem value={ExerciseGoalType.RepsGoal}>Reps</MenuItem>
				</Select>;
			case ExerciseType.DistanceAndTime:
				return <Select
					value={this.state.selectedGoalTypeId}
					onChange={this.updateSelectedGoalTypeIdFromDropdownInputSelection}
					disabled={!!this.state.goal}
				>
					<MenuItem value={ExerciseGoalType.DistanceAndTimeGoal}>Distance & Time</MenuItem>
					<MenuItem value={ExerciseGoalType.DistanceGoal}>Distance</MenuItem>
					<MenuItem value={ExerciseGoalType.TimeGoal}>Time</MenuItem>
				</Select>;
			case ExerciseType.Time:
				return <Select
					value={this.state.selectedGoalTypeId}
					onChange={this.updateSelectedGoalTypeIdFromDropdownInputSelection}
					disabled={!!this.state.goal}
				>
					<MenuItem value={ExerciseGoalType.TimeGoal}>Time</MenuItem>
				</Select>;
			case ExerciseType.WeightAndTime:
				return <Select
					value={this.state.selectedGoalTypeId}
					onChange={this.updateSelectedGoalTypeIdFromDropdownInputSelection}
					disabled={!!this.state.goal}
				>
					<MenuItem value={ExerciseGoalType.WeightAndTimeGoal}>Weight & Time</MenuItem>
					<MenuItem value={ExerciseGoalType.WeightGoal}>Weight</MenuItem>
					<MenuItem value={ExerciseGoalType.TimeGoal}>Time</MenuItem>
				</Select>;
		}
	}

	renderTextField = (label, key, unitLabel = null) => {
		return <TextField
			variant='outlined'
			margin="dense"
			type='number'
			inputMode='decimal'
			required
			error={this.state[key] < 0}
			name={key}
			value={this.state[key]}
			label={label}
			InputProps={{
				endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment>,
			}}
			onChange={this.updateStateInputValues}
			onKeyPress={this.submitWithEnter}
			style={{maxWidth: 150}}
			disabled={this.state.goal && this.state.goal.dateCompleted}
		/>;
	}

	/**
	 * @param key
	 * @param val A string representing a dateTime object.
	 */
	updateTimeAttributeValue = (key, val) => {
		this.setState({
			[key]: (HoursMinutesTimeConverter.convertDateStringToFloat(val)),
		});
	}
	
	renderTimeField = (label, key) => {
		return <LocalizationProvider dateAdapter={AdapterDateFns}>
			<TimePicker
				ampm={false}
				openTo="minutes"
				views={["hours", "minutes", "seconds"]}
				inputFormat="HH:mm:ss"
				label={label}
				value={HoursMinutesTimeConverter.convertFloatToDateTime(this.state[key])}
				onChange={(val) => this.updateTimeAttributeValue(key, val)}
				disabled={this.state.goal && this.state.goal.dateCompleted}
				renderInput={(params) => <TextField {...params} sx={{maxWidth: 150}} />}
			/>
		</LocalizationProvider>;
	}

	renderGoalTypeValues = () => {
		switch (this.state.selectedGoalTypeId) {
			case ExerciseGoalType.WeightAndRepsGoal:
				return <>
					{this.renderTextField('Weight', 'value1', UnitPrettifier.weight())}
					<br/>
					{this.renderTextField('Reps', 'value2', 'reps')}
				</>;
			case ExerciseGoalType.WeightGoal:
				return this.renderTextField('Weight', 'value1', UnitPrettifier.weight());
			case ExerciseGoalType.RepsGoal:
				return this.renderTextField('Reps', 'value1', 'reps');
			case ExerciseGoalType.DistanceAndTimeGoal:
				return <>
					{this.renderTextField('Distance', 'value1', UnitPrettifier.distance())}
					<br/>
					{this.renderTimeField('Time', 'value2')}
				</>;
			case ExerciseGoalType.DistanceGoal:
				return this.renderTextField('Distance', 'value1', UnitPrettifier.distance());
			case ExerciseGoalType.TimeGoal:
				return this.renderTimeField('Time', 'value1');
			case ExerciseGoalType.WeightAndTimeGoal:
				return <>
					{this.renderTextField('Weight', 'value1', UnitPrettifier.weight())}
					<br/>
					{this.renderTimeField('Time', 'value2')}
				</>;
		}
	}


	render() {
		const {classes} = this.props;

		return (
            <Dialog open={this.state.isOpen} onClose={() => this.props.closeSelfFunc(false)} fullWidth TransitionComponent={Transition}>
                <DialogTitle>{this.state.goal ? 'Edit Goal' : 'Create Goal'}</DialogTitle>
                <Divider light/>
                <DialogContent>
                    <Grid container justifyContent="center" direction='column'>
                        <FormControl className={classes.formControl} variant='standard'>
                            <InputLabel>
                                Goal Type
                            </InputLabel>
                            {this.renderGoalTypesMenu()}
                        </FormControl>
                    </Grid>
                    
                    <Collapse in={this.state.selectedGoalTypeId !== null}>
                        <br/>
                        
                        <Grid container justifyContent="center" alignItems='center' direction='column' spacing={1}>
                            <Grid item>
                                {this.renderGoalTypeValues()}
                            </Grid>
                            <Grid item>
	                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        variant="inline"
                                        inputFormat="dd/MM/yyyy"
                                        name="deadline"
                                        label="Deadline (optional)"
                                        value={this.state.deadline}
                                        disabled={this.state.goal && this.state.goal.dateCompleted}
                                        onChange={this.handleDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
	                            </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="description"
                                    label="Description (optional)"
                                    type="text"
                                    onChange={this.updateStateInputValues}
                                    value={this.state.description}
                                    onKeyPress={this.submitWithEnter}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.isRoutineSpecific}
                                    onChange={this.updateRoutineSpecificCheckboxInputValue}
                                    color="secondary"
                                    disabled={this.state.goal && this.state.goal.dateCompleted}
                                />
                            }
                            label={<Typography variant='subtitle1'>Make routine-specific</Typography>}
                        />
                        <br/>
                        <Typography variant='caption'>
                            {this.state.isRoutineSpecific ? 
                                'Unchecking this will make this goal available for any routine that has this exercise.' :
                                'Checking this will make this goal available for only this specific routine-exercise combination.'
                            }
                        </Typography>
                        <br/>
                    </Collapse>

                    {this.renderDeleteConfirmationDialog()}

                </DialogContent>
                <Divider light/>
                <DialogActions>
                    {this.state.goal ?
                        <IconButton onClick={() => this.openGoalDeleteConfirmationDialog(true)} size="large">
                            <DeleteIcon/>
                        </IconButton> : null
                    }
                    <Button variant="contained" color='grey' onClick={() => this.props.closeSelfFunc(false)} className={classes.button}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.submit}
                        type="submit"
                        value="Submit"
                        disabled ={this.state.isLoading || this.state.selectedGoalTypeId === null || !this.state.value1}
                        className={classes.button}
                    >
                        {this.state.isLoading ?
                            <div>
                                <CircularProgress size="1rem" color="inherit" /> Loading...
                            </div> :
                            'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        );
	}
}

export default withStyles(useStyles)(GoalNewOrEditFormPopUp);