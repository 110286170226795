import React, {PureComponent} from 'react';
import {UserContext} from "../../../core/UserContext";
import {UrlBuilder} from "../../../core/url/UrlBuilder";

/**
 * Just submits that the user has no notifications after X seconds. This is because the parent
 * class already has another context. This is a workaround to load the UserContext.
 */
class SubmitHasNoNotifications extends PureComponent {
	
	componentDidMount() {
		if (this.context.user.hasNotifications) {
			setTimeout(() => this.submitUserHasNoNotifications(), 2000);
		}
	}

	submitUserHasNoNotifications = async () => {
		let user = {
			hasNotifications: false,
		};

		let response = await fetch(UrlBuilder.user.currentUserApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(user)
		}).then(res => res.json());

		this.context.setUser(response['data']);
	}
	
	render() {
		return <></>;
	}
}

SubmitHasNoNotifications.contextType = UserContext;

export default SubmitHasNoNotifications;