import React, {Component} from 'react';
import {ExerciseGoalType} from "../../../../core/models/ExerciseGoalType";
import WeightRepsGoalCard from "./WeightRepsGoalCard";
import WeightGoalCard from "./WeightGoalCard";
import RepsGoalCard from "./RepsGoalCard";
import DistanceTimeGoalCard from "./DistanceTimeGoalCard";
import DistanceGoalCard from "./DistanceGoalCard";
import TimeGoalCard from "./TimeGoalCard";
import WeightTimeGoalCard from "./WeightTimeGoalCard";

class GoalCard extends Component {
	/**
	 * @param props Containing:
	 *      'routine': The routine
	 *      'exercise': The exercise
	 *      'goal': The goal
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise,
			 */
			exercise: this.props.exercise,
			/**
			 * The goal to show.
			 */
			goal: this.props.goal,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			goal: nextProps.goal,
			exercise: nextProps.exercise,
		});
	}

	renderGoalCard = () => {
		const goal = this.state.goal;
		
		switch (goal.type.id) {
			case ExerciseGoalType.WeightAndRepsGoal:
				return <WeightRepsGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.WeightGoal:
				return <WeightGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.RepsGoal:
				return <RepsGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.DistanceAndTimeGoal:
				return <DistanceTimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>
			case ExerciseGoalType.DistanceGoal:
				return <DistanceGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.TimeGoal:
				return <TimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
			case ExerciseGoalType.WeightAndTimeGoal:
				return <WeightTimeGoalCard goal={goal} routine={this.state.routine} exercise={this.state.exercise}/>;
		}
	}
	
	render() {
		return this.renderGoalCard();
	}
}

export default GoalCard;