import React, {PureComponent} from 'react';
import {Bar} from 'react-chartjs-2';
import {colors} from "@mui/material";

export default class AsyncBarGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			fetchData: this.props.fetchData,
			label: this.props.label,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			fetchData: nextProps.fetchData,
			label: nextProps.label,
		});
	}
	
	render() {
		let [y, x, z] = this.state.fetchData();

		const data = {
			labels: y,
			datasets: [
				{
					label: this.state.label,
					backgroundColor: colors.indigo[500],
					maxBarThickness: 10,
					barThickness: 12,
					barPercentage: 0.5,
					categoryPercentage: 0.5,
					data: x,
				},
				{
					label: 'Lowest RPE',
					backgroundColor: colors.orange[500],
					maxBarThickness: 10,
					barThickness: 12,
					barPercentage: 0.5,
					categoryPercentage: 0.5,
					data: z,
				}
			]
		};

		const options = {
			cornerRadius: 20,
			layout: { padding: 0 },
			scales: {
				xAxes: [
					{
						gridLines: {
							display: false,
							drawBorder: false
						}
					}
				],
				yAxes: [
					{
						id: 'y-axis-1',
						ticks: {
							beginAtZero: true,
							min: 0
						},
						gridLines: {
							borderDash: [2],
							borderDashOffset: [2],
							drawBorder: false,
							zeroLineBorderDash: [2],
							zeroLineBorderDashOffset: [2],
						}
					},
					{
						id: 'y-axis-2',
						position: 'right',
						ticks: {
							beginAtZero: false,
							min: 1,
							max: 10
						},
						gridLines: {
							display: false
						}
					}
				]
			},
			tooltips: {
				borderWidth: 1,
				enabled: true,
				intersect: false,
				mode: 'index',
			}
		};

		return (
			<Bar ref="chart" data={data} options={options}/>
		);
	}

	componentDidMount() {
		const { datasets } = this.refs.chart.chartInstance.data
	}
}