import React, {PureComponent} from 'react';
import { UserContext } from "../../core/UserContext";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import {Regexer} from "../../core/auth/Regexer";
import CircularProgress from "@mui/material/CircularProgress";
import {PageDataContext} from "../../core/PageDataContext";
import {Divider, IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {withRouter} from "../../hooks/withRouter";
import {Link as RouterLink} from "react-router-dom";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import {Utils} from "../../core/util/Utils";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#2d3b4a',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                My Work in Progress
                </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            <br/>
            <Link component={RouterLink} to={UrlBuilder.user.privacyPage()}>
                Privacy Policy
            </Link>
        </Typography>
    );
}


class Signup extends PureComponent {
    static displayName = Signup.name;

    /**
     * @param props No params needed.
     */
    constructor(props) {
        super(props);
        this.state ={
            /**
             * Used as a component 'isLoading' indicator.
             */
            isLoading: false,
            /**
             * Indicates whether the email is already taken by someone else.
             */
            isEmailAlreadyTaken: false,
            /**
             * Do we show the password field?
             */
            showPassword: false,
            isSigningUpAsGuest: false,
        };

        this.updateInputValue = this.updateInputValue.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.context.setPageData({
            title: "Sign Up to My Work in Progress"
        });
    }

    updateInputValue(event) {
        this.setState({
            [event.target.name]: event.target.value,
            isEmailAlreadyTaken: false,
        });
    }

    redirectToGoogleAuth = () => {
        window.location = '/api/users/google-signin';
    }

    redirectToAppleAuth = () => {
        window.location = '/api/users/apple-signin';
    }

    async submit(event, setUser) {
        event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

        this.setState({
            isLoading: true
        });

        let signupUser = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            dateCreated: moment().format('L, h:mm:ss A'),
        };

        let response = await fetch('/api/users/signup', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(signupUser)
        });

        let jsonResponse = await response.json();

        if (response.status === 200) {
            setUser(jsonResponse['data']);
            this.props.navigate('/');
        } else if (jsonResponse.message === "email_already_taken") {
            this.setState({
                isEmailAlreadyTaken: true,
                isLoading: false,
            })
        } else {
            this.setState({
                isLoading: false,
            })
        }
    }

    showPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    }

    signUpAsGuest = async (event, setUser) => {
        event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

        this.setState({
            isSigningUpAsGuest: true
        });

        let guestUser = {
            dateCreated: moment().format('L, h:mm:ss A'),
        };

        let response = await fetch(UrlBuilder.user.SignUpAsGuestApi(), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(guestUser)
        });

        let jsonResponse = await response.json();

        if (response.status === 200) {
            setUser(jsonResponse['data']);
            this.props.navigate('/');
        }

        this.setState({
            isSigningUpAsGuest: false,
        })
    }

    render() {
        const { classes } = this.props;

        let isEmailInvalid = false;
        let emailFieldHelperText = null;
        if (this.state.email && !Regexer.testEmail(this.state.email)) {
            isEmailInvalid = true;
            emailFieldHelperText = 'Please enter a valid email'
        } else if (this.state.isEmailAlreadyTaken) {
            isEmailInvalid = true;
            emailFieldHelperText = 'Email is already taken, please try another one';
        }

        let isPasswordInvalid = false;
        let passwordFieldHelperText = null;
        if (this.state.password && !Regexer.testPassword(this.state.password)) {
            isPasswordInvalid = true;
            passwordFieldHelperText = 'Password must have a minimum length of 6 and contain a number.';
        }
        
        let isSubmitDisabled = !this.state.name || !this.state.email || isEmailInvalid || !this.state.password || isPasswordInvalid;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" align="center">
                        Sign Up
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    onChange={this.updateInputValue}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={this.updateInputValue}
                                    error={isEmailInvalid}
                                    helperText={emailFieldHelperText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.showPassword} edge="end" size="large">
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={this.state.showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.updateInputValue}
                                    error={isPasswordInvalid}
                                    helperText={passwordFieldHelperText}
                                />
                            </Grid>
                        </Grid>
                        <UserContext.Consumer>
                            {({ user, setUser }) => (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={e => this.submit(e, setUser)}
                                    disabled={isSubmitDisabled}
                                >
                                    {
                                        this.state.isLoading ?
                                            <div>
                                                <CircularProgress size="1rem" color="inherit" /> Sign up
                                            </div> :
                                            <div>
                                                Sign up
                                            </div>
                                    }
                                </Button>
                            )}
                        </UserContext.Consumer>

                        <Box paddingTop={0.5}/>
                        <Divider thin/>

                        <Grid container direction='column' display="flex" justifyContent="center" alignItems="center" spacing={1} sx={{ marginTop: 3 }}>
                            <Grid item>
                                <Button sx={{width: 285, height: 45}} variant='outlined'
                                        onClick={this.redirectToGoogleAuth} size='medium'
                                        startIcon={<GoogleIcon fontSize='large'/>}>
                                    Continue with Google
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button sx={{width: 285, height: 45}} variant='outlined'
                                        onClick={this.redirectToAppleAuth} size='medium'
                                        startIcon={<AppleIcon fontSize='large'/>}>
                                    Continue with Apple
                                </Button>
                            </Grid>

                            <Grid item>
                                <UserContext.Consumer>
                                    {({user, setUser}) => (
                                        <Button
                                            type="submit"
                                            startIcon={this.state.isSigningUpAsGuest ? <CircularProgress size="1rem" color="inherit" /> : <PersonIcon/>}
                                            variant="outlined"
                                            size='medium'
                                            sx={{ width: 285, height: 45 }}
                                            color="primary"
                                            onClick={e => this.signUpAsGuest(e, setUser)}
                                            disabled={this.state.isSigningUpAsGuest}
                                        >
                                            Continue as Guest
                                        </Button>
                                    )}
                                </UserContext.Consumer>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={4}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}

Signup.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(Signup));