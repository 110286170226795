export class UserLocalStorage {
	/**
	 * @param key
	 * @param value
	 * @param ttl_seconds if not set it will be without expire date and will be there till user deletes or your other js deletes it.
	 */
	static set(key, value, ttl_seconds = null) {
		const data = {
			value: value, 
			expires_at: ttl_seconds !== null ? (new Date().getTime() + ttl_seconds * 1000) : null
		};
		localStorage.setItem(this.getKey(key), JSON.stringify(data));
	}

	/**
	 * @param key
	 * @returns {mixed}
	 */
	static get(key) {
		try {
			key = this.getKey(key);
			
			const data = JSON.parse(localStorage.getItem(key.toString()));
		
			if (data !== null) {
				if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
					localStorage.removeItem(key.toString());
				} else {
					return data.value;
				}
			}
			
		} catch (e) {} // Something made the local storage invalid :( We ignore it, the app will overwrite it with a good value :).
		
		return null;
	}
	
	static getKey(key) {
		return "v2_" + key;
	}

	static remove(key) {
		localStorage.removeItem(this.getKey(key));
	}
} 