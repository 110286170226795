import React, {PureComponent} from 'react';
import {
	Card,
	CardContent,
	Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActionArea from "@mui/material/CardActionArea";
import {withRouter} from "../../../hooks/withRouter";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {Line} from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import WorkoutSmallNote from "../../workout/partials/WorkoutSmallNote";
import moment from "moment";
import BarChartIcon from '@mui/icons-material/BarChart';

const useStyles = theme => ({});

class WorkoutCard extends PureComponent {

	/**
	 * @param props Containing:
	 *      'workout': The workout you want to show.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The statistics.
			 */
			statistics: UserLocalStorage.get(UrlBuilder.user.statisticsApi()) ?? null,
			/**
			 * Is the collapse open?
			 */
			isCollapseOpen: false,
			/**
			 * Is loading?
			 */
			isLoading: false,
			/**
			 * Has it loaded at least once?
			 */
			hasLoadedAtLeastOnce: false,
		};
	}
	
	hasNeverBeenFetched = () => {
		return this.state.statistics === null;
	}

	fetch = async () => {
		this.setState({
			isLoading: true,
		});

		const now = (moment()).format('L');
		const response = await fetch(UrlBuilder.user.statisticsApi(now));

		if (response.status !== 200) {
			this.setState({
				isLoading: false,
			});
			return;
		}

		const responseData = await response.json();

		this.setState({
			statistics: responseData['data'] ?? [],
			isLoading: false,
			hasLoadedAtLeastOnce: true,
		});

		UserLocalStorage.set(UrlBuilder.user.statisticsApi(), responseData['data'] ?? []);
	}

	triggerCollapse = (event) => {
		this.setState({
			isCollapseOpen: !this.state.isCollapseOpen
		});

		const isOpening = !this.state.isCollapseOpen;
		if (isOpening && !this.state.hasLoadedAtLeastOnce) {
			this.fetch();
		}
	}

	getChartData = () => {
		const labels = this.state.statistics.lastTwelveMonthsWorkoutCount.map(item => `${item.month}/${item.year}`);
		const data = this.state.statistics.lastTwelveMonthsWorkoutCount.map(item => item.workoutCount);

		return {
			labels,
			datasets: [
				{
					label: 'Workouts Per Month (12 Months)',
					data,
					fill: false,
					backgroundColor: 'rgb(75, 192, 192)',
					borderColor: 'rgba(75, 192, 192, 0.2)',
				},
			],
		};
	}

	renderLoading = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="pulse" variant="rectangular" style={{height: 275}}/>

			<Box paddingTop={4}/>

			<Skeleton animation="pulse" variant="text" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media}/>
		</>;
	}

	renderWorkoutTime = (workoutTime) => {
		const [hours, minutes, seconds] = workoutTime.split(':');

		return `${parseInt(hours)}h ${minutes}m`;
	}
	
	renderCardContent = (statistics) => {
		return <Grid container spacing={1}>
			<Grid item xs={12}>
				<WorkoutSmallNote title="Total Workouts" description={statistics.totalWorkoutCount}
				                  smallDetail='All Time'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Workouts" description={statistics.workoutCountThisYear}
				                  smallDetail='This Year'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Workouts" description={statistics.workoutCountThisMonth}
				                  smallDetail='This Month'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Workouts"
				                  description={statistics.averageWorkoutCountPerWeek.toFixed(1)}
				                  smallDetail='Per Week All Time'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Workouts"
				                  description={statistics.averageWorkoutCountPerMonth.toFixed(1)}
				                  smallDetail='Per Month All Time'/>
			</Grid>
			<Grid item xs={12}>
				<Line data={this.getChartData()}/>
			</Grid>

			<Grid item xs={12}>
				<Box paddingTop={2.5}/>
				<Divider variant='middle'/>
				<Box paddingTop={2}/>
			</Grid>

			<Grid item xs={12}>
				<WorkoutSmallNote title="Average Workout Time"
				                  description={this.renderWorkoutTime(statistics.averageWorkoutTime)}
				                  smallDetail='All Time'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Workout Time"
				                  description={this.renderWorkoutTime(statistics.averageWorkoutTimeThisMonth)}
				                  smallDetail='This Month'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Workout Time"
				                  description={this.renderWorkoutTime(statistics.averageWorkoutTimeThisYear)}
				                  smallDetail='This Year'/>
			</Grid>
		</Grid>;
	}

	render() {
		const {statistics} = this.state;

		return (
			<Card variant='outlined'>
				<CardActionArea>
					<CardHeader
						avatar={<BarChartIcon/>}
						title={<Typography variant='h5' sx={{ fontSize: 21 }}>Workout Statistics</Typography>}
						style={{paddingBottom: '12px', paddingTop: '12px'}}
						
						action={
							<IconButton size="small">
								{this.state.isCollapseOpen ? <ExpandLess/> : <ExpandMore/>}
							</IconButton>
						}
						onClick={this.triggerCollapse}
					/>
					<Collapse in={this.state.isLoading}>
						<LinearProgress/>
					</Collapse>
				</CardActionArea>

				<Collapse in={this.state.isCollapseOpen}>
					<Divider/>
					<CardContent>
						{this.hasNeverBeenFetched() ?
							<Skeleton animation="pulse" variant="rectangular" style={{height: 275}}/>
							: this.renderCardContent(statistics)
						}
					</CardContent>
				</Collapse>
			</Card>
		);
	}
}

export default withStyles(useStyles)(withRouter(WorkoutCard));