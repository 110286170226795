import React, {PureComponent} from 'react'
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Backdrop from "@mui/material/Backdrop";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogActions from "@mui/material/DialogActions";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import ColorSelector from "../../core/utils/ColorSelector";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {blue} from "@mui/material/colors";

const useStyles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
	},
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	suggestions: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class MeasurementNewOrEditFormPopUp extends PureComponent {
	/**
	 * @param props Containing:
	 *      'measurement': The measurement.
	 *      'measurements': All the measurements (used for filtering).
	 *      'isOpen': Says whether this modal is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * 'isOpen': Says whether this modal is open.
			 */
			isOpen: this.props.isOpen,

			/**
			 * The measurement to edit, if any. Otherwise it's a 'new' form.
			 */
			measurement: this.props.measurement,
			
			/**
			 * The name of the measurement.
			 */
			name: this.props.measurement ? this.props.measurement.name : '',
			
			/**
			 * Is it currently being submitted?
			 */
			isSubmissionLoading: false,
			
			/**
			 * All the measurements the user has. This is used to filter them out from suggestions.
			 */
			measurements: [],
			/**
			 * The measurement color.
			 */
			color: props.measurement?.color ?? (props.measurement ? ColorPicker.pick(props.measurement.name) : blue[500]),
		};
	}

	submitWithEnter = (e) => {
		if (e.key === 'Enter') {
			this.submit(e);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.state.isOpen !== Boolean(nextProps.isOpen)) {
			this.setState({
				isOpen: Boolean(nextProps.isOpen),
				measurement: nextProps.measurement,
				name: nextProps.measurement ? nextProps.measurement.name : '',
				measurements: nextProps.measurements ?? [],
				color: nextProps.measurement?.color ?? (nextProps.measurement ? ColorPicker.pick(nextProps.measurement.name) : blue[500]),
			});
		}
	}

	submit = async (event) => {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});
		
		let measurementToSave = {
			Name: this.state.name,
			Color: this.state.color,
		};

		if (this.state.measurement) {
			measurementToSave['Id'] = this.state.measurement.id;
		}
		
		await fetch(UrlBuilder.measurements.measurementsApi(), {
			method: this.state.measurement ? 'PUT' : 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(measurementToSave)
		}).then(this.props.closeSelfFunc);

		this.setState({
			isSubmissionLoading: false,
		});
	}

	updateStateInputValues = (event) => {
		let state = {
			[event.target.name]: event.target.value,
		};

		if (event.target.name === 'name') {
			state['color'] = ColorPicker.pick(event.target.value);
		}

		this.setState(state);
	}

	clearStateInputValue = (fieldName) => {
		this.setState({
			[fieldName]: '',
		});
	}
	
	updateNameState = (name) => {
		this.setState({
			name: name,
			color: ColorPicker.pick(name),
		});
	}

	updateStateInputColorValue = (color) => {
		this.setState({
			color: color,
		});
	}
	
	getSuggestions = () => {
		let suggestions = [
			'Weight',
			'BMI',
			'Fat Percentage',
			'Muscle Percentage',
			'Arm Size',
			'Leg Size',
			'Forearm Size',
			'Chest Size',
		];
		
		return suggestions.filter(suggestion => {
			for (let i = 0; i < this.state.measurements.length; i++) {
				if (this.state.measurements[i].name === suggestion) {
					return false;
				}
			}
			return true;
		})
	}

	render() {
		const {classes} = this.props;
		
		return (
            <Dialog
				className={classes.modal}
				open={this.state.isOpen}
				onClose={() => this.props.closeSelfFunc(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 250,
				}}
				TransitionComponent={Transition}
            >
				<AppBar style={{ position: 'relative'}} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap>
							{this.state.measurement ? 'Edit Body Measurement' : 'Track New Body Measurement'}
						</Typography>
					</Toolbar>
				</AppBar>
				
				<DialogContent dividers>
					<FormControl fullWidth size={'small'} variant="standard">
						<InputLabel htmlFor="name">Name</InputLabel>
						<Input
							required
							fullWidth
							id="name"
							label="Name"
							autoComplete='off'
							name="name"
							autoFocus
							onChange={this.updateStateInputValues}
							onKeyPress={this.submitWithEnter}
							placeholder='Weight, BMI, etc..'
							value={this.state.name}
							endAdornment={
								<IconButton onClick={() => this.clearStateInputValue('name')} size="large">
									<ClearIcon />
								</IconButton>
							}
						/>
					</FormControl>
					
					<br/>
					<br/>
					
					<Typography variant='caption'>
						Suggestions:
					</Typography>

					<div className={classes.suggestions}>
						{this.getSuggestions().map(suggestion =>
							<Chip
								key={suggestion}
								size='small'
								avatar={<Avatar>{suggestion.charAt(0)}</Avatar>}
								label={suggestion}
								color="secondary"
								onClick={() => this.updateNameState(suggestion)}
							/>
						)}
					</div>

					<Box paddingTop={0.85}/>

					<Typography variant='subtitle1' color='textSecondary' style={{ fontSize: 12 }}>
						&nbsp; Color
					</Typography>

					<ColorSelector color={this.state.color} updateColorValueParentFunc={this.updateStateInputColorValue}/>

				</DialogContent>
				<DialogActions>
					<Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>
						Close
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={this.submit}
						type="submit"
						value="Submit"
						disabled = {this.state.isSubmissionLoading}
					>
						{this.state.isSubmissionLoading ?
							<div>
								<CircularProgress size="1rem" color="inherit" /> Loading...
							</div> :
							'Save'
						}
					</Button>
				</DialogActions>
			</Dialog>
        );
	}
}

export default withStyles(useStyles)(MeasurementNewOrEditFormPopUp);