import {HoursMinutesTimeConverter} from "./HoursMinutesTimeConverter";
import {UnitPrettifier} from "./UnitPrettifier";
import {ExerciseTypeAttribute} from "../models/ExerciseTypeAttribute";

export class AttributeValuesPrettier {
	/**
	 * @returns {string}
	 */
	static prettifyAttributeValues(exerciseType, log) {
		let string = '';
		for (let i = 0; i < exerciseType.attributes.length; i++) {
			let attribute = exerciseType.attributes[i];
			string += AttributeValuesPrettier.prettifyLogValueForAttribute(attribute.key, log.values[i]) + ' ';

			if (attribute.key === ExerciseTypeAttribute.Reps) {
				string += 'reps';
			} else {
				string += UnitPrettifier.forAttribute(attribute);
			}
			

			if (i !== exerciseType.attributes.length - 1) {
				string += ' ⨯ ';
			}
		}

		return string;
	}

	static prettifyLogValueForAttribute(attributeKey, value) {
		if (attributeKey === ExerciseTypeAttribute.Time) {
			return HoursMinutesTimeConverter.convertFloatToString(value);
		}

		return value;
	}
}