import React from 'react';
import {useLocation, useNavigate} from "react-router";

const Redirect = () => {
	let location = useLocation();
	let navigate = useNavigate();

	let redirect = (new URLSearchParams(location.search)).get('link');
	
	React.useEffect(() => {
		navigate(redirect ? redirect : '/');
	});
	
	return null;
}

export default Redirect;
