import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import WorkoutSmallNote from "../../workout/partials/WorkoutSmallNote";
import moment from "moment";
import {HoursMinutesTimeConverter} from "../../../core/prettifier/HoursMinutesTimeConverter";
import {ExerciseType} from "../../../core/models/ExerciseType";

const useStyles = theme => ({
});

class RoutineExerciseDetailBloopStatsTab extends Component {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise you want to modify.
	 *      'isShowing': Is it showing?
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * Has it ever been shown? Similar to 'isShowing' but once it's true, it's never false again.
			 */
			hasEverBeenShown: false,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			hasEverBeenShown: !this.state.hasEverBeenShown || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId ? nextProps.isShowing : true, // once it's true, it's never false again, unless the exercise changes.
		});
	}

	shouldComponentUpdate (nextProps) {
		return nextProps.isShowing || nextProps.exercise.routineExerciseId !== this.state.exercise.routineExerciseId; // once it's true, it's never false again, unless the exercise changes.
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}
	
	getWeightRepsTypeData = (logGroups) => {
		let totalSets = 0;
		let totalWorkouts = 0;
		
		let totalWeight = 0;
		let totalReps = 0;
		let totalVolume = 0;
		
		let averageWeight = 0;
		let averageReps = 0;
		let averageVolume = 0;
		
		let maxWeightLog = null;
		let maxRepsLog = null;
		let maxVolumeLog = null;

		let averageMaxWeight = 0;
		let averageMaxReps = 0;
		let averageMaxVolume = 0;

		for (const logGroup of logGroups) {
			for (const log of logGroup.logs) {
				totalSets++;
				totalWeight += log.values[0];
				totalReps += log.values[1];
			}
			
			totalWorkouts++;
			totalVolume += logGroup.totalVolume;

			let topWeightLog = logGroup.logs[logGroup.topWeightLogIdx];
			if (maxWeightLog === null || topWeightLog.values[0] > maxWeightLog.values[0]) {
				maxWeightLog = topWeightLog;
			}
			averageMaxWeight += topWeightLog.values[0];

			let topRepsLog = logGroup.logs[logGroup.topRepsLogIdx];
			if (maxRepsLog === null || topRepsLog.values[1] > maxRepsLog.values[1]) {
				maxRepsLog = topRepsLog;
			}
			averageMaxReps += topRepsLog.values[1];

			let topVolumeLog = logGroup.logs[logGroup.topVolumeLogIdx];
			if (maxVolumeLog === null || topVolumeLog.multiplied > maxVolumeLog.multiplied) {
				maxVolumeLog = topVolumeLog;
			}
			averageMaxVolume += topVolumeLog.multiplied;
		}

		averageWeight = totalSets > 0 ? totalWeight / totalSets : 0;
		averageReps = totalSets > 0 ? totalReps / totalSets : 0;
		averageVolume = totalSets > 0 ? totalVolume / totalSets : 0;

		averageMaxWeight = totalWorkouts > 0 ? averageMaxWeight / totalWorkouts : 0;
		averageMaxReps = totalWorkouts > 0 ? averageMaxReps / totalWorkouts : 0;
		averageMaxVolume = totalWorkouts > 0 ? averageMaxVolume / totalWorkouts : 0;

		return {
			totalSets,
			totalWorkouts,
			totalWeight: totalWeight.toFixed(1),
			totalReps: totalReps.toFixed(0),
			totalVolume: totalVolume.toFixed(1),
			averageWeight: averageWeight.toFixed(1),
			averageReps: averageReps.toFixed(1),
			averageVolume: averageVolume.toFixed(1),
			maxWeightLog,
			maxRepsLog,
			maxVolumeLog,
			averageMaxWeight: averageMaxWeight.toFixed(1),
			averageMaxReps: averageMaxReps.toFixed(1),
			averageMaxVolume: averageMaxVolume.toFixed(1),
		};
	}
	
	renderWeightRepsType = () => {
		const {
			totalSets,
			totalWorkouts,
			totalWeight,
			totalReps,
			totalVolume,
			averageWeight,
			averageReps,
			averageVolume,
			maxWeightLog,
			maxRepsLog,
			maxVolumeLog,
			averageMaxWeight,
			averageMaxReps,
			averageMaxVolume,
		} = this.getWeightRepsTypeData(this.state.exercise.logGroups);

		return <Grid container spacing={1}>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Sets" description={totalSets} smallDetail={""} popOverText='The total amount of sets you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Workouts" description={totalWorkouts} smallDetail={''} popOverText='The total amount of days you have performed this exercise over the filtered amount of time.'/>
			</Grid>

			<Grid item xs={4} >
				<WorkoutSmallNote title="Total Weight" description={totalWeight} smallDetail={""} popOverText='The total amount of weight you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={4}>
				<WorkoutSmallNote title="Total Reps" description={totalReps} smallDetail={""} popOverText='The total amount of reps you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={4} >
				<WorkoutSmallNote title="Total Volume" description={totalVolume} smallDetail={''} popOverText='The total volume (weight×reps) you have done over the filtered amount of time.'/>
			</Grid>

			<Grid item xs={4} >
				<WorkoutSmallNote title="Average Weight" description={averageWeight} smallDetail={""} popOverText='The average weight you have done for this exercise over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={4}>
				<WorkoutSmallNote title="Average Reps" description={averageReps} smallDetail={""} popOverText='The average reps you have done for this exercise over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={4} >
				<WorkoutSmallNote title="Average Volume" description={averageVolume} smallDetail={''} popOverText='The average volume (weight×reps) you have done for this exercise over the filtered amount of time.'/>
			</Grid>

			<Grid item xs={4} >
				<WorkoutSmallNote title="Max Weight"
				                  description={maxWeightLog ? (maxWeightLog.values[0]).toFixed(1) : 0}
				                  smallDetail={maxWeightLog ? moment(maxWeightLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The set with the highest amount of weight you have done for this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={4} >
				<WorkoutSmallNote title="Max Reps"
				                  description={maxRepsLog ? (maxRepsLog.values[1]).toFixed(0) : 0}
				                  smallDetail={maxRepsLog ? moment(maxRepsLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The set with the highest amount of reps you have done for this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={4} >
				<WorkoutSmallNote title="Max Volume"
				                  description={maxVolumeLog ? (maxVolumeLog.multiplied).toFixed(1) : 0}
				                  smallDetail={maxVolumeLog ? moment(maxVolumeLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The set with the highest volume (weight×reps) you have done for this exercise for any set over the filtered amount of time.'
				/>
			</Grid>

			<Grid item xs={4} >
				<WorkoutSmallNote title="Avg. Max Weight" description={averageMaxWeight} smallDetail={""}
	                  popOverText='The average of only the logs that have the highest weight per workout over the filtered amount of time. In other words, the average of your best-weight-per-workout sets.'
				/>
			</Grid>
			<Grid item xs={4}>
				<WorkoutSmallNote title="Avg. Max Reps" description={averageMaxReps} smallDetail={""}
	                  popOverText='The average of only the logs that have the highest reps per workout over the filtered amount of time. In other words, the average of your best-reps-per-workout sets.'
				/>
			</Grid>
			<Grid item xs={4} >
				<WorkoutSmallNote title="Avg. Max Volume" description={averageMaxVolume} smallDetail={''}
	                  popOverText='The average of only the logs that have the highest volumes (weight×reps) per workout over the filtered amount of time. In other words, the average of your best-volume-per-workout sets.'
				/>
			</Grid>
		</Grid>
	}

	getDistanceTimeTypeData = (logGroups) => {
		let totalSets = 0;
		let totalWorkouts = 0;

		let totalDistance = 0;
		let totalTime = 0;

		let averageDistance = 0;
		let averageTime = 0;

		let maxDistanceLog = null;
		let maxTimeLog = null;

		let averageMaxDistance = 0;
		let averageMaxTime = 0;

		for (const logGroup of logGroups) {
			for (const log of logGroup.logs) {
				totalSets++;
				totalDistance += log.values[0];
				totalTime += log.values[1];
			}

			totalWorkouts++;

			let topDistanceLog = logGroup.logs[logGroup.topDistanceLogIdx];
			if (maxDistanceLog === null || topDistanceLog.values[0] > maxDistanceLog.values[0]) {
				maxDistanceLog = topDistanceLog;
			}
			averageMaxDistance += topDistanceLog.values[0];

			let topTimeLog = logGroup.logs[logGroup.topTimeLogIdx];
			if (maxTimeLog === null || topTimeLog.values[1] > maxTimeLog.values[1]) {
				maxTimeLog = topTimeLog;
			}
			averageMaxTime += topTimeLog.values[1];
		}

		averageDistance = totalSets > 0 ? totalDistance / totalSets : 0;
		averageTime = totalSets > 0 ? totalTime / totalSets : 0;

		averageMaxDistance = totalWorkouts > 0 ? averageMaxDistance / totalWorkouts : 0;
		averageMaxTime = totalWorkouts > 0 ? averageMaxTime / totalWorkouts : 0;

		return {
			totalSets,
			totalWorkouts,
			totalDistance: totalDistance.toFixed(1),
			totalTime: HoursMinutesTimeConverter.convertFloatToString(totalTime),
			averageDistance: averageDistance.toFixed(1),
			averageTime: HoursMinutesTimeConverter.convertFloatToString(averageTime),
			maxDistanceLog: maxDistanceLog,
			maxTimeLog: maxTimeLog,
			averageMaxDistance: averageMaxDistance.toFixed(1),
			averageMaxTime: HoursMinutesTimeConverter.convertFloatToString(averageMaxTime),
		};
	}

	renderDistanceTimeType = () => {
		const {
			totalSets,
			totalWorkouts,
			totalDistance,
			totalTime,
			averageDistance,
			averageTime,
			maxDistanceLog,
			maxTimeLog,
			averageMaxDistance,
			averageMaxTime,
		} = this.getDistanceTimeTypeData(this.state.exercise.logGroups);

		return <Grid container spacing={1}>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Sets" description={totalSets} smallDetail={""} popOverText='The total amount of sets you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Workouts" description={totalWorkouts} smallDetail={''} popOverText='The total amount of days you have performed this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Distance" description={totalDistance} smallDetail={""} popOverText='The total distance you have covered over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Time" description={totalTime} smallDetail={""} popOverText='The total time you have spent doing this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6}>
				<WorkoutSmallNote title="Average Distance" description={averageDistance} smallDetail={''} popOverText='The average distance per set you have covered over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Average Time" description={averageTime} smallDetail={""} popOverText='The average time per set you have spent on this exercise over the filtered amount of time.'/>
			</Grid>

			<Grid item xs={6}>
				<WorkoutSmallNote title="Max Distance"
				                  description={maxDistanceLog ? (maxDistanceLog.values[0]).toFixed(1) : 0}
				                  smallDetail={maxDistanceLog ? moment(maxDistanceLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The maximum distance you have covered for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Max Time"
				                  description={maxTimeLog ? HoursMinutesTimeConverter.convertFloatToString(maxTimeLog.values[1]) : 0}
				                  smallDetail={maxTimeLog ? moment(maxTimeLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The maximum time you have spent doing this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Max Distance" description={averageMaxDistance} smallDetail={""} 
				                  popOverText='The average of only the logs that have the highest distance per workout over the filtered amount of time. In other words, the average of your best-distance-per-workout sets.'
				/>
			</Grid>
			
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Max Time" description={averageMaxTime} smallDetail={""}
	                  popOverText='The average of only the logs that have the highest time per workout over the filtered amount of time. In other words, the average of your best-time-per-workout sets.'
				/>
			</Grid>

		</Grid>
	}

	getTimeTypeData = (logGroups) => {
		let totalSets = 0;
		let totalWorkouts = 0;

		let totalTime = 0;

		let averageTime = 0;

		let maxTimeLog = null;

		let averageMaxTime = 0;

		for (const logGroup of logGroups) {
			for (const log of logGroup.logs) {
				totalSets++;
				totalTime += log.values[0];
			}

			totalWorkouts++;

			let topTimeLog = logGroup.logs[logGroup.topTimeLogIdx];
			if (maxTimeLog === null || topTimeLog.values[0] > maxTimeLog.values[0]) {
				maxTimeLog = topTimeLog;
			}
			averageMaxTime += topTimeLog.values[0];
		}

		averageTime = totalSets > 0 ? totalTime / totalSets : 0;

		averageMaxTime = totalWorkouts > 0 ? averageMaxTime / totalWorkouts : 0;

		return {
			totalSets,
			totalWorkouts,
			totalTime: HoursMinutesTimeConverter.convertFloatToString(totalTime),
			averageTime: HoursMinutesTimeConverter.convertFloatToString(averageTime),
			maxTimeLog: maxTimeLog,
			averageMaxTime: HoursMinutesTimeConverter.convertFloatToString(averageMaxTime),
		};
	}

	renderTimeType = () => {
		const {
			totalSets,
			totalWorkouts,
			totalTime,
			averageTime,
			maxTimeLog,
			averageMaxTime,
		} = this.getTimeTypeData(this.state.exercise.logGroups);

		return <Grid container spacing={1}>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Sets" description={totalSets} smallDetail={""} popOverText='The total amount of sets you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Workouts" description={totalWorkouts} smallDetail={''} popOverText='The total amount of days you have performed this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Time" description={totalTime} smallDetail={""} popOverText='The total time you have spent doing this exercise over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Average Time" description={averageTime} smallDetail={""} popOverText='The average time per set you have spent on this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Max Time"
				                  description={maxTimeLog ? HoursMinutesTimeConverter.convertFloatToString(maxTimeLog.values[0]) : 0}
				                  smallDetail={maxTimeLog ? moment(maxTimeLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The maximum time you have spent doing this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Max Time" description={averageMaxTime} smallDetail={""}
	                  popOverText='The average of only the logs that have the highest time per workout over the filtered amount of time. In other words, the average of your best-time-per-workout sets.'
				/>
			</Grid>
		</Grid>
	}
	
	getRepsTypeData = (logGroups) => {
		let totalSets = 0;
		let totalWorkouts = 0;
		
		let totalReps = 0;
		
		let averageReps = 0;
		
		let maxRepsLog = null;

		let averageMaxReps = 0;

		for (const logGroup of logGroups) {
			for (const log of logGroup.logs) {
				totalSets++;
				totalReps += log.values[0];
			}
			
			totalWorkouts++;
			
			let topRepsLog = logGroup.logs[logGroup.topRepsLogIdx];
			if (maxRepsLog === null || topRepsLog.values[0] > maxRepsLog.values[0]) {
				maxRepsLog = topRepsLog;
			}
			averageMaxReps += topRepsLog.values[0];
		}

		averageReps = totalSets > 0 ? totalReps / totalSets : 0;

		averageMaxReps = totalWorkouts > 0 ? averageMaxReps / totalWorkouts : 0;
		
		return {
			totalSets,
			totalWorkouts,
			totalReps: totalReps.toFixed(0),
			averageReps: averageReps.toFixed(1),
			maxRepsLog,
			averageMaxReps: averageMaxReps.toFixed(1),
		};
	}
	
	renderRepsType = () => {
		const {
			totalSets,
			totalWorkouts,
			totalReps,
			averageReps,
			maxRepsLog,
			averageMaxReps,
		} = this.getRepsTypeData(this.state.exercise.logGroups);

		return <Grid container spacing={1}>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Sets" description={totalSets} smallDetail={""} popOverText='The total amount of sets you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Workouts" description={totalWorkouts} smallDetail={''} popOverText='The total amount of days you have performed this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Reps" description={totalReps} smallDetail={""} popOverText='The total amount of reps you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Average Reps" description={averageReps} smallDetail={""} popOverText='The average reps you have done for this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Max Reps"
				                  description={maxRepsLog ? (maxRepsLog.values[0]).toFixed(0) : 0}
				                  smallDetail={maxRepsLog ? moment(maxRepsLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The set with the highest amount of reps you have done for this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Max Reps" description={averageMaxReps} smallDetail={""}
	                  popOverText='The average of only the logs that have the highest reps per workout over the filtered amount of time. In other words, the average of your best-reps-per-workout sets.'
				/>
			</Grid>
		</Grid>
	}

	getWeightTimeTypeData = (logGroups) => {
		let totalSets = 0;
		let totalWorkouts = 0;

		let totalWeight = 0;
		let totalTime = 0;

		let averageWeight = 0;
		let averageTime = 0;

		let maxWeightLog = null;
		let maxTimeLog = null;

		let averageMaxWeight = 0;
		let averageMaxTime = 0;

		for (const logGroup of logGroups) {
			for (const log of logGroup.logs) {
				totalSets++;
				totalWeight += log.values[0];
				totalTime += log.values[1];
			}

			totalWorkouts++;


			let topWeightLog = logGroup.logs[logGroup.topWeightLogIdx];
			if (maxWeightLog === null || topWeightLog.values[0] > maxWeightLog.values[0]) {
				maxWeightLog = topWeightLog;
			}
			averageMaxWeight += topWeightLog.values[0];

			let topTimeLog = logGroup.logs[logGroup.topTimeLogIdx];
			if (maxTimeLog === null || topTimeLog.values[1] > maxTimeLog.values[1]) {
				maxTimeLog = topTimeLog;
			}
			averageMaxTime += topTimeLog.values[1];
		}

		averageWeight = totalSets > 0 ? totalWeight / totalSets : 0;
		averageTime = totalSets > 0 ? totalTime / totalSets : 0;

		averageMaxWeight = totalWorkouts > 0 ? averageMaxWeight / totalWorkouts : 0;
		averageMaxTime = totalWorkouts > 0 ? averageMaxTime / totalWorkouts : 0;

		return {
			totalSets,
			totalWorkouts,
			totalWeight: totalWeight.toFixed(1),
			totalTime: HoursMinutesTimeConverter.convertFloatToString(totalTime),
			averageWeight: averageWeight.toFixed(1),
			averageTime: HoursMinutesTimeConverter.convertFloatToString(averageTime),
			maxWeightLog,
			maxTimeLog: maxTimeLog,
			averageMaxWeight: averageMaxWeight.toFixed(1),
			averageMaxTime: HoursMinutesTimeConverter.convertFloatToString(averageMaxTime),
		};
	}

	renderWeightTimeType = () => {
		const {
			totalSets,
			totalWorkouts,
			totalWeight,
			totalTime,
			averageWeight,
			averageTime,
			maxWeightLog,
			maxTimeLog,
			averageMaxWeight,
			averageMaxTime,
		} = this.getWeightTimeTypeData(this.state.exercise.logGroups);

		return <Grid container spacing={1}>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Sets" description={totalSets} smallDetail={""} popOverText='The total amount of sets you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Workouts" description={totalWorkouts} smallDetail={''} popOverText='The total amount of days you have performed this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Total Weight" description={totalWeight} smallDetail={""} popOverText='The total amount of weight you have done over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Total Time" description={totalTime} smallDetail={""} popOverText='The total time you have spent doing this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Average Weight" description={averageWeight} smallDetail={""} popOverText='The average weight you have done for this exercise over the filtered amount of time.'/>
			</Grid>
			<Grid item xs={6}>
				<WorkoutSmallNote title="Average Time" description={averageTime} smallDetail={""} popOverText='The average time per set you have spent on this exercise over the filtered amount of time.'/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Max Weight"
				                  description={maxWeightLog ? (maxWeightLog.values[0]).toFixed(1) : 0}
				                  smallDetail={maxWeightLog ? moment(maxWeightLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The set with the highest amount of weight you have done for this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			<Grid item xs={6} >
				<WorkoutSmallNote title="Max Time"
				                  description={maxTimeLog ? HoursMinutesTimeConverter.convertFloatToString(maxTimeLog.values[1]) : 0}
				                  smallDetail={maxTimeLog ? moment(maxTimeLog.dateCreated).format('DD/MM/YYYY') : '-'}
				                  popOverText='The maximum time you have spent doing this exercise for any set over the filtered amount of time.'
				/>
			</Grid>
			
			<Grid item xs={6} >
				<WorkoutSmallNote title="Avg. Max Weight" description={averageMaxWeight} smallDetail={""}/>
			</Grid>

			<Grid item xs={6}>
				<WorkoutSmallNote title="Avg. Max Time" description={averageMaxTime} smallDetail={""}
		              popOverText='The average of only the logs that have the highest time per workout over the filtered amount of time. In other words, the average of your best-time-per-workout sets.'
				/>
			</Grid>
		</Grid>
	}

	render() {
		if (!this.state.hasEverBeenShown) {
			return <div/>;
		}

		if (this.state.exercise.type.id === ExerciseType.WeightAndReps) {
			return this.renderWeightRepsType();
		}

		if (this.state.exercise.type.id === ExerciseType.WeightAndTime) {
			return this.renderWeightTimeType();
		}

		if (this.state.exercise.type.id === ExerciseType.Reps) {
			return this.renderRepsType();
		}

		if (this.state.exercise.type.id === ExerciseType.DistanceAndTime) {
			return this.renderDistanceTimeType();
		}

		if (this.state.exercise.type.id === ExerciseType.Time) {
			return this.renderTimeType();
		}

		return <React.Fragment/>
	}
}

export default withStyles(useStyles)(RoutineExerciseDetailBloopStatsTab);