import React, {PureComponent} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {PageDataContext} from "../../../core/PageDataContext";
import WorkoutCallToAction from "../../workout/WorkoutCallToAction";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import RoutineOverviewCard from "../partials/RoutineOverviewCard";
import Zoom from "@mui/material/Zoom";
import Snackbar from "@mui/material/Snackbar";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import {UserContext} from "../../../core/UserContext";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArchiveIcon from '@mui/icons-material/Archive';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import RoutineNewOrEditFormBloop from "../partials/RoutineNewOrEditFormBloop";
import {withRouter} from "../../../hooks/withRouter";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	speedDialWrapper: {
		margin: 0,
		top: 'auto',
		right: 35,
		bottom: 80,
		left: 'auto',
		position: 'fixed',
	},
	largeIcon: {
		width: 200,
		height: 200,
	},
	snackbar: {
		bottom: 75,
		[theme.breakpoints.down('sm')]: {
			bottom: 150,
		},
	},
	media: {
		height: 110,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class RoutinesOverview extends PureComponent {
	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * All the routines that we have available. These are fetched from the backend.
			 */
			routines: UserLocalStorage.get(UrlBuilder.routine.routinesApi()) ?? null,
			/**
			 * Indicates loading or not
			 */
			isLoading: true,
			/**
			 * Indicates if the 'add routine' popup is being shown.
			 */
			showRoutineAddEditPopUp: false,
			/**
			 * The routine to be edited. If not null, popup opens for the routine given here.
			 */
			routineToEdit: null,
			/**
			 * The toast notification if it should show up. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationObject: null,
			/**
			 * Speed dial open?
			 */
			isSpeedDialOpen: false,
			/**
			 * Are we going into archived mode?
			 */
			isInArchivedMode: false,
			/**
			 * If we want to delete a routine, which is only possible in archive mode, then we confirm it first with a dialog.
			 */
			routineToConfirmDeletion: null
		};

		this.fetchRoutines = this.fetchRoutines.bind(this);
		this.refresh = this.refresh.bind(this);
		this.toggleCloseAllPopups = this.toggleCloseAllPopups.bind(this);
		this.toggleOpenAddRoutinePopup = this.toggleOpenAddRoutinePopup.bind(this);
		this.toggleOpenEditRoutinePopup = this.toggleOpenEditRoutinePopup.bind(this);
	}

	componentDidMount() {
		this.mount();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.isInArchivedMode !== this.state.isInArchivedMode) {
			// We mount again if archived mode is activated to set the title, etc.
			this.mount();
		}
	}

	mount = () => {
		this.context.setPageData({
			title: this.state.isInArchivedMode ? "My Archived Routines" : "My Routines",
		});

		this.fetchRoutines().then(r => {
			if (!this.state.isInArchivedMode && Array.isArray(this.state.routines) && this.state.routines.length === 0) {
				const shouldOpenAddBloop = (new URLSearchParams(this.props.location.search)).get('add') === 'true';
				if (shouldOpenAddBloop) {
					this.setState({
						showRoutineAddEditPopUp: true
					});
				}
			}
		});
	}

	refresh() {
		this.fetchRoutines();
	}

	async fetchRoutines() {
		this.setState({
			isLoading: true
		});

		let response;
		if (this.state.isInArchivedMode) {
			response = await fetch(UrlBuilder.routine.archivedRoutinesApi());
		} else {
			response = await fetch(UrlBuilder.routine.routinesApi());
		}
		const routines = await response.json();
		
		this.setState({
			routines: routines,
			isLoading: false
		});

		if (!this.state.isInArchivedMode) {
			UserLocalStorage.set(UrlBuilder.routine.routinesApi(), routines ?? []);
		}
	}
	
	showRoutineStatusToastNotification = (routine, mode) => {
		const {classes} = this.props

		let toastNotificationObject;
		if (mode === 'delete_routine') {
			toastNotificationObject = <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={routine.name + " has been deleted."}
				className={classes.snackbar}
			/>;
		} else if (mode === 'archive_routine') {
			toastNotificationObject = <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={routine.name + " has been archived."}
				className={classes.snackbar}
				action={
					<Button style={{ color: '#5d88bc'}} size="small" onClick={() => this.archiveRoutine(routine, false)}>
						Undo
					</Button>
				}
			/>;
		} else if (mode === 'unarchive_routine') {
			toastNotificationObject = <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={routine.name + " has been unarchived."}
				className={classes.snackbar}
			/>;
		}
		
		this.setState({
			toastNotificationObject: toastNotificationObject
		});
	}
	
	closeToastNotification = (e, reason) => {
		this.setState({
			toastNotificationObject: null
		});
	}

	deleteRoutine = async (routine) => {
		this.setState({
			isLoading: true,
			routineToConfirmDeletion: null,
		});
		
		await fetch(UrlBuilder.routine.routinesApi(routine.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(this.refresh)
		
		// Refresh will set isLoading to false if success.
		
		this.showRoutineStatusToastNotification(routine, 'delete_routine')
	}

	archiveRoutine = async (routine, shouldArchive) => {
		this.setState({
			isLoading: true
		});

		let newRoutineView = {
			id: routine.id,
			isArchived: shouldArchive
		};

		await fetch(UrlBuilder.routine.routinesApi(), {
			method: 'PUT',
			headers: {
				'accept': 'application/json',
				'content-type': 'application/json'
			},
			body: JSON.stringify(newRoutineView)
		})
			.then(this.refresh);
		
		// Refresh will set isLoading to false if success.

		let mode = shouldArchive ? 'archive_routine' : 'unarchive_routine';
		this.showRoutineStatusToastNotification(routine, mode)
	}

	toggleCloseAllPopups(shouldRefresh = true) {
		this.setState({
			showRoutineAddEditPopUp: false,
			routineToEdit: null,
			isSpeedDialOpen: false,
		});
		if (shouldRefresh) {
			this.refresh();
		}
	}

	toggleOpenAddRoutinePopup() {
		this.setState({
			isSpeedDialOpen: false,
			showRoutineAddEditPopUp: !this.state.showRoutineAddEditPopUp,
		});
	}

	toggleOpenEditRoutinePopup(routine) {
		this.setState({
			routineToEdit: routine,
		});
	}

	openRoutineDeleteConfirmationDialog = (routine) => {
		this.setState({
			routineToConfirmDeletion: routine
		})
	}

	toggleArchivedMode = (isInArchiveMode) => {
		this.setState({
			isInArchivedMode: isInArchiveMode,
			isSpeedDialOpen: false,
			routines: isInArchiveMode ? null : (UserLocalStorage.get(UrlBuilder.routine.routinesApi()) ?? null)  // Going to archive mode? we will fetch again, so set routines null, or go back to original state.
		});
	}

	openSpeedDial = (isOpen) => {
		this.setState({
			isSpeedDialOpen: isOpen,
		})
	}

	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	onDragEnd = async (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		let routines = this.reorder(
			this.state.routines,
			result.source.index,
			result.destination.index
		);

		this.setState({
			routines: routines
		});
		this.forceUpdate();

		await this.submitRoutinesReordering(routines);
	};

	submitRoutinesReordering = async (routines) => {
		let routineViews = [];
		routines.forEach((routine, idx) => routineViews.push({
			id: routine.id,
			sortNumber: idx,
		}));

		await fetch(UrlBuilder.routine.routinesBulkApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(routineViews)
		});

		UserLocalStorage.remove(UrlBuilder.routine.routinesApi()); // Let's stale the localStorage so that the next time a refresh occurs it doesnt look choppy.
	}

	renderRoutineDeleteConfirmationDialog = () => {
		if (!this.state.routineToConfirmDeletion || !this.state.isInArchivedMode) {
			return null;
		}

		return <Dialog
			open={true}
			onClose={() => this.openRoutineDeleteConfirmationDialog(null)}
			TransitionComponent={Transition}
		>
			<DialogTitle>{"Are you sure you want to delete " + this.state.routineToConfirmDeletion.name + '?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Deleting a routine will delete all the logs you have made for this routine. This will remove the logs from your historical workouts as well.
					<br/>
					This cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' color='grey' onClick={() => this.openRoutineDeleteConfirmationDialog(null)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={() => this.deleteRoutine(this.state.routineToConfirmDeletion)} color="primary" autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	}

	renderSpeedDial = () => {
		const {classes} = this.props

		if (this.state.isInArchivedMode) {
			return <Fab color="primary" className={classes.speedDialWrapper} onClick={() => this.toggleArchivedMode(false)}>
				<ArrowBackIcon />
			</Fab>
		}

		return <SpeedDial
				className={classes.speedDialWrapper}
				icon={<MenuIcon/>}
				onClose={() => this.openSpeedDial(false)}
				onOpen={() => this.openSpeedDial(true)}
				open={this.state.isSpeedDialOpen}
				ariaLabel={'Routine Menu'}
			>
				<SpeedDialAction
					icon={<AddIcon/>}
					tooltipTitle={<Typography noWrap>New Routine</Typography>}
					tooltipOpen
					onClick={this.toggleOpenAddRoutinePopup}
					title={'New Routine'}
				/>
				<SpeedDialAction
					icon={<ArchiveIcon/>}
					tooltipTitle={<Typography noWrap>Archived</Typography>}
					tooltipOpen
					onClick={() => this.toggleArchivedMode(true)}
					title={'Archived'}
				/>
			</SpeedDial>;
	}

	renderPage = () => {
		if (this.state.routines && this.state.routines.length > 0) {
			return this.renderRoutinesOverview();
		}
		return this.renderNoRoutines();
	}
	
	renderNoRoutines = () => {
		const {classes} = this.props;

		return <>
			<Box paddingTop={5}/>
			
			<Grid container justifyContent="center">
				{this.state.isInArchivedMode ?
					<IconButton
                        touch
                        tooltip="Go Back"
                        onClick={() => this.toggleArchivedMode(false)}
                        size="large">
						<ArrowBackIcon className={classes.largeIcon}/>
					</IconButton>
					:
					<IconButton
                        touch
                        tooltip="See routines"
                        onClick={this.toggleOpenAddRoutinePopup}
                        size="large">
						<AddToPhotosIcon className={classes.largeIcon}/>
					</IconButton>
				}
			</Grid>
			
			<Grid container justifyContent="center">
				<Typography variant="overline" gutterBottom>
					{this.state.isInArchivedMode ? 
						"You have no archived routines"
						: "You have no routines yet"
					}
				</Typography>
			</Grid>

			<Grid container justifyContent="center">
				<Button>
					{this.state.isInArchivedMode ?
						<Typography variant="overline" onClick={() => this.toggleArchivedMode(false)}>
							<strong>Go back</strong>
						</Typography>
						:
						<Typography variant="overline" onClick={this.toggleOpenAddRoutinePopup}>
							<strong>Create a routine</strong>
						</Typography>
					}
				</Button>
			</Grid>
		</>;
	}

	renderRoutinesOverview = () => {
		return (
			<>
				<UserContext.Consumer>
					{({user}) => user && this.state.routines.length > 0 && !this.state.isInArchivedMode ?
						<>
							<WorkoutCallToAction/>
							<br/><Divider light/><br/>
						</> : null
					}
				</UserContext.Consumer>

				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<Grid container spacing={1} ref={provided.innerRef}>
								{this.state.routines.map((routine, index) => {
									let key = "routine_" + routine.id;
									return <Draggable key={key} draggableId={key} index={index}>
										{(provided, snapshot) => (
											<Grid item xs={12} sm={12} md={12} ref={provided.innerRef}{...provided.draggableProps}>
												<RoutineOverviewCard
													key={routine.id}
													routine={routine}
													toggleOpenEditRoutinePopup={() => this.toggleOpenEditRoutinePopup(routine)}
													deleteRoutine={this.openRoutineDeleteConfirmationDialog}
													archiveRoutine={(shouldArchive) => this.archiveRoutine(routine, shouldArchive)}
													isInArchivedMode={this.state.isInArchivedMode}
													dragHandle={<Typography {...provided.dragHandleProps}><IconButton size="small"><UnfoldMoreIcon color="action" /></IconButton></Typography>}
												/>
											</Grid>
										)}
									</Draggable>
								})}
							</Grid>
						)}
					</Droppable>
				</DragDropContext>
			</>
		);
	}

	hasNeverBeenFetched = () => {
		return this.state.routines === null;
	}
	
	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}
	
	render() {
		const {classes} = this.props
		
		return (
			<div>
				<Fade in={this.state.isLoading}>
					<LinearProgress />
				</Fade>

				<Box paddingTop={1} />
			
				<RoutineNewOrEditFormBloop
					routine={this.state.routineToEdit}
					isOpen={this.state.showRoutineAddEditPopUp || this.state.routineToEdit}
					closeSelfFunc={this.toggleCloseAllPopups}
				/>
				
				{this.renderRoutineDeleteConfirmationDialog()}
				
				{
					this.hasNeverBeenFetched() ?
						this.renderLoadingSkeletons()
						: this.renderPage()
				}

				{this.renderSpeedDial()}
				
				{this.state.toastNotificationObject}
			</div>
		);
	}
}

RoutinesOverview.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(RoutinesOverview));