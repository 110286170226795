import React, {PureComponent} from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import {Link} from "react-router-dom";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {UserContext} from "../../core/UserContext";
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Utils} from "../../core/util/Utils";
import { styled } from '@mui/system';

const useStyles = theme => ({
	stickToBottom: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
		zIndex: 1,
		background: theme.palette.primary.main
	},
	navButton: {
		color: 'rgba(255, 255, 255, 0.5)',
		'&$selected': {
			color: theme.palette.primary.contrastText,
		},
	},
	selected: {}
});

const Responsiveness = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'none',
	},
}));

class BottomNavMenu extends PureComponent {
	/**
	 * No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setValue(this.recognizePageIndex());
	}

	recognizePageIndex = () => {
		let path = window.location.pathname;
		if (path.startsWith(UrlBuilder.routine.routineOverviewPage())) {
			return 1;
		} else if (path.startsWith(UrlBuilder.exercise.exercisesOverviewPage())) {
			return 2;
		} else if (path.startsWith(UrlBuilder.me.mePage())) {
			return 3;
		} else if (path.startsWith(UrlBuilder.user.SignUpPage())) {
			return 2;
		} else if (path.startsWith(UrlBuilder.user.SignInPage())) {
			return 1;
		} else if (path.startsWith(UrlBuilder.user.settingsPage())) {
			return null; // Don't show any selection on the settings page.
		}
		
		return 0;
	}
	
	setValue = (val) => {
		this.setState({
			value: val,
		});
	}
	
	render() {
		const { classes } = this.props;

		return ( <Responsiveness>
			<Box paddingTop={8}/>
			<UserContext.Consumer>
				{({user, setUser}) => user ?
					<BottomNavigation
						className={classes.stickToBottom}
						showLabels
						value={this.state.value}
						onChange={(event, newValue) => {
							this.setValue(newValue);
						}}
					>
						<BottomNavigationAction component={Link}
						                        to="/"
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label="Home"
						                        icon={<HomeIcon />}
						/>
						<BottomNavigationAction component={Link}
						                        to={UrlBuilder.routine.routineOverviewPage()}
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label="Routines"
						                        icon={<BookIcon />}
						/>
						<BottomNavigationAction component={Link}
						                        to={UrlBuilder.exercise.exercisesOverviewPage()}
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label="Exercises"
						                        icon={<FitnessCenterIcon />}
						/>
						<BottomNavigationAction component={Link}
						                        to={UrlBuilder.me.mePage()}
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label={'Me'}
						                        icon={<AccessibilityIcon />}
						/>
					</BottomNavigation>
					:
					<BottomNavigation 
						className={classes.stickToBottom} 
						showLabels
						value={this.state.value}
						onChange={(event, newValue) => {
							this.setValue(newValue);
						}}
					>
						{Utils.isApp() ? null // in TWA- mode, don't show home page.
							 : <BottomNavigationAction component={Link}
							                           to={'/'}
							                           classes={{
								                           root: classes.navButton,
								                           selected: classes.selected
							                           }}
							                           label="Home"
							                           icon={<HomeIcon />}
							/>
						}
						<BottomNavigationAction component={Link}
						                        to={UrlBuilder.user.SignInPage()}
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label="Sign in"
						                        icon={<LockOpenIcon />}
						/>
						<BottomNavigationAction component={Link}
						                        to={UrlBuilder.user.SignUpPage()}
						                        classes={{
							                        root: classes.navButton,
							                        selected: classes.selected
						                        }}
						                        label="Sign up"
						                        icon={<PersonAddIcon />}
						/>
					</BottomNavigation>
				}
			</UserContext.Consumer>
			</Responsiveness>
		);
	}
}

export default (withStyles(useStyles)(BottomNavMenu))
