import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {PageDataContext} from "../../../core/PageDataContext";
import Grid from "@mui/material/Grid";
import WorkoutSmallNote from "../partials/WorkoutSmallNote";
import Box from "@mui/material/Box";
import moment from "moment";
import {Typography} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Skeleton from '@mui/material/Skeleton';
import {SentimentVeryDissatisfied, SentimentVerySatisfied} from "@mui/icons-material";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import EditIcon from "@mui/icons-material/Edit";
import WorkoutCompleteEditForm from "../partials/WorkoutCompleteEditForm";
import {withRouter} from "../../../hooks/withRouter";
import Slide from "@mui/material/Slide";
import WorkoutLogsSection from "../partials/WorkoutLogsSection";

const useStyles = theme => ({
	nested: {
		paddingLeft: theme.spacing(5),
	},
	tabBar: {
		width: '100%',
		left: 0,
		position: 'fixed',
		'z-index' :99,
	},
	media: {
		height: 125,
	},
	button: {
		minWidth: '30px',
		width: '30px',
		height: '30px',
		padding: '0',
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class WorkoutDetail extends PureComponent {
	/**
	 * No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The workout to be fetched if a workout is active.
			 */
			workout: null,
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: true,
			/**
			 * Whether the graphs are being fetched.
			 */
			isGraphsLoading: true,
			/**
			 * Should we show stuff saying "Workout done!"?
			 */
			showWorkoutDoneMessage: Boolean((new URLSearchParams(this.props.location.search)).get('just_finished') !== null),
			/**
			 * The congrats message
			 */
			workoutDoneMessage: this.getWorkoutDoneMessage(),
			/**
			 * Is the delete confirmation dialog open?
			 */
			isDeleteConfirmationDialogOpen: false,
			/**
			 * The popup for a log comment.
			 */
			dropDownCommentAnchorEl: null,
			/**
			 * Is the edit form open?
			 */
			isWorkoutEditFormOpen: false,
		};
	}

	componentDidMount() {
		this.fetchWorkout();
	}

	getNumberOfExercisesDone = (workout) => {
		let total = 0;
		workout.routines?.forEach(routine => {
			total += routine.exercises.length;
		});
		return total;
	}

	fetchWorkout = async () => {
		this.setState({
			isLoading: true
		});

		const response = await fetch(UrlBuilder.workout.workoutsApi(this.props.params.workoutId))
			.then(response => response.json());

		let workout = response.data;

		this.setState({
			workout: workout,
			isLoading: false
		});
		this.context.setPageData({
			title: workout.name,
		});
	}

	getWorkoutDoneMessage = () => {
		let messages = [
			'Good work!',
			'Good job!',
			'Great work!',
			'Great job!',
		];

		return messages[Math.floor(Math.random() * messages.length)];
	}
	
	deleteSelf = async (e) => {
		e.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		await fetch(UrlBuilder.workout.workoutsApi(this.state.workout.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		});

		this.props.navigate('/');
	}

	openWorkoutDeleteConfirmationDialog = (isOpen) => {
		this.setState({
			isDeleteConfirmationDialogOpen: isOpen
		})
	}
	
	renderMood = (moodLevel) => {
		switch (moodLevel) {
			case 1:
				return <SentimentVeryDissatisfied color='secondary'/>;
			case 2:
				return <SentimentSatisfiedIcon color='secondary'/>;
			case 3:
				return <SentimentVerySatisfied color='secondary'/>
		}
	}

	openWorkoutEditForm = (isOpen, shouldRefresh = false) => {
		if (shouldRefresh) {
			this.fetchWorkout();
		}
		this.setState({
			isWorkoutEditFormOpen: isOpen,
		});
	}

	renderDeleteConfirmationDialog = () => {
		if (!this.state.isDeleteConfirmationDialogOpen) {
			return null;
		}

		return <Dialog open={true} onClose={() => this.openWorkoutDeleteConfirmationDialog(false)} TransitionComponent={Transition}>
			<DialogTitle>{"Are you sure you want to delete this workout?"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Deleting it will remove its corresponding logs. This cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' color='grey' onClick={() => this.openWorkoutDeleteConfirmationDialog(false)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={this.deleteSelf} color="primary" autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	}
	
	renderPage = () => {
		const {classes} = this.props;

		const numberOfExercisesDone = this.getNumberOfExercisesDone(this.state.workout);

		const dateTimeStarted = moment(this.state.workout.dateTimeStarted);
		const dateTimeEnded = moment(this.state.workout.dateTimeEnded);
		let workoutDurationObj = moment.duration(dateTimeEnded.diff(dateTimeStarted));
		let workoutDuration = moment.utc(workoutDurationObj.asMilliseconds()).format('HH:mm');
		let timeRange = dateTimeStarted.format('LT') + " - " + dateTimeEnded.format('LT');
		
		return <>
			<Box paddingTop={1.5}/>

			{this.state.showWorkoutDoneMessage ?
				<Zoom in>
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
						<Typography variant="h3" gutterBottom>
							{this.state.workoutDoneMessage} 🏆
						</Typography>
					</Box>
				</Zoom> : null
			}
			
			<Grid container spacing={1}>
				<Grid item xs={4} >
					<WorkoutSmallNote title="Exercises" description={numberOfExercisesDone} smallDetail={"Total"}/>
				</Grid>

				<Grid item xs={4}>
					<WorkoutSmallNote title="Volume" description={this.state.workout.totalVolume} smallDetail={"Total"}/>
				</Grid>

				<Grid item xs={4} >
					<WorkoutSmallNote title="Duration" description={workoutDuration} smallDetail={timeRange}/>
				</Grid>
			</Grid>

			<br/>
			
			{this.renderDeleteConfirmationDialog()}

			<Grid container justifyContent='space-between'>
				<Grid item>
					<Typography variant="body2">
						<strong>Date:</strong> {dateTimeStarted.fromNow() + ' - ' + dateTimeStarted.calendar()}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
                        onClick={() => this.openWorkoutDeleteConfirmationDialog(true)}
                        size="small"
						className={classes.button}
					>
						<DeleteIcon/>
					</Button>
					&nbsp;&nbsp;
					<Button
						variant='contained'
						onClick={() => this.openWorkoutEditForm(true)}
                        size="small"
						className={classes.button}
					>
						<EditIcon/>
					</Button>
				</Grid>
			</Grid>
			
			{this.state.workout.moodLevel ?
				<Typography variant="body2">
					<strong>Mood: </strong>
					<IconButton style={{padding: 0}} size="large">
						{this.renderMood(this.state.workout.moodLevel)}
					</IconButton>
				</Typography>
				: null
			}

			{this.state.workout.comment ?
				<>
					<Typography variant="body2">
						<strong>Notes:</strong> {this.state.workout.comment}
					</Typography>
				</> : null
			}

			<WorkoutCompleteEditForm
				workout={this.state.workout}
				isOpen={this.state.isWorkoutEditFormOpen}
				closeSelfFunc={(shouldRefresh) => this.openWorkoutEditForm(false, shouldRefresh)}
			/>

			<Box paddingTop={1.5}/>
			<Divider light/>
			<Box paddingTop={1.5}/>

			<WorkoutLogsSection workout={this.state.workout} />
		</>;
	}

	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<br/>

			<Grid container spacing={1}>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
			</Grid>

			<br/>

			<Skeleton animation="wave" variant="text" />

			<br/>

			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}
	
	render() {
		return <div>
			{this.state.isLoading ?
				this.renderLoadingSkeletons()
				: this.renderPage()
			}
		</div>
	}
}

WorkoutDetail.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(WorkoutDetail));