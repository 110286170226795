import React, {PureComponent} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../../core/PageDataContext";
import Button from "@mui/material/Button";
import MeasurementNewOrEditFormPopUp from "../partials/MeasurementNewOrEditFormPopUp";
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MeasurementCard from "../partials/MeasurementCard";
import Snackbar from "@mui/material/Snackbar";
import MeasurementDetailBloop from "../partials/MeasurementDetailBloop";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import {GraphQLQueryBuilder} from "../../../core/url/GraphQLQueryBuilder";
import {withRouter} from "../../../hooks/withRouter";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

const useStyles = theme => ({
	speedDialWrapper: {
		margin: 0,
		top: 'auto',
		right: 35,
		bottom: 80,
		left: 'auto',
		position: 'fixed',
	},
	largeIcon: {
		width: 200,
		height: 200,
	},
	snackbar: {
		bottom: 75,
		[theme.breakpoints.down('sm')]: {
			bottom: 150,
		},
	},
	media: {
		height: 110,
	},
});

class MeOverview extends PureComponent {
	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * All the routines that we have available. These are fetched from the backend.
			 */
			measurements: UserLocalStorage.get(this.getCacheKey()) ?? null,
			/**
			 * Indicates loading or not
			 */
			isLoading: true,
			/**
			 * Should we show the add measurement pop-up?
			 */
			showAddMeasurementPopUp: false,
			/**
			 * The measurement that is being edited.
			 */
			measurementInEditableMode: null,
			/**
			 * The toast notification if it should show up. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationObject: null,
			/**
			 * The measurement ID we should open the bloop for. We use ID and not measurement to make sure the measurement we were given is not outdated.
			 * This class will always hold the most up-to-date measurement.
			 */
			measurementIdBloopOpen: null,
		};
	}
	
	getCacheKey = () => {
		return UrlBuilder.graphql + '/measurements';
	}

	componentDidMount() {
		this.context.setPageData({
			title: "Me"
		});

		if (this.hasNeverBeenFetched()) {
			this.fetch().then(() => {
				this.openBloopFromURLIfPossible();
			});
		} else {
			this.openBloopFromURLIfPossible();
			this.fetch(); // Don't try to open bloop after this.
		}
	}

	openBloopFromURLIfPossible = () => {
		let measurementIdToOpen = (new URLSearchParams(this.props.location.search)).get('measurement');
		if (measurementIdToOpen && this.state.measurements) {
			for (let i = 0; i < this.state.measurements.length; i++) {
				let measurement = this.state.measurements[i];

				if (measurement.id == measurementIdToOpen) {
					this.setState({
						measurementIdBloopOpen: measurement.id,
					});
				}
			}
		}
	}
	
	openAddMeasurementPopUp = () => {
		this.setState({
			showAddMeasurementPopUp: true
		});
	}

	toggleEditableMeasurement(event, measurement) {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.
		event.stopPropagation(); // Prevents from click from propagating.

		this.setState({
			measurementInEditableMode: measurement,
		});
	}

	refresh = () => {
		this.fetch();
	}
	
	fetch = async () => {
		this.setState({
			isLoading: true
		});
		
		const response = await fetch(UrlBuilder.graphql, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: GraphQLQueryBuilder.fullMeasurements(),
		}).then(res => res.json());

		this.setState({
			measurements: response.data.measurements ?? [],
			isLoading: false
		});
		
		UserLocalStorage.set(this.getCacheKey(), response.data.measurements ?? []);
	}

	deleteMeasurement = async (event, measurement) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.
		event.stopPropagation(); // Prevents from click from propagating.

		await fetch(UrlBuilder.measurements.measurementsApi(measurement.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(this.refresh)

		this.showMeasurementDeletedToastNotification(measurement);
	}

	undeleteMeasurement = async(event, measurement) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		let measurementView = {
			id: measurement.id,
			isDeleted: false,
		};

		await fetch(UrlBuilder.measurements.measurementsApi(), {
			method: 'PUT',
			headers: {
				'accept': 'application/json',
				'content-type': 'application/json'
			},
			body: JSON.stringify(measurementView)
		})
			.then(this.refresh);

		this.closeToastNotification();
	}

	showMeasurementDeletedToastNotification = (measurement) => {
		const {classes} = this.props
		this.setState({
			toastNotificationObject: <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={true}
				autoHideDuration={3000}
				onClose={this.closeToastNotification}
				message={measurement.name + " moved to trash."}
				className={classes.snackbar}
				action={
					<Button style={{ color: '#5d88bc'}} size="small" onClick={(e) => this.undeleteMeasurement(e, measurement)}>
						Undo
					</Button>
				}
			/>
		});
	}

	closeToastNotification = (e, reason) => {
		this.setState({
			toastNotificationObject: null
		});
	}

	getMeasurementFromId = (measurementId) => {
		if (measurementId) {
			for (let i = 0; i < this.state.measurements.length; i++) {
				let measurement = this.state.measurements[i];
				if (measurement.id == measurementId) {
					return measurement;
				}
			}
		}
		return null;
	}

	handleBloopOpen = (measurement) => {
		if (measurement) {
			measurement = this.getMeasurementFromId(measurement.id);

			this.setState({
				measurementIdBloopOpen: measurement.id,
			});

			let currentUrlParams = new URLSearchParams(window.location.search);
			currentUrlParams.set('measurement', measurement.id);
			this.props.navigate(window.location.pathname + "?" + currentUrlParams.toString());
		} else {
			this.setState({
				measurementIdBloopOpen: null,
			});

			this.props.navigate(window.location.pathname);
		}
	};

	switchMeasurementBloop = (measurement) => {
		this.setState({
			measurementIdBloopOpen: null,
		});

		// Wait before doing this, to get the full animation :)
		setTimeout(() => this.handleBloopOpen(measurement), 100);
	}

	updateMeasurement = (measurement) => {
		let measurements = this.state.measurements;

		for (let i = 0; i < this.state.measurements.length; i++) {
			let measurementTemp = this.state.measurements[i];

			if (measurementTemp.id == measurement.id) {
				measurements[i] = measurement;
				this.setState({
					measurements: measurements,
				});
				this.forceUpdate(); // We do this because this is a PureComponent and the routine's memory address didn't change, so we need to force it.
				UserLocalStorage.set(this.getCacheKey(), measurements);
			}
		}
	}

	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	onDragEnd = async (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		let measurements = this.reorder(
			this.state.measurements,
			result.source.index,
			result.destination.index
		);

		this.setState({
			measurements: measurements
		});
		this.forceUpdate();

		await this.submitMeasurementsReordering(measurements);
	};

	submitMeasurementsReordering = async (measurements) => {
		let measurementViews = [];
		measurements.forEach((measurement, idx) => measurementViews.push({
			id: measurement.id,
			sortNumber: idx,
		}));

		await fetch(UrlBuilder.measurements.measurementsBulkApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(measurementViews)
		});

		UserLocalStorage.remove(this.getCacheKey()); // Let's stale the localStorage so that the next time a refresh occurs it doesnt look choppy.
	}

	renderPage = () => {
		if (this.state.measurements && this.state.measurements.length > 0) {
			return this.renderOverview();
		}
		return this.renderNoRoutines();
	}

	toggleCloseAllPopups = (shouldRefresh = true) => {
		this.setState({
			showAddMeasurementPopUp: false,
			measurementInEditableMode: null,
		});
		if (shouldRefresh) {
			this.fetch();
		}
	}

	hasNeverBeenFetched = () => {
		return this.state.measurements === null;
	}

	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}
	
	renderOverview = () => {
		return <>
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<Grid container spacing={1} ref={provided.innerRef}>
							{this.state.measurements.map((measurement, index) => {
								let key = "measurement_" + measurement.id;
								return <Draggable key={key} draggableId={key} index={index}>
									{(provided, snapshot) => (
										<Grid item xs={12} sm={12} md={12} ref={provided.innerRef}{...provided.draggableProps}>
											<MeasurementCard
												measurement={measurement}
												editSelfFunc={(e) => this.toggleEditableMeasurement(e, measurement)}
												deleteSelfFunc={(e) => this.deleteMeasurement(e, measurement)}
												setBloopOpen={this.handleBloopOpen}
												dragHandle={<Typography {...provided.dragHandleProps}><IconButton size="small"><UnfoldMoreIcon color="action" /></IconButton></Typography>}
											/>
										</Grid>
									)}
								</Draggable>
							})}
						</Grid>
					)}
				</Droppable>
			</DragDropContext>

			<MeasurementDetailBloop
				measurement={this.getMeasurementFromId(this.state.measurementIdBloopOpen)}
				isOpen={this.state.measurementIdBloopOpen !== null}
				closeSelfFunc={() => this.handleBloopOpen(null)}
				updateParentMeasurement={this.updateMeasurement}
				switchMeasurementBloop={this.switchMeasurementBloop}
			/>
		</>;
	}

	renderNoRoutines = () => {
		const {classes} = this.props;

		return <>
			<Box paddingTop={5}/>

			<Grid container justifyContent="center">
				<IconButton
                    touch
                    tooltip="See Measurements"
                    onClick={this.openAddMeasurementPopUp}
                    size="large">
					<AddToPhotosIcon className={classes.largeIcon}/>
				</IconButton>
			</Grid>
			<Grid container justifyContent="center">
				<Typography variant="overline" gutterBottom>
					You have no measurements yet
				</Typography>
			</Grid>

			<Grid container justifyContent="center">
				<Button>
					<Typography variant="overline" onClick={this.openAddMeasurementPopUp}>
						<strong>Create a measurement</strong>
					</Typography>
				</Button>
			</Grid>
		</>;
	}
	
	render() {
		const {classes} = this.props;

		return <>
			<Fade in={this.state.isLoading}>
				<LinearProgress />
			</Fade>

			<Box paddingTop={1} />

			<MeasurementNewOrEditFormPopUp
				isOpen={this.state.showAddMeasurementPopUp || this.state.measurementInEditableMode}
				measurement={this.state.measurementInEditableMode}
				closeSelfFunc={this.toggleCloseAllPopups}
				measurements={this.state.measurements}
			/>

			{
				this.hasNeverBeenFetched() ?
					this.renderLoadingSkeletons()
					: this.renderPage()
			}

			<Zoom in={true} unmountOnExit>
				<Fab color="primary" className={classes.speedDialWrapper} onClick={this.openAddMeasurementPopUp}>
					<AddIcon />
				</Fab>
			</Zoom>

			{this.state.toastNotificationObject}
		</>;
	}
}

MeOverview.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(MeOverview));