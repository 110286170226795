import React, {PureComponent} from 'react';
import { Line } from 'react-chartjs-2';
import {ColorPicker} from "../../core/colors/ColorPicker";

export default class AsyncLineGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			fetchData: this.props.fetchData,
			label: this.props.label,
			dates: this.props.dates,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			fetchData: nextProps.fetchData,
			label: nextProps.label,
			dates: nextProps.dates,
		});
	}
	
	render() {
		let graphData = this.state.fetchData();
		
		let color = ColorPicker.pick(this.state.label);
		
		const data = {
			labels: this.state.dates,
			datasets: [
				{
					label: this.state.label,
					fill: false,
					lineTension: 0.1,
					backgroundColor: color,
					borderColor: color,
					borderCapStyle: 'butt',
					borderDash: [],
					borderDashOffset: 0.0,
					borderJoinStyle: 'miter',
					pointBorderColor: color,
					pointBackgroundColor: '#fff',
					pointBorderWidth: 1,
					pointHoverRadius: 5,
					pointHoverBackgroundColor: color,
					pointHoverBorderColor: color,
					pointHoverBorderWidth: 2,
					pointRadius: 1,
					pointHitRadius: 10,
					data: graphData
				}
			]
		};
		
		let options= {
			legend: {
				display: typeof(this.props.showLegend) === 'boolean' ? this.props.showLegend : true,
			},
		};

		return (
			<Line ref="chart" data={data} options={options}/>
		);
	}

	componentDidMount() {
		const { datasets } = this.refs.chart.chartInstance.data
	}
}