import React, {PureComponent} from 'react'
import SwipeableViews from 'react-swipeable-views';
import withStyles from '@mui/styles/withStyles';
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from '@mui/icons-material/Close';
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import RoutineExerciseDetailBloopGraphsTab from "./RoutineExerciseDetailBloopGraphsTab";
import Fade from "@mui/material/Fade";
import RoutineExerciseDetailBloopLogsTab from "./RoutineExerciseDetailBloopLogsTab";
import Box from "@mui/material/Box";
import {UserContext} from "../../../core/UserContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListIcon from "@mui/icons-material/List";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Avatar from "@mui/material/Avatar";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {Button, CircularProgress, Divider, ListItemIcon, Popover} from "@mui/material";
import Fab from "@mui/material/Fab";
import SortIcon from '@mui/icons-material/Sort';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RoutineExerciseDetailBloopStatsTab from "./RoutineExerciseDetailBloopStatsTab";
import RoutineExerciseDetailBloopGoalsTab from "./RoutineExerciseDetailBloopGoalsTab";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import Backdrop from "@mui/material/Backdrop";
import SupersetBadge from "../../core/custom/SupersetBadge";
import {withRouter} from "../../../hooks/withRouter";
import { red } from '@mui/material/colors';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import HelpIcon from "@mui/icons-material/Help";
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = theme => ({
	title: {
		marginTop: 8,
		flex: 100,
		fontSize: 18,
	},
	container: {
		width: '100%',
		padding: 0,
		backgroundColor: theme.palette.background.default,
	},
	grow: {
		flexGrow: 1,
	},
	tabMenu: {
		color: "rgba(241,241,241,0.89)",
	},
	bottomAppBar: {
		top: 'auto',
		bottom: 0,
		zIndex: 1,
	},
	smallAvatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	appBar: {
		position: 'relative',
	},
	fabButton: {
		position: 'absolute',
		top: -22,
		left: 0,
		right: 0,
		margin: '0 auto',
	},
	'@keyframes flicker': {
		from: {
			opacity: 1,
		},
		to: {
			opacity: 0.6,
		},
	},
	flicker: {
		animationName: '$flicker',
		animationDuration: '1000ms',
		animationIterationCount: 'infinite',
		animationDirection: 'alternate',
		animationTimingFunction: 'ease-in-out',
		color: red[300],
	},
	popOverText: {
		padding: theme.spacing(2),
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			id={`full-width-tabpanel-${index}`}
			style={{ willChange: 'scroll-position', minHeight: '80vh' }}
			{...other}
		>
			<Fade in>
				{children}
			</Fade>
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

/**
 * A card that can be popped up into fullscreen mode.
 */
class RoutineExerciseDetailBloop extends PureComponent {
	static displayName = RoutineExerciseDetailBloop.name;

	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'exercise': The exercise that belongs to the routine.
	 *      'isOpen': Is this bloop open?
	 *      'closeSelfFunc': Close self.
	 *      'updateParentExercise': The function the parent's exercise.
	 *      'switchExerciseBloop': The function to switch which exercise is displayed here.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: false,
			/**
			 * Indicates whether the bloop is open.
			 */
			isOpen: this.props.isOpen,
			/**
			 * The tab that's currently being shown. Default: 2 -> Logs.
			 */
			value: 2,
			/**
			 * The message to show for the toast notification. Null if it shouldn't show. Initializing
			 * this will cause the toast notification to show up.
			 */
			toastNotificationMessage: null,
			/**
			 * The app-bar menu El.
			 */
			appBarMenuAnchorEl: null,
			/**
			 * The date-range fetched. Default goes here.
			 */
			dateRangeFetched: '3_months',
			/**
			 * The dropdown menu for all routine-exercises dropdown.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * Should we show all the logs for this routine-exercise? in other words, do we show the logs for this exercise for all routine exercises there?
			 */
			shouldShowAllLogs: false,
			/**
			 * The help text for the tooltip explaining what the Default/All button does.
			 */
			showingAllPopupHelpText: null,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		let state = {
			routine: nextProps.routine,
			exercise: nextProps.exercise ? nextProps.exercise : this.state.exercise, // No good reason to undefine the exercise. This allows us to have nicer animations.
			isOpen: nextProps.isOpen,
			shouldShowAllLogs: false, // Reset this.
			showingAllPopupHelpText: null,
			dropDownMenuAnchorEl: null,
		};

		if (this.state.exercise?.id != nextProps.exercise?.id) { // Reset if you switch exercises.
			state['value'] = 2; // Reset to the Logs tab.
			state['dateRangeFetched'] = '3_months'; 
		}
		
		this.setState(state);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Detects the browser back button and closes the detail popup.
		window.onpopstate = e => {
			this.props.closeSelfFunc();
		}
	}

	/**
	 * @param event
	 * @param reason
	 */
	closeToastNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({
			toastNotificationMessage: null,
		});
	};

	refresh = async (withMessage = null, time = null, showAllLogs = false, isQuickFetch = false) => {
		this.setState({
			isLoading: true,
			appBarMenuAnchorEl: null,
		});

		let datetime = (moment()).subtract(3, 'months');
		let dateRangeFetched = '3_months'; // We always reset when we refresh, to avoid slowdowns.
		if (time !== null) {
			switch (time) {
				case '1_month':
					datetime = (moment()).subtract(1, 'month');
					dateRangeFetched = '1_month';
					break;
				case '3_months':
					datetime = (moment()).subtract(3, 'months');
					dateRangeFetched = '3_months';
					break;
				case '6_months':
					datetime = (moment()).subtract(6, 'months');
					dateRangeFetched = '6_months';
					break;
				case '1_year':
					datetime = (moment()).subtract(1, 'year');
					dateRangeFetched = '1_year';
					break;
				case 'all_time':
					datetime = null;
					dateRangeFetched = 'all_time';
			}
		} else {
			if (isQuickFetch && this.context.user?.activeWorkout) { // Double-check: quick fetches can only be done on active workouts.
				datetime = moment(this.context.user.activeWorkout.dateTimeStarted); // Get as least logs as possible: only for the current workout (note that we format this to 'L', so we only care about the current date, not time).
				dateRangeFetched = this.state.dateRangeFetched;
			}
		}
		
		await fetch(
			UrlBuilder.routine.routineExercisesApi(this.state.exercise.routineExerciseId, datetime ? datetime.format('L') : null, showAllLogs)
		)
			.then(response => response.json())
			.then(exercise => {
				if (isQuickFetch) {
					let currentExerciseLogGroups = this.state.exercise.logGroups ?? [];
					if (
						// We can only replace the first log group (by definition a quick fetch is only done on current non-past workouts).
						currentExerciseLogGroups[0] && 
						exercise.logGroups[0] && 
						// Make sure that the dates are equal just in case.
						moment(currentExerciseLogGroups[0].date).format('YYYY-MM-DD') === moment(exercise.logGroups[0].date).format('YYYY-MM-DD')
					) {
						// Replace the appropriate log group.
						currentExerciseLogGroups[0] = exercise.logGroups[0];
						exercise.logGroups = currentExerciseLogGroups;
					}
				}
				
				this.props.updateParentExercise(exercise); // This will pass the new exercise to the bloop, so not need to set it here.
				this.setState({
					isLoading: false,
					toastNotificationMessage: withMessage,
					dateRangeFetched: dateRangeFetched,
					shouldShowAllLogs: showAllLogs,
				});
			})
			.catch(() => {
				this.setState({
					isLoading: false,
					toastNotificationMessage: withMessage,
				});
			});
	}

	handleChange = (event, newValue) => {
		this.setState({
			value: newValue
		});
	};

	handleChangeIndex = (index) => {
		this.setState({
			value: index
		});
	};

	openAppBarMenu = (event) => {
		this.setState({
			appBarMenuAnchorEl: event.currentTarget
		});
	}
	
	closeAppBarMenu = () => {
		this.setState({
			appBarMenuAnchorEl: null
		});
	}

	/**
	 * https://github.com/oliviertassinari/react-swipeable-views/issues/339
	 * We have to manually tell the swipeable views to update its height, since the height can change.
	 */
	updateSwipeableViewsHeight = () => {
		if (this.refs.swipeableViewsRef) {
			this.refs.swipeableViewsRef.updateHeight();
		}
	}

	openDropdownMenu = (event) => {
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget
		});
	};

	closeDropdownMenu = () => {
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};

	goToNextExercise = () => {
		for (let i = 0; i < this.state.routine.exercises.length; i++) {
			let exercise = this.state.routine.exercises[i];
			if (this.state.exercise.routineExerciseId == exercise.routineExerciseId) {
				this.props.switchExerciseBloop(this.state.routine.exercises[i + 1]);
			}
		}
	}

	goToPreviousExercise = () => {
		for (let i = 0; i < this.state.routine.exercises.length; i++) {
			let exercise = this.state.routine.exercises[i];
			if (this.state.exercise.routineExerciseId == exercise.routineExerciseId) {
				this.props.switchExerciseBloop(this.state.routine.exercises[i - 1]);
			}
		}
	}

	startWorkout = async (event) => {
		event.preventDefault();

		this.setState({
			isWorkoutLoading: true,
		});

		let workout = {
			dateTimeStarted: moment().format('L, h:mm:ss A')
		};

		let res = await fetch(UrlBuilder.workout.startWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		}).then(res => res.json());

		this.context.setUser(res.data);

		this.setState({
			isWorkoutLoading: false,
			toastNotificationMessage: 'Workout started. Tracking exercises.'
		});
	}
	
	changeShowAllLogs = (showAll) => {
		this.setState({
			shouldShowAllLogs: showAll,
		});
	}
	
	showShowAllPopupHelpText = (e) => {
		this.setState({
			showingAllPopupHelpText: e.currentTarget,
		});
	}

	closeShowAllPopupHelpText = () => {
		this.setState({
			showingAllPopupHelpText: null,
		});
	}

	downloadLogs = () => {
		let logs = this.state.exercise.logGroups.map(group => group.logs).flat();
		
		const formattedLogs = logs.map(log => {
			let map = {
				Date: moment(log.dateCreated).format('DD-MM-YYYY HH:mm:ss'),
			};

			if (this.state.exercise.type.attributes[0]) {
				map[this.state.exercise.type.attributes[0].name] = log.values[0];
			}

			if (this.state.exercise.type.attributes[1]) {
				map[this.state.exercise.type.attributes[1].name] = log.values[1];
			}
			
			return map;
		});

		// unparse data to CSV
		const csv = Papa.unparse(formattedLogs);
		
		// create a blob and download
		const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
		saveAs(blob, this.state.exercise.name + ".csv");
		
		this.closeAppBarMenu();
	}

	renderShowingAllPopupHelpText = () => {
		const {classes} = this.props;

		return <Popover
			open={this.state.showingAllPopupHelpText !== null}
			anchorEl={this.state.showingAllPopupHelpText}
			onClose={this.closeShowAllPopupHelpText}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Typography variant='body2' className={classes.popOverText}>
				By default, the logs fetched are the logs done for this particular exercise in this particular routine (i.e. this routine-exercise combination). <br/><br/>
				You can alternatively display 'all' logs for this exercise in general, meaning that all logs for this exercise will be fetched, regardless of which routine they are in. <br/>
			</Typography>
		</Popover>
	}

	render() {
		const {classes} = this.props;

		if (!this.state.exercise) {
			return <React.Fragment/>;
		}
		
		let dateRangeFetchedText = '1 Month';
		switch (this.state.dateRangeFetched) {
			case '3_months':
				dateRangeFetchedText = '3 Months'
				break;
			case '6_months':
				dateRangeFetchedText = '6 Months'
				break;
			case '1_year':
				dateRangeFetchedText = '1 Year'
				break;
			case 'all_time':
				dateRangeFetchedText = 'All Time'
		}

		if (this.state.value === 0) {
			dateRangeFetchedText = 'All Time';
		}
		
		return (
            <Dialog fullScreen open={this.state.isOpen} onClose={this.props.closeSelfFunc} TransitionComponent={Transition}>
				<AppBar position={"relative"} enableColorOnDark>
					<Toolbar variant={"dense"} style={{minHeight: 35}}>
						<Typography variant="h6" noWrap className={classes.title}>
							{this.state.exercise.name} - {this.state.routine.name}
						</Typography>
						
						<IconButton size={"small"} color="inherit" onClick={this.props.closeSelfFunc}>
							<CloseIcon/>
						</IconButton>
					</Toolbar>
					<Toolbar variant={"dense"} disableGutters>
						<Tabs
							value={this.state.value}
							onChange={this.handleChange}
							indicatorColor="secondary"
							textColor="secondary"
							variant="fullWidth"
							style={{flexGrow: 1}}
						>
							<Tab className={classes.tabMenu} label="Goals" {...a11yProps(0)} />
							<Tab className={classes.tabMenu} label="Stats" {...a11yProps(1)} />
							<Tab className={classes.tabMenu} label="Log" {...a11yProps(2)} />
							<Tab className={classes.tabMenu} label="Graphs" {...a11yProps(3)} />
						</Tabs>
					</Toolbar>
				</AppBar>

				<DialogContent className={classes.container}>
					<SwipeableViews
						axis={'x'}
						index={this.state.value}
						onChangeIndex={this.handleChangeIndex}
						animateHeight
						ref='swipeableViewsRef'
					>
						<TabPanel value={this.state.value} index={0} dir={'x'}>
							<Container>
								<Box paddingTop={2}/>
								<RoutineExerciseDetailBloopGoalsTab
									routine={this.state.routine}
									exercise={this.state.exercise}
									isShowing={this.state.value === 0}
									updateSwipeableViewsHeightFunc={this.updateSwipeableViewsHeight}
								/>
							</Container>
						</TabPanel>
						<TabPanel value={this.state.value} index={1} dir={'x'}>
							<Container>
								<Box paddingTop={2}/>
								<RoutineExerciseDetailBloopStatsTab
									exercise={this.state.exercise}
									isShowing={this.state.value === 1}
									updateSwipeableViewsHeightFunc={this.updateSwipeableViewsHeight}
								/>
							</Container>
						</TabPanel>
						<TabPanel value={this.state.value} index={2} dir={'x'}>
							<Container className={classes.container}>
								<Box paddingTop={2}/>
								<RoutineExerciseDetailBloopLogsTab 
									routine={this.state.routine} 
									exercise={this.state.exercise} 
									refreshParentFunc={this.refresh}
									isLoading={this.state.isLoading}
									switchExerciseBloop={this.props.switchExerciseBloop}
									openFilterByMenu={this.openAppBarMenu}
									isShowing={this.state.value === 2}
									updateSwipeableViewsHeightFunc={this.updateSwipeableViewsHeight}
								/>
							</Container>
						</TabPanel>
						<TabPanel value={this.state.value} index={3} dir={'x'}>
							<Container className={classes.container}>
								<RoutineExerciseDetailBloopGraphsTab 
									exercise={this.state.exercise} 
									isShowing={this.state.value === 3}
									updateSwipeableViewsHeightFunc={this.updateSwipeableViewsHeight}
								/>
							</Container>
						</TabPanel>
					</SwipeableViews>
					<Box paddingTop={10}/>
				</DialogContent>

				<AppBar position="fixed" enableColorOnDark color="primary" className={classes.bottomAppBar}>
					<Toolbar variant='dense'>
						<Button edge="start" color="inherit" size='small' onClick={this.openAppBarMenu} disabled={this.state.isLoading || this.state.value === 0}
							startIcon={this.state.isLoading ? <CircularProgress size="1.75rem" color='inherit'/> : <SortIcon />}
						>
							{dateRangeFetchedText}
						</Button>
						
						{this.renderShowingAllPopupHelpText()}
						
						<Menu
							anchorEl={this.state.appBarMenuAnchorEl}
							open={Boolean(this.state.appBarMenuAnchorEl !== null)}
							onClose={this.closeAppBarMenu}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<MenuItem disabled dense><Typography variant='subtitle2' gutterBottom> Options:</Typography></MenuItem>
							<MenuItem dense onClick={this.downloadLogs} sx={{ paddingTop: 1 }}>
								<ListItemIcon>
									<DownloadIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>CSV</ListItemText>
							</MenuItem>

							<Divider/>
							
							<div onClick={this.showShowAllPopupHelpText}>
								<MenuItem dense disabled>
									<Typography variant='subtitle2'> Show: </Typography>
	
									<div className={classes.grow}/>
									
									<IconButton
										edge="end"
										color="inherit"
										sx={{ fontSize: '0.5rem !important', padding: '0px !important'}}
										size="small"
									>
										<HelpIcon/>
									</IconButton>
								</MenuItem>
							</div>
							<MenuItem dense>
								<ToggleButtonGroup
									color="secondary"
									size='small'
									fullWidth
									value={this.state.shouldShowAllLogs ? 'all' : 'default'}
									exclusive
									onChange={(e, v) => this.changeShowAllLogs(v === 'all')}
								>
									<ToggleButton value="default" size='small' fullWidth onClick={() => this.refresh(null, this.state.dateRangeFetched, false)}>Default</ToggleButton>
									<ToggleButton value="all" size='small' fullWidth onClick={() => this.refresh(null, this.state.dateRangeFetched, true)}>All</ToggleButton>
								</ToggleButtonGroup>
							</MenuItem>

							<Divider/>

							<MenuItem disabled dense><Typography variant='subtitle2' gutterBottom> Filter by:</Typography></MenuItem>
							<MenuItem dense onClick={() => this.refresh(null, '1_month', this.state.shouldShowAllLogs)} selected={this.state.dateRangeFetched === '1_month'}><Typography variant='body2' gutterBottom> 1 month </Typography></MenuItem>
							<MenuItem dense onClick={() => this.refresh(null, '3_months', this.state.shouldShowAllLogs)} selected={this.state.dateRangeFetched === '3_months'}><Typography variant='body2' gutterBottom> 3 months </Typography></MenuItem>
							<MenuItem dense onClick={() => this.refresh(null, '6_months', this.state.shouldShowAllLogs)} selected={this.state.dateRangeFetched === '6_months'}><Typography variant='body2' gutterBottom> 6 months </Typography></MenuItem>
							<MenuItem dense onClick={() => this.refresh(null, '1_year', this.state.shouldShowAllLogs)} selected={this.state.dateRangeFetched === '1_year'}><Typography variant='body2' gutterBottom> 1 year </Typography></MenuItem>
							<MenuItem dense onClick={() => this.refresh(null, 'all_time', this.state.shouldShowAllLogs)} selected={this.state.dateRangeFetched === 'all_time'}><Typography variant='body2' gutterBottom> All time </Typography></MenuItem>
						</Menu>

						<Fab variant="circular" className={classes.fabButton} style={{backgroundColor: this.context.user && this.context.user.activeWorkout ? '#cd4141' : '#4caf50'}}>
							{this.context.user && this.context.user.activeWorkout ?
								<RadioButtonCheckedIcon fontSize='large' className={classes.flicker} onClick={() => this.context.openActiveWorkoutBloop(true)}/> :
								<PlayArrowIcon style={{color: '#0f3b04'}} fontSize='large' className={classes.flicker} onClick={this.startWorkout}/>
							}
						</Fab> 
						
						<div className={classes.grow} />
						
						<IconButton
                            color="inherit"
                            onClick={this.goToPreviousExercise}
                            disabled={this.state.exercise.routineExerciseId == this.state.routine.exercises[0].routineExerciseId}
                            size="large">
							<ArrowBackIcon/>
						</IconButton>

						<IconButton
                            onClick={this.openDropdownMenu}
                            disabled={this.state.isTimerOn}
                            size="large">
							<ListIcon/>
						</IconButton>

						<IconButton
                            edge="end"
                            color="secondary"
                            onClick={this.goToNextExercise}
                            disabled={this.state.exercise.routineExerciseId == this.state.routine.exercises[this.state.routine.exercises.length - 1].routineExerciseId}
                            size="large">
							<ArrowForwardIcon/>
						</IconButton>

						<Popover
							anchorEl={this.state.dropDownMenuAnchorEl}
							open={this.state.dropDownMenuAnchorEl !== null}
							onClose={this.closeDropdownMenu}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
						>
							<AppBar className={classes.appBar} enableColorOnDark>
								<Toolbar variant='dense'>
									<Typography variant="h6" noWrap>
										Exercise Switcher
									</Typography>
								</Toolbar>
							</AppBar>

							{this.state.routine.exercises.map((exercise, idx) =>
								<ListItem button
								          dense
								          divider={idx < this.state.routine.exercises.length - 1}
								          key={exercise.routineExerciseId}
								          onClick={() => this.props.switchExerciseBloop(exercise)}
								          disabled={exercise.routineExerciseId == this.state.exercise.routineExerciseId}
								>
									<ListItemAvatar>
										{exercise.superset ?
											<SupersetBadge color={exercise.superset.color}>
												<Avatar className={classes.smallAvatar} style={{backgroundColor: exercise.color ?? ColorPicker.pick(exercise.name)}}>
													<Typography variant='body2'>
														{exercise.name.charAt(0).toUpperCase()}
													</Typography>
												</Avatar>
											</SupersetBadge>
										:
											<Avatar className={classes.smallAvatar} style={{backgroundColor: exercise.color ?? ColorPicker.pick(exercise.name)}}>
												<Typography variant='body2'>
													{exercise.name.charAt(0).toUpperCase()}
												</Typography>
											</Avatar>
										}
									</ListItemAvatar>
									<ListItemText 
										primary={exercise.name} 
										secondary={
											<Typography variant='caption'>
												{exercise.dateLastLogged ? DatePrettifier.prettifyLastTrained(new Date(exercise.dateLastLogged)) : '–'}
											</Typography>
										} 
									/>
								</ListItem>
							)}
						</Popover>
					</Toolbar>
				</AppBar>
				
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.toastNotificationMessage !== null}
					autoHideDuration={1500}
					onClose={this.closeToastNotification}
					message={this.state.toastNotificationMessage}
					action={
						<React.Fragment>
							<IconButton size="small" aria-label="close" color="inherit" onClick={this.closeToastNotification}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
				/>
				
			</Dialog>
        );
	}
}

RoutineExerciseDetailBloop.contextType = UserContext;

export default withStyles(useStyles)(withRouter(RoutineExerciseDetailBloop));