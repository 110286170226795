import React, {PureComponent} from 'react'
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {HoursMinutesTimeConverter} from "../../../core/prettifier/HoursMinutesTimeConverter";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import {UnitPrettifier} from "../../../core/prettifier/UnitPrettifier";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Slider from "@mui/material/Slider";
import {Checkbox, FormControlLabel} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider, TimePicker} from "@mui/lab";
import Slide from "@mui/material/Slide";
import {ExerciseTypeAttribute} from "../../../core/models/ExerciseTypeAttribute";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputField: {
		maxWidth: 110,
	},
	inputFieldLarger: {
		maxWidth: 125,
	},
	avatarButton: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
		backgroundColor: theme.palette.primary.main,
	},
});

class RoutineExerciseLogEditFormPopUp extends PureComponent {
	/**
	 * @param props Containing:
	 *      'exercise': The exercise.
	 *      'log': The routine-exercise log we want to modify. Can be null.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The exercise the log belongs to.
			 */
			exercise: this.props.exercise,
			/**
			 * The routine-exercise log we want to modify.
			 */
			log: this.props.log,
			/**
			 * Is it open?
			 */
			isOpen: props.isOpen,
			/**
			 * Has it ever loaded?
			 */
			hasEverLoaded: false,
			/**
			 * Is it loading?
			 */
			isLoading: false,
			/**
			 * The popup for logging RPE.
			 */
			dropDownRPEAnchorEl: null,
			/**
			 * The RPE value, if applicable (basically when an attribute is 'Reps').
			 */
			RPE: 8,
			/**
			 * The RIR value, if applicable (basically when an attribute is 'Reps').
			 */
			RIR: 2,
			/**
			 * Should we save the RPE/RIR value?  (basically when an attribute is 'Reps').
			 */
			shouldSaveRPE: false,
			/**
			 * The popup for the comment.
			 */
			dropDownCommentAnchorEl: null,
			/**
			 * The comment, if any.
			 */
			comment: '',
		};

		this.timer = null;
		this.updateInputValue = this.updateInputValue.bind(this);
		this.submitWithEnter = this.submitWithEnter.bind(this);
	}

	submitWithEnter(e) {
		if (e.key === 'Enter') {
			this.submit(e);
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen && this.state.isOpen) {
			return; // If it's already open, do nothing. We don't want to update again unnessarily.
		}
		
		let newState = this.getLogValues(nextProps.exercise, nextProps.log);
		newState['log'] = nextProps.log;
		newState['exercise'] = nextProps.exercise;
		newState['isOpen'] = nextProps.isOpen;

		if (nextProps.isOpen) {
			newState['hasEverLoaded'] = true;
		}
		
		this.setState(newState);
	}

	componentDidMount() {
		this.setState(this.getLogValues(this.state.exercise, this.state.log));
	}

	getLogValues = (exercise, log) => {
		if (!log) {
			return {};
		}
		
		let newDefaultValues = {};
		
		let idx = 0;
		for (const attribute of exercise.type.attributes) {
			newDefaultValues[attribute.key] = log.values[idx];
			idx++;
		}

		if (!isNaN(log.rpeValue) && !isNaN(log.rirValue)) {
			newDefaultValues['RPE'] = log.rpeValue;
			newDefaultValues['RIR'] = log.rirValue;
			newDefaultValues['shouldSaveRPE'] = true;
		} else {
			newDefaultValues['RPE'] = 8; // Default value
			newDefaultValues['RIR'] = 2; // Default value
			newDefaultValues['shouldSaveRPE'] = false;
		}

		if (log.comment) {
			newDefaultValues['comment'] = log.comment;
		} else {
			newDefaultValues['comment'] = '';
		}
		
		return newDefaultValues;
	}

	submit = async (event) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		this.setState({
			isLoading: true
		});
		
		let values = [];
		for (const attribute of this.state.exercise.type.attributes) {
			values.push(this.state[attribute.key]);
		}

		let exerciseLogToUpdate = {
			id: this.state.log.id,
			values: values,
			comment: this.state.comment,
		};
		
		if (this.state.shouldSaveRPE) {
			exerciseLogToUpdate['rpeValue'] = this.state.RPE;
			exerciseLogToUpdate['rirValue'] = this.state.RIR;
		}

		await fetch(UrlBuilder.routine.routineExerciseLogsApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(exerciseLogToUpdate)
		})
			.then(() => this.setState({
				isLoading: false,
			}))
			.then(() => this.props.closeSelfFunc(true, "Log successfully updated."))
	}

	canSubmit = () => {
		if (this.state.isLoading || !this.state.log) {
			return false;
		}

		let attributes = this.state.exercise.type.attributes;
		if (attributes.length === 1 && !this.isAcceptableInput(this.state[attributes[0].key])) {
			return false;
		}

		if (attributes.length === 2 && (!this.isAcceptableInput(this.state[attributes[0].key]) || !this.isAcceptableInput(this.state[attributes[1].key]))) {
			return false;
		}


		if (this.state.RPE && (isNaN(this.state.RPE) || this.state.RPE < 1)) {
			return false;
		}

		if (this.state.RIR && (isNaN(this.state.RIR) || this.state.RIR < 0)) {
			return false;
		}
		
		return true;
	}

	updateInputValue(event) {
		if (event.target.name === 'RIR') {
			let RIR = event.target.value;
			let RPE = 10 - RIR;

			this.setState({
				[event.target.name]: RIR,
				RPE: RPE < 1 ? 1 : RPE,
				shouldSaveRPE: true,
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	}

	/**
	 * @param val A string representing a dateTime object.
	 */
	updateTimeAttributeValue = (val) => {
		this.setState({
			time: (HoursMinutesTimeConverter.convertDateStringToFloat(val)),
		});
	}

	isAcceptableInput = (numberString) => {
		let number = Number(numberString);
		return !isNaN(number) && number > 0 ;
	}

	/**
	 * @param attribute
	 * @param isSmall Should it increase/decrease 'little' or a lot?
	 * @param isDecrease Is it a decrease? Otherwise it's an increase.
	 */
	incdecAttributeValue = (attribute, isSmall, isDecrease) => {
		let val;
		if (isSmall) {
			if (attribute.key === ExerciseTypeAttribute.Time) {
				val = 0.0166666666666666667; // one second in scale of 1-100.
			} else {
				val = 0.5;
			}
		} else {
			val = 1;
		}

		if (isDecrease) {
			val *= -1.0;
		}

		if (this.state[attribute.key] + val < 0) {
			this.stopTimer(); // Just for safety
			return;
		}

		let number = (this.isAcceptableInput(this.state[attribute.key]) ? Number(this.state[attribute.key]) : 0) + val;
		// We don't round time because we need it to be as precise as possible.
		number = attribute.key === ExerciseTypeAttribute.Time ? number : Math.round(number * 100) / 100;

		if (attribute.key === 'RIR') { // Business rule: When the attribute is RIR, we want to also set RPE.
			let RPE = 10 - number;
			this.setState({
				[attribute.key]: number,
				RPE: RPE < 1 ? 1 : RPE,
				shouldSaveRPE: true, // Set this to true if they tried to change it.
			});
		} else {
			this.setState({
				[attribute.key]: number
			});
		}

		// Makes the function be executed after waiting 200ms.
		this.timer = setTimeout(() => this.incdecAttributeValue(attribute, isSmall, isDecrease), 200); // Store the timer. We will destroy when mouse is up.
	}

	stopTimer = () => {
		// To prevent the setTimeout function from running.
		clearTimeout(this.timer);
	}

	openDropDownRPEAnchorEl = (event) => {
		this.setState({
			dropDownRPEAnchorEl: event.currentTarget
		});
	};

	closeDropDownRPEAnchorEl = () => {
		this.setState({
			dropDownRPEAnchorEl: null
		});
	};

	openDropDownCommentAnchorEl = (event) => {
		this.setState({
			dropDownCommentAnchorEl: event.currentTarget
		});
	};

	closeDropDownCommentAnchorEl = () => {
		this.setState({
			dropDownCommentAnchorEl: null
		});
	};

	updateRPE = (e, newValue) => {
		let RIR = 10 - newValue;

		this.setState({
			RPE: newValue,
			RIR: RIR < 1 ? 0 : RIR,
			shouldSaveRPE: true, // Set this to true if they tried to change it.
		})
	}

	setShouldSaveRPE = (e) => {
		this.setState({
			shouldSaveRPE: e.target.checked,
		});
	}

	renderRPEMenu = () => {
		const {classes} = this.props;

		let attribute = { // We fake it so it works.
			key: 'RIR'
		};

		return <>
			<IconButton size='small' onClick={this.openDropDownRPEAnchorEl}>
				<AssessmentIcon/>
			</IconButton>

			<Popover
				open={this.state.dropDownRPEAnchorEl !== null}
				anchorEl={this.state.dropDownRPEAnchorEl}
				onClose={this.closeDropDownRPEAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Card>
					<CardHeader subheader={<Typography variant='h6'>Log RPE/RIR</Typography>}/>
					<Divider light/>
					<CardContent>

						<Typography variant='caption' color='textSecondary'>
							Rated Perceived Exertion
						</Typography>
						<Slider
							value={this.state.RPE}
							onChange={this.updateRPE}
							step={1}
							marks
							min={1}
							max={10}
							valueLabelDisplay="on"
						/>

						<Divider light/>

						<Grid container spacing={1} justifyContent='center' alignItems="center">
							<Grid item>
								<IconButton size='small'
								            onPointerDown={() => this.incdecAttributeValue(attribute, false, true)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								            disabled={this.state[attribute.key] < 1}
								>
									<Avatar className={classes.avatarButton}>
										<RemoveIcon/>
									</Avatar>
								</IconButton>
							</Grid>
							<Grid item>
								<TextField
									className={classes.inputFieldLarger}
									variant='outlined'
									margin="normal"
									type='number'
									inputMode='decimal'
									id={attribute.key}
									label={'Reps in Reserve'}
									name={attribute.key}
									value={this.state[attribute.key]}
									onChange={this.updateInputValue}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item>
								<IconButton size='small'
								            onPointerDown={() => this.incdecAttributeValue(attribute, false, false)}
								            onPointerUp={this.stopTimer}
								            onPointerLeave={this.stopTimer}
								>
									<Avatar className={classes.avatarButton}>
										<AddIcon />
									</Avatar>
								</IconButton>
							</Grid>
						</Grid>
					</CardContent>

					<Grid container justifyContent='flex-end' alignItems="flex-end">
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.shouldSaveRPE}
									onChange={this.setShouldSaveRPE}
									color="secondary"
								/>
							}
							label={<Typography variant='button'>Save with log</Typography>}
						/>
					</Grid>

					<br/>

				</Card>
			</Popover>
		</>;
	}

	renderCommentPopup = () => {
		return <>
			<IconButton size='small' onClick={this.openDropDownCommentAnchorEl}>
				<MessageIcon/>
			</IconButton>

			<Popover
				open={this.state.dropDownCommentAnchorEl !== null}
				anchorEl={this.state.dropDownCommentAnchorEl}
				onClose={this.closeDropDownCommentAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Card>
					<CardContent>
						<TextField
							label="Add a Comment"
							multiline
							rows={4}
							variant="outlined"
							fullWidth
							name="comment"
							value={this.state.comment}
							onChange={this.updateInputValue}
						/>
					</CardContent>
				</Card>
			</Popover>
		</>
	}

	renderMenuButtons = () => {
		return (
            <Grid container spacing={1} justifyContent='center' alignItems="center">
                <Grid item>
                    {this.renderCommentPopup()}
                </Grid>

	            {this.state.exercise.type.attributes.find(attr => attr.key === ExerciseTypeAttribute.Reps) ?
		            <Grid item>
			            {this.renderRPEMenu()}
		            </Grid> : null
	            }
            </Grid>
        );
	}

	render() {
		if (!this.state.hasEverLoaded) {
			return <React.Fragment/>
		}
		
		const {classes} = this.props;

		return (
			<Dialog
				className={classes.modal}
				open={this.state.isOpen}
				onClose={() => this.props.closeSelfFunc(false)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				TransitionComponent={Transition}
			>
				<AppBar style={{position: 'relative'}} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap>
							{this.state.log ?
								<>Edit log created on {(new Date(this.state.log.dateCreated)).toDateString()}</>
								:
								<>Edit log</>
							}
						</Typography>
					</Toolbar>
				</AppBar>

				<DialogContent dividers>
					<form className={classes.form} noValidate>
						{this.state.log ?
							this.state.exercise.type.attributes.map(attribute =>
								<Grid key={attribute.key} container spacing={1} justifyContent='center' alignItems="center">
									<Grid item>
										{attribute.key !== ExerciseTypeAttribute.Reps ?
											<IconButton size='small' color='inherit'
											            onPointerDown={() => this.incdecAttributeValue(attribute, true, true)}
											            onPointerUp={this.stopTimer}
											            onPointerLeave={this.stopTimer}
											            disabled={this.state[attribute.key] < 0.05}
											>
												<RemoveIcon/>
											</IconButton> : null
										}
										<IconButton size='small'
										            onPointerDown={() => this.incdecAttributeValue(attribute, false, true)}
										            onPointerUp={this.stopTimer}
										            onPointerLeave={this.stopTimer}
										            disabled={this.state[attribute.key] < 1}
										>
											<Avatar className={classes.avatarButton}>
												<RemoveIcon/>
											</Avatar>
										</IconButton>
									</Grid>
									<Grid item>
										{attribute.key === ExerciseTypeAttribute.Time ?
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePicker
													key={attribute.key}
													ampm={false}
													openTo="minutes"
													views={["hours", "minutes", "seconds"]}
													inputFormat="HH:mm:ss"
													label={attribute.name}
													value={HoursMinutesTimeConverter.convertFloatToDateTime(this.state[attribute.key])}
													onChange={this.updateTimeAttributeValue}
													renderInput={(params) => <TextField {...params}
													                                    className={classes.inputField}/>}
												/>
											</LocalizationProvider>
											:
											<TextField
												key={attribute.key}
												className={classes.inputField}
												variant='outlined'
												margin="normal"
												type='number'
												inputMode='decimal'
												required
												id={attribute.key}
												label={attribute.name}
												name={attribute.key}
												value={this.state[attribute.key]}
												onChange={this.updateInputValue}
												onKeyPress={this.submitWithEnter}
												InputLabelProps={{shrink: true}}
												InputProps={{
													endAdornment: <InputAdornment
														position="end">{UnitPrettifier.forAttribute(attribute)}</InputAdornment>
												}}
											/>
										}
									</Grid>
									<Grid item>
										<IconButton size='small'
										            onPointerDown={() => this.incdecAttributeValue(attribute, false, false)}
										            onPointerUp={this.stopTimer}
										            onPointerLeave={this.stopTimer}
										>
											<Avatar className={classes.avatarButton}>
												<AddIcon/>
											</Avatar>
										</IconButton>
										{attribute.key !== ExerciseTypeAttribute.Reps ?
											<IconButton size='small' color='inherit'
											            onPointerDown={() => this.incdecAttributeValue(attribute, true, false)}
											            onPointerUp={this.stopTimer}
											            onPointerLeave={this.stopTimer}
											>
												<AddIcon/>
											</IconButton> : null
										}
									</Grid>
								</Grid>
							)
							: null
						}
					</form>
					{this.renderMenuButtons()}
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color='grey'
					        onClick={() => this.props.closeSelfFunc(false)}>Close</Button>
					<Button variant="contained" color='primary' onClick={this.submit} disabled={!this.canSubmit()}>
						{this.state.isLoading ?
							<>
								<CircularProgress size="1rem" color="inherit"/> Loading...
							</> :
							'Save'
						}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(useStyles)(RoutineExerciseLogEditFormPopUp);