import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {AttributeValuesPrettier} from "../../../core/prettifier/AttributeValuesPrettier";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import moment from "moment";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import {Button, TextField} from "@mui/material";
import {HoursMinutesTimeConverter} from "../../../core/prettifier/HoursMinutesTimeConverter";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider, TimePicker} from "@mui/lab";
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import Popover from "@mui/material/Popover";
import MessageIcon from "@mui/icons-material/Message";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Badge from "@mui/material/Badge";
import {ExerciseTypeAttribute} from "../../../core/models/ExerciseTypeAttribute";

const useStyles = theme => ({
	root: {
		width: '100%',
		padding: 0
	},
	logComment: {
		padding: theme.spacing(2),
	},
});

class WorkoutPlanCard extends PureComponent {
	/**
	 * @param props Containing:
	 *      'exercise': The routine-exercise to view the workout plan for.
	 *      'updateSwipeableViewsHeightFunc': Update parent height of view.
	 */
	constructor(props) {
		super(props);
		this.state = {
			exercise: props.exercise,
			plannedLogs: UserLocalStorage.get(UrlBuilder.routine.routineExercisePlannedLogsApi(props.exercise.routineExerciseId)) ?? null,
			editablePlannedLogId: null,
			isNewPlannedLogBeingAdded: false,
			isLoading: false,
			/**
			 * A log comment to show in the dropDownCommentAnchorEl, if any.
			 */
			logComment: null,
			/**
			 * Anchor-EL for comment box.
			 */
			dropDownCommentAnchorEl: null,
			/**
			 * The ID of the log whose comment we want to edit..
			 */
			logCommentId: null,
		};
	}

	componentDidMount() {
		this.fetch();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.updateSwipeableViewsHeightFunc();
	}

	enableNewPlannedLogBeingAdded = () => {
		let state = {
			isNewPlannedLogBeingAdded: true,
			editablePlannedLogId: null
		};

		for (const attribute of this.state.exercise.type.attributes) {
			state[attribute.key] = 0;
		}
		
		this.setState(state);
	}
	
	setEditablePlannedLog = (e, plannedLog) => {
		e.stopPropagation();
		
		let state = {
			editablePlannedLogId: plannedLog.id,
			isNewPlannedLogBeingAdded: false,
		};
		
		let idx = 0;
		for (const attribute of this.state.exercise.type.attributes) {
			state[attribute.key] = plannedLog.values[idx];
			idx++;
		}
		
		this.setState(state);
	}

	fetch = async () => {
		this.setState({
			isLoading: true
		});

		let response = await fetch(UrlBuilder.routine.routineExercisePlannedLogsApi(this.state.exercise.routineExerciseId));
		response = await response.json();
		const plannedLogs = response['data'];
		
		this.setState({
			plannedLogs: plannedLogs,
			isLoading: false
		});

		UserLocalStorage.set(UrlBuilder.routine.routineExercisePlannedLogsApi(this.state.exercise.routineExerciseId), plannedLogs ?? []);
	}

	isAcceptableInput = (numberString) => {
		let number = Number(numberString);
		return !isNaN(number) && number >= 0 ;
	}

	submitWithEnter = (e) => {
		if (e.key === 'Enter' && this.canSubmit()) {
			if (this.state.editablePlannedLogId) {
				const plannedLog = this.state.plannedLogs.find(log => log.id === this.state.editablePlannedLogId);
				if (plannedLog) {
					this.submitEditedPlannedLog();
				}
			} else {
				this.submitNewPlannedLog();
			}
		}
	}
	
	canSubmit = () => {
		for (const attribute of this.state.exercise.type.attributes) {
			if (!this.isAcceptableInput(this.state[attribute.key])) {
				return false;
			}
		}
		
		return true;
	}
	
	submitNewPlannedLog = async () => {
		this.setState({
			isLoading: true
		});

		let values = [];
		for (const attribute of this.state.exercise.type.attributes) {
			values.push(this.state[attribute.key]);
		}

		let plannedLogToSave = {
			routineExerciseId: this.state.exercise.routineExerciseId,
			dateCreated: moment().format('L, h:mm:ss A'),
			comment: this.state.logComment,
			values: values,
		};
		
		await fetch(UrlBuilder.routine.routineExercisePlannedLogsApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(plannedLogToSave)
		});

		this.setState({
			isNewPlannedLogBeingAdded: false,
			logComment: null,
		});
		
		await this.fetch();
	}
	
	submitEditedPlannedLog = async () => {
		this.setState({
			isLoading: true
		});

		let values = [];
		for (const attribute of this.state.exercise.type.attributes) {
			values.push(this.state[attribute.key]);
		}

		let plannedLogToSave = {
			id: this.state.editablePlannedLogId,
			values: values,
		};
		
		await fetch(UrlBuilder.routine.routineExercisePlannedLogsApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(plannedLogToSave)
		});

		await this.fetch();

		this.setState({
			editablePlannedLogId: null,
		});
	}
	
	deletePlannedLog = async () => {
		this.setState({
			isLoading: true
		});

		await fetch(UrlBuilder.routine.routineExercisePlannedLogsApi(this.state.editablePlannedLogId), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});

		this.setState({
			editablePlannedLogId: null,
		});

		await this.fetch();
	}
	
	/**
	 * @param val A string representing a dateTime object.
	 */
	updateTimeAttributeValue = (val) => {
		this.setState({
			time: (HoursMinutesTimeConverter.convertDateStringToFloat(val)),
		});
	}

	updateStateInputValues = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}
	
	renderInputField = (attribute) => {
		if(attribute.key === ExerciseTypeAttribute.Time) {
			return <LocalizationProvider dateAdapter={AdapterDateFns}>
				<TimePicker
					ampm={false}
					openTo="minutes"
					views={["hours", "minutes", "seconds"]}
					inputFormat="HH:mm:ss"
					value={HoursMinutesTimeConverter.convertFloatToDateTime(this.state[attribute.key])}
					onChange={(val) => this.updateTimeAttributeValue(val)}
					renderInput={(params) => <TextField {...params} size='small' sx={{maxWidth: 91}} />}
				/>
			</LocalizationProvider>
		}
		
		return <TextField
			variant='outlined'
			margin="dense"
			type='number'
			onKeyPress={this.submitWithEnter}
			inputMode='decimal'
			required
			name={attribute.key}
			value={this.state[attribute.key]}
			onChange={this.updateStateInputValues}
			style={{maxWidth: 60, marginTop: 0, marginBottom: 0}}
			size='small'
		/>
	}

	setSelectedLog = (plannedLog) => {
		if (this.state.editablePlannedLogId === plannedLog.id) {
			return;
		}
		
		this.props.setSelectedLogParentFunc(plannedLog);
	}

	openDropDownCommentAnchorEl = (event, logComment = null, logId = null) => {
		event.stopPropagation();
		this.setState({
			dropDownCommentAnchorEl: event.currentTarget,
			logComment: logComment ?? null,
			logCommentId: logId ?? null,
		});
	};

	closeDropDownCommentAnchorEl = async () => {
		let log = this.state.plannedLogs?.find(log => log.id === this.state.logCommentId);
		
		// If the planned log already exists...
		if (log) {
			// and the comment changed -> update it.
			if (this.state.logComment != log?.comment) {
				this.setState({
					isLoading: true,
				})
				
				let plannedLogToSave = {
					Id: log.id,
					comment: this.state.logComment,
				};

				await fetch(UrlBuilder.routine.routineExercisePlannedLogsApi(), {
					method: 'PUT',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(plannedLogToSave)
				});

				await this.fetch();
			}

			// Then, close the popup clearing the log comment related states.
			
			this.setState({
				dropDownCommentAnchorEl: null,
				logComment: null,
				logCommentId: null,
			});
			
			return;
		}
		
		// Otherwise we're looking at a new log being created. Then we do not clear the logComment state.

		this.setState({
			dropDownCommentAnchorEl: null,
			logCommentId: null,
		});
	};

	updateLogComment = (event) => {
		this.setState({
			logComment: event.target.value,
		});
	}

	render() {
		const {classes} = this.props;

		return (
			<>
			<Popover
				open={this.state.dropDownCommentAnchorEl !== null}
				anchorEl={this.state.dropDownCommentAnchorEl}
				onClose={this.closeDropDownCommentAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Card>
					<CardContent>
						<TextField
							label="Add a comment"
							multiline
							rows={4}
							variant="outlined"
							fullWidth
							value={this.state.logComment}
							onChange={this.updateLogComment}
						/>
					</CardContent>
				</Card>
			</Popover>
			
            <TableContainer className={classes.root}>
	            <Fade in={this.state.isLoading}>
		            <LinearProgress />
	            </Fade>
	            
                <Table stickyHeader size="small">
                    {/*The top row (the head)*/}
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Set</TableCell>
                            {this.state.exercise.type.attributes.map(attribute =>
                                <TableCell key={"h_attr_" + attribute.key} align="center">{attribute.name}</TableCell>
                            )}
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.state.plannedLogs ? this.state.plannedLogs.map((plannedLog, idx) =>
                            <TableRow key={"logs_" + plannedLog.id} hover onClick={() => this.setSelectedLog(plannedLog)}>
                                <TableCell component="th" scope="row" align="left">
                                    <Typography variant='caption'>
                                        &nbsp;&nbsp;<strong>{idx + 1}</strong>
                                    </Typography>
                                </TableCell>

	                            {this.state.exercise.type.attributes.map((attribute, idx) => 
                                    <TableCell key={"attr_" + attribute.key} align="center" padding='none'>
                                        {this.state.editablePlannedLogId === plannedLog.id ?
                                            this.renderInputField(attribute)
                                            :
                                            <Typography variant={"body2"}>
                                                {AttributeValuesPrettier.prettifyLogValueForAttribute(attribute.key, plannedLog.values[idx])}
                                            </Typography>
                                        }
                                    </TableCell>
                                )}

                                <TableCell align="right">
                                    {this.state.editablePlannedLogId === plannedLog.id ?
                                        <div style={{ minWidth: 60}}>
                                            <IconButton onClick={this.submitEditedPlannedLog} size="small" disabled={!this.canSubmit()}>
                                                <SaveIcon />
                                            </IconButton>

	                                        <IconButton size='small' onClick={(e) => this.openDropDownCommentAnchorEl(e, plannedLog.comment, plannedLog.id)}>
		                                        {plannedLog.comment ?
			                                        <Badge color='secondary' variant='dot'>
				                                        <MessageIcon/>
			                                        </Badge>
			                                        :
			                                        <MessageIcon/>
		                                        }
	                                        </IconButton>

	                                        <IconButton onClick={(e) => this.deletePlannedLog(plannedLog)} size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                        :
                                        <div style={{ minWidth: 60}}>
                                            <IconButton onClick={(e) => this.setEditablePlannedLog(e, plannedLog)} size="small">
                                                <EditIcon />
                                            </IconButton>

	                                        <IconButton size='small' onClick={(e) => this.openDropDownCommentAnchorEl(e, plannedLog.comment, plannedLog.id)}>
		                                        {plannedLog.comment ?
			                                        <Badge color='secondary' variant='dot'>
				                                        <MessageIcon/>
			                                        </Badge>
			                                        :
			                                        <MessageIcon/>
		                                        }
	                                        </IconButton>
	                                        
                                            <IconButton size="small">
                                                <QueuePlayNextIcon />
                                            </IconButton>
                                        </div>
                                    }
                                    
                                </TableCell>
                            </TableRow>
                        ) : null}
                        
                        {this.state.isNewPlannedLogBeingAdded &&
                            <TableRow key='new_log'>
                                <TableCell component="th" scope="row" align="left"/>
        
                                {this.state.exercise.type.attributes.map(attribute =>
                                    <TableCell key={"new_attr_" + attribute.key} align="center" padding='none'>
                                        {this.renderInputField(attribute)}
                                    </TableCell>
                                )}
        
                                <TableCell align="right">
	                                <div style={{ minWidth: 60}}>
		                                <IconButton size='small' onClick={(e) => this.openDropDownCommentAnchorEl(e, this.state.logComment ?? null, null)}>
			                                <MessageIcon/>
		                                </IconButton>
		                                
	                                    <IconButton size='small' onClick={this.submitNewPlannedLog} disabled={!this.canSubmit()}>
	                                        <SaveIcon />
	                                    </IconButton>
	                                </div>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                <>
                    <Box paddingTop={0.5}/>
                    
                    <Grid container justifyContent="center">
                        {!this.state.isNewPlannedLogBeingAdded &&
                            <Button onClick={this.enableNewPlannedLogBeingAdded}>
                                Add New Set
                            </Button>
                        }
                    </Grid>

                    <Box paddingTop={0.5}/>
                </>
            </TableContainer>
			</>
        );
	}
}

export default withStyles(useStyles)(WorkoutPlanCard);