import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {UserLocalStorage} from "../../../core/storage/UserLocalStorage";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {Avatar} from "@mui/material";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Zoom from "@mui/material/Zoom";
import Skeleton from '@mui/material/Skeleton';
import {UserContext} from "../../../core/UserContext";
import {Link as RouterLink} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import {withRouter} from "../../../hooks/withRouter";
import Slide from "@mui/material/Slide";

const useStyles = theme => ({
	speedDialWrapper: {
		margin: 0,
		top: 'auto',
		right: 35,
		bottom: 80,
		left: 'auto',
		position: 'fixed',
	},
	largeIcon: {
		width: 100,
		height: 100,
	},
	appBar: {
		position: 'relative',
		minWidth: 275,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class HomeFabButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			routines: UserLocalStorage.get(UrlBuilder.routine.routinesApi(null, true)) ?? null,
			isLoading: true,
		};
	}

	hasNeverBeenFetched = () => {
		return this.state.routines === null;
	}
	
	fetch = async () => {
		this.setState({
			isLoading: true
		});

		const response = await fetch(UrlBuilder.routine.routinesApi(null, true));
		const routines = await response.json();
		this.setState({
			routines: routines,
			isLoading: false
		});

		UserLocalStorage.set(UrlBuilder.routine.routinesApi(null, true), routines ?? []);
	}

	startWorkout = async (event, routine) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let workout = {
			dateTimeStarted: moment().format('L, h:mm:ss A')
		};

		let res = await fetch(UrlBuilder.workout.startWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		}).then(res => res.json());

		this.context.setUser(res.data);

		this.props.navigate(UrlBuilder.routine.routineDetailPage(routine.id));
	}
	
	renderLoadingDialog = () => {
		return (
            <List>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
				<ListItem button>
					<Skeleton variant="circular" width={40} height={40} /> &nbsp;
					<Skeleton width={165} height={20}/>
				</ListItem>
			</List>
        );
	}
	
	renderNoRoutinesDialog = () => {
		const {classes} = this.props;

		return <>
            <Box paddingTop={2}/>

            <Grid container justifyContent="center">
                <Link component={RouterLink} to={UrlBuilder.routine.routineOverviewPage(true)} color="inherit">
                    <IconButton
                        touch
                        tooltip="See routines"
                        onClick={this.toggleOpenAddRoutinePopup}
                        size="large">
                        <AddToPhotosIcon className={classes.largeIcon}/>
                    </IconButton>
                </Link>
            </Grid>

            <Box paddingTop={1}/>

            <Grid container justifyContent="center">
                <Typography variant="overline" gutterBottom>
                    You have no routines
                </Typography>
            </Grid>

            <Grid container justifyContent="center">
                <Link component={RouterLink} to={UrlBuilder.routine.routineOverviewPage(true)} color="inherit">
                    <Typography variant="overline">
                        <strong>Create A Routine</strong>
                    </Typography>
                </Link>
            </Grid>

            <Box paddingTop={3}/>
        </>;
	}
	
	renderRoutines = () => {
		return <List>
			{this.state.routines.map((routine) => (
				<ListItem button onClick={(e) => this.startWorkout(e, routine)} key={routine.id}>
					<ListItemAvatar>
						<Avatar style={{backgroundColor: routine.color ?? ColorPicker.pick(routine.name)}}>
							{routine.name.charAt(0).toUpperCase()}
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={routine.name} secondary={routine.dateLastLogged ? DatePrettifier.prettifyLastTrained(new Date(routine.dateLastLogged)) : ''}/>
				</ListItem>
			))}
		</List>;
	}

	renderPopUp = () => {
		const {classes} = this.props;

		if (!this.state.isRoutinesPopUpOpen) {
			return null;
		}

		let content;
		if (this.hasNeverBeenFetched()) {
			content = this.renderLoadingDialog();
		} else if (this.state.routines.length === 0) {
			content = this.renderNoRoutinesDialog();
		} else {
			content = this.renderRoutines();
		}
		
		return (
			<Dialog onClose={this.closeRoutinesPopUp} open={true} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap>
							Start a Workout
						</Typography>
					</Toolbar>
				</AppBar>
				
				{content}
			</Dialog>
		);
	}

	openRoutinesPopUp = () => {
		this.setState({
			isRoutinesPopUpOpen: true,
		});
		this.fetch();
	}

	closeRoutinesPopUp = () => {
		this.setState({
			isRoutinesPopUpOpen: false,
		});
	}
	
	render() {
		const {classes} = this.props;

		if (this.context.user && this.context.user.activeWorkout) {
			return null;
		}

		return <>
			<Zoom in={true} unmountOnExit>
				<Fab color="primary" className={classes.speedDialWrapper} onClick={this.openRoutinesPopUp}>
					<PlayArrowIcon />
				</Fab>
			</Zoom>
			{this.renderPopUp()}
		</>;
	}
}

HomeFabButton.contextType = UserContext;

export default withStyles(useStyles)(withRouter(HomeFabButton));