import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import WorkoutSmallNote from "./partials/WorkoutSmallNote";
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import Skeleton from '@mui/material/Skeleton';
import WorkoutCompleteEditForm from "./partials/WorkoutCompleteEditForm";
import {withRouter} from "../../hooks/withRouter";
import {createStyles} from "@mui/styles";
import {ButtonGroup} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WorkoutLogsSection from "./partials/WorkoutLogsSection";

const useStyles = theme => (
createStyles({
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 100,
	},
	grow: {
		flexGrow: 1,
	},
	nested: {
		paddingLeft: theme.spacing(5),
	},
	media: {
		height: 125,
	},
	container: {
		backgroundColor: theme.palette.background.default,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * {@see App} This class is strongly tied to it. The app class decides this bloop's visibility.
 */
class WorkoutCompleteBloop extends PureComponent {
	/**
	 * @param props Containing:
	 *      'setUser': Updates the user -> needed to finish the workout.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The workout to show. None initially. We fetch it later.
			 */
			workout: null,
			/**
			 * All the exercises in the workout.
			 */
			allExercises: [],
			/**
			 * Whether the modal is open. False initially. We never want the bloop to open automatically.
			 */
			isOpen: false,
			/**
			 * Whether this bloop is considered to be loading (i.e. the workout is being fetched). Initially true.
			 */
			isLoading: true,
			/**
			 * Is the workout currently being submitted? (i.e. has the submit button been pressed?)
			 */
			isSubmissionLoading: false,
			/**
			 * The workout comment.
			 */
			workoutComment: null,
			/**
			 * The popup for a log comment.
			 */
			dropDownCommentAnchorEl: null,
			/**
			 * Is it open?
			 */
			isConfirmationDialogOpen: false,
			/**
			 * The data stored by the child component. Used so that we don't lose the state when it's remounted.
			 */
			workoutsData: {},
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.state.isOpen !== nextProps.isOpen) {
			if (nextProps.isOpen) {
				this.openSelf();
			} else {
				this.setState({
					isOpen: false,
				});
			}
		}
	}
	
	openSelf = async () => {
		this.setState({
			isLoading: true,
			isOpen: true,
			isConfirmationDialogOpen: false, // reset
		});

		const response = await fetch(
			UrlBuilder.workout.currentWorkoutApi()
		).then(res => res.json());

		let workout = response.data;
		
		this.setState({
			workout: workout,
			workoutComment: workout.comment,
			allExercises: this.getAllExercisesFromRoutine(workout),
			isLoading: false,
		});
	}

	getAllExercisesFromRoutine(workout) {
		let exercises = [];
		workout?.routines.forEach(routine => {
			routine.exercises.forEach(exercise => {
				exercises.push(exercise);
			})
		});
		return exercises;
	}
	
	handleFinishWorkoutButton = async (e) => {
		if (this.state.workout?.routines?.length > 0) { // If there is anything to submit, then open the dialog.
			this.openConfirmationDialog(true);
		} else {
			await this.finishWorkout(e);
		}
	}

	finishWorkout = async (event) => {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});

		let workout = {
			dateTimeEnded: moment().format('L, h:mm:ss A'),
			comment: this.state.workoutComment,
		};
		
		// This will just cancel the workout.
		await fetch(UrlBuilder.workout.finishWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		})
			.then(response => response.json())
			.then(response => {
				this.setState({
					isSubmissionLoading: false,
				});

				let user = response.data;
				
				this.props.setUser(user);

				if (this.state.workout.routines.length > 0) { // Only redirect if you had some exercises to show.. (backend logic duplication)
					this.props.navigate(UrlBuilder.workout.workoutDetailPage(this.state.workout.id, true));
				}
			})
	}

	saveWorkoutNotes = async (event) => {
		event.preventDefault();

		let workout = {
			comment: this.state.workoutComment ?? event.target.value,
		};

		await fetch(UrlBuilder.workout.currentWorkoutApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		})
	}

	setWorkoutCommentState = (event) => {
		this.setState({
			workoutComment: event.target.value ?? '',
		});
	}
	
	openConfirmationDialog = (isOpen) => {
		this.setState({
			isConfirmationDialogOpen: isOpen,
		});
	}
	
	saveCachedWorkoutsData = (data) => {
		this.setState({
			workoutsData: data,
		})
	}

	renderBody() {
		const numberOfExercisesDone = this.state.allExercises.length;

		let workoutDuration, timeRange, title;

		if (this.state.workout?.isPastWorkout) {
			title = 'Started at'
			let dateTimeMoment = moment(this.state.workout.dateTimeStarted);
			workoutDuration = dateTimeMoment.format('h:mm a');
			timeRange = dateTimeMoment.format('DD/MM/YYYY')
		} else {
			const dateTimeStarted = moment(this.state.workout.dateTimeStarted);
			const dateTimeEnded = moment(this.state.workout.dateTimeEnded);
			let workoutDurationObj = moment.duration(dateTimeEnded.diff(dateTimeStarted));
			
			title = 'Duration';
			workoutDuration = moment.utc(workoutDurationObj.asMilliseconds()).format('HH:mm');
			timeRange = dateTimeStarted.format('LT') + " - " + dateTimeEnded.format('LT');
		}

		return <>
			<WorkoutCompleteEditForm
				workout={this.state.workout}
				isOpen={this.state.isConfirmationDialogOpen}
				setUser={this.props.setUser}
				closeSelfFunc={() => this.openConfirmationDialog(false)}
			/>

			<Grid container spacing={1}>
				<Grid item xs={4} >
					<WorkoutSmallNote title="Exercises" description={numberOfExercisesDone} smallDetail={"Total"}/>
				</Grid>

				<Grid item xs={4}>
					<WorkoutSmallNote title="Volume" description={this.state.workout.totalVolume} smallDetail={"Total"}/>
				</Grid>

				<Grid item xs={4} >
					<WorkoutSmallNote title={title} description={workoutDuration} smallDetail={timeRange}/>
				</Grid>
			</Grid>

			<br/>
			
			<Typography variant="subtitle2">
				<strong>Time started:</strong> {moment(this.state.workout.dateTimeStarted).fromNow() + ' - ' + moment(this.state.workout.dateTimeStarted).calendar()}
			</Typography>

			<Box paddingTop={3}/>

			<TextField
				id="workout-notes"
				label="Notes"
				multiline
				fullWidth
				rows={2}
				value={this.state.workoutComment}
				variant="outlined"
				onBlur={this.saveWorkoutNotes}
				onChange={this.setWorkoutCommentState}
			/>

			<Box paddingTop={2.5}/>
			<Divider light/>
			<Box paddingTop={2.5}/>

			<WorkoutLogsSection 
				workout={this.state.workout}
				shouldUpdate={!this.state.isLoading}
				cachedWorkoutsData={this.state.workoutsData}
				saveCachedWorkoutsData={this.saveCachedWorkoutsData}
			/>
		</>;
	}

	hasNeverBeenFetched = () => {
		return this.state.workout === null;
	}

	renderLoadingSkeletons = () => {
		const {classes} = this.props;

		return <>
			<Grid container spacing={1}>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
				<Grid item xs={4} >
					<Skeleton animation="pulse" variant="rectangular" className={classes.media} />
				</Grid>
			</Grid>

			<br/>

			<Skeleton animation="wave" variant="text" />

			<br/>

			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
			<br/>
			<Skeleton animation="wave" variant="rectangular" className={classes.media} />
		</>;
	}
	
	render() {
		const {classes} = this.props;

		return (
            <Dialog fullScreen open={this.state.isOpen} onClose={this.props.closeSelfFunc} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} enableColorOnDark>
					<Toolbar variant={"dense"}>
						<Typography variant="h6" noWrap className={classes.title}>
							{this.state.workout?.isPastWorkout ? 'Logging Past Workout' : 'Active Workout'}
						</Typography>

						<div className={classes.grow}/>

						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.props.closeSelfFunc}
                            aria-label="close"
                            size="large">
							<CloseIcon/>
						</IconButton>
					</Toolbar>
					<Fade in={this.state.isLoading}>
						<LinearProgress />
					</Fade>
				</AppBar>

				<DialogContent dividers className={classes.container}>
					<Container disableGutters>
						{this.hasNeverBeenFetched() ?
							this.renderLoadingSkeletons()
							: this.renderBody()
						}
					</Container>
				</DialogContent>

				<DialogActions>
					<Button variant="contained" color='grey' className={classes.button} onClick={this.props.closeSelfFunc}>Close</Button>

					{this.state.workout?.isPastWorkout ?
						<Button
							onClick={this.handleFinishWorkoutButton}
							disabled={!this.state.workout || this.state.isLoading || this.state.isSubmissionLoading}
							color='primary'
							variant="contained"
							className={classes.button}
							endIcon={this.state.workout?.routines?.length > 0 ? <MoreVertIcon/> : null}
						>
							{this.state.workout?.routines?.length > 0 ? 'Complete Workout' : 'Quit Workout'}
						</Button>
						:
						<ButtonGroup variant="contained" color="primary">
							<Button
								onClick={this.finishWorkout}
								disabled={!this.state.workout || this.state.isLoading || this.state.isSubmissionLoading}
							>
								{this.state.isSubmissionLoading && !this.state.isConfirmationDialogOpen ?
									<><CircularProgress size="1rem" color="inherit" />&nbsp;Loading... </>
									:
									<>{this.state.workout?.routines?.length > 0 ? 'Complete Workout' : 'Quit Workout'}</>
								}
							</Button>
							{this.state.workout?.routines?.length > 0 &&
							<Button
								onClick={() => this.openConfirmationDialog(true)}
								disabled={this.state.isLoading || this.state.isSubmissionLoading}
							>
								<MoreVertIcon fontSize='small'/>
							</Button>
							}
						</ButtonGroup>
					}
					
				</DialogActions>
			</Dialog>
        );
	}
}
	
export default withStyles(useStyles)(withRouter(WorkoutCompleteBloop));