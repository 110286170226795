import React, {PureComponent} from "react";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import TableContainer from "@mui/material/TableContainer";
import DeleteIcon from '@mui/icons-material/Delete';
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import TextField from "@mui/material/TextField";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const useStyles = theme => ({
	root: {
		width: '100%',
		padding: 0
	},
});

class MeasurementLogsTable extends PureComponent {

	/**
	 * @param props Containing:
	 *      'exercise': The exercise you want to add an exercise-log for.
	 *      'isLoading': Is component loading?
	 *      'refreshParentFunc': The function to call to refresh the parent.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The measurement whose logs we want to show.
			 */
			measurement: this.props.measurement,
			/**
			 * Indicates which 'edit log' popup is being shown.
			 */
			logInEditableMode: null,
			/**
			 * The dropdown menu for each exercise.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * Indicates whether it's loading.
			 */
			isLoading: false,
			/**
			 * Is a submission being loaded?
			 */
			isSubmissionLoading: false,

			/**
			 * The value of the 'new' log row.
			 */
			value: this.props.measurement && this.props.measurement.logs[0] ? this.props.measurement.logs[0].value : 0,
			/**
			 * The dateCreated value of the 'new' log row.
			 */
			dateCreated: new Date(),
			
			/** 
			 * Only not null if logInEditableMode is not null. This is the edited value of a log if it's in edit mode.
			 */
			editedLogValue: null,
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			measurement: nextProps.measurement,
			isLoading: nextProps.isLoading
		});
	}
	
	submitNewLog = async (event) => {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});

		let measurementLogToSave = {
			MeasurementId: this.state.measurement.id,
			Value: this.state.value,
			DateCreated: moment(this.state.dateCreated).format('L, h:mm:ss A'),
		};

		await fetch(UrlBuilder.measurements.measurementLogsApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(measurementLogToSave)
		}).then(() => this.props.refreshParentFunc('New log created.'));

		this.setState({
			isSubmissionLoading: false,
			dateCreated: new Date(), // Reset timer (this is so that the same time is not used if another log is submitted).
		});
	}

	submitLogEdit = async (event) => {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});

		let measurementLogToUpdate = {
			Id: this.state.logInEditableMode.id,
			Value: this.state.editedLogValue,
		};

		await fetch(UrlBuilder.measurements.measurementLogsApi(), {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(measurementLogToUpdate)
		}).then(() => this.props.refreshParentFunc('Log updated.'));

		this.setState({
			isSubmissionLoading: false,
			editedLogValue: null,
			logInEditableMode: null,
		});
	}

	deleteMeasurementLog = async (event, log) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.
		event.stopPropagation();

		await fetch(UrlBuilder.measurements.measurementLogsApi(log.id), {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
			.then(() => this.props.refreshParentFunc("Log successfully deleted."));
	}

	openDropdownMenu = (event) => {
		event.preventDefault();
		event.stopPropagation();
		
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget
		});
	};

	closeDropdownMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();
		
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};

	toggleEditableLog(e, log) {
		e.preventDefault();
		e.stopPropagation();
		
		this.setState({
			logInEditableMode: log,
			editedLogValue: log.value,
			dropDownMenuAnchorEl: null
		});
	}

	isToday = (someDateString) => {
		const someDate = new Date(someDateString);
		const today = new Date();
		return (someDate.toDateString() === today.toDateString());
	}

	setSelectedLog = (log) => {
		this.setState({
			value: log.value,
			dateCreated: log.dateCreated,
		});
	}

	handleDateChange = (date) => {
		this.setState({
			dateCreated: date
		});
	};

	updateStateInputValues = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	submitWithEnter = (e) => {
		if (e.key === 'Enter') {
			this.submitNewLog(e);
		}
	}

	submitEditWithEnter = (e) => {
		if (e.key === 'Enter') {
			this.submitLogEdit(e);
		}
	}

	render() {
		const {classes} = this.props;
		
		return (
			<div className={classes.root}>
				<Typography variant="h5" component="h2">
					Log History: {this.state.isLoading ? <CircularProgress size="1rem" color="inherit" /> : null}
				</Typography>

				<br />
				
				<TableContainer component={Paper} className={classes.root}>
					<Table stickyHeader size="small">
						{/*The top row (the head)*/}
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell align="left">Value</TableCell>
								<TableCell align="left"/>
							</TableRow>
						</TableHead>

						<TableBody>
							{/*Create new entry row*/}
							<TableRow >
								<TableCell component="th" scope="row" align="left">
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											inputFormat="dd/MM/yyyy"
											id="dateCreated"
											name="dateCreated"
											disableFuture
											value={this.state.dateCreated}
											onChange={this.handleDateChange}
											renderInput={(params) => <TextField {...params} size='small' sx={{maxWidth: 150}} />}
										/>
									</LocalizationProvider>
								</TableCell>

								<TableCell align="left" padding='none'>
									<TextField
										variant='outlined'
										margin="dense"
										type='number'
										inputMode='decimal'
										required
										id='value'
										name={'value'}
										value={this.state.value}
										onChange={this.updateStateInputValues}
										onKeyPress={this.submitWithEnter}
										InputLabelProps={{ shrink: true }}
										style={{maxWidth: 80, marginTop: 0, marginBottom: 0}}
										size='small'
									/>
								</TableCell>

								<TableCell align="right">
									<IconButton size={'small'} onClick={this.submitNewLog} disabled={this.state.isSubmissionLoading}>
										<SaveIcon/>
									</IconButton>
								</TableCell>
							</TableRow>
							
							{/*The logs in the measurement*/}
							{this.state.measurement?.logs.map((log, idx) =>
								<TableRow key={"logs_" + log.id} hover onClick={() => this.setSelectedLog(log)}>
									<TableCell component="th" scope="row" align="left">
										<Typography variant='caption'>
											{this.isToday(log.dateCreated) ?
												<strong>{(new Date(log.dateCreated)).toDateString()}</strong>
												: (new Date(log.dateCreated)).toDateString()
											}
										</Typography>
									</TableCell>

									<TableCell align="left" padding='none'>
										{this.state.logInEditableMode && log.id === this.state.logInEditableMode.id ?
											<TextField
												variant='outlined'
												margin="dense"
												type='number'
												inputMode='decimal'
												required
												id='editedLogValue'
												name='editedLogValue'
												value={this.state.editedLogValue}
												onClick={(e) => e.stopPropagation()}
												onChange={this.updateStateInputValues}
												autoFocus
												onKeyPress={this.submitEditWithEnter}
												InputLabelProps={{ shrink: true }}
												style={{maxWidth: 80, marginTop: 0, marginBottom: 0}}
												size='small'
											/>
											:
											<Typography variant={"body2"}>
												{log.value}
											</Typography>
										}
										
									</TableCell>

									<TableCell align="right">
										{this.state.logInEditableMode && log.id === this.state.logInEditableMode.id ?
											<IconButton size={'small'} onClick={this.submitLogEdit} disabled={this.state.isSubmissionLoading}>
												<SaveIcon/>
											</IconButton>
											:
											<>
												<IconButton id={log.id} onClick={this.openDropdownMenu} size="small">
													<MoreVertIcon/>
												</IconButton>
												<Menu
													anchorEl={this.state.dropDownMenuAnchorEl}
													open={Boolean(this.state.dropDownMenuAnchorEl && this.state.dropDownMenuAnchorEl.id == log.id)}
													onClose={this.closeDropdownMenu}
													TransitionComponent={Fade}
												>
													<MenuItem onClick={(e) => this.toggleEditableLog(e, log)}>
														<ListItemIcon>
															<EditIcon fontSize="small"/>
														</ListItemIcon>
														<ListItemText primary="Edit"/>
													</MenuItem>
													<MenuItem onClick={e => this.deleteMeasurementLog(e, log)}>
														<ListItemIcon>
															<DeleteIcon fontSize="small"/>
														</ListItemIcon>
														<ListItemText primary="Delete"/>
													</MenuItem>
												</Menu>
											</>
										}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}

export default withStyles(useStyles)(MeasurementLogsTable);