import React from "react";
import withStyles from '@mui/styles/withStyles';
import Badge from "@mui/material/Badge";

const styles = theme => ({
	customBadge: {
		backgroundColor: props => props.color,
		color: "rgb(238,238,238)",
	}
});

function SimpleBadge(props) {
	const { classes, children } = props;
	return (
        <Badge
			classes={{ badge: classes.customBadge }}
			badgeContent={props.icon}
			overlap="circular"
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			{children}
		</Badge>
    );
}
const SmallBadge = withStyles(styles)(SimpleBadge);

export default SmallBadge;
