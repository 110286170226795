import React, {PureComponent} from 'react';
import {UserContext} from "../../core/UserContext";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import CircularProgress from "@mui/material/CircularProgress";
import {Regexer} from "../../core/auth/Regexer";
import {PageDataContext} from "../../core/PageDataContext";
import {Divider, IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {withRouter} from "../../hooks/withRouter";
import {Utils} from "../../core/util/Utils";
import {Link as RouterLink} from "react-router-dom";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";

const useStyles = theme => ({
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#2d3b4a',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
});

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			{'My Work in Progress '}
			{new Date().getFullYear()}
			{'.'}
			<br/>
			<Link component={RouterLink} to={UrlBuilder.user.privacyPage()}>
				Privacy Policy
			</Link>
		</Typography>
	);
}

class Signin extends PureComponent {

	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Used as a component 'isLoading' indicator.
			 */
			isLoading: false,
			/**
			 * Indicates whether someone tried to sign in with a user that was not found.
			 */
			isUserNotFound: false,
			/**
			 * Indicates whether someone tried to sign in with a user that exists but whose password is wrong.
			 */
			isPasswordIncorrect: false,
			/**
			 * Do we show the password field?
			 */
			showPassword: false,
			isSigningUpAsGuest: false,
		};

		this.updateInputValue = this.updateInputValue.bind(this);
		this.submit = this.submit.bind(this);
	}
	
	componentDidMount() {
		this.context.setPageData({
			title: "Sign In to My Work in Progress"
		});
	}

	updateInputValue(event) {
		this.setState({
			[event.target.name]: event.target.value,
			isUserNotFound: false,
			isPasswordIncorrect: false,
		});
	}

	redirectToGoogleAuth = () => {
		window.location.href = '/api/users/google-signin';
	}

	redirectToAppleAuth = () => {
		window.location = '/api/users/apple-signin';
	}
	
	async submit(event, setUser) {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		this.setState({
			isLoading: true
		});

		let signinUser = {
			email: this.state.email,
			password: this.state.password,
			keepSignedIn: this.state.rememberMe
		};

		let response = await fetch('/api/users/signin', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(signinUser)
		});

		let jsonResponse = await response.json();
		
		if (response.status === 200) {
			setUser(jsonResponse['data']);
			this.props.navigate('/');
		} else if (jsonResponse.message === "user_not_found") {
			this.setState({
				isUserNotFound: true,
				isPasswordIncorrect: false,
				isLoading: false,
			})
		} else if (jsonResponse.message === "incorrect_password") {
			this.setState({
				isPasswordIncorrect: true,
				isUserNotFound: false,
				isLoading: false,
			})
		} else {
			this.setState({
				isLoading: false,
			})
		}
	}

	showPassword = () => {
		this.setState({
			showPassword: !this.state.showPassword,
		});
	}

	signUpAsGuest = async (event, setUser) => {
		event.preventDefault(); // Prevents it from reloading the page and adding the params to the URL.

		this.setState({
			isSigningUpAsGuest: true
		});

		let guestUser = {
			dateCreated: moment().format('L, h:mm:ss A'),
		};

		let response = await fetch(UrlBuilder.user.SignUpAsGuestApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(guestUser)
		});

		let jsonResponse = await response.json();

		if (response.status === 200) {
			setUser(jsonResponse['data']);
			this.props.navigate('/');
		}

		this.setState({
			isSigningUpAsGuest: false,
		})
	}

	render() {
		const {classes} = this.props;

		let isEmailInvalid = false;
		let emailFieldHelperText = null;
		if (this.state.email && !Regexer.testEmail(this.state.email)) {
			isEmailInvalid = true;
			emailFieldHelperText = 'Please enter a valid email'
		} else if (this.state.isUserNotFound) {
			isEmailInvalid = true;
			emailFieldHelperText = 'Email not found'
		}

		let isPasswordInvalid = false;
		let passwordFieldHelperText = null;
		if (this.state.isPasswordIncorrect) {
			isPasswordInvalid = true;
			passwordFieldHelperText = 'Password is incorrect'
		}

		let isSubmitDisabled = !this.state.email || !Regexer.testEmail(this.state.email) || !this.state.password;
		
		return (
            <Container component="main" maxWidth="xs">
				<CssBaseline/>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon/>
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<form className={classes.form} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							onChange={this.updateInputValue}
							error={isEmailInvalid}
							helperText={emailFieldHelperText}
						/>
						<TextField
							InputProps={{
								endAdornment:
									<InputAdornment position="end">
										<IconButton onClick={this.showPassword} edge="end" size="large">
											{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
							}}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type={this.state.showPassword ? "text" : "password"}
							id="password"
							autoComplete="current-password"
							onChange={this.updateInputValue}
							error={isPasswordInvalid}
							helperText={passwordFieldHelperText}
						/>
						<UserContext.Consumer>
							{({user, setUser}) => (
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={e => this.submit(e, setUser)}
									disabled={isSubmitDisabled}
								>
									{
										this.state.isLoading ?
											<div>
												<CircularProgress size="1rem" color="inherit" /> Sign in
											</div> :
											<div>
												Sign in
											</div>
									}
								</Button>
							)}
						</UserContext.Consumer>

						<Box paddingTop={0.5}/>
						<Divider />

						<Grid container direction='column' display="flex" justifyContent="center" alignItems="center" spacing={1} sx={{ marginTop: 3 }}>
							<Grid item>
								<Button sx={{width: 285, height: 45}} variant='outlined'
								        onClick={this.redirectToGoogleAuth} size='medium'
								        startIcon={<GoogleIcon fontSize='large'/>}>
									Continue with Google
								</Button>
							</Grid>
							<Grid item>
								<Button sx={{width: 285, height: 45}} variant='outlined' onClick={this.redirectToAppleAuth} size='medium' startIcon={<AppleIcon fontSize='large'/>}>
									Continue with Apple
								</Button>
							</Grid>
							
							<Grid item>
								<UserContext.Consumer>
									{({user, setUser}) => (
										<Button
											type="submit"
											startIcon={this.state.isSigningUpAsGuest ? <CircularProgress size="1rem" color="inherit" /> : <PersonIcon/>}
											variant="outlined"
											size='medium'
											sx={{ width: 285, height: 45 }}
											color="primary"
											onClick={e => this.signUpAsGuest(e, setUser)}
											disabled={this.state.isSigningUpAsGuest}
										>
											Continue as Guest
										</Button>
									)}
								</UserContext.Consumer>
							</Grid>
						</Grid>
					</form>
				</div>
	            
				<Box mt={8}>
					<Copyright/>
				</Box>
			</Container>
        );
	}
}

Signin.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(Signin));