import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import {UrlBuilder} from "../../core/url/UrlBuilder";
import Button from "@mui/material/Button";
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from '@mui/icons-material/Info';
import { green, red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from "@mui/material/CircularProgress";
import {UserContext} from "../../core/UserContext";
import {Dialog, ListItemIcon, ListItemText, Menu, MenuItem, Popover} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {LocalizationProvider, StaticDateTimePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

const useStyles = theme => ({
	cardStart: {
		backgroundColor: theme.palette.secondary.startWorkoutColor,
	},
	start: {
		backgroundColor: green[500],
	},
	cardStop: {
		backgroundColor: theme.palette.secondary.stopWorkoutColor,
	},
	stop: {
		backgroundColor: red[500],
	},
	grow: {
		flexGrow: 1,
	},
	popOverText: {
		padding: theme.spacing(2),
	},
});

class WorkoutCallToAction extends PureComponent {

	/**
	 * No params needed. This uses the User context to get the workout stuff.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Indicates whether this component is in a loading state.
			 */
			isLoading: false,
			/**
			 * The dropdown menu.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * The anchorEl for the startDate overwrite dialog.
			 */
			dropDownStartDateOverwriteAnchorEl: null,
			/**
			 * The start date overwrite.
			 */
			startDateOverwrite: null,
			/**
			 * The anchorEl for the tooltip.
			 */
			startDateOverwriteTooltipAnchorEl: null,
		};
	}
	
	startWorkout = async (event, startDateOverwrite = null) => {
		event.preventDefault();

		this.setState({
			isLoading: true,
		});

		let workout = {
			dateTimeStarted: startDateOverwrite ? moment(startDateOverwrite).format('L, h:mm:ss A') : moment().format('L, h:mm:ss A'),
			isPastWorkout: !!startDateOverwrite, // If there a start date overwrite, then it is a past workout.
		};

		let res = await fetch(UrlBuilder.workout.startWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		}).then(res => res.json());
		
		this.context.setUser(res.data);
		
		this.setState({
			workout: res.data,
			isLoading: false,
			dropDownStartDateOverwriteAnchorEl: null,
		});
	}
	
	finishWorkout = async () => {
		this.setState({
			isLoading: true,
		});

		const response = await fetch(
			UrlBuilder.workout.currentWorkoutApi()
		).then(res => res.json());
		const fetchedWorkout = response.data;
		
		// If there is something to finish, then open bloop and let that take care of it, otherwise just 'cancel' it.
		if (fetchedWorkout && fetchedWorkout.routines.length > 0) {
			this.setState({
				isLoading: false,
			});
			this.context.openActiveWorkoutBloop(true);
			return;
		}
		
		let workout = {
			dateTimeEnded: moment().format('L, h:mm:ss A')
		};
		
		const res = await fetch(UrlBuilder.workout.finishWorkoutApi(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(workout)
		}).then(res => res.json());

		this.context.setUser(res['data']);

		this.setState({
			isLoading: false,
		});
	}

	openDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget,
		});
	};

	closeDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};

	openDropDownStartDateOverwriteAnchorEl = (event) => {
		this.setState({
			dropDownStartDateOverwriteAnchorEl: event.currentTarget,
			dropDownMenuAnchorEl: null,
		});
	};

	closeDropDownStartDateOverwriteAnchorEl = () => {
		this.setState({
			dropDownStartDateOverwriteAnchorEl: null
		});
	};

	openStartDateOverwriteTooltip = (event) => {
		this.setState({
			startDateOverwriteTooltipAnchorEl: event?.currentTarget ?? null,
		});
	}

	setStartDateOverwrite = (dateString) => {
		this.setState({
			startDateOverwrite: dateString
		});
	}
	
	renderStartPastWorkoutDialog = () => {
		const {classes} = this.props;

		return <Dialog
			open={this.state.dropDownStartDateOverwriteAnchorEl !== null}
			anchorEl={this.state.dropDownStartDateOverwriteAnchorEl}
			onClose={this.closeDropDownStartDateOverwriteAnchorEl}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<AppBar style={{ position: 'relative'}} enableColorOnDark>
				<Toolbar variant='dense'>
					<Typography variant="h6" noWrap className={classes.title}>
						Select a Workout Start Date
					</Typography>

					<div className={classes.grow}/>

					<IconButton size='small' onClick={this.openStartDateOverwriteTooltip}>
						<InfoIcon/>
					</IconButton>
					<Popover
						open={this.state.startDateOverwriteTooltipAnchorEl !== null}
						anchorEl={this.state.startDateOverwriteTooltipAnchorEl}
						onClose={() => this.openStartDateOverwriteTooltip(null)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Typography className={classes.popOverText}>You can start a workout with a date and time from the past. This can be used to log previous workouts you did not get to log in real-time.</Typography>
					</Popover>
				</Toolbar>
			</AppBar>
			<Card>
				<CardContent>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
					<StaticDateTimePicker
							label='Pick a Date and Time'
							disableFuture
							value={this.state.startDateOverwrite ? new Date(this.state.startDateOverwrite) : new Date()}
							onChange={this.setStartDateOverwrite}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</CardContent>
				<Grid container alignItems='center' justify="center" direction="column" style={{marginBottom: 14}}>
					<Grid xs item>
						<Button 
							variant="contained" 
							color='primary' 
							disabled={this.state.startDateOverwrite ? moment(this.state.startDateOverwrite).isAfter(moment()) : false} 
							onClick={(e) => this.startWorkout(e, this.state.startDateOverwrite)}
						>
							Start Workout
						</Button>
					</Grid>
				</Grid>
			</Card>
		</Dialog>;
	}

	render() {
		const {classes} = this.props;
		
		let activeWorkout = this.context.user?.activeWorkout;

		if (!activeWorkout) {
			return (
                <Card className={classes.cardStart}>
                    <CardHeader
                        avatar={
                            <Avatar className={classes.start} >
                                {this.state.isLoading ? 
                                    <CircularProgress color="inherit" /> 
                                    :
                                    <IconButton
                                        color="inherit"
                                        onClick={this.startWorkout}
                                        disabled={this.state.isLoading}
                                        component="span"
                                        size="large">
                                        <PlayArrowIcon/>
                                    </IconButton>
                                }
                            </Avatar>
                        }
                        title={<Button type="button" onClick={this.startWorkout} disabled={this.state.isLoading}>Start Workout</Button>}
                        action={
	                        <span>
							<IconButton
								size="small"
								onClick={this.openDropdownMenu}
							>
								<MoreVertIcon/>
							</IconButton>
							<Menu
								anchorEl={this.state.dropDownMenuAnchorEl}
								open={Boolean(this.state.dropDownMenuAnchorEl)}
								onClose={this.closeDropdownMenu}
								TransitionComponent={Fade}
							>
								<MenuItem>
									<ListItemIcon>
										<HistoryToggleOffIcon fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Start Past Workout" onClick={this.openDropDownStartDateOverwriteAnchorEl}/>
								</MenuItem>
							</Menu>
		                        
	                        {this.renderStartPastWorkoutDialog()}
						</span>
                        }
                    />
                </Card>
            );
		}

		const subheader = <>&nbsp; {"⌚ Started " + moment(activeWorkout.dateTimeStarted).fromNow()}</>;
		
		return <>
            <Card className={classes.cardStop}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.stop} >
                            {this.state.isLoading ? 
                                <CircularProgress color="inherit"/> 
                                :
                                <IconButton
                                    color="inherit"
                                    component="span"
                                    onClick={this.finishWorkout}
                                    size="large">
                                    <StopIcon/>
                                </IconButton>
                            }
                        </Avatar>
                    }
                    title={<Button type="button" onClick={this.finishWorkout} disabled={this.state.isLoading}>Finish Workout</Button>}
                    subheader={subheader}
                    action={
                        <IconButton
                            size="small"
                            onClick={() => this.context.openActiveWorkoutBloop(true)} // We assume that the workout is active here, thats why we call this func.
                            disabled={this.state.isLoading}
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    }
                />
            </Card>
        </>;
	}
}

WorkoutCallToAction.contextType = UserContext;

export default withStyles(useStyles)(WorkoutCallToAction);