import React from 'react';
import {useNavigate, useParams} from "react-router";
import {useLocation} from "react-router-dom";

/**
 * https://github.com/remix-run/react-router/issues/7156
 */
export const withRouter = (Component) => {
	return (props) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		return (
			<Component
				navigate={navigate}
				location={location}
				params={params}
				{...props}
			/>
		);
	};
};