import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../../core/PageDataContext";
import {withRouter} from "../../../hooks/withRouter";
import {Container} from "@mui/material";

const useStyles = theme => ({});

class PrivacyPolicyPage extends PureComponent {

	/**
	 * @param props No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.context.setPageData({
			title: "Privacy Policy",
		});
	}

	render() {
		const {classes} = this.props;

		return <Container>
			<h2>My Work in Progress Privacy Policy</h2>
			This app, My Work in Progress, is a workout tracking app provided as a free service. This Service is provided by
			My Work in Progress at no cost and is intended for use as
			is.
		<p>
			This page is used to inform visitors regarding my
			policies with the collection, use, and disclosure of Personal
			Information if anyone decided to use my Service.
		</p> <p>
			If you choose to use my Service, then you agree to
			the collection and use of information in relation to this
			policy. The Personal Information that I collect is
			used for providing and improving the Service. I will not use or share your information with
			anyone except as described in this Privacy Policy.
		</p> 
			<p><strong>Information Collection and Use</strong></p> <p>
			For a better experience, while using our Service, I
			may require you to provide us with certain personally
			identifiable information, including but not limited to email and your name/nickname.
			The email is used with the sole purpose to create an account for you on the web, so that you may access your
			workout data on the internet with said account, on any device through <a href='https://myworkinprogress.app'>https://myworkinprogress.app</a>. Your email
			does not require verification.
		</p>
			<p><strong>Log Data</strong></p> <p>
			I want to inform you that whenever you
			use my Service, in a case of an error in the app
			I collect data and information (through third-party
			products) on my server called Log Data. This Log Data may
			include information such as your device Internet Protocol
			(“IP”) address, device name, operating system version, the
			configuration of the app when utilizing my Service,
			the time and date of your use of the Service, and other
			statistics.
		</p> <p><strong>Cookies</strong></p> <p>
			Cookies are files with a small amount of data that are
			commonly used as anonymous unique identifiers. These are sent
			to your browser from the websites that you visit and are
			stored on your device's internal memory.
		</p> <p>
			This Service requires the use of “cookies”. They are required to keep you signed in
			and provide a personalized experience. If you
			choose to refuse our cookies, you may not be able to use the service.
		</p>
			<p><strong>Security</strong></p> <p>
			I value your trust in providing us your
			Personal Information, thus we are striving to use commercially
			acceptable means of protecting it. But remember that no method
			of transmission over the internet, or method of electronic
			storage is 100% secure and reliable, and I cannot
			guarantee its absolute security.
		</p>
			<p><strong>Changes to This Privacy Policy</strong></p> <p>
			I may update our Privacy Policy from
			time to time. Thus, you are advised to review this page
			periodically for any changes. I will
			notify you of any changes by posting the new Privacy Policy on
			this page.
		</p> <p><strong>Contact Us</strong></p> <p>
			If you have any questions or suggestions about my
			Privacy Policy, do not hesitate to contact me at myworkinprogress.app@gmail.com.
		</p>
		<br/>
		</Container>
	}
}

PrivacyPolicyPage.contextType = PageDataContext;

export default withStyles(useStyles)(withRouter(PrivacyPolicyPage));