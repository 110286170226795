import React, {PureComponent} from "react";
import withStyles from '@mui/styles/withStyles';
import {PageDataContext} from "../../../core/PageDataContext";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {AttributeValuesPrettier} from "../../../core/prettifier/AttributeValuesPrettier";
import IconButton from "@mui/material/IconButton";
import MessageIcon from "@mui/icons-material/Message";
import moment from "moment";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LineGraph from "../../graphs/LineGraph";
import {WorkoutVolumeParser} from "../../../core/parser/WorkoutVolumeParser";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import Popover from "@mui/material/Popover";
import SortIcon from "@mui/icons-material/Sort";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {Doughnut} from "react-chartjs-2";
import {ExerciseType} from "../../../core/models/ExerciseType";
import {ExerciseTypeAttribute} from "../../../core/models/ExerciseTypeAttribute";
import Button from "@mui/material/Button";

const useStyles = theme => ({
	logComment: {
		padding: theme.spacing(2),
	},
	floatRight: {
		float: 'right',
	},
	button: {
		minWidth: '30px',
		width: '30px',
		height: '30px',
		padding: '0',
	},
});

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
	};
}

class WorkoutLogsSection extends PureComponent {
	/**
	 * No params needed.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The workout in mind.
			 */
			workout: props.workout,
			/**
			 * Whether the graphs are being fetched.
			 */
			isGraphsLoading: false,
			/**
			 * Fetch from.. when?
			 */
			dateRangeFetched: '3_months',
			/**
			 * The workouts done per routine.
			 */
			routineWorkouts: props.cachedWorkoutsData ?? {},
			/**
			 * The graph menu.
			 */
			graphsMenuAnchorEl: null,
			/**
			 * A log comment to show in the dropDownCommentAnchorEl, if any.
			 */
			logComment: null,
			/**
			 * The selected tab index. By default it is 0 (the logs).
			 */
			selectedTabIndex: 0,
		};
	}
	
	componentDidMount() {
		if (this.props.shouldUpdate === undefined || this.props.shouldUpdate) {
			this.fetchWorkoutData();
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (!nextProps.shouldUpdate || this.state.workout === nextProps.workout) {
			return;
		}
		
		this.setState({
			workout: nextProps.workout,
		});

		this.fetchWorkoutData(null, nextProps.workout);
	}
	
	componentWillUnmount() {
		if ('saveCachedWorkoutsData' in this.props) {
			// We only save this if we unmount so we don't lose the state.
			this.props.saveCachedWorkoutsData(this.state.routineWorkouts);
		}
	}

	selectTabIndex = (e, idx) => {
		this.setState({
			selectedTabIndex: idx,
		});
	}

	openGraphMenu = (event) => {
		this.setState({
			graphsMenuAnchorEl: event.currentTarget
		});
	}

	closeGraphMenu = () => {
		this.setState({
			graphsMenuAnchorEl: null
		});
	}

	openDropDownCommentAnchorEl = (event, comment) => {
		event.stopPropagation();
		this.setState({
			dropDownCommentAnchorEl: event.currentTarget,
			logComment: comment,
		});
	};

	closeDropDownCommentAnchorEl = () => {
		this.setState({
			dropDownCommentAnchorEl: null,
			logComment: null,
		});
	};

	/**
	 * Fetch workout-data from start to end. Start is a shortName (3_months, 6_months, etc). End should be a real datetime string, or null, in which case we fallback to the state-workout.
	 */
	fetchWorkoutData = async(start = null, workout = null) => {
		workout = workout ?? this.state.workout;

		if (workout?.routines.length === 0) {
			return;
		}

		let end = workout.dateTimeStarted;

		let datetime = (moment(end)).subtract(3, 'months');
		let dateRangeFetched = '3_months';
		if (start) {
			switch (start) {
				case '1_month':
					datetime = (moment()).subtract(1, 'month');
					dateRangeFetched = '1_month';
					break;
				case '3_months':
					datetime = (moment()).subtract(3, 'months');
					dateRangeFetched = '3_months';
					break;
				case '6_months':
					datetime = (moment()).subtract(6, 'months');
					dateRangeFetched = '6_months';
					break;
				case '1_year':
					datetime = (moment()).subtract(1, 'year');
					dateRangeFetched = '1_year';
					break;
				case 'all_time':
					datetime = null;
					dateRangeFetched = 'all_time';
			}
		}

		this.setState({
			isGraphsLoading: true,
			dateRangeFetched: dateRangeFetched,
			graphsMenuAnchorEl: null,
		});

		let existingRoutineWorkouts = {};
		for (const routine of workout.routines) {
			const routineWorkoutsResponse = await fetch(
				UrlBuilder.workout.workoutsForRoutineApi(
					routine.id, 
					datetime ? datetime.format('L') : null,
					workout.dateTimeStarted
				)
			).then(res => res.json());

			const routineWorkouts = routineWorkoutsResponse.data;

			existingRoutineWorkouts[routine.id] = {
				name: routine.name,
				workouts: routineWorkouts,
			};

			this.setState({
				routineWorkouts: existingRoutineWorkouts,
			});
			this.forceUpdate();
		}

		this.setState({
			isGraphsLoading: false,
		});
	}
	
	renderVolumeGraphs = () => {
		const {classes} = this.props;

		return (
			<>
				<Button variant='contained' size='small' onClick={this.openGraphMenu} className={`${classes.floatRight} ${classes.button}`}>
					<SortIcon />
				</Button>
				{this.state.isGraphsLoading ?
					<IconButton size='small' disabled className={classes.floatRight}>
						<CircularProgress color="primary" size={21} />
					</IconButton>
					: null
				}
				<Menu
					anchorEl={this.state.graphsMenuAnchorEl}
					open={Boolean(this.state.graphsMenuAnchorEl !== null)}
					onClose={this.closeGraphMenu}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<MenuItem disabled dense><Typography variant='subtitle2' gutterBottom> Filter graphs by:</Typography></MenuItem>
					<MenuItem dense onClick={() => this.fetchWorkoutData('1_month')} selected={this.state.dateRangeFetched === '1_month'}><Typography variant='body2' gutterBottom> 1 month </Typography></MenuItem>
					<MenuItem dense onClick={() => this.fetchWorkoutData('3_months')} selected={this.state.dateRangeFetched === '3_months'}><Typography variant='body2' gutterBottom> 3 months </Typography></MenuItem>
					<MenuItem dense onClick={() => this.fetchWorkoutData('6_months')} selected={this.state.dateRangeFetched === '6_months'}><Typography variant='body2' gutterBottom> 6 months </Typography></MenuItem>
					<MenuItem dense onClick={() => this.fetchWorkoutData('1_year')} selected={this.state.dateRangeFetched === '1_year'}><Typography variant='body2' gutterBottom> 1 year </Typography></MenuItem>
					<MenuItem dense onClick={() => this.fetchWorkoutData('all_time')} selected={this.state.dateRangeFetched === 'all_time'}><Typography variant='body2' gutterBottom> All time </Typography></MenuItem>
				</Menu>

				<Box paddingTop={2.5}/>

				{Object.keys(this.state.routineWorkouts).map((routineId) =>
					<React.Fragment key={routineId}>
						<Typography variant="h5" gutterBottom>
							{this.state.routineWorkouts[routineId]?.name}:
						</Typography>

						<LineGraph data={WorkoutVolumeParser.parse(this.state.routineWorkouts[routineId]?.workouts)}/>

						<br/>
					</React.Fragment>
				)}
			</>
		)
	}
	
	renderMuscleBreakdown = () => {
		let musclesWorkByExerciseType = {};

		this.state.workout?.routines.forEach(routine => {
			routine.exercises.forEach(exercise => {
				exercise.muscles.forEach(exerciseMuscle => {
					let totalWorkForExercise = 0;

					for (const log of exercise.logs) {
						switch (exercise.type.id) {
							case ExerciseType.WeightAndReps:
							case ExerciseType.DistanceAndTime:
							case ExerciseType.WeightAndTime:
								totalWorkForExercise += log.multiplied * exerciseMuscle.percentage / 100; // Out of the total volume, we only set a given percentage for it.
								break;
							case ExerciseType.Reps:
							case ExerciseType.Time:
								totalWorkForExercise += log.values[0] * exerciseMuscle.percentage / 100; // Out of the total volume, we only set a given percentage for it.
								break;
						}
					}

					// Needed to initialize the exercise type object cuz JS is dumb.
					musclesWorkByExerciseType[exercise.type.name] = musclesWorkByExerciseType[exercise.type.name] ?? {};
					
					musclesWorkByExerciseType[exercise.type.name][exerciseMuscle.muscleId] = {
						name: exerciseMuscle.muscle.name,
						workTotal: totalWorkForExercise + 
							(
								musclesWorkByExerciseType[exercise.type.name] && musclesWorkByExerciseType[exercise.type.name][exerciseMuscle.muscleId]
								? musclesWorkByExerciseType[exercise.type.name][exerciseMuscle.muscleId].workTotal
								: 0
							),
					};
				})
			})
		});

		if (Object.keys(musclesWorkByExerciseType).length === 0) {
			return <Typography variant='subtitle2' align='center'>
					No muscles have been configured for the exercises in this workout. Do this through the exercises page.
			</Typography>
		}

		let volumeChartDataByExerciseType = {};

		Object.keys(musclesWorkByExerciseType).forEach(exerciseTypeName => {
			let labels = [];
			let backgroundColors = [];
			let totalWorkPerMuscle = [];

			let muscles = musclesWorkByExerciseType[exerciseTypeName];
			Object.keys(muscles).forEach(muscleId => {
				let muscle = muscles[muscleId];

				labels.push(muscle.name);
				backgroundColors.push(ColorPicker.pick(muscle.name + '_ab'));

				totalWorkPerMuscle.push(muscle.workTotal);
			});
			
			let totalWork = totalWorkPerMuscle.reduce((a,b) => a + b, 0);

			volumeChartDataByExerciseType[exerciseTypeName] =  {
				labels: labels,
				datasets: [
					{
						label: 'Work per Muscle',
						data: totalWorkPerMuscle.map(muscleWork => Math.round(muscleWork / totalWork * 1000) / 10),
						backgroundColor: backgroundColors,
						borderWidth: 0,
						borderColor: '#aeaeae'
					},
				],
			};
		});

		let exerciseTypes = Object.keys(volumeChartDataByExerciseType);
		
		return <>
			<Typography variant="h5">
				Muscle Work Breakdown
			</Typography>

			<br/>
			
			{exerciseTypes.map(exerciseTypeName =>
				<React.Fragment key={exerciseTypeName}>
					{exerciseTypes.length > 1 &&
						<Typography variant="subtitle2" sx={{ fontSize: 18 }} gutterBottom>
							{exerciseTypeName}-based exercises
						</Typography>
					}
					
					<Doughnut 
						data={volumeChartDataByExerciseType[exerciseTypeName]}
						options={{
							legend: {
								position: 'bottom',
							},
						}}
					/>
					<br/>
				</React.Fragment>
			)}
		</>;
	}

	render() {
		const {classes} = this.props;

		return this.state.workout.routines.length > 0 ?
			<>
				{this.state.logComment && this.state.dropDownCommentAnchorEl ?
					<Popover
						open={this.state.dropDownCommentAnchorEl !== null}
						anchorEl={this.state.dropDownCommentAnchorEl}
						onClose={this.closeDropDownCommentAnchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Typography variant='body2' className={classes.logComment}>
							{this.state.logComment}
						</Typography>
					</Popover> : null
				}
				
				{this.state.workout.routines.map(routine =>
					<React.Fragment key={routine.id}>
						<Typography variant="h5">
							{routine.name}
						</Typography>

						<br/>

						{routine.exercises.map(exercise => {
							const key = routine.id + '_' + exercise.id;

							return (
								<React.Fragment key={key}>
									<ListItem>
										<ListItemIcon>
											<FitnessCenterIcon/>
										</ListItemIcon>
										<ListItemText primary={exercise.name}/>
									</ListItem>

									<List dense>
										{exercise.logs.map((log, idx) =>
											<ListItem key={log.id} className={classes.nested}>
												<ListItemIcon>
													<Typography variant="subtitle2">
														{idx + 1}
													</Typography>
												</ListItemIcon>
												<ListItemText
													primary={
														<Typography variant={"body2"}>
															{AttributeValuesPrettier.prettifyAttributeValues(exercise.type, log)}
															{
																exercise.type.attributes.find(attr => attr.key === ExerciseTypeAttribute.Reps) && !isNaN(log.rpeValue) && !isNaN(log.rirValue) ?
																	<Typography variant='caption'>
																		&nbsp; @{log.rpeValue},{log.rirValue}
																	</Typography> : null
															}
															{log.comment ?
																<IconButton size='small' onClick={(e) => this.openDropDownCommentAnchorEl(e, log.comment)} className={classes.floatRight}>
																	<MessageIcon/>
																</IconButton> : null
															}
														</Typography>
													}
												/>
												<ListItemIcon>
													<Typography variant="subtitle2">
														{moment(log.dateCreated).format('HH:mm')}
													</Typography>
												</ListItemIcon>
											</ListItem>
										)}
									</List>
								</React.Fragment>
							);
						})}
					</React.Fragment>
				)}

				<Box paddingTop={2.5}/>
				<Divider light/>

				<Toolbar variant={"dense"} disableGutters>
					<Tabs
						value={this.state.selectedTabIndex}
						onChange={this.selectTabIndex}
						indicatorColor="secondary"
						textColor="inherit"
						variant="fullWidth"
						visibleScrollbar
						style={{flexGrow: 1}}
					>
						<Tab label="Volume" {...a11yProps(0)} />
						<Tab label="Muscles" {...a11yProps(1)} />
					</Tabs>
				</Toolbar>

				<Box paddingTop={2.5}/>

				{this.state.selectedTabIndex === 0 && this.renderVolumeGraphs()}
				{this.state.selectedTabIndex === 1 && this.renderMuscleBreakdown()}
			</>
			:
			<>No logs yet for this workout. Start by logging exercises.</>
	}
}

WorkoutLogsSection.contextType = PageDataContext;

export default withStyles(useStyles)(WorkoutLogsSection);