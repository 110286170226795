import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogContent, Grid} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import {ExerciseType} from "../../../core/models/ExerciseType";

const useStyles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
	},
});

class ExampleExerciseNewOrEditFormPopUp extends PureComponent {

	/**
	 * @param props Containing:
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The example-exercise to edit, if any. If this is given, the category is ignored.
			 */
			exercise: props.exercise,
			/**
			 * The category to use to add the exercise (i.e. which category does this example-exercise belong to?).
			 */
			category: props.category,
			/**
			 * The name of the new example exercise.
			 */
			name: props.exercise ? props.exercise.name : '',
			/**
			 * What type of exercise is it?
			 */
			selectedExerciseTypeId: 1,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			category: nextProps.category,
			exercise: nextProps.exercise,
			
			name: nextProps.exercise ? nextProps.exercise.name : '',
			selectedExerciseTypeId: nextProps.exercise ? nextProps.exercise.exerciseTypeId : 1,
			
			...this.getMusclesStateFromExercise(nextProps.exercise)
		});
	}
	
	getMusclesStateFromExercise = (exercise) => {
		if (!exercise) {
			return {};
		}
		
		let state = {};

		let i = 0;
		for (const exampleMuscle of exercise.exampleMuscles) {
			state[this.getStateKeyForMuscleId(i)] = exampleMuscle.muscle.id;
			state[this.getStateKeyForMusclePercentage(i)] = exampleMuscle.percentage;
			i++;
		}
		
		return state;
	}

	updateStateInputValues = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	submit = async (event) => {
		event.preventDefault();
		
		let muscles = [];
		
		[0, 1, 2, 3, 4].forEach(num => {
			if (this.state[`musclePercentage${num}`] && this.state[`musclePercentage${num}`] > 0) {
				muscles.push({
					muscleId: this.state[this.getStateKeyForMuscleId(num)] ?? 1, // Fallback to Triceps,
					percentage: this.state[this.getStateKeyForMusclePercentage(num)],
				});
			}
		})

		let exampleExerciseToSave = {
			name: this.state.name,
			exerciseTypeId: this.state.selectedExerciseTypeId,
			exampleMuscles: muscles,
		};

		if (this.state.exercise) { // When updating
			exampleExerciseToSave['id']= this.state.exercise.id;
		}

		if (this.state.category) { // When new (so category cannot change).
			exampleExerciseToSave['exampleExerciseCategoryId']= this.state.category.id;
		}

		let res = await fetch(UrlBuilder.exercise.exerciseFormExamplesApi(), {
			method: this.state.category ? 'POST' : 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(exampleExerciseToSave)
		});

		this.props.closeSelfFunc(true);
	}

	getStateKeyForMuscleId = (num) => {
		return `muscle${num}`;
	}

	getStateKeyForMusclePercentage = (num) => {
		return `musclePercentage${num}`;
	}
	
	render() {
		const {classes} = this.props;

		if (!this.state.category && !this.state.exercise) {
			return null;
		}

		return <Dialog
			open={true}
			onClose={() => this.props.closeSelfFunc(false)}
			closeAfterTransition
		>
			<DialogTitle>
				{this.state.category ?
					<>Add exercise for {this.state.category.name}</>
					:
					<>Edit exercise {this.state.exercise.name}</>
				}
				

				<IconButton edge="end" size='small' color="inherit" onClick={() => this.props.closeSelfFunc(false)}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			
			<DialogContent>
				<FormControl fullWidth size={'small'} variant="standard">
					<InputLabel htmlFor="name">Name</InputLabel>
					<Input
						required
						fullWidth
						id="name"
						label="Name"
						autoComplete='off'
						name="name"
						autoFocus
						onChange={this.updateStateInputValues}
						value={this.state.name}
					/>
				</FormControl>
				
				<FormControl className={classes.formControl}>
					<InputLabel shrink>
						Type
					</InputLabel>
					<Select
						name="selectedExerciseTypeId"
						id="selectedExerciseTypeId"
						value={this.state.selectedExerciseTypeId}
						onChange={this.updateStateInputValues}
					>
						<MenuItem value={ExerciseType.WeightAndReps}>Weight & Reps</MenuItem>
						<MenuItem value={ExerciseType.WeightAndTime}>Weight & Time</MenuItem>
						<MenuItem value={ExerciseType.Reps}>Reps</MenuItem>
						<MenuItem value={ExerciseType.DistanceAndTime}>Distance & Time</MenuItem>
						<MenuItem value={ExerciseType.Time}>Time</MenuItem>
					</Select>
				</FormControl>
				
				<br/>
				<br/>

				<FormControl className={classes.formControl}>
					{[0, 1, 2, 3, 4].map(num => {
						const muscle = this.getStateKeyForMuscleId(num);
						const musclePercentage = this.getStateKeyForMusclePercentage(num)
						
						return <Grid container direction='row'>
							<Grid item>
								<Select
									name={muscle}
									id={muscle}
									required
									value={this.state[muscle] ?? 1} // Fallback to 1 (Triceps, since that one won't be initialized)
									onChange={this.updateStateInputValues}
								>
									<MenuItem value={1}>Triceps</MenuItem>
									<MenuItem value={2}>Biceps</MenuItem>
									<MenuItem value={3}>Forearms</MenuItem>
									<MenuItem value={4}>Shoulders</MenuItem>
									<MenuItem value={5}>Chest</MenuItem>
									<MenuItem value={6}>Lats</MenuItem>
									<MenuItem value={7}>Traps</MenuItem>
									<MenuItem value={8}>Quads</MenuItem>
									<MenuItem value={9}>Hamstrings</MenuItem>
									<MenuItem value={10}>Calves</MenuItem>
									<MenuItem value={11}>Glutes</MenuItem>
									<MenuItem value={12}>Core</MenuItem>
								</Select>
							</Grid>
							&nbsp;
							<Grid item>
								<Input
									required
									fullWidth
									id={musclePercentage}
									name={musclePercentage}
									type='number'
									label="Percentage (0-100)"
									autoComplete='off'
									onChange={this.updateStateInputValues}
									value={this.state[musclePercentage]}
								/>
							</Grid>
						</Grid>
					})}
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" color='grey' onClick={() => this.props.closeSelfFunc(false)}>
					Close
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={this.submit}
					type="submit"
					value="Submit"
					disabled={!this.state.name}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>;
	}
}

export default withStyles(useStyles)(ExampleExerciseNewOrEditFormPopUp);