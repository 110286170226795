import React, {Component} from 'react';
import {UrlBuilder} from "../../../core/url/UrlBuilder";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import moment from "moment";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ColorSelector from "../../core/utils/ColorSelector";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import {blue} from "@mui/material/colors";

const useStyles = theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 100,
	},
	grow: {
		flexGrow: 1,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class RoutineNewOrEditFormBloop extends Component {
	/**
	 * @param props Containing:
	 *      'isOpen': Says whether this modal is open.
	 *      'closeSelfFunc': The function to call to close this popup.
	 */
	constructor(props) {
		super(props);

		this.state = {
			/**
			 * The routine to edit, if any. Otherwise the form will try to create one instead.
			 */
			routine: this.props.routine,
			/**
			 * 'isOpen': Says whether this bloop is open.
			 */
			isOpen: false,
			/**
			 * Is it currently being submitted?
			 */
			isSubmissionLoading: false,
			/**
			 * The name of the routine
			 */
			name : this.props.routine ? this.props.routine.name : '',
			/**
			 * The description of the routine.
			 */
			description : this.props.routine ? this.props.routine.description : '',
			/**
			 * The routine color.
			 */
			color: props.routine?.color ?? (props.routine ? ColorPicker.pick(props.routine.name) : blue[500]),
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.state.isOpen !== Boolean(nextProps.isOpen)) {
			this.setState({
				isOpen: Boolean(nextProps.isOpen),
				routine: nextProps.routine,
				name : nextProps.routine ? nextProps.routine.name : '',
				color: nextProps.routine?.color ?? (nextProps.routine ? ColorPicker.pick(nextProps.routine.name) : blue[500]),
				description : nextProps.routine ? nextProps.routine.description : '',
			});
		}
	}

	submitWithEnter = (e) => {
		if (e.key==='Enter') {
			this.submit(e);
		}
	}

	submit = async (event) => {
		event.preventDefault();

		this.setState({
			isSubmissionLoading: true,
		});

		let routineToSave = {
			Name: this.state.name,
			Description: this.state.description,
			Color: this.state.color,
			dateCreated: moment().format('L, h:mm:ss A')
		};

		if (this.state.routine) {
			routineToSave['id'] = this.state.routine.id; // Only necessary when editing an existing one

			await fetch(UrlBuilder.routine.routinesApi(), {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(routineToSave)
			})
				.then(response => response.json())
				.then(routine => this.props.closeSelfFunc(true));
		} else {
			await fetch(UrlBuilder.routine.routinesApi(), {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(routineToSave)
			})
				.then(this.props.closeSelfFunc);
		}

		this.setState({
			isSubmissionLoading: false,
		});
	}

	updateStateInputValues = (event) => {
		let state = {
			[event.target.name]: event.target.value,
		};

		if (event.target.name === 'name') {
			state['color'] = ColorPicker.pick(event.target.value);
		}

		this.setState(state);
	}

	updateStateInputColorValue = (color) => {
		this.setState({
			color: color,
		});
	}

	render() {
		const {classes} = this.props;

		return (
            <Dialog open={this.state.isOpen} onClose={() => this.props.closeSelfFunc(false)} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} enableColorOnDark>
					<Toolbar variant='dense'>
						<Typography variant="h6" noWrap className={classes.title}>
							{this.state.routine ? 'Edit ' + this.state.routine.name + ' routine' : 'Create A New Routine'}
						</Typography>

						<div className={classes.grow}/>

						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.props.closeSelfFunc(false)}
                            size="large">
							<CloseIcon/>
						</IconButton>
					</Toolbar>
				</AppBar>

				<DialogContent dividers>
					<Container maxWidth='md' disableGutters>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="name"
							label="Name"
							name="name"
							value={this.state.name}
							onChange={this.updateStateInputValues}
							onKeyPress={this.submitWithEnter}
							placeholder="e.g. Upper body"
						/>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							name="description"
							label="Description (optional)"
							type="text"
							id="description"
							value={this.state.description}
							onChange={this.updateStateInputValues}
							onKeyPress={this.submitWithEnter}
							placeholder="e.g. My summer upper body routine"
						/>

						<Box paddingTop={0.75}/>

						<Typography variant='subtitle1' color='textSecondary' style={{ fontSize: 12 }}>
							&nbsp; Color
						</Typography>

						<ColorSelector color={this.state.color} updateColorValueParentFunc={this.updateStateInputColorValue}/>
					</Container>
				</DialogContent>

				<DialogActions>
					<Button variant="contained" color='grey' className={classes.button} onClick={() => this.props.closeSelfFunc(false)}>Close</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={this.submit}
						type="submit"
						value="Submit"
						disabled={this.state.isSubmissionLoading || this.state.name.trim() === ''}
					>
						{this.state.isSubmissionLoading ?
							<div>
								<CircularProgress size="1rem" color="inherit" /> Loading...
							</div> :
							'Save Routine'
						}
					</Button>
				</DialogActions>
			</Dialog>
        );
	}
}

export default withStyles(useStyles)(RoutineNewOrEditFormBloop);