import React, {PureComponent} from 'react'
import Typography from "@mui/material/Typography";
import { blue, green, purple, yellow, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import { HexColorPicker } from "react-colorful";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import {Popover} from "@mui/material";
import SmallBadge from "../custom/SmallBadge";
import CreateIcon from '@mui/icons-material/Create';

class ColorSelector extends PureComponent {
	/**
	 * @param props Containing:
	 *      'color': The color.
	 *      'updateColorValueParentFunc': The function to call to update the color.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The color.
			 */
			color: props.color ?? blue[500],
			/**
			 * Is the custom color picker open?
			 */
			customColorPickAnchorEl: null,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			color: nextProps.color ?? blue[500],
		})
	}

	updateStateInputColorValue = (color) => {
		this.props.updateColorValueParentFunc(color);
	}

	openCustomColorPicker = (event) => {
		this.setState({
			customColorPickAnchorEl: event.currentTarget,
		});
	}

	closeCustomColorPicker = () => {
		this.setState({
			customColorPickAnchorEl: null,
		});
	}

	renderCustomColorPicker = () => {
		return <Popover
			open={this.state.customColorPickAnchorEl !== null}
			anchorEl={this.state.customColorPickAnchorEl}
			onClose={this.closeCustomColorPicker}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Card>
				<CardHeader subheader={<Typography variant='h6'>Pick Custom Color</Typography>}/>

				<Divider light/>

				<CardContent>
					<HexColorPicker color={this.state.color} onChange={this.updateStateInputColorValue}/>
				</CardContent>
			</Card>

		</Popover>;
	}

	render() {
		return (
			<>
				{this.renderCustomColorPicker()}
				
				<Grid container direction='row' alignItems='space-between' alignContent='center'>
					<Grid item>
						<IconButton size='small' onClick={() => this.updateStateInputColorValue(blue[500])}>
							<Avatar style={{backgroundColor: blue[500]}}>{this.state.color === blue[500] ? <CheckIcon/> : ''}</Avatar>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton size='small' onClick={() => this.updateStateInputColorValue(green[500])}>
							<Avatar style={{backgroundColor: green[500]}}>{this.state.color === green[500] ? <CheckIcon/> : ''}</Avatar>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton size='small' onClick={() => this.updateStateInputColorValue(red[500])}>
							<Avatar style={{backgroundColor: red[500]}}>{this.state.color === red[500] ? <CheckIcon/> : ''}</Avatar>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton size='small' onClick={() => this.updateStateInputColorValue(yellow[600])}>
							<Avatar style={{backgroundColor: yellow[600]}}>{this.state.color === yellow[600] ? <CheckIcon/> : ''}</Avatar>
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton size='small' onClick={this.openCustomColorPicker}>
							<SmallBadge color={'rgba(50,50,50,0.4)'} icon={<CreateIcon style={{ fontSize: '1.15rem'}}/>}>
								<Avatar
									style={{backgroundColor:
											this.state.color !== blue[500] &&
											this.state.color !== green[500] &&
											this.state.color !== red[500] &&
											this.state.color !== yellow[600] ? this.state.color : '#9f9f9f'
									}}
								>
									{
										this.state.color !== blue[500] &&
										this.state.color !== green[500] &&
										this.state.color !== red[500] &&
										this.state.color !== yellow[600] ? <CheckIcon/> : '...'
									}
								</Avatar>
							</SmallBadge>
						</IconButton>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default ColorSelector;