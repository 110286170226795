import React, {PureComponent} from 'react'
import withStyles from '@mui/styles/withStyles';
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {DatePrettifier} from "../../../core/prettifier/DatePrettifier";
import Typography from "@mui/material/Typography";
import {Avatar} from "@mui/material";
import {ColorPicker} from "../../../core/colors/ColorPicker";
import LinkIcon from '@mui/icons-material/Link';
import CardActionArea from "@mui/material/CardActionArea";
import SupersetBadge from "../../core/custom/SupersetBadge";
import InputIcon from '@mui/icons-material/Input';
import {withRouter} from "../../../hooks/withRouter";
import ArchiveIcon from "@mui/icons-material/Archive";

const useStyles = theme => ({
	floatRight: {
		float: 'right'
	},
	grow: {
		flexGrow: 1,
	},
});

/**
 * A card that can be popped up into fullscreen mode.
 */
class RoutineExerciseDetailCard extends PureComponent {
	/**
	 * @param props Containing:
	 *      'routine': The routine.
	 *      'exercise': The exercise that belongs to the routine.
	 *      'deleteSelfFunc': Deletes itself.
	 *      'openMoveRoutineExerciseDialog': Open dialog to move this exercise to another routine.
	 *      'archiveRoutineExercise': Archives an exercise
	 *      'isInArchivedMode': Are we in archive mode?
	 *      'openSupersetMenu': Opens the superset menu.
	 *      'setBloopOpen': Sets the bloop open with the exercise in this card.
	 *      'dragHandle': The handle icon for dragging this card to reorder it.
	 */
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The routine.
			 */
			routine: this.props.routine,
			/**
			 * The exercise that belongs to the routine.
			 */
			exercise: this.props.exercise,
			/**
			 * The dropdown menu for each routine card.
			 */
			dropDownMenuAnchorEl: null,
			/**
			 * In archive mode?
			 */
			isInArchivedMode: props.isInArchivedMode,
		};
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			exercise: nextProps.exercise,
			isInArchivedMode: nextProps.isInArchivedMode,
		});
	}

	openDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: event.currentTarget,
		});
	};

	closeDropdownMenu = (event) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
	};
	
	deleteSelf = (e) => {
		e.stopPropagation();

		this.setState({
			dropDownMenuAnchorEl: null
		});
		
		this.props.deleteSelfFunc(this.state.exercise);
	}

	openMoveRoutineExerciseDialog = (e) => {
		e.stopPropagation();

		this.setState({
			dropDownMenuAnchorEl: null
		});

		this.props.openMoveRoutineExerciseDialog(this.state.exercise);
	}

	openSupersetMenu = (e) => {
		e.stopPropagation();

		this.setState({
			dropDownMenuAnchorEl: null
		});
		
		this.props.openSupersetMenu(this.state.exercise);
	}

	archiveRoutineExercise = async (event, shouldArchive) => {
		event.stopPropagation();
		this.setState({
			dropDownMenuAnchorEl: null
		});
		this.props.archiveRoutineExercise(shouldArchive);
	}

	render() {
		const {classes} = this.props;

		let subheader = this.state.exercise.dateLastLogged ?
			DatePrettifier.prettifyLastTrained(new Date(this.state.exercise.dateLastLogged))
			: null;

		if (this.state.exercise.description) {
			if (subheader) {
				subheader += ' — ' + this.state.exercise.description;
			} else {
				subheader = this.state.exercise.description;
			}
		}

		return (
			<Card variant={'outlined'}>
				<CardActionArea>
				<CardHeader
					title={
						<Typography variant="button" display="block">
							{this.state.exercise.name}
						</Typography>
					}
					avatar={
						this.state.exercise.superset ?
							<SupersetBadge color={this.state.exercise.superset.color}>
								<Avatar style={{backgroundColor: this.state.exercise.color ?? ColorPicker.pick(this.state.exercise.name)}}>
									{this.state.exercise.name.charAt(0).toUpperCase()}
								</Avatar>
							</SupersetBadge>
							:
							<Avatar style={{backgroundColor: this.state.exercise.color ?? ColorPicker.pick(this.state.exercise.name)}}>
								{this.state.exercise.name.charAt(0).toUpperCase()}
							</Avatar>
					}
					subheader={subheader}
					action={
						<span>
							<IconButton
								id={this.state.exercise.routineExerciseId}
								size="small"
								className={classes.floatRight}
								onClick={this.openDropdownMenu}
							>
								<MoreVertIcon/>
							</IconButton>
							<Menu
								id={this.state.exercise.routineExerciseId}
								anchorEl={this.state.dropDownMenuAnchorEl}
								open={Boolean(this.state.dropDownMenuAnchorEl && this.state.dropDownMenuAnchorEl.id == this.state.exercise.routineExerciseId)}
								onClose={this.closeDropdownMenu}
								TransitionComponent={Fade}
							>
								{this.state.isInArchivedMode ?
									<div>
										<MenuItem onClick={(e) => this.archiveRoutineExercise(e, false)}>
											<ListItemIcon>
												<ArchiveIcon fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary='Unarchive'/>
										</MenuItem>
										<MenuItem onClick={this.deleteSelf}>
											<ListItemIcon>
												<DeleteIcon fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary="Delete"/>
										</MenuItem>
									</div>
									: 
									<div>
										<MenuItem onClick={(e) => this.archiveRoutineExercise(e, true)}>
											<ListItemIcon>
												<ArchiveIcon fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary='Archive'/>
										</MenuItem>
										<MenuItem onClick={this.openMoveRoutineExerciseDialog}>
											<ListItemIcon>
												<InputIcon fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary="Move"/>
										</MenuItem>
										<MenuItem onClick={this.openSupersetMenu}>
											<ListItemIcon>
												<LinkIcon fontSize="small"/>
											</ListItemIcon>
											<ListItemText primary={this.state.exercise.superset ? 'Edit Superset' : 'Add Superset'}/>
										</MenuItem>
									</div>
								}
								
								
							</Menu>
						</span>
					}
					onClick={() => this.props.setBloopOpen(this.state.exercise)}
				/>
				</CardActionArea>
				
				<Divider/>

				<CardActions disableSpacing>
					<Button type="button" onClick={() => this.props.setBloopOpen(this.state.exercise)}>Open</Button>
					<div className={classes.grow}/>
					{this.props.dragHandle}
				</CardActions>
			</Card>
		);
	}
}

export default withStyles(useStyles)(withRouter(RoutineExerciseDetailCard));